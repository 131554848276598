/* eslint-disable no-unused-vars */
import { useEffect, useState } from "react";
import Title from "../../common/Title";
import { isData, isPermission } from "../../../redux/selectors/authSelector";
import { useDispatch, useSelector } from "react-redux";
import Link from "../../common/Link";
import '../../../styles/Profile.scss';
import { Tabs, Tab } from "react-bootstrap";
import { EditProfile } from "./Edit_profile";
import { UpdatePassword } from "./Password";
import { GameSetting } from "./Game_setting";
import { CMSSetting } from "./CMS_setting";
import { BankDetails } from "./Bank_details";
import { AgentRequestForm } from "./Agent_request_form";
import { Get } from "../../../utils/api.service";
import HttpService from "../../../utils/http.service";
import { loginOverride } from "../../../redux/actions/authActions";
import Breadcrumb from "../../common/Breadcrumb";
import { CheckPermission } from "../../../utils/Helper";


const Profile = () => {
  const data = useSelector(isData);
  const [image, setimage] = useState(data.user.profile_pic);
  const [key, setKey] = useState(window.sessionStorage.getItem('profileTabs') ? window.sessionStorage.getItem('profileTabs') : 'profile');
  const permission = useSelector(isPermission);
  const dispatch = useDispatch();

  useEffect(() => {
    setimage(data.user.profile_pic);
  }, [data]);

  // useEffect(() => {
  //   Get(HttpService.Admin_profile_get).then((res) => {
  //     if (res.status === 200) {
  //       dispatch(loginOverride(res.data));
  //     }
  //   })
  // }, []);

  return (
    <>
      <Title name='Profile' />
      <h4>Profile</h4>
      <Breadcrumb
        active="Profile"
      />

      <div className="row profile-page">
        <div className="col-lg-3">
          <div className="card">
            <p className="card-header">Profile</p>
            <div className="card-body">
              <div className="user">
                <img src={image} alt={data.user.name} />
                <p>{data?.user?.username}</p>
                <div className="details">
                  <span><i className="mdi mdi-account" />{data.user.name}</span>
                  <span><i className="mdi mdi-email" />{data.user.email}</span>
                  <span><i className="mdi mdi-phone" />{data.user.mobile}</span>
                  {/* <Link to={'/'} className='btn btn-danger'>Logout</Link> */}
                </div>
              </div>
            </div>
          </div>
        </div>


        <div className="col-lg-9">
          <div className="card tabspage">
            <Tabs
              activeKey={key}
              onSelect={(k) => { window.sessionStorage.setItem('profileTabs', k); setKey(k) }}
            >
              <Tab eventKey="profile" title="Profile" disabled={!CheckPermission(permission, '/profile/edit')}>
                {key === 'profile' && CheckPermission(permission, '/profile/edit') && <EditProfile />}
              </Tab>
              <Tab eventKey="cms" title="CMS Settings" disabled={!CheckPermission(permission, '/cms')}>
                {key === 'cms' && CheckPermission(permission, '/cms') && <CMSSetting />}
              </Tab>
              {/* <Tab eventKey="agentreqform" title="Agent Request Form" disabled={!CheckPermission(permission, '/profile/edit')}>
                {key === 'agentreqform' && <AgentRequestForm />}
              </Tab> */}
              <Tab eventKey="password" title="Change Password" disabled={!CheckPermission(permission, '/profile/password')}>
                {key === 'password' && CheckPermission(permission, '/profile/password') && <UpdatePassword />}
              </Tab>
              {(data?.user?.role_id?.type == 'admin' || data?.user?.role_id?.type == 'subadmin') ?
                <Tab eventKey="bankdetails" title="Bank Details" disabled={!CheckPermission(permission, '/profile/bankdetails')}>
                  {key === 'bankdetails' && CheckPermission(permission, '/profile/bankdetails') && <BankDetails />}
                </Tab>
                : ''}
              {/* <Tab eventKey="game" title="Game Configuration" disabled={!CheckPermission(permission, '/profile/edit')}>
                {key === 'game' && <GameSetting />}
              </Tab> */}
            </Tabs>
          </div>
        </div>
      </div>
    </>
  )
}

export { Profile }