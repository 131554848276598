import Title from "../../common/Title";
import { useNavigate, useParams } from "react-router-dom";
import Link from "../../common/Link";
import { useEffect, useState } from "react";
import HttpService from "../../../utils/http.service";
import { Postdata } from "../../../utils/api.service";
import { ConvertToDMY, ConvertToDMYHIS } from "../../../utils/Helper";
import { useDispatch } from "react-redux";
import Loader from "../../common/Loader";
import Breadcrumb from "../../common/Breadcrumb";
import { Tab, Tabs } from "react-bootstrap";
import DataTableBase from "../../common/DataTableBase";
import { message } from "../../../redux/actions/toastActions";
import CSV from "../../common/CSV";


const GameManagementView = () => {
  const [key, setKey] = useState('subscribe');
  const topnames = {
    gameview: 'Game View',
    subscribe: 'Subscribed',
  };
  return (
    <>
      <Title name='Game Management' />
      <h4>Game Management</h4>
      <Breadcrumb
        pages={[
          { title: 'Settings', link: 'settings' },
          { title: 'Game Management', link: 'settings' }
        ]}
        active={topnames[key]}
      />

      <div className="row">
        <div className="col-lg-3">
          <div className="card">
            <p className="card-header">Game View</p>
            <div className="card-body">
              <GameView />
            </div>
          </div>
        </div>
        <div className="col-lg-9">
          <div className="card tabspage">
            <Tabs
              activeKey={key}
              onSelect={(k) => setKey(k)}
            >
              <Tab eventKey="subscribe" title="Subscribed">
                {key === 'subscribe' && <SubscribedList />}
              </Tab>
            </Tabs>
          </div>
        </div>
      </div>
    </>
  );

}

const GameView = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const navigate = useNavigate();

  const [totalSubscribed, settotalSubscribed] = useState(0);
  const [apiloader, setapiloader] = useState(false);
  const [readmore, setreadmore] = useState(true);
  const [data, setdata] = useState({});

  useEffect(() => {
    async function fetchData(id) {
      const count = await Postdata(HttpService.Game_subscribed, { game_id: id, page: 1, perpage: 10 });
      if (count.status === 200) {
        settotalSubscribed(count.data.count);
      }

      const response = await Postdata(HttpService.Game_show, {
        id: id
      }, dispatch);
      if (response.status !== 200) {
        navigate("/settings")
      }
      setdata(response.data);
      setapiloader(false);
    }
    if (params.id) {
      setapiloader(true);
      fetchData(params.id);
    }
  }, [])

  return (
    <>
      {apiloader ?
        <Loader />
        :
        <div className="row profile-page">
          <div className="col-md-12">
            <div className="user">
              <img src={data.image} alt={data.title} />
              <p>{data.title}</p>
              <div className="details removeStyle">
                <span><b>Game Type:</b>{data.gametype_id ? data.gametype_id.name : ''}&nbsp;</span>
                <span><b>Description:</b>
                  {data.description ?
                    readmore
                      ?
                      <>
                        {data.description.toString().substring(0, 50)}...
                        <i className="readmore" onClick={() => setreadmore(!readmore)}>Read more</i>
                      </>
                      :
                      <>
                        {data.description}
                        <i className="readmore" onClick={() => setreadmore(!readmore)}>View less</i>
                      </>
                    : null}
                  &nbsp;</span>
                <span><b>Launch Date:</b>{ConvertToDMY(data.launch_date)}&nbsp;</span>
                <span><b>Target Link:</b>{data.target_link}&nbsp;</span>
                <span><b>Total Subscribed:</b>{totalSubscribed}&nbsp;</span>
                <span><b>Total Played:</b>{data.total_played ? data.total_played : 0}&nbsp;</span>
                <span><b>Want to check excitement?</b>{data.is_subscribe ? 'Yes' : 'No'}</span>
                <span><b>Make it Live?</b>{data.is_live ? 'Yes' : 'No'}</span>
                <Link to={'/settings'} className='btn btn-danger'>Go back</Link>
              </div>
            </div>
          </div>
        </div>
      }
    </>
  )
}


const SubscribedList = () => {
  const [subscribedata, setsubscribedata] = useState([]);
  const [isloading, setIsloading] = useState(true);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setperPage] = useState(process.env.REACT_APP_RecordPerPage);
  const [currentPage, setcurrentPage] = useState(1);
  const dispatch = useDispatch();
  const params = useParams();

  useEffect(() => {
    setIsloading(true);
    Postdata(HttpService.Game_subscribed, {
      game_id: params.id,
      page: currentPage,
      perpage: perPage,
    }).then((response) => {
      if (response.status === 200) {
        setsubscribedata(response.data.games);
        setTotalRows(response.data.count);
        setIsloading(false);
      } else {
        setIsloading(false);
        dispatch(message(response.message));
      }
    }).catch((e) => {
      setIsloading(false);
      dispatch(message(e.message));
    });
  }, [currentPage, perPage]);

  const handlePerRowsChange = async (newPerPage, page) => {
    setperPage(newPerPage);
    setcurrentPage(page);
  };

  const handlePageChange = (page) => {
    setcurrentPage(page);
  };

  const columns = [
    {
      name: '#',
      width: "55px",
      selector: (row, i) => (currentPage * perPage + (i + 1) - perPage),
    },
    {
      name: 'User ID',
      selector: row => row.user_id.user_id,
    },
    {
      name: 'Username',
      selector: row => row.user_id.username,
    },
    {
      name: 'Subscribed At',
      selector: row => ConvertToDMYHIS(row.updatedAt),
    },
  ];

  return (
    <>
      <div className="card-body filters">
        <div className="row searchaction">
          <div className="col-lg-6">
            <h5>Subscribed</h5>
          </div>
          <div className="col-lg-6 text-end">
            <CSV
              link={HttpService.Game_subscribed}
              extra={{
                game_id: params.id
              }}
              name="Game Subscribed"
              response="games"
              headers={[
                { label: "User ID", key: "user_id.user_id" },
                { label: "Username", key: "user_id.username" },
                { label: "Subscribed At", key: "updatedAt" },
              ]}
            />
          </div>
        </div>
        <DataTableBase
          columns={columns}
          loader={isloading}
          data={subscribedata}
          paginationTotalRows={totalRows}
          onChangeRowsPerPage={handlePerRowsChange}
          onChangePage={handlePageChange}
        />
        {/* <Link to={'/settings'} className='btn btn-danger'>Go back</Link> */}
      </div>
    </>
  );
}

export { GameManagementView }