/* eslint-disable react/prop-types */
import '../../../styles/Profile.scss';
import DataTableBase from '../../common/DataTableBase';
import React, { useState, useEffect } from 'react';
import { Postdata } from '../../../utils/api.service';
import HttpService from '../../../utils/http.service';
import { useDispatch } from 'react-redux';
import { message } from '../../../redux/actions/toastActions';
import CSV from '../../common/CSV';

const Commission = (props) => {
  const [isloading, setIsloading] = useState(true);
  const [data, setData] = useState([]);
  const dispatch = useDispatch();

  const columns = [
    {
      name: '#',
      width: "55px",
      selector: (row, i) => (i + 1)
    },
    {
      name: 'Game Name',
      selector: row => row.name,
    },
    {
      name: 'Commission (%)',
      selector: row => row.commission ? row.commission : 0,
    },
  ];

  useEffect(() => {
    setIsloading(true);
    Postdata(HttpService.User_game_commission, {
      id: props.id,
    }).then((response) => {
      if (response.status === 200) {
        setData(response.data);
        setIsloading(false);
      } else {
        setIsloading(false);
        dispatch(message(response.message));
      }
    }).catch((e) => {
      setIsloading(false);
      dispatch(message(e.message));
    });
  }, []);

  return (
    <>
      <div className="card-body filters">
        <div className="row searchaction">
          <div className="col-lg-6">
            <h5>Commission (%)</h5>
          </div>
          <div className="col-lg-6 text-end">
            <CSV
              link={HttpService.User_game_commission}
              name="Agent : Commission"
              headers={[
                { label: "Game Name", key: "name" },
                { label: "Commission(%)", key: "commission" }
              ]}
              extra={{
                id: props.id,
              }}
            />
          </div>
        </div>

        <DataTableBase
          columns={columns}
          loader={isloading}
          pagination={false}
          data={data}
        />
      </div>
    </>
  )
}

export { Commission }