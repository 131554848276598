import Title from "../../common/Title";
import { Form } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import HttpService from "../../../utils/http.service";
import { Postdata } from "../../../utils/api.service";
import { useDispatch } from "react-redux";
import { message } from "../../../redux/actions/toastActions";
import Loader from "../../common/Loader";
import Breadcrumb from "../../common/Breadcrumb";
import Link from "../../common/Link";

const SubadminManagementAdd = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const navigate = useNavigate();
  const [loader, setLoader] = useState(false);
  const [apiloader, setapiloader] = useState(false);
  const [permissions, setPermissions] = useState([]);
  const [formdata, setFormdata] = useState({
    username: '',
    email: '',
    password: '123456',
    status: 1,
    admin_modules: [],
    current_permissions: [],
  });
  const [checktick, setchecktick] = useState([]);

  useEffect(() => {
    async function fetchData(id) {
      const response = await Postdata(HttpService.Subadmin_show, {
        id: id
      });
      if (response.status !== 200) {
        navigate("/subadmin-management")
      }
      setapiloader(false);
      setFormdata({
        id: params.id,
        username: (response.data.user.username != undefined) ? response.data.user.username : '',
        email: (response.data.user.email != undefined) ? response.data.user.email : '',
        password: (id != 'show') ? '' : formdata.password,
        status: (response.data.user.status != undefined) ? response.data.user.status : 1,
        admin_modules: response.data.admin_modules,
        current_permissions: (response.data.current_permissions != undefined) ? response.data.current_permissions : [],
      })
      const checkTick = [];
      response?.data?.admin_modules?.map(e => e.permissions.map(d => checkTick.push({ key: d._id, id: [d.admin_module_id, d.name] })))
      setchecktick(checkTick)
      setPermissions(response.data.current_permissions);
    }
    if (params.id) {
      setapiloader(true);
      fetchData(params.id);
    }
  }, [])

  const onAddSubadminAction = (event) => {
    event.preventDefault();
    setLoader(true);
    // delete formdata.admin_modules
    // delete formdata.current_permissions

    formdata.permissions = []
    for (let i = 0; i < permissions.length; i++) {
      formdata.permissions[i] = {
        permission_id: permissions[i],
        type: 'all'
      };
    }

    formdata.permissions = JSON.stringify(formdata.permissions)
    Postdata(HttpService.Subadmin_add, {
      username: formdata.username,
      id: formdata.id,
      email: formdata.email,
      password: formdata.password,
      status: formdata.status,
      permissions: formdata.permissions,
    }).then((res) => {
      if (res.status === 200) {
        navigate("/subadmin-management");
      }
      setLoader(false);
      dispatch(message(res.message));
    }).catch(e => {
      dispatch(message(e.message));
      setLoader(false);
      navigate("/subadmin-management");
    });
  };


  const handleCheck = (event) => {
    const mykey = checktick.filter(k => k.key === event.target.value)[0].id;

    var permissions_array = [...permissions];
    if (event.target.checked) {
      permissions_array = [...permissions_array, event.target.value];
      if (['store', 'delete'].includes(mykey[1])) permissions_array = [...permissions_array, formdata.admin_modules.filter(e => e._id === mykey[0])[0].permissions.filter(v => v.name === 'view')[0]._id];
    } else {
      // permissions_array.splice(permissions.indexOf(event.target.value), 1);
      permissions_array = permissions_array.filter(v => v !== event.target.value);
      if (mykey[1] === 'view') formdata.admin_modules.filter(e => e._id === mykey[0])[0].permissions.map(v => permissions_array = permissions_array.filter(d => d !== v._id))
    }
    permissions_array = [...new Set(permissions_array)]
    setPermissions(permissions_array);
  };

  return (
    <>
      <Title name='Subadmin Management - Add' />
      <div className="row">
        <div className="col-lg-6">
          <h4>Subadmin Management</h4>
          <Breadcrumb
            pages={[
              { title: 'Subadmin Management', link: 'subadmin-management' }
            ]}
            active={`${params.id !== 'show' ? ((window.location.search) ? 'View' : 'Edit') : 'Add'}`}
          />
        </div>
      </div>

      <div className="row">
        <div className="col-lg-12">
          <div className="card">
            <p className="card-header">{params.id !== 'show' ? ((window.location.search) ? 'View' : 'Edit') : 'Add'} Subadmin</p>
            <div className="card-body">
              {apiloader ?
                <Loader /> :
                <form onSubmit={onAddSubadminAction}>
                  <Form.Group className="form-group">
                    <Form.Label>Username*</Form.Label>
                    <Form.Control
                      type="text"
                      onChange={e => setFormdata({ ...formdata, username: (e.target.value).toString().replace(/[^a-zA-Z0-9]+/, "").trim() })}
                      pattern="[a-zA-Z0-9]{5,15}"
                      onInvalid={e => e.target.setCustomValidity('Username must be between 5 to 15 characters')}
                      onInput={e => e.target.setCustomValidity('')}
                      disabled={window.location.search}
                      value={formdata.username}
                      required
                    />
                  </Form.Group>

                  <Form.Group className="form-group">
                    <Form.Label>Email</Form.Label>
                    <Form.Control
                      type="email"
                      disabled={window.location.search}
                      value={formdata.email}
                      onChange={e => setFormdata({ ...formdata, email: e.target.value })}
                    // required
                    />
                  </Form.Group>

                  <Form.Group className="form-group">
                    <Form.Label>Password{params.id ? '' : '*'}</Form.Label>
                    <Form.Control
                      type="password"
                      disabled={window.location.search}
                      value={formdata.password}
                      onChange={e => setFormdata({ ...formdata, password: e.target.value })}
                      required={(params.id && params.id != 'show') ? false : true}
                    />
                    {(params.id && params.id != 'show') ? <small></small> :
                      <small>Default Password is : 123456</small>
                    }
                  </Form.Group>

                  <Form.Group className="form-group">
                    <Form.Label>Status</Form.Label>
                    <Form.Check
                      type="switch"
                      disabled={window.location.search}
                      checked={formdata.status}
                      onChange={e => setFormdata({ ...formdata, status: Number(e.target.checked) })}
                    />
                  </Form.Group>

                  <Form.Group className="form-group">
                    <Form.Label>Permissions</Form.Label>
                    <table className="table">
                      <thead>
                        <tr>
                          <th>Modules</th>
                          <th className="text-center">Save/Update</th>
                          <th className="text-center">View</th>
                          {/* <th>Delete</th> */}
                        </tr>
                      </thead>
                      <tbody>

                        {(formdata.admin_modules)?.map((admin_module, index) => (

                          <tr data-index={index} key={admin_module._id}>
                            <td>{admin_module.name}</td>

                            {(admin_module.permissions)?.map((permission, p_index) => (

                              <td data-index={p_index} key={permission._id}>

                                <Form.Check
                                  type="checkbox"
                                  value={permission._id}
                                  checked={permissions.includes(permission._id)}
                                  className="text-center"
                                  disabled={window.location.search}
                                  // defaultChecked={formdata.current_permissions.includes(permission._id) ? true : false}
                                  onChange={handleCheck}
                                />

                              </td>
                            ))}
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </Form.Group>

                  {!window.location.search ?
                    <>
                      <button className="btn btn-primary" type="submit" disabled={loader}>
                        {loader ? 'Please wait...' : ((formdata.id && formdata.id != 'show') ? 'Update' : 'Submit')}
                      </button>
                      <Link className="btn btn-danger" to={'/subadmin-management'}>Cancel</Link>
                    </>
                    :
                    <Link className="btn btn-danger" to={'/subadmin-management'}>Go Back</Link>
                  }
                </form>
              }
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export { SubadminManagementAdd }