/* eslint-disable react/prop-types */
import { Form, Collapse } from "react-bootstrap";
import { useState, useEffect } from "react";
import { Get, Post, Postdata } from "../../../utils/api.service";
import DataTableBase from "../../common/DataTableBase";
import HttpService from "../../../utils/http.service";
// import { ConvertToDMY, ConvertToDMYHIS, CheckPermission } from "../../../utils/Helper";
import { useDispatch,useSelector } from "react-redux";
import { message } from "../../../redux/actions/toastActions";
import ModalPopup from "../../common/Modal";
import "../../../styles/Profile.scss";
import { DigitainEditGameModal } from "./DigitainEditGameModal";
// import CSV from "../../common/CSV";
import Link from "../../common/Link";
import { CheckPermission } from '../../../utils/Helper';
import { isPermission } from "../../../redux/selectors/authSelector";


let categoryData = null;

const DigitainGameList = (props) => {
  const [data, setData] = useState([]);
  const [isloading, setIsloading] = useState(true);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setperPage] = useState(process.env.REACT_APP_RecordPerPage);
  const [currentPage, setcurrentPage] = useState(1);
  const [refresh, setRefresh] = useState(true);
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const {gameType} = props;

  //Game edit modal
  const [gameEditModalOpenStatus, setGameEditModalOpenStatus] = useState(false);
  const [gameToEdit, setGameToEdit] = useState(null);

  const [search, setSearch] = useState(false);
  const [categoryFilter, setCategoryFilter] = useState(null);
  const [enabledStatusFilter, setEnabledStatusFilter] = useState(null);
  const [promotedStatusFilter, setPromotedStatusFilter] = useState(null);

  const [modalswitch, setmodalswitch] = useState(false);
  const [modalswitchcontent, setmodalswitchcontent] = useState({});
    const permission = useSelector(isPermission);

  const columns = [
    {
      name: "#",
      width: "55px",
      selector: (row, i) => currentPage * perPage + (i + 1) - perPage,
    },
    {
      name: "Game Id",
      selector: (row) => row.gameId,
    },
    {
      name: "Game Display Name",
      selector: (row) => row.gameDisplayName,
    },
    {
      name: "Game Provider",
      selector: (row) => row.provider.name,
    },
    {
      // Actually refers to the sub-category
      name: "Game Category",
      selector: (row) => {
        let categoryString = "";
        if (categoryData != null) {
          row.iGameCategory.forEach((categoryId) => {
            categoryString += categoryData[categoryId]
              ? `${categoryData[categoryId]}, `
              : "";
          });
        }
        return categoryString;
      },
    },
    {
      name: "Status",
      cell: (row) => (
        <Link permission={'/digitain/game-sort/s'} status={row.isEnabled}>
          <Form.Check
            type="switch"
            checked={row.isEnabled}
            onChange={(e) => handleStatusChange(row.gameId, e.target.checked)}
          />
        </Link>
      ),
      button: true,
    },
    {
      name: "Promoted",
      cell: (row) => (
        <Link permission={'/digitain/game-sort/s'} status={row.isPromoted}>
          <Form.Check
            type="switch"
            checked={row.isPromoted}
            onChange={(e) =>
              handlePromotedStatusChange(row.gameId, e.target.checked)
            }
          />
        </Link>
      ),
      button: true,
    },
    {
      name: "Action",
      id: "actions",
      hide: (!CheckPermission(permission, '/digitain/game-sort/s') && 999999),
      cell: (row) => (
        <span className="actions">
          <i
            className="mdi mdi-pen"
            onClick={() => openGameEditModal(row.gameId)}
          />
        </span>
      ),
      button: true,
    },
  ];

  const handleStatusChange = (id, status) => {
    setmodalswitchcontent({ id, status });
    setmodalswitch(true);
  };

  const handleStatusChangeAction = () => {
    setmodalswitch(false);
    setIsloading(true);
    Postdata(HttpService.Digitain_game, {
      gameId: modalswitchcontent.id,
      status: modalswitchcontent.status,
    })
      .then((res) => {
        setRefresh(!refresh);
        dispatch(message(res.message));
      })
      .catch((err) => {
        console.error(err.toString());
        dispatch(message(err.message));
      });
  };

  const handlePromotedStatusChange = (id, status) => {
    Postdata(HttpService.Digitain_game, {
      gameId: id,
      isPromoted: status,
    })
      .then((res) => {
        //TODO: Stop refreshing the whole page and update only the gamedata which was updated or look for other option other than refresh.
        setRefresh(!refresh);
        dispatch(message(res.message));
      })
      .catch((err) => {
        console.error(err.toString());
        dispatch(message(err.message));
      });
  };

  const openGameEditModal = (gameId) => {
    let gameToEdit = data.find(
      (gameDataItem) => gameDataItem.gameId === gameId
    );
    setGameEditModalOpenStatus(true);
    setGameToEdit(gameToEdit);
  };

  const saveGameDetails = (gameDetails) => {
    Post(HttpService.Digitain_game, gameDetails)
      .then((res) => {
        //TODO: Stop refreshing the whole page and update only the gamedata which was updated or look for other option other than refresh.
        setRefresh(!refresh);
        dispatch(message(res.message));
        closeGameEditModal();
      })
      .catch((err) => {
        console.error(err.toString());
        dispatch(message(err.message));
        closeGameEditModal();
      });
  };

  const closeGameEditModal = () => {
    setGameEditModalOpenStatus(false);
    setGameToEdit(null);
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    setperPage(newPerPage);
    setcurrentPage(page);
  };

  const handlePageChange = (page) => {
    setcurrentPage(page);
  };

  useEffect(() => {
    if (search !== false) {
      const timeOutId = setTimeout(() => setRefresh(!refresh), 500);
      return () => clearTimeout(timeOutId);
    }
  }, [search]);

  useEffect(() => {
    categoryData = null;
  }, [gameType]);

  useEffect(() => {
    setIsloading(true);
    let params = {
      page: currentPage,
      perpage: perPage,
      search: search ? search : "",
      categoryId: categoryFilter? categoryFilter: "",
    };
    if (enabledStatusFilter !== null && enabledStatusFilter !== '') {
      params.isEnabled = enabledStatusFilter;
    }
    if (promotedStatusFilter !== null && promotedStatusFilter !== '') {
      params.isPromoted = promotedStatusFilter;
    }
    try {
      (async () => {
        if (categoryData == null) {
          let categoryDataResponse = await Get(
            HttpService.Digitain_category+'/'+gameType,
            //WARN: assuming there will be max 100 categories
            { perPage: 100 }
          );
          if (categoryDataResponse.status === 200) {
            categoryData = {};
            categoryDataResponse.data.forEach((categoryDataItem) => {
              categoryData[categoryDataItem.id] = categoryDataItem.name;
            });
          }
        }
        let response = await Get(HttpService.Digitain_game+'/'+gameType, { params });
        if (response.status === 200) {
          setData(response.data);
          setTotalRows(response.count);
          setIsloading(false);
        } else {
          setIsloading(false);
          dispatch(message(response.message));
        }
      })();
    } catch (error) {
      setIsloading(false);
      dispatch(message(error.message));
    }
  }, [currentPage, perPage, refresh, categoryFilter, enabledStatusFilter, promotedStatusFilter]);

  return (
    <>
      <ModalPopup
        className="alert-box-popup"
        show={modalswitch}
        size="sm"
        onHide={() => setmodalswitch(false)}
        heading="Are you sure?"
      >
        <i className="mdi mdi-check"></i>
        <p>You want to change this status!</p>
        <div className="actions">
          <button
            className="btn btn-primary"
            onClick={() => handleStatusChangeAction()}
          >
            Yes, Change Status
          </button>
          <button
            className="btn btn-danger"
            onClick={() => setmodalswitch(false)}
          >
            Cancel
          </button>
        </div>
      </ModalPopup>
      {gameEditModalOpenStatus && (
        <DigitainEditGameModal
          show={gameEditModalOpenStatus}
          gameToEdit={gameToEdit}
          categoryData={categoryData ? categoryData : {}}
          onSaveGameDetailsClickedCallback={saveGameDetails}
          onCancelClickedCallback={closeGameEditModal}
          onHide={closeGameEditModal}
        />
      )}

      <div className="card-body filters">
        <div className="row searchaction">
          <div className="col-lg-6">
            <h5>Game Management</h5>
          </div>
          <div className="col-lg-6 text-end">
            <button className="btn btn-danger" onClick={() => setOpen(!open)}>
              Search
            </button>
          </div>
        </div>

        <Collapse in={open}>
          <div>
            <div className="card">
              <p className="card-header">Search</p>
              <div className="card-body">
                <Form.Group className="form-group mb-0">
                  <Form.Label>Search</Form.Label>
                  <Form.Control
                    type="text"
                    onChange={(e) => setSearch(e.target.value)}
                    required
                  />
                  <Form.Label>Category Filter</Form.Label>
                  <Form.Select
                    onChange={(e) => setCategoryFilter(e.target.value)}
                  >
                    <option value={null} label="All" />
                    {categoryData &&
                      Object.keys(categoryData).map((categoryId) => (
                        <option
                          value={categoryId}
                          key={categoryId}
                          label={categoryData[categoryId]}
                        />
                      ))}
                  </Form.Select>
                  <Form.Label>Enabled Status Filter</Form.Label>
                  <Form.Select
                    onChange={(e) => setEnabledStatusFilter(e.target.value)}
                  >
                    <option value={null} key={"neither"} label="--" />
                    <option value={true} key={"enabled"} label={"Enabled"} />
                    <option value={false} key={"disabled"} label={"Disabled"} />
                  </Form.Select>
                  <Form.Label>Promoted Status Filter</Form.Label>
                  <Form.Select
                    onChange={(e) => setPromotedStatusFilter(e.target.value)}
                  >
                    <option value={null} key={"neither"}  label="--" />
                    <option value={true} key={"promoted"} label={"Promoted"} />
                    <option
                      value={false}
                      key={"notPromoted"}
                      label={"Not Promoted"}
                    />
                  </Form.Select>
                </Form.Group>
              </div>
            </div>
          </div>
        </Collapse>

        <DataTableBase
          columns={columns}
          loader={isloading}
          data={data}
          paginationTotalRows={totalRows}
          onChangeRowsPerPage={handlePerRowsChange}
          onChangePage={handlePageChange}
        />
      </div>
    </>
  );
};

export { DigitainGameList };
