/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import Breadcrumb from '../../common/Breadcrumb'
import Title from '../../common/Title'
import DataTableBase from '../../common/DataTableBase'
import React, { useState, useEffect } from 'react'
import HttpService from '../../../utils/http.service'
import { Postdata } from '../../../utils/api.service'
import { useDispatch } from 'react-redux'
import { message } from '../../../redux/actions/toastActions'
import { ConvertToDMYHIS } from '../../../utils/Helper';
import { Link } from 'react-router-dom'
import { Form, Collapse } from "react-bootstrap";
import CSV from '../../common/CSV'

function Bet_History() {
    const [roles, setRoles] = useState([]);
    const [open, setOpen] = useState(false);
    const [isloading, setIsloading] = useState(true);
    const [data, setData] = useState([]);
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setperPage] = useState(10);
    const [currentPage, setcurrentPage] = useState(1);
    const [postdata, setPostdata] = useState(getDefault);


    const [search, setSearch] = useState(false);
    const [refresh, setRefresh] = useState(false);
    const [startSearch, setStartSearch] = useState(false)
    const dispatch = useDispatch();

    function getDefault(){
        return {
          search_by_date: "week",
          search_by: "stake_amount",
          sort_order: "desc",
          search: "",
          sort_by: "date",
        };
    }

    const handlePerRowsChange = async (newPerPage, page) => {
        setperPage(newPerPage);
        setcurrentPage(page);
    };

    const handlePageChange = (page) => {
        setcurrentPage(page);
    };

    const handleSearch = () => {
        setStartSearch(!startSearch);
        setRefresh(!refresh); // This ensures the API call is triggered when "Submit" is clicked
    };

    const columns = [
        {
            name: '#',
            width: "40px",
            cell: (row, i) => <>
                {row?.type != 'total' ? (currentPage * perPage + (i + 1) - perPage) : ''}
            </>
        },
        {
            name: 'Profile',
            cell: (row) => <>
                {row?.type != 'total' ? <div className="avatar">
                    <img
                        alt={row.user_id._id[0]}
                        src={row.user_id.profile_pic[0]}
                    />
                </div> : ''}
            </>,
            width: "75px",
        },
        {
            name: 'Player ID',
            selector: row => row?.user_id?._id?.toString() || ''
        },
        {
            name: 'Username',
            cell: (row) =>
                <>
                    {row?.type != 'total' ?
                        <Link to={`/user-management/view/${row.user_id._id}`} style={{ textDecoration: 'none' }}>
                            {row.user_id.username[0]}
                        </Link> :
                        <b>{row.user_id.username[0]}</b>
                    }
                </>
        },
        // {
        //     name: 'Transaction ID',
        //     selector: row => row._id?.toString().slice(-7) || '',
        //     width: "125px"
        // },
        {
            name: 'Date',
            selector: row => ConvertToDMYHIS(row?.createdAt) || '',
        },
        {
            name: 'Bet Amount',
            cell: (row) => <>
                {row?.type != 'total' ?
                    parseInt(row.coins_price - row.profitLoss)
                    :
                    <b>{parseInt(row.coins_price - row.profitLoss)}</b>
                }
            </>
        },
        {
            name: 'Win Amount',
            cell: (row) => <>
                {row?.type != 'total' ?
                    parseFloat(row.coins_price).toFixed(2)
                    :
                    <b>{parseFloat(row.coins_price).toFixed(2)}</b>
                }
            </>
        },
        {
            name: 'Profit/Loss',
            cell: (row) => <>
                {row?.type != 'total' ?
                    parseFloat(row.profitLoss).toFixed(2)
                    :
                    <b>{parseFloat(row.profitLoss).toFixed(2)}</b>
                }
            </>
        },
        {
            name: 'Type',
            selector: row => row.transfer_type === 'none' ? '' : <span className="text-cap">{row.transfer_type}</span>,
        },
        {
            name: 'Round Id',
            selector: row => row.round_id === 'none' ? '' : <span className="text-cap">{row.round_id}</span>,
        },
        {
            name: 'Game',
            selector: row => row.game_name === '' ? '-' : <span className="text-cap">{(row.game_name) ? row.game_name : '-'}</span>,
        },
        {
            name: 'Status',
            cell: (row) => <>
                {row.type != 'total' ? <div style={{ backgroundColor: (row.coins_price > 0) ? '#e9f9ef' : '#ffe9e9', width: "100px", textAlign: 'center', padding: '2px', color: (row.coins_price > 0) ? '#3dc67b' : '#fe2b2a', fontWeight: '500' }}>
                    {(row.coins_price > 0) ? 'WIN' : 'LOST'}
                </div> : ''}

            </>,
            center: true
        },
        {
            name: 'Opening Balance',
            selector: row => row.type != 'total' ? parseFloat(row?.prev_balance).toFixed(2) : '',
            width: "140px",
            centre: true
        },
        {
            name: 'Closing Balance',
            selector: row => row.type != 'total' ? parseFloat(row?.new_balance).toFixed(2) : '',
            width: "125px"
        },
    ];

    useEffect(() => {
        

        setIsloading(true);
        const postBody = Object.fromEntries(Object.entries({
            ...postdata,
            page: currentPage,
            perpage: perPage
        }).filter(([, value]) => value !== ''));
        Postdata(HttpService.Reports_bet_history, postBody).then((response) => {
            // console.log(response)
            if (response.status === 200) {
                setData(response.data.records);
                console.log('response.data :>> ', response.data);
                setTotalRows(response.data.total);
                setIsloading(false);
            } else {
                setIsloading(false);
                dispatch(message(response.message));
            }
        }).catch((e) => {
            dispatch(message(e.message));
        });
    }, [currentPage, perPage, refresh]);

    return (
        <>
            <Title name='Bet History' />
            <div className='row'>
                <div className='col-lg-4'>
                    <h4>Bet History</h4>
                    <Breadcrumb
                        pages={[
                            { title: 'Reports', link: '' }
                        ]}
                        active="Bet History"
                    />
                </div>
                <div className="col-lg-8 text-end">
                    <button className="btn btn-danger" onClick={() => setOpen(!open)}>Search</button>
                    <CSV
                        link={HttpService.Reports_bet_history}
                        name="Bet History"
                        response="records"
                        headers={[
                            { label: "ID", key: "_id" },
                            { label: "Username", key: "user_id.username[0]" },
                            { label: "Date", key: "createdAt"},
                            { label: "Product", key: "transfer_type"},
                            { label: "Bet Amount", key: "stake_amount"},
                            { label: "Win Amount", key: "coins_price"},
                            { label: "Opening Balance", key: "prev_balance"},
                            { label: "Closing Balance", key: "new_balance"},
                        ]}
                        extra={{
                            ...postdata,
                            page: currentPage,
                            perpage: perPage
                        }}
                    />
                </div>
            </div>

            <Collapse in={open}>
                <div>
                    <div className="card">
                        <p className="card-header">Filters</p>
                        <div className="card-body">
                            <div className="row">
                                <div className="col-lg-3">
                                    <Form.Group className="form-group mb-0">
                                        <Form.Label>Search By</Form.Label>
                                        <Form.Select 
                                            value={postdata.search_by}
                                            onChange={e => setPostdata({ ...postdata, search_by: e.target.value })}>
                                            <option value="username">Username</option>
                                            <option value="stake_amount">Bet Amount</option>
                                            <option value="game_name">Product</option>
                                            <option value="win_amount">Win Amount</option>
                                        </Form.Select>
                                    </Form.Group>
                                </div>
                                <div className="col-lg-4">
                                    <Form.Group className="form-group">
                                        <Form.Label>Search</Form.Label>
                                        <Form.Control
                                            type="text"
                                            value={postdata.search}
                                            onChange={e => setPostdata({ ...postdata, search: e.target.value })}
                                        />
                                    </Form.Group>
                                </div>
                                <div className="col-lg-3">
                                    <Form.Group className="form-group mb-0">
                                        <Form.Label>Sort By</Form.Label>
                                        <Form.Select 
                                            value={postdata.sort_by}
                                            onChange={e => setPostdata({ ...postdata, sort_by: e.target.value })}>
                                            <option value="date">Date</option>
                                            <option value="username">Username</option>
                                            <option value="stake_amount">Bet Amount</option>
                                            <option value="game_name">Product</option>
                                            <option value="win_amount">Win Amount</option>
                                        </Form.Select>
                                    </Form.Group>
                                </div>
                                
                                <div className="col-lg-2">
                                        <Form.Group className="form-group mb-0">
                                            <Form.Label>Sort Order</Form.Label>
                                            <Form.Select
                                                value={postdata.sort_order}
                                                onChange={e => { 
                                                    setPostdata({ ...postdata, sort_order: e.target.value })
                                                }}
                                            >
                                                <option value="asc" key="asc">Asc</option>
                                                <option value="desc" key="desc">Desc</option>
                                            </Form.Select>
                                        </Form.Group>
                                    </div>

                                <div className="col-lg-3">
                                    <Form.Group className="form-group mb-0">
                                        <Form.Label>Transaction Date</Form.Label>
                                        <Form.Select 
                                            value={postdata.search_by_date}
                                            onChange={e => setPostdata({ ...postdata, search_by_date: e.target.value })}>
                                            <option value="">All</option>
                                            <option value="today">Today</option>
                                            <option value="week">Last Week</option>
                                            <option value="month">Last Month</option>
                                            <option value="year">Last Year</option>
                                            <option value="range">Select Date</option>
                                        </Form.Select>
                                    </Form.Group>
                                </div>
                                {postdata.search_by_date === 'range' ?
                                    <>
                                        <div className="col-lg-3">
                                            <Form.Group className="form-group mb-0">
                                                <Form.Label>From Date</Form.Label>
                                                <Form.Control
                                                    type="date"
                                                    onChange={e => setPostdata({ ...postdata, from_date: e.target.value })}
                                                />
                                            </Form.Group>
                                        </div>
                                        <div className="col-lg-3">
                                            <Form.Group className="form-group mb-0">
                                                <Form.Label>To Date</Form.Label>
                                                <Form.Control
                                                    type="date"
                                                    onChange={e => setPostdata({ ...postdata, to_date: e.target.value })}
                                                />
                                            </Form.Group>
                                        </div>
                                    </>
                                    : ''}

                                    <div className="col-lg-3">
                                        <button style={{marginTop : '20px'}} 
                                                className="btn btn-success" 
                                                onClick={handleSearch}
                                                > Submit
                                        </button>
                                        <button 
                                            style={{marginTop : '20px'}} 
                                            className="btn btn-danger" 
                                            onClick={() => { setPostdata(getDefault());
                                                            handleSearch(); // Trigger reset and search
                                                            }
                                            }>Reset</button>
                                    </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Collapse>

            <div className='card'>
                <p className='card-header'>
                    List of Bets
                </p>

                <div className='card-body'>
                    <DataTableBase
                        columns={columns}
                        loader={isloading}
                        data={data}
                        paginationTotalRows={totalRows}
                        onChangeRowsPerPage={handlePerRowsChange}
                        onChangePage={handlePageChange}
                    />
                </div>
            </div>
        </>
    )
}

export { Bet_History }