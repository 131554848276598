const baseUrl = process.env.REACT_APP_API_URL

const HttpService = {
    loginUrl: baseUrl + 'admin/login',
    verifyTokenUrl: baseUrl + 'admin/verify-token',
    forgotpasswordUrl: baseUrl + 'admin/forgot-password',
    forgotUsernameUrl: baseUrl + 'admin/forgot-username',
    validateResetPasswordUrl : baseUrl + 'admin/validate_reset_link',
    resetPasswordWithLinkUrl : baseUrl + 'admin/reset_password_with_link',

    Dashboard: baseUrl + 'admin/dashboard',
    Admin_profile_get: baseUrl + 'admin/profile/show',
    Admin_profile_update: baseUrl + 'admin/profile/update_profile',
    Admin_change_password: baseUrl + 'admin/profile/change_password',

    CMS_list: baseUrl + 'admin/cms/fetch',
    CMS_add: baseUrl + 'admin/cms/store',
    
    User_list: baseUrl + 'admin/user/fetch',
    User_add: baseUrl + 'admin/user/create',
    User_show: baseUrl + 'admin/user/show',

    User_arrowwood_show: baseUrl + 'admin/get_user_arrowwood_balance',
    User_arrowwood_open_bets: baseUrl + 'admin/get_user_arrowwood_open_bets',

    User_status: baseUrl + 'admin/user/change_status',
    User_social_media_status: baseUrl + 'admin/user/change_social_media_restriction',
    User_friends: baseUrl + 'admin/user/fetch_friends',
    User_blocked_friends: baseUrl + 'admin/user/fetch_blocked_friends',
    User_refer: baseUrl + 'admin/user/refer_history',
    User_game_commission: baseUrl + 'admin/user/game_commission',
    User_transaction: baseUrl + 'admin/user/transaction_history',
    User_game_played: baseUrl + 'admin/user/game_played_history',
    User_add_coins: baseUrl + 'admin/user/add_coins',
    User_deduct_coins: baseUrl + 'admin/user/deduct_coins',
    User_assign_agent: baseUrl + 'admin/user/assign_agent',
    User_get_profile: baseUrl + 'admin/user/get_profile',
    User_Chat_User_List : baseUrl + 'admin/user/get_chat_user_list',
    User_Chats : baseUrl + 'admin/user/get_chats',

    Request_list: baseUrl + 'admin/request/fetch',
    Request_update: baseUrl + 'admin/request/update',
    Request_rewards_update : baseUrl + 'admin/request/reward/update',
    Request_coin_add_list : baseUrl + 'admin/request/add_coins/fetch',
    Request_coin_add_update : baseUrl + 'admin//request/add_coins/update',

    Banner_list: baseUrl + 'admin/banner/fetch',
    Banner_add: baseUrl + 'admin/banner/store',
    Banner_show: baseUrl + 'admin/banner/show',

    Promocode_list: baseUrl + 'admin/promocode/fetch',
    Promocode_add: baseUrl + 'admin/promocode/store',
    Promocode_show: baseUrl + 'admin/promocode/show',
    Promocode_applied_user: baseUrl + 'admin/promocode/applied',
    Promocode_valid: baseUrl + 'admin/promocode/valid_now',

    Subadmin_list: baseUrl + 'admin/subadmin/fetch',
    Subadmin_add: baseUrl + 'admin/subadmin/store',
    Subadmin_show: baseUrl + 'admin/subadmin/show',

    Bankdetails_store: baseUrl + 'admin/bankdetails/store',
    Bankdetails_user_store: baseUrl + 'admin/bankdetails/add_user',
    Bankdetails_show: baseUrl + 'admin/bankdetails/show',
    Bankdetails_delete: baseUrl + 'admin/bankdetails/delete',
    Bankdetails_edit: baseUrl + 'admin/bankdetails/edit',

    Gametype_show: baseUrl + 'admin/gametype/fetch',
    Gametype_save: baseUrl + 'admin/gametype/store',

    Game_list: baseUrl + 'admin/game/fetch',
    Game_add: baseUrl + 'admin/game/store',
    Game_show: baseUrl + 'admin/game/show',

    Game_subscribed: baseUrl + 'admin/game/subscribed',

    Setting_show_hidden: baseUrl + 'admin/setting/fetch-day',

    Setting_show: baseUrl + 'admin/setting/fetch',
    Setting_save: baseUrl + 'admin/setting/store',

    Role_list: baseUrl + 'admin/role/fetch',
    Role_add: baseUrl + 'admin/role/store',
    Role_show: baseUrl + 'admin/role/show',

    Agent_request_update: baseUrl + 'admin/agents/request_update',
    Agent_request: baseUrl + 'admin/agents/request',
    Agent_list: baseUrl + 'admin/agents/fetch',
    Agent_add: baseUrl + 'admin/agents/store',
    Agent_show: baseUrl + 'admin/agents/show',

    Reports_commission: baseUrl + 'admin/report/commission',
    Reports_commission_user: baseUrl + 'admin/report/commission_userview',
    Reports_all_transactions : baseUrl + 'admin/report/transactions/all',
    Reports_bet_history : baseUrl + 'admin/report/bet_history',
    Reports_bet_report : baseUrl + 'admin/report/bet_report',
    Reports_user_bet_report : baseUrl + 'admin/report/user_bet_report',
    Reports_earnings_agents_list : baseUrl + 'admin/report/earnings_report/agents_list',
    Reports_earnings_sub_agents_list : baseUrl + 'admin/report/earnings_report/sub_agents_list',
    Reports_earnings_game_wise_transaction : baseUrl + 'admin/report/earnings_report/game_wise_transactions',
    Reports_arrowwood_open_bets : baseUrl + 'admin/report/open_bets',

    Contest_list: baseUrl + 'admin/contest/fetch',
    Contest_add: baseUrl + 'admin/contest/store',
    Contest_show: baseUrl + 'admin/contest/show',

    Match_list: baseUrl + 'admin/match/fetch',
    Match_contest: baseUrl + 'admin/match/contest',
    Match_users: baseUrl + 'admin/match/users',
    Match_users_list: baseUrl + 'admin/match/users_list',
    Match_user_contest_details : baseUrl + 'admin/match/user/contest_list',

    Notification_add : baseUrl + 'admin/notification/add',
    Notification_fetch : baseUrl + 'admin/notification/fetch',
    Notification_getById : baseUrl + 'admin/notification/getById',

    Spin_wheel_add : baseUrl + 'admin/spin_wheel/add',
    Spin_wheel_fetch : baseUrl + 'admin/spin_wheel/fetch',
    Spin_wheel_update : baseUrl + 'admin/spin_wheel/update',
    Spin_wheel_status_update : baseUrl + 'admin/spin_wheel/update',
    Spin_wheel_update_reward_for_user : baseUrl + 'admin/spin_wheel/update_reward_for_user',
    Spin_wheel_get_by_id : baseUrl + 'admin/spin_wheel/getById',
    Spin_wheel_user_records_list : baseUrl + 'admin/spin_wheel_records/list',

    Kyc_user_requests : baseUrl + 'admin/get_users_with_kyc_request',
    Update_kyc_request : baseUrl + 'admin/update_user_kyc_request',
    User_kyc : baseUrl + 'admin/get_user_kyc',

    Leaderboard_fetch : baseUrl + 'admin/leaderboard/fetch',
    Leaderboard_GameTypes : baseUrl + 'admin/leaderboard/game_types',

    Vip_membership_fetch : baseUrl + 'admin/vip_user/fetch',
    Vip_membership_add : baseUrl + 'admin/vip_user/add',
    Vip_membership_update : baseUrl + 'admin/vip_user/update',
    Vip_membership_get_by_id : baseUrl + 'admin/vip_user/get_by_id',
    Vip_membership_list_of_vip_users : baseUrl + 'admin/vip_user/list_vip_users',

    Digitain_game: baseUrl + 'admin/digitain/game',
    Digitain_provider: baseUrl + 'admin/digitain/provider',
    Digitain_category: baseUrl + 'admin/digitain/category',
    Digitain_save_category_order: baseUrl + 'admin/digitain/order_categories',
    Digitain_fetch_games_by_id: baseUrl + 'admin/digitain/fetch_games_by_category',
    Digitain_save_game_order: baseUrl + 'admin/digitain/order_games_by_category',   

    Fetch_all_games_from_server: baseUrl + 'admin/livecasino/fetch_all_games',
    Get_last_fetched_time: baseUrl + 'admin/livecasino/fetch_last_fetched_time',


};

export default HttpService;