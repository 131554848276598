/* eslint-disable react/prop-types */
//********************************************************************
//Digitian Settings -> Category managment - displays category 
// with edit options 
//********************************************************************
import { Form } from "react-bootstrap";
import { useState, useEffect } from "react";
import { Delete, Get, Post } from '../../../utils/api.service';
import DataTableBase from "../../common/DataTableBase";
import HttpService from "../../../utils/http.service";
import { useDispatch,useSelector } from "react-redux";
import { message } from "../../../redux/actions/toastActions";
import '../../../styles/Profile.scss'
import { DigitainCategoryModal } from "./DigitainCategoryModal";
import { ConfirmationModal } from "../../common/ConfirmationModal";
import { CheckPermission } from '../../../utils/Helper';
import {isPermission} from '../../../redux/selectors/authSelector'
import Link from '../../common/Link'


const DigitainCategoryList = (props) => {
  const [data, setData] = useState([]);
  const [isloading, setIsloading] = useState(true);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setperPage] = useState(process.env.REACT_APP_RecordPerPage);
  const [currentPage, setcurrentPage] = useState(1);
  const [refresh, setRefresh] = useState(true);
  const dispatch = useDispatch();

  const {gameType} = props;
  const categoryApi = HttpService.Digitain_category+"/"+gameType;


  const [categoryModalOpenStatus, setCategoryModalOpenStatus] = useState(false);
  const [categoryToEdit, setCategoryToEdit] = useState(null);

  const [deleteCategoryModalOpenStatus, setDeleteCategoryModalOpenStatus] =
    useState(false);
  const [categoryIdToDelete, setCategoryIdToDelete] = useState(null);
  const [categoryError, setCategoryError] = useState("");


  const [modalswitch, setmodalswitch] = useState(false);
  const [modalswitchcontent, setmodalswitchcontent] = useState({});
  const permission = useSelector(isPermission);

  //react-data-table-component lib Config
  const columns = [
    {
      name: "#",
      width: "55px",
      selector: (row, i) => currentPage * perPage + (i + 1) - perPage,
    },
    {
      name: "Category Id",
      selector: (row) => row.id,
    },
    {
      name: "Category Name",
      selector: (row) => row.name,
    },
    {
      name: "Display Name",
      selector: (row) => row.displayName,
    },
    {
      name: "Status",
      cell: (row) => (
        <Link permission={'/digitain/game-sort/s'} status={row.isEnabled}>
          <Form.Check
            type="switch"
            checked={row.isEnabled}
            onChange={(e) => handleStatusChange(row.id, e.target.checked)}
          />
        </Link>
      ),
      button: true,
    },
    {
      name: "Action",
      id: "actions",
      hide: (!CheckPermission(permission, '/digitain/game-sort/s') && 999999),
      cell: (row) => (
        <span className="actions">
          {/* <i className="mdi mdi-delete" onClick={() => openDeleteCategoryModal(row.id)}/> */}
          <i className="mdi mdi-pen" onClick={() => openCategoryModal(row.id)} />
        </span>
      ),
      button: true,
    },
    {
      name: "View / Sort Games",
      id: "sortgame",
      hide: (!CheckPermission(permission, '/digitain-game-settings') && 999999),
      cell: (row) => (
        <span className="actions">
          <Link to={`/digitain/game-view/${row.id}`}><i className="mdi mdi-eye" /></Link>
          <Link to={`/digitain/game-sort/${row.id}`}><i className="mdi mdi-table-edit"/> </Link>
        </span>
      ),
      button: true,
    },
  ];

  //Methods
  const handleStatusChange = (id, status) => {
    setmodalswitchcontent({id, status});
    setmodalswitch(true);
  };


  const handleStatusChangeAction = () => {
    setmodalswitch(false);
    setIsloading(true);
    saveCategoryDetails({
      id: modalswitchcontent.id,
      status: modalswitchcontent.status,
    }); 
  }

  const handlePerRowsChange = async (newPerPage, page) => {
    setperPage(newPerPage);
    setcurrentPage(page);
  };

  const handlePageChange = (page) => {
    setcurrentPage(page);
  };

  //Edit Category Name - 
  const openCategoryModal = (categoryId = null) => {
    
    if(categoryId !== null){
      //Edit category
      let categoryToEdit = data.find(categoryItem => categoryItem.id === categoryId);
      setCategoryToEdit(categoryToEdit);
    }
    setCategoryModalOpenStatus(true);
  }

  //************************************************
  //Save Category Modal
  //************************************************
  const saveCategoryDetails = async(categoryDetails) => {
    try {
      //category.displayName check handles new category and edit category, categoryDetails.id handles change status
      if (categoryDetails.displayName || categoryDetails.id) {
        let res = await Post(categoryApi, categoryDetails);
        if (res.status === 200) {
          setRefresh(!refresh);
          dispatch(message(res.message));
          cancelCategoryModal(false);
        } else {
          setCategoryError(res.message);
        }
      } else {
        setCategoryError("Display name cannot be empty");
      }
    } catch (err) {
      dispatch(message(err.message));
    }
  }

  const handleStatusChangeConfirmation = (confirmationResult) => {
    if(confirmationResult){
      handleStatusChangeAction();
    }else{
      setmodalswitch(false);
    }
  }
  const cancelCategoryModal = () => {
    setCategoryModalOpenStatus(false);
    setCategoryToEdit(null);
    setCategoryError("");
  }

  //************************************************
  //Delete Category Modal
  //************************************************
  const openDeleteCategoryModal = (categoryIdToDelete) => {
   
    setDeleteCategoryModalOpenStatus(true);
    setCategoryIdToDelete(categoryIdToDelete);
  }

  const cancelDeleteCategoryModal = () => {
    setDeleteCategoryModalOpenStatus(false);
    setCategoryIdToDelete(null);
  };

  const handleDeleteCategoryConfirmation = (confirmationResult) => {
    if (confirmationResult) {
      //delete category
      deleteCategoryById();
    } 
    cancelDeleteCategoryModal(false);
  };


  //callback fn - passed to digitainCategoryModal.js
  const onDeleteCallback =() =>{
    const id = categoryToEdit.id
    cancelCategoryModal()
    openDeleteCategoryModal(id)
  }

  const deleteCategoryById = async() => {
    try {
      let data = {id: categoryIdToDelete};
      let res = await Delete(categoryApi, {data})
      setRefresh(!refresh);
      dispatch(message(res.message));
    } catch (err) {
      dispatch(message(err.message));
    }
  }

  // Get API - fetches category information
  useEffect(() => {
    setIsloading(true);
    let params = {
      page: currentPage,
      perpage: perPage,
      // search: (search ? search : ''),
    }
    Get(categoryApi,{params}).then((response) => {
      // console.log(response)
      if (response.status === 200) {  
        setData(response.data);
        setTotalRows(response.count);
        setIsloading(false);
      } else {
        setIsloading(false);
        dispatch(message(response.message));
      }
    }).catch((e) => {
      setIsloading(false);
      dispatch(message(e.message));
    });
  }, [currentPage, perPage, refresh]);

  return (
    <>
      {categoryModalOpenStatus && (
        <DigitainCategoryModal
          onSaveCategoryClickedCallback={saveCategoryDetails}
          onCancelClickedCallback={cancelCategoryModal}
          onDeleteCallback = {onDeleteCallback}
          categoryToEdit={categoryToEdit}
          show={categoryModalOpenStatus}
          errorMsg={categoryError}
          onHide={cancelCategoryModal}
        />
      )}
      <ConfirmationModal
        show={modalswitch}
        size="sm"
        onHide={() => setmodalswitch(false)}
        heading="Are you sure?"
        promptMessage="You want to change this status!"
        confirmationResultCallback={handleStatusChangeConfirmation}
      />
      <ConfirmationModal
        show={deleteCategoryModalOpenStatus}
        size="sm"
        onHide={cancelDeleteCategoryModal}
        heading="Delete Category"
        promptMessage={"Are you sure, \n you want to delete the category? "}
        confirmationResultCallback={handleDeleteCategoryConfirmation}
      />

      <div className="card-body filters">
        <div className="row searchaction">
          <div className="col-lg-6">
            <h5>Category Management</h5>
          </div>
          <div className="col-lg-6 text-end">
            <Link permission={'/digitain/category-sort'} to={'/digitain/category-sort/'+gameType}  className="btn btn-secondary">Sort Categories</Link>
            <Link permission={'/digitain/category-sort'}>
              <button className="btn btn-primary" onClick={() => openCategoryModal()}>
                Add Category
              </button>
            </Link>
          </div>
        </div>

        <DataTableBase
          columns={columns}
          loader={isloading}
          data={data}
          paginationTotalRows={totalRows}
          onChangeRowsPerPage={handlePerRowsChange}
          onChangePage={handlePageChange}
        />
      </div>
    </>
  );
}

export { DigitainCategoryList }