import {useEffect, useState} from 'react'
import { useDispatch } from "react-redux";
import { Get } from "../../../../utils/api.service";
import HttpService from "../../../../utils/http.service";
import { message } from "../../../../redux/actions/toastActions";
import Spinner from 'react-bootstrap/Spinner';
import './FetchAllGamesFromServerBtn.styles.scss'
import { ConvertToDMYHIS } from '../../../../utils/Helper';
// import Loader from "../../common/Loader";

const FetchAllGamesFromServerBtn = () => {
    
  const [loading, setLoading] = useState(false);
  const [lastFetchedTimestamp, setLastFetchedTimestamp] = useState(null);

  useEffect(() => {
    async function getLastFetchedData () {
      let lastFetchedTimeRespose = await Get(HttpService.Get_last_fetched_time);
      if(lastFetchedTimeRespose){
        setLastFetchedTimestamp(lastFetchedTimeRespose?.data);
      }
    }
    getLastFetchedData();
  },[]);
  
    let dispatch = useDispatch();

    const onFetchGamesBtnClicked = async () => {
      if(loading){
        return
      }
      setLoading(true);
      // Set the timestamp when the button is clicked
      // setLastFetchedTimestamp(new Date().toLocaleString());
      try {
        let fetchResponse = await Get(HttpService.Fetch_all_games_from_server);
        dispatch(message(fetchResponse.message));
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    };
    return (
      <div className="col-lg-12 text-end ">
        <div className='btn_content_wrapepr'>
            { lastFetchedTimestamp && <div className="text-muted me-3 timestamp_placeholder">Last Fetched: {ConvertToDMYHIS(lastFetchedTimestamp)}</div>}
         
            <button className="btn btn-danger" onClick={onFetchGamesBtnClicked}>
              {loading ? (
                <>
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />{' '}
                  Loading...
                </>
              ) : (
                'Fetch all games'
              )}
            </button>
        </div>
      </div>
    );
}

export { FetchAllGamesFromServerBtn };