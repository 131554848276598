/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import '../../../styles/Profile.scss';
import DataTableBase from '../../common/DataTableBase';
import { Collapse, Form } from 'react-bootstrap';
import ModalPopup from '../../common/Modal';
import { useEffect, useState } from 'react';
import { Postdata } from '../../../utils/api.service';
import HttpService from '../../../utils/http.service';
import CSV from '../../common/CSV';

const Chat = (props) => {
  const [open, setOpen] = useState(false);
  const [modalChatView, setmodalChatView] = useState(false);
  const [data, setData] = useState({});
  const [isloading, setIsloading] = useState(true);
  const [chatGroupDetails, setChatGroupDetails] = useState({})
  const [postdata, setPostdata] = useState({})

  const getChats = (data) => {
    setmodalChatView(true)
    setChatGroupDetails(data)
    console.log(data)
  }

  const columns = [
    {
      name: 'ID',
      selector: row => row.group_id.toString(),
      width: '50px'
    },
    {
      name: 'Chat Type',
      selector: row => <p style={{ textTransform: 'capitalize' }}>{row.type}</p>,
    },
    {
      name: 'User Type',
      selector: row => <p style={{ textTransform: 'capitalize' }}>{row.role}</p>,
    },
    {
      name: 'Name',
      selector: row => row.username,
    },
    {
      name: 'Profile',
      cell: (row) =>
        <div className="avatar">
          <img
            alt={row.id}
            src={row.profile_pic}
          />
        </div>,
    },
    {
      name: 'Action',
      id: 'actions',
      cell: (row) =>
        <span className="actions">
          <a href="#" onClick={() => {
            setChatGroupDetails(row)
            setmodalChatView(true)
          }}><i className="mdi mdi-database-export" /></a>
          {/* <a href="#"><i className="mdi mdi-database-export" /></a> */}
          {/* <a href="#"><i className="mdi mdi-delete" /></a> */}
        </span>,
      button: true,
    }
  ];

  useEffect(() => {
    setIsloading(true);
    Postdata(HttpService.User_Chat_User_List, {
      id: props.id
    }).then((response) => {
      console.log(response)
      if (response.status === 200) {
        setData([...response.data.admin, ...response.data.users]);
        setIsloading(false);
      } else {
        setIsloading(false);
        // dispatch(message(response.message));
      }
    }).catch((e) => {
      setIsloading(false);
      // dispatch(message(e.message));
    });
  }, []);


  return (
    <>
      <div className="card-body filters">
        <div className="row searchaction">
          <div className="col-lg-6">
            <h5>Chat</h5>
          </div>
          <div className="col-lg-6 text-end">
            <button className="btn btn-danger" onClick={() => setOpen(!open)}>Search</button>
          </div>
        </div>

        <Collapse in={open}>
          <div>
            <div className="card">
              <p className="card-header">Search</p>
              <div className="card-body">
                <Form.Group className="form-group mb-0">
                  <Form.Label>Search</Form.Label>
                  <Form.Control
                    type="text"
                    required
                  />
                </Form.Group>
              </div>
            </div>
          </div>
        </Collapse>

        <DataTableBase
          columns={columns}
          pagination={false}
          data={data}
        />

        <ModalPopup
          show={modalChatView}
          size="lg"
          onHide={() => setmodalChatView(false)}
          heading="Export Chat"
        >
          <div className='row'>
          <div className='col-lg-2' style={{ marginTop: '18px' }}>
              <CSV
                link={HttpService.User_Chats}
                extra={{
                  ...postdata,
                  id: chatGroupDetails.group_id
                }}

                name="User Chat"
                headers={[
                  { label: "Group ID", key: "group_id" },
                  { label: "ID", key: "_id" },
                  { label: "User ID", key: "user_id" },
                  { label: "Username", key: "username" },
                  { label: "Type", key: "type" },
                  { label: "Content", key: "content" }
                ]}

                />
            </div>
            <div className="col-lg-3">
              <Form.Group className="form-group mb-0">
                <Form.Label>Chat Duration</Form.Label>
                <Form.Select onChange={e => setPostdata({ ...postdata, search_by_date: e.target.value })}>
                  <option value="">All</option>
                  <option value="today">Today</option>
                  <option value="week">Last Week</option>
                  <option value="month">Last Month</option>
                  <option value="year">Last Year</option>
                  <option value="range">Select Date</option>
                </Form.Select>
              </Form.Group>
            </div>
            {postdata.search_by_date === 'range' ?
              <>
                <div className="col-lg-3">
                  <Form.Group className="form-group mb-0">
                    <Form.Label>From Date</Form.Label>
                    <Form.Control
                      type="date"
                      onChange={e => setPostdata({ ...postdata, from_date: e.target.value })}
                    />
                  </Form.Group>
                </div>
                <div className="col-lg-3">
                  <Form.Group className="form-group mb-0">
                    <Form.Label>To Date</Form.Label>
                    <Form.Control
                      type="date"
                      onChange={e => setPostdata({ ...postdata, to_date: e.target.value })}
                    />
                  </Form.Group>
                </div>
              </>
              : ''}
          </div>
        </ModalPopup>
      </div>
    </>
  )
}

export { Chat }