/* eslint-disable no-unused-vars */
import Title from "../../common/Title";
import { Form } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import Link from "../../common/Link";
import { useEffect, useState } from "react";
import HttpService from "../../../utils/http.service";
import { Get, Postdata } from "../../../utils/api.service";
import { useDispatch, useSelector } from "react-redux";
import { message } from "../../../redux/actions/toastActions";
import Loader from "../../common/Loader";
import Breadcrumb from "../../common/Breadcrumb";
import { isData, isRoleID } from "../../../redux/selectors/authSelector";

const UserManagementAdd = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const navigate = useNavigate();
  const [games, setgames] = useState([]);
  const [roles, setroles] = useState([]);
  const [localdata, setlocaldata] = useState({});
  const [percentage, setpercentage] = useState([]);
  const [loadeddone, setloadeddone] = useState(false);
  const [loader, setLoader] = useState(false);
  const [apiloader, setapiloader] = useState(false);
  const localData = useSelector(isData);
  const myisRoleID = useSelector(isRoleID);

  const [updateId, setupdateId] = useState([]);
  const [myroles, setmyroles] = useState(100);
  const [refresh, setrefresh] = useState(false);
  const [loaderforagent, setIsloading] = useState(false);
  const [agentData, setAgentData] = useState([]);

  const [roleusercall, setroleusercall] = useState({ role: '', user_id: '', byuser: false });
  const [roletable, setroletable] = useState([]);
  const [formdata1, setFormdata1] = useState({
    parent_user_id: '',
    role_id: '',
  });
  const [formdata, setFormdata] = useState({
    username: '',
    mobile: '',
    email: '',
    // role_id: '',
    password: '',
    status: 1,
    commissions: '[]',
  });
  const [isAgent, setIsAgent] = useState(true)

  useEffect(() => {
    if (!apiloader && roleusercall.role) {
      // console.log(roleusercall);
      const index = roletable.map((o) => o.order).indexOf(roleusercall.role);
      const setDatsjsf = roletable;
      if (roletable.length && roleusercall.user_id) {
        setDatsjsf[index - 1].value = roleusercall.user_id;
        // setDatsjsf[index].value = '';
        setDatsjsf[index].hide = true;
        setroletable(setDatsjsf);
        setrefresh(Math.random());
      }
      if (index != -1) {
        Postdata(HttpService.Agent_list, {
          page: 1,
          perpage: 99999,
          show: 1,
          role_id: roletable[index].id,
          id: (roleusercall.role == 3 || roleusercall.role == 4) ? '' : roleusercall.user_id,
          // role_id: roleusercall.role,
        }).then((response) => {
          setDatsjsf[index].hide = false;
          setDatsjsf[index].users = response.data.users
          if (roleusercall.byuser) {
            for (let usersetindex = index; usersetindex <= 10; usersetindex++) {
              if (params.id) {
                if (setDatsjsf[usersetindex + 1]) setDatsjsf[usersetindex + 1].users = [];
              } else {
                if (setDatsjsf[usersetindex]) setDatsjsf[usersetindex].value = '';
              }
            }
          }
          // for (let usersetindex = index; usersetindex <= 10; usersetindex++) {
          //   if (setDatsjsf[usersetindex] && (setDatsjsf[usersetindex].order) == roleusercall.role) {
          //     const checkid = setDatsjsf[usersetindex].users?.filter(d => d._id == setDatsjsf[usersetindex - 1].value);
          //     if (!checkid || !checkid.length) setDatsjsf[usersetindex].value = '';
          //   }
          // }
          setroletable(setDatsjsf);
          setrefresh(Math.random());
        });
      }
    }
  }, [roleusercall, apiloader]);

  useEffect(() => {
    let myuser_id = '';
    let myrole_id = '';
    roletable.map(e => {
      if (e.value) {
        myrole_id = e.id;
        const counts = e.users?.filter(d => d._id == e.value);
        if (counts && counts.length) {
          myuser_id = e.value;
          myrole_id = e.id;
        }
      }
    });
    if (!myuser_id) {
      myuser_id = localData?.user?._id;
    }
    if (myroles === 100) myrole_id = '';
    setFormdata1({ parent_user_id: myuser_id, role_id: myrole_id })
  }, [roletable, refresh, myroles]);

  useEffect(() => {
    if (updateId.length && myroles !== 100) {
      let setDatsjsf = roletable;
      updateId.forEach((e1, vt) => {
        const index = roletable.map((e, ind) => roletable[ind - 1] && e.order < 8 && myroles <= e.order && e.id).indexOf(e1.role_id);
        if (index != -1) {
          setDatsjsf[index].value = e1.parent_user_id;
          setTimeout(() => {
            setroleusercall({ role: setDatsjsf[index].order + 1, user_id: e1.parent_user_id, byuser: false })
          }, 1 * vt);
        }
        if (index == 0) {
          setTimeout(() => {
            setroleusercall({ role: 8, user_id: e1.parent_user_id, byuser: false })
          }, 1 * vt);
        }
      })
      setroletable(setDatsjsf);
      setrefresh(Math.random());
    }
  }, [updateId, myroles]);

  useEffect(() => {
    if (localData.user.role_id?.name != 'Admin') {
      setIsAgent(true)
    } else {
      setIsAgent(false)
    }
    setloadeddone(false);
    Get(HttpService.Role_list).then(roleAPI => {
      // setroles(roleAPI.data.length ? roleAPI.data.filter(e => e.type !== 'subadmin') : []);
      if (roleAPI.status === 200 && roleAPI.data.length) {
        const pushRoles = [{ id: Math.random(), order: 2, name: 'Subadmin', value: '' }];
        // const pushRoles = [];
        let setId = false;
        roleAPI.data.map(e1 => {
          if (!setId) setId = e1.order + 1;
          pushRoles.push({ id: e1._id, name: e1.name, order: e1.order, value: (e1.order == 3) ? 'asd' : '', users: [] })
        });
        pushRoles.push({ id: '', name: 'Users', order: 7, value: '', users: [] })
        pushRoles.push({ id: Math.random(), name: 'User', order: 8, value: '', users: [] })
        setroletable(pushRoles);
        if (!params.id) setroleusercall({ role: setId, user_id: '', byuser: false });
        setloadeddone(true);
      }
    });
  }, []);

  useEffect(() => {
    async function fetchData(id) {
      const response = await Postdata(HttpService.Agent_show, {
        id: id ? id : 'show'
      });
      if (response.status !== 200) {
        navigate("/user-management")
      }
      setapiloader(false);
      setgames(response.data.gametypes);
      let setformdata = {};

      if (id) {
        setformdata = {
          id: params.id,
          username: response.data.user.username,
          mobile: response.data.user.mobile,
          // role_id: response.data.user.role_id._id,
          // role_type: response.data.user.role_id.name,
          email: response.data.user.email,
          parent_user_id: response.data.user.parent_user_id || '',
          // password: response.data.user.password,
          status: response.data.user.status,
          // commissions: response.data.user.commissions,
        }

        if (response.data.user.commissions.length) {
          const setPercentages = {};
          response.data.user.commissions.map(d => {
            setPercentages[d.gametype_id] = d.commission_percent ? d.commission_percent : 0;
          })
          setTimeout(() => {
            setpercentage(setPercentages);
          }, 100);
        }
      }

      if (response.data.parent_lists) {
        setupdateId(response.data.parent_lists);
      }

      if (response.data.myrole) {
        setmyroles(response.data.myrole.order + 1);
        // id && setroleusercall({ role: response.data.myrole.order + 1, user_id: localData?.user?._id });
        setroleusercall({ role: response.data.myrole.order + 1, user_id: localData?.user?._id, byuser: false });
      }
      if (myisRoleID && !myisRoleID?.type) {
        // setformdata.role_id = response.data.myrole;
      }
      setFormdata({ ...formdata, ...setformdata });
    }
    setapiloader(true);
    if (loadeddone) {
      fetchData(params.id);
    }
  }, [loadeddone]);

  // useEffect(() => {
  //   // if (localdata?.user?.role_id) {
  //   if (myisRoleID && !myisRoleID?.type) {
  //     setFormdata({ ...formdata, parent_user_id: localData.user._id })
  //   } else if (formdata.role_id) {
  //     setAgentData([]);
  //     setIsloading(true);
  //     Postdata(HttpService.Agent_list, {
  //       page: 1,
  //       perpage: 99999,
  //       role_id: formdata.role_id,
  //     }).then((response) => {
  //       if (response.status === 200) {
  //         setAgentData(response.data.users);
  //         setIsloading(false);
  //       } else {
  //         setIsloading(false);
  //       }
  //     }).catch(() => {
  //       setIsloading(false);
  //     });
  //   }
  // }, [formdata.role_id]);

  useEffect(() => {
    const commissions = [];
    Object.keys(percentage).map((d) => commissions.push({ gametype_id: d, commission_percent: percentage[d] }));
    setFormdata({ ...formdata, commissions: JSON.stringify(commissions) })
  }, [percentage]);

  useEffect(() => {
    setlocaldata(localData);
  }, [localData]);

  const onAddAgentAction = (event) => {
    event.preventDefault();
    
    setapiloader(true);
    Postdata(HttpService.Agent_add, { ...formdata, ...formdata1 }).then((res) => {
      // if (res.status === 200) {
      //   navigate("/user-management/add");
      // }
      setLoader(true);
      dispatch(message(res.message));
      setFormdata({
        username: '',
        mobile: '',
        email: '',
        // role_id: '',
        password: '',
        status: 1,
        commissions: '[]',
      })
      
      // let current_route = window.location.pathname
      // let no_of_slashes = current_route.split('/')
      // if(no_of_slashes == 3) {
      //   current_route = "/user-management/add"
      // } else {
      //   current_route = "/user-management/add/#"
      // }
      // window.history.replaceState("", "", current_route);
      setrefresh(Math.random())
      setapiloader(false);
      // setLoader(false);
      if(params.id) {
        navigate("/user-management");
      } else {
        setLoader(false);
      }
    }).catch(e => {
      dispatch(message(e.message));
      setLoader(false);
    });
  };

  return (
    <>
      <Title name='User Management - Add' />
      <div className="row">
        <div className="col-lg-6">
          <h4>User Management</h4>
          <Breadcrumb
            pages={[
              { title: 'User Management', link: 'user-management' }
            ]}
            active={`${params.id ? 'Edit' : 'Add'} User`}
          />
        </div>
        <div className="col-lg-6 text-end">
          <button className="btn btn-danger" onClick={() => navigate(-1)}>
            Back
          </button>
        </div>
      </div>

      <div className="row">
        <div className="col-lg-12">
          <form onSubmit={onAddAgentAction}>
            <div className={`card ${apiloader ? 'd-none' : ''}`}>
              <p className="card-header">Parent User</p>
              {/* <p className="card-header">Parent {roletable.length && roletable.filter(d => d.order === 7)[0]?.value}</p> */}
              <div className="card-body">
                {(roletable)?.map((e, index) =>
                  (roletable[index - 1] && e.order < 8 && myroles <= e.order) ?
                    <div key={e.id} className={(!roletable[index - (params.id ? 0 : 1)].value || e.order === 3) ? 'd-none' : ''}>
                      {/* <div key={e.id} className={(!roletable[index - 1].value || e.order === 3) ? 'd-none' : ''}> */}
                      {/* <div key={e.id} className={e.order <= roleusercall.role ? '' : 'd-none'}> */}
                      <Form.Group className="form-group">
                        <Form.Label>{roletable[index - 1]?.name}{(!params.id || (!roletable[index - (params.id ? 0 : 1)].value || e.order === 3)) ? '' : '*'}</Form.Label>
                        <Form.Select
                          required={(!params.id || (!roletable[index - (params.id ? 0 : 1)].value || e.order === 3)) ? false : true}
                          // onChange={e1 => setroleusercall(e.order + 1)}
                          // onChange={e1 => setroleusercall(e.id)}
                          // required={e.order === 3}
                          disabled={e.hide}
                          onChange={e1 => setroleusercall({ role: e.order + 1, user_id: e1.target.value, byuser: true })}
                          value={e.value}
                        >
                          <option value="0">{e.hide ? 'Please wait...' : '--- Please Select ---'}</option>
                          {(e.users)?.map((es) => (
                            <option value={es._id} key={es._id}>{es.username}</option>
                          ))}
                        </Form.Select>
                      </Form.Group>
                    </div>
                    : ''
                )}
              </div>
            </div>

            <div className="card">
              <p className="card-header">{params.id ? 'Edit User' : 'Add User'}</p>
              <div className="card-body">
                {apiloader ?
                  <Loader /> :
                  <>
                    <Form.Group className="form-group">
                      <Form.Label>Username*</Form.Label>
                      <Form.Control
                        type="text"
                        value={formdata.username}
                        onChange={e => setFormdata({ ...formdata, username: (e.target.value).toString().replace(/[^a-zA-Z0-9]+/, "").trim() })}
                        pattern="[a-zA-Z0-9]{5,15}"
                        onInvalid={e => e.target.setCustomValidity('Username must be between 5 to 15 characters')}
                        onInput={e => e.target.setCustomValidity('')}
                        disabled={params.id}
                        required={!formdata.id}
                      />
                    </Form.Group>

                    {/* {(!myisRoleID || myisRoleID?.type) ? <> */}
                    {/* <Form.Group className="form-group">
                      <Form.Label>Role*</Form.Label>
                      <Form.Select
                        value={formdata1.role_id}
                        required
                        onChange={e => setFormdata({ ...formdata, role_id: e.target.value })}
                      >
                        <option value="" hidden> </option>
                        {(roles)?.map((e) => (
                          <option value={e._id} key={e._id}>{e.name}</option>
                        ))}
                      </Form.Select>
                    </Form.Group> */}

                    {/* <Form.Group className="form-group">
                      <Form.Label>Parent User / Member*</Form.Label>
                      <Form.Select
                        required
                        value={formdata.parent_user_id}
                        onChange={e => setFormdata({ ...formdata, parent_user_id: e.target.value })}
                        disabled={loaderforagent}
                      >
                        <option value="" hidden>{loaderforagent ? 'Please wait...' : ' '}</option>
                        {(agentData)?.map((e) => (
                          <option value={e._id} key={e._id}>{e.name}</option>
                        ))}
                      </Form.Select>
                    </Form.Group> */}
                    {/* </> : ''} */}

                    <Form.Group className="form-group">
                      <Form.Label>Password{!formdata.id && '*'}</Form.Label>
                      <Form.Control
                        type="password"
                        value={formdata.password}
                        onChange={e => setFormdata({ ...formdata, password: e.target.value })}
                        required={!formdata.id}
                      />
                    </Form.Group>

                    <Form.Group className="form-group">
                      <Form.Label>Email</Form.Label>
                      <Form.Control
                        type="email"
                        value={formdata.email}
                        onChange={e => setFormdata({ ...formdata, email: e.target.value })}
                        disabled={isAgent && params.id}
                      // required
                      />
                    </Form.Group>
                    <Form.Group className="form-group">
                      <Form.Label>Mobile</Form.Label>
                      <Form.Control
                        type="text"
                        pattern="[0-9]{9,15}"
                        onInvalid={e => e.target.setCustomValidity('Please enter valid mobile number')}
                        onInput={e => e.target.setCustomValidity('')}
                        value={formdata.mobile}
                        onChange={e => setFormdata({ ...formdata, mobile: e.target.value })}
                        required={Boolean(formdata.mobile.length)}
                        disabled={isAgent && params.id}
                      />
                    </Form.Group>

                    <Form.Group className="form-group">
                      <Form.Label>Status</Form.Label>
                      <Form.Check
                        type="switch"
                        checked={formdata.status}
                        onChange={e => setFormdata({ ...formdata, status: Number(e.target.checked) })}
                        disabled={isAgent && params.id}
                      />
                    </Form.Group>

                    {(games.length && roletable.length && !roletable.filter(d => d.order === 7)[0]?.value) ?
                      <Form.Group className="form-group">
                        {/* <Form.Label>Commission</Form.Label> */}
                        <table className="table v-center">
                          <thead>
                            <tr>
                              <th width='200'>Game Name</th>
                              <th>Commission (%)*</th>
                            </tr>
                          </thead>
                          <tbody>

                            {(games)?.map((e) => (
                              <tr key={e._id}>
                                <td>{e.name}</td>
                                <td>
                                  <Form.Control
                                    type="number"
                                    min="0"
                                    max="100"
                                    step="0.01"
                                    value={percentage[e._id] ? percentage[e._id] : ""}
                                    onChange={d => setpercentage({ ...percentage, [e._id]: d.target.value })}
                                  />
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </Form.Group>
                      : ''}

                    <button className="btn btn-primary" type="submit" disabled={loader}>
                      {loader ? 'Please wait...' : (formdata.id ? 'Update' : 'Submit')}
                    </button>
                    <Link className="btn btn-danger" to={'/user-management'}>Cancel</Link>
                  </>
                }
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  )
}

export { UserManagementAdd }