import * as types from "../constants/constants";
import { Postdata } from "../../utils/api.service"
import HttpService from "../../utils/http.service"

export const verifyToken = () => {
  return (dispatch) => {
    try {
      Postdata(HttpService.verifyTokenUrl, { token: window.localStorage.getItem('authorizationToken') }).then(
        (result) => {
          if (result.status === 200) {
            dispatch(loginSuccess(result));
          } else {
            dispatch(loginFail(result.message));
            dispatch(loginForce());
          }
        },
        (e) => {
          dispatch(loginFail(e.message));
          dispatch(loginForce());
        }
      );
    } catch (error) {
      dispatch(loginFail(error.message));
      dispatch(loginForce());
    }
  };
};

export const login = (data) => {
  return (dispatch) => {
    try {
      dispatch(loginInit());
      Postdata(HttpService.loginUrl, data).then(
        (result) => {
          if (result.status === 200) {
            dispatch(loginSuccess(result));
          } else {
            dispatch(loginFail(result.message));
          }
        },
        (e) => {
          dispatch(loginFail(e.message));
        }
      );
    } catch (error) {
      dispatch(loginFail(error.message));
    }
  };
};

export const loginInit = (data) => {
  return { type: types.LOGIN_DATA, data };
};

export const loginSuccess = (data) => {
  window.localStorage.setItem('authorizationToken', data.data.token);
  return { type: types.LOGIN_DATA_SUCCESS, data };
};

export const loginOverride = (data) => {
  return { type: types.LOGIN_DATA_OVERRIDE, data };
};

export const loginFail = (data) => {
  window.localStorage.removeItem('authorizationToken');
  window.sessionStorage.clear();
  return { type: types.LOGIN_DATA_FAIL, data };
};

export const loginClear = (data) => {
  window.localStorage.removeItem('authorizationToken');
  window.sessionStorage.clear();
  return { type: types.LOGIN_DATA_CLEAR, data };
};

export const loginForce = () => {
  window.localStorage.removeItem('authorizationToken');
  window.sessionStorage.clear();
  return window.location.href = '/';
};