/* eslint-disable no-unused-vars */
import { Form } from 'react-bootstrap';
import '../../../styles/Profile.scss';
import { useState } from 'react';
import { message } from '../../../redux/actions/toastActions';
import { useDispatch } from 'react-redux';
import { Postdata } from '../../../utils/api.service';
import HttpService from '../../../utils/http.service';

const UpdatePassword = () => {
  const [loader, setLoader] = useState(false);
  const [formdata, setFormdata] = useState({
    old: '',
    new: '',
    confirm: '',
  });
  const dispatch = useDispatch();

  const onUpdatePassword = (event) => {
    event.preventDefault();
    setLoader(true);
    if (formdata.new !== formdata.confirm) {
      dispatch(message('New password and confirm password do not match. please enter again.'));
      setFormdata({ ...formdata, confirm: '' });
      setLoader(false);
    } else {
    Postdata(HttpService.Admin_change_password, {
      // old_password: formdata.old,
      new_password: formdata.confirm,
    }).then((res) => {
      setLoader(false);
      setFormdata({
        old: '',
        new: '',
        confirm: '',
      });
      dispatch(message(res.message));
    }).catch((e) => {
      setLoader(false);
      dispatch(message(e.message));
    });
    }
  }

  return (
    <>
      <div className="card-body">
        <div className="row">
          <div className="col-lg-12">
            <form onSubmit={onUpdatePassword}>
              {/* <Form.Group className="form-group">
                <Form.Label>Old Password*</Form.Label>
                <Form.Control
                  type="password"
                  value={formdata.old}
                  onChange={e => setFormdata({ ...formdata, old: e.target.value })}
                  autoComplete="current-password"
                  required
                />
              </Form.Group> */}

              <Form.Group className="form-group">
                <Form.Label>New Password*</Form.Label>
                <Form.Control
                  type="password"
                  value={formdata.new}
                  onChange={e => setFormdata({ ...formdata, new: e.target.value })}
                  autoComplete="new-password"
                  required
                />
              </Form.Group>

              <Form.Group className="form-group">
                <Form.Label>Confirm Password*</Form.Label>
                <Form.Control
                  type="password"
                  value={formdata.confirm}
                  onChange={e => setFormdata({ ...formdata, confirm: e.target.value })}
                  autoComplete="repeat-password"
                  required
                />
              </Form.Group>

              <button className="btn btn-primary" type="submit" disabled={loader}>
                {loader ? 'Please wait...' : 'Update'}
              </button>
            </form>
          </div>
        </div>
      </div>
    </>
  )
}

export { UpdatePassword }