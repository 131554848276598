/* eslint-disable no-unused-vars */
import { useEffect, useState } from "react";
import { Tabs, Tab } from "react-bootstrap";
import { useSelector } from "react-redux";

import Title from "../../common/Title";
import Breadcrumb from "../../common/Breadcrumb";
import { CheckPermission } from "../../../utils/Helper";
import { isPermission } from "../../../redux/selectors/authSelector";
import { Get } from "../../../utils/api.service";
import HttpService from "../../../utils/http.service";
import { DigitainGameList } from "./DigitainGameList";
import { DigitainProviderList } from "./DigitainProviderList";
import { DigitainCategoryList } from "./DigitainCategoryList";
import { FetchAllGamesFromServerBtn } from "./FetchAllGamesFromServerBtn/FetchAllGamesFromServerBtn";
import { useParams } from "react-router-dom";


const DigitainGameSettings = () => {
  const { gameType } = useParams();
  const [key, setKey] = useState(window.sessionStorage.getItem('digitainTabs') ? window.sessionStorage.getItem('digitainTabs') : 'categories');
  const permission = useSelector(isPermission);
  console.log(gameType);
  const  heading = gameType==='slots'?'Slot Game Settings': 'Live Casino Settings';
  //api expects words separated by underscores and not hyphens
  const apiKeyForGameType = gameType === "slots" ? "slots" : "live_dealer";

  // const topnames = {
  //   coins: '',
  //   gamemanagement: 'Game Management',
  //   gametype: 'Game Type',
  //   agent: 'Agent Request',
  //   vip_membership : 'Vip Membership'
  // };

  // let fetchDigitainGames = async(pagetoLoad = 0) => {
  //   let response = await Get(HttpService.Fetch_digitain_games);
  //   console.log("fetched all games. Next page to load "+response.message);
  //   // if(response.data.nextPage > 0){
  //   //   console.log("fetched all games. Next page to load "+nextPage);
  //   // // }
  // }

  // useEffect(() => {
  //   fetchDigitainGames();

  // }, []);

  return (
    <>
      <Title name={heading} />
      <h4>{heading}</h4>
      <Breadcrumb active={heading}/>
      <FetchAllGamesFromServerBtn/>

      <div className="row">
        <div className="col-lg-12">
          <div className="card tabspage">
            <Tabs
              activeKey={key}
              onSelect={(k) => { window.sessionStorage.setItem('digitainTabs', k); setKey(k) }}
            >
              <Tab eventKey="categories" title="Categories">
                <DigitainCategoryList key={apiKeyForGameType} gameType={apiKeyForGameType}/>
              </Tab>
              <Tab eventKey="games" title="Games">
                <DigitainGameList key={apiKeyForGameType} gameType={apiKeyForGameType}/>
              </Tab>
              <Tab eventKey="providers" title="Providers">
                <DigitainProviderList/>
              </Tab>
            </Tabs>
          </div>
        </div>
      </div>
    </>
  )
}

export { DigitainGameSettings }