import * as types from "../constants/constants";

let initialState = {
  data: [],
  error: "",
  message: "",
  loading: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case types.FORGOTPASSWORD_DATA:
      return {
        ...state,
        error: "",
        message: "",
        loading: true
      };
    case types.FORGOTPASSWORD_DATA_SUCCESS:
      return {
        ...state,
        data: action.data.data,
        error: "",
        message: action.data.message,
        loading: false
      };
    case types.FORGOTPASSWORD_DATA_FAIL:
      return {
        ...state,
        data: [],
        message: "",
        error: action.data,
        loading: false
      };
    case types.FORGOTPASSWORD_DATA_CLEAR:
      return {
        ...state,
        message: "",
        data: [],
        error: "",
        loading: false
      };
    default:
      return state;
  }
};
