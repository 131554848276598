/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import Title from "../../common/Title";
import { Form, Collapse } from "react-bootstrap";
import React, { useState, useEffect } from "react";
import { Postdata } from '../../../utils/api.service';
import DataTableBase from "../../common/DataTableBase";
import HttpService from "../../../utils/http.service";
import { CheckPermission, ConvertToDMYHIS } from "../../../utils/Helper";
import { useDispatch, useSelector } from "react-redux";
import { message } from "../../../redux/actions/toastActions";
import Breadcrumb from "../../common/Breadcrumb";
import ModalPopup from "../../common/Modal";
import Loader from "../../common/Loader";
import CSV from "../../common/CSV";
import Link from "../../common/Link";
import { isPermission } from "../../../redux/selectors/authSelector";

function Request_coin_add() {
    const [data, setData] = useState([]);
    const [isloading, setIsloading] = useState(true);
    const [databank, setDataBank] = useState([]);
    const [isbankloading, setBankIsloading] = useState(true);
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setperPage] = useState(process.env.REACT_APP_RecordPerPage);
    const [currentPage, setcurrentPage] = useState(1);
    const [refresh, setRefresh] = useState(false);
    const dispatch = useDispatch();
    const [open, setOpen] = useState(false);
    const [search, setSearch] = useState('');
    const [coinStatus, setCoinStatus] = useState('all')

    const [modalswitch, setmodalswitch] = useState(false);
    const [modalswitchcontent, setmodalswitchcontent] = useState([0, 0, 0]);
    const [formdata, setFormdata] = useState({
        id: '',
        remarks: '',
        status: '',
    });

    const handlePerRowsChange = async (newPerPage, page) => {
        setperPage(newPerPage);
        setcurrentPage(page);
    };

    const handlePageChange = (page) => {
        setcurrentPage(page);
    };

    const handleStatusChange = (id) => {
        setFormdata({
            ...formdata,
            id
        })
        setmodalswitch(true);
    }

    const handleStatusChangeAction = (event) => {
        event.preventDefault();
        setmodalswitch(false);
        setIsloading(true);
        Postdata(HttpService.Request_coin_add_update, formdata).then((res) => {
            setRefresh(!refresh);
            dispatch(message(res.message));
        }).catch((res) => {
            setRefresh(!refresh);
            dispatch(message(res.message));
        })
    }

    const columns = [
        {
            name: '#',
            width: "55px",
            selector: (row, i) => (currentPage * perPage + (i + 1) - perPage),
            center: true
        },
        {
            name: 'Username',
            selector: row => row.user_id.username,
            center: true
        },
        {
            name: 'Coins',
            selector: row => row.coins,
            center: true,
        },
        {
            name: 'Amount (INR)',
            selector: row => row.coins_price,
            center: true
        },
        {
            name: 'Documents',
            cell: (row) =>
                <div>
                    <a href={row.image_url} target="_blank" rel="noopener noreferrer">
                        <img src={row.image_url} style={{ width: "50px", height: "50px" }} />
                    </a>
                </div>,
            center: true
        },
        {
            name: 'Status',
            selector: row => <span className="text-cap">{row.status}</span>,
            center: true
        },
        {
            name: 'Request Date',
            selector: row => ConvertToDMYHIS(row.createdAt),
            center: true,
            width: "150px"
        },
        // {
        //     name: 'Action By',
        //     cell : (row) => <>
        //     <span className="text-cap">
        //         { row?.approved_by_user_type != 'bank' ? (row?.approved_by_details?.username || '-') : 'Bank' }
        //     </span>
        //     </>,
        //     center : true,
        // },
        {
            name: 'Verified By (Role)',
            cell : (row) => <>
            <span className="text-cap">
            { row?.approved_by_user_type != 'bank' && row?.approved_by_details?.username && row?.approved_by_user_type ? 
            <>
            {`${row?.approved_by_details?.username || ''}(${row?.approved_by_user_type || ''})` || 'Bank'}
            </> : ''}
            </span>
            </>,
            center : true,
            width : '150px'
        },
        {
            name: 'Action',
            id: 'actions',
            cell: (row) =>
                <span className="actions">
                    <i className="mdi mdi-eye" onClick={() => handleStatusChange(row._id)} />
                </span>,
            button: true,
            center: true
        }
    ];

    useEffect(() => {
        setIsloading(true);
        Postdata(HttpService.Request_coin_add_list, {
            page: currentPage,
            perpage: perPage,
            search,
            coinStatus
        }).then((response) => {
            if (response.status === 200) {
                // console.log(response.data.records)
                setData(response.data.records);
                setTotalRows(response.data.total);
                setIsloading(false);
            } else {
                setIsloading(false);
                dispatch(message(response.message));
            }
        }).catch((e) => {
            setIsloading(false);
            dispatch(message(e.message));
        });
    }, [currentPage, perPage, refresh, search, coinStatus]);

    return (
        <>
            <Title name='Request Management - Coin Add Request' />

            <ModalPopup
                className="alert-box-popup"
                show={modalswitch}
                size="md"
                onHide={() => setmodalswitch(false)}
                heading="Update Request?"
            >
                <div className='actions mt-0'>
                    <form onSubmit={handleStatusChangeAction} className="text-start" style={{ width: '100%' }}>
                        <Form.Group className="form-group">
                            <Form.Label>Remarks</Form.Label>
                            <Form.Control
                                as="textarea"
                                value={formdata.remarks}
                                onChange={e => setFormdata({ ...formdata, remarks: e.target.value })}
                            />
                        </Form.Group>

                        <button className="btn btn-primary" onClick={() => setFormdata({ ...formdata, status: 'approved' })}>Approve</button>
                        <button className="btn btn-danger" onClick={() => setFormdata({ ...formdata, status: 'rejected' })}>Reject</button>
                    </form>
                </div>
            </ModalPopup>


            <div className="row">
                <div className="col-lg-8">
                    <h4>Request Management</h4>
                    <Breadcrumb
                        pages={[
                            { title: 'Request Management', link: '' }
                        ]}
                        active="Coin add request"
                    />
                </div>
                <div className="col-lg-4 text-end">
                    <button className="btn btn-danger" onClick={() => setOpen(!open)}>Search</button>
                    <CSV
                        link={HttpService.Request_coin_add_list}
                        name="Request Management - Coin Add Request"
                        response="records"
                        headers={[
                            { label: "User ID", key: "user_id.user_id" },
                            { label: "Username", key: "user_id.username" },
                            { label: "Email", key: "user_id.email" },
                            { label: "Add Request (Coins)", key: "coins" },
                            { label: "Amount in Rupees (INR)", key: "coins_price" },
                            { label: "Status", key: "status" },
                            { label: "Request Date", key: "createdAt" },
                        ]}
                    />
                </div>
            </div>

            <Collapse in={open}>
                <div>
                    <div className="card">
                        <p className="card-header">Search</p>
                        <div className="card-body">
                            <div className="row">
                                <div className="col-lg-4">
                                    <Form.Group className="form-group mb-0">
                                        <Form.Label>Username</Form.Label>
                                        <Form.Control
                                            type="text"
                                            onChange={e => setSearch(e.target.value)}
                                            required
                                        />
                                    </Form.Group>
                                </div>
                                <div className="col-lg-4">
                                    <Form.Group className="form-group">
                                        <Form.Label>Status</Form.Label>
                                        <Form.Select onChange={e => setCoinStatus(e.target.value)}>
                                            <option value="all">All</option>
                                            <option value="approved">Approved</option>
                                            <option value="pending">Pending</option>
                                            <option value="rejected">Rejected</option>
                                        </Form.Select>
                                    </Form.Group >
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Collapse>

            <div className="card">
                <p className="card-header">List of Coin Add Requests</p>
                <div className="card-body">
                    <DataTableBase
                        columns={columns}
                        loader={isloading}
                        data={data}
                        paginationTotalRows={totalRows}
                        onChangeRowsPerPage={handlePerRowsChange}
                        onChangePage={handlePageChange}
                    />
                </div>
            </div>
        </>
    )
}

export { Request_coin_add }