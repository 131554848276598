/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import '../../../styles/Profile.scss';
import DataTableBase from '../../common/DataTableBase';
import { Collapse, Form } from 'react-bootstrap';
import ModalPopup from '../../common/Modal';
import { Postdata } from '../../../utils/api.service';
import HttpService from '../../../utils/http.service';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { message } from '../../../redux/actions/toastActions';
import { ConvertToDMYHIS } from '../../../utils/Helper';
import CSV from '../../common/CSV'

const Open_Bets = (props) => {
    const [open, setOpen] = useState(false);
    const [modalChatView, setmodalChatView] = useState(false);
    const [Data, setData] = useState([])
    const [isLoading, setIsloading] = useState(true)
    const dispatch = useDispatch()
    const [refresh, setrefresh] = useState(true)

    useEffect(() => {
        Postdata(HttpService.User_arrowwood_open_bets, { username: props.username }).then((response) => {
            // console.log(response.data)
            setData(response.data);
            setIsloading(false);
        }).catch((e) => {
            setIsloading(false);
            dispatch(message(e.message));
        });
    }, [refresh]);

    const columns = [
        {
            name: 'No.',
            selector: (row, i) => i + 1,
            width: '50px'
        },
        {
            name: 'Market Name',
            selector: row => row?.marketName || "-",
            width : '150px'
        },
        {
            name: 'Sport',
            selector: row => row?.sport || "-"
        },
        {
            name: 'Type',
            selector: row => row?.bettingType || "-"
        },
        {
            name: 'Competition Name',
            selector: row => row?.competitionName || '-' ,
            width : '150px'
        },
        {
            name: 'Event Name',
            selector: row => row?.eventName || '-',
        },
        {
            name: 'Selection Name',
            selector: row => row?.selectionName || '-',
            width : '150px'
        },
        {
            name: 'Odds',
            selector: row => row?.odds || '-',
        },
        {
            name: 'Stake',
            selector: row => row?.stake || '-',
        },
        {
            name: 'Profit/Loss',
            selector: row => row?.profitLoss || '-',
        },
        {
            name: 'Market Time',
            selector: row => ConvertToDMYHIS(row.marketTime),
        },
        {
            name: 'Bet Placed At',
            selector: row => ConvertToDMYHIS(row.betPlacedDate),
        },
    ];


    return (
        <>
            <div className="card-body filters">
                <div className="row searchaction">
                    <div className="col-lg-4">
                        <h5>Open Bets</h5>
                    </div>
                    {/* <div className="col-lg-8 text-end">
                        <CSV
                            link={HttpService.Match_user_contest_details}
                            extra={{
                                id: props.id
                            }}
                            response="contest"
                            name="User : Fantasy Games"
                            headers={[
                                { label: "ID", key: "_id" },
                                { label: "Type", key: "match_details.sports" },
                                { label: "Match Name", key: "match_details.title" },
                                { label: "Entry Coins", key: "contest_details.entry_fee" },
                                { label: "Total Gained Points", key: "score" },
                                { label: "Created At", key: "score" },
                            ]} />
                        <button className="btn btn-danger" onClick={() => setOpen(!open)}>Search</button>
                    </div> */}
                </div>

                <Collapse in={open}>
                    <div>
                        <div className="card">
                            <p className="card-header">Search</p>
                            <div className="card-body">
                                <Form.Group className="form-group mb-0">
                                    <Form.Label>Search</Form.Label>
                                    <Form.Control
                                        type="text"
                                        required
                                    />
                                </Form.Group>
                            </div>
                        </div>
                    </div>
                </Collapse>

                <DataTableBase
                    columns={columns}
                    pagination={true}
                    data={Data}
                />

                <ModalPopup
                    show={modalChatView}
                    size="lg"
                    onHide={() => setmodalChatView(false)}
                    heading="Team T21"
                >
                    Team List
                </ModalPopup>
            </div>
        </>
    )
}

export { Open_Bets }