/* eslint-disable no-unused-vars */
import { Form } from 'react-bootstrap';
import { useEffect, useState } from 'react';
import '../../styles/Setting.scss';
import DataTableBase from '../common/DataTableBase';
import { useDispatch, useSelector } from 'react-redux';
import { Postdata } from '../../utils/api.service';
import HttpService from '../../utils/http.service';
import { message } from '../../redux/actions/toastActions';
import ModalPopup from '../common/Modal';
import { CheckPermission, ConvertToDMYHIS } from '../../utils/Helper';
import Title from '../common/Title';
import Breadcrumb from '../common/Breadcrumb';
import Link from '../common/Link';
import { isPermission } from '../../redux/selectors/authSelector';

const Rewards = () => {
  const [data, setData] = useState([]);
  const [isloading, setIsloading] = useState(true);
  const [refresh, setrefresh] = useState(true);
  const permission = useSelector(isPermission);
  const dispatch = useDispatch();

  const [modalAddUser, setmodalAddUser] = useState(false);
  const [loader, setLoader] = useState(false);
  const [formdata, setformdata] = useState({
    id: '',
    key: '',
    api_key: '',
    value: '',
  });

  useEffect(() => {
    setIsloading(true);
    Postdata(HttpService.Setting_show_hidden, {}).then((response) => {
      setData(response.data);
      setIsloading(false);
    }).catch((e) => {
      setIsloading(false);
      dispatch(message(e.message));
    });
  }, [refresh]);

  const onAddUserAction = (event) => {
    event.preventDefault();
    setLoader(true);
    Postdata(HttpService.Setting_save, { ...formdata, type: 'rewards' }).then((res) => {
      dispatch(message(res.message));
      setLoader(false);
      if (res.status === 200) {
        setmodalAddUser(false);
        setrefresh(!refresh);
      }
    });
  };

  const editPopup = (e) => {
    setformdata({
      id: e._id,
      key: e.key,
      value: e.value,
      api_key: e.api_key,
    });
    setmodalAddUser(true);
  }

  const columns = [
    {
      name: 'Sr. No.',
      selector: (row, i) => i + 1,
      width: '70px'
    },
    {
      name: 'Day',
      selector: row => row.key,
    },
    {
      name: 'Coins',
      selector: row => row.value,
    },
    {
      name: 'Updated',
      selector: row => ConvertToDMYHIS(row.updatedAt),
    },
    {
      name: 'Action',
      id: 'actions',
      hide: (!CheckPermission(permission, '/rewards-management/edit') && 999999),
      cell: (row) =>
        <span className="actions">
          <Link permission="/rewards-management/edit"><i onClick={() => editPopup(row)} className="mdi mdi-pen" /></Link>
        </span>,
      button: true,
    }
  ];

  return (
    <>
      <Title name='Rewards Management' />
      <div className="row">
        <div className="col-lg-6">
          <h4>Rewards Management</h4>
          <Breadcrumb
            active="Rewards Management"
          />
        </div>
        <div className="col-lg-6">
        </div>
      </div>

      <ModalPopup
        show={modalAddUser}
        size="md"
        onHide={() => setmodalAddUser(false)}
        heading="Rewards"
      >
        <form onSubmit={onAddUserAction}>
          <Form.Group className="form-group">
            <Form.Label>{formdata.key}*</Form.Label>
            <Form.Control
              autoFocus
              type="number"
              min="1"
              step="1"
              onChange={(e) => setformdata({ ...formdata, value: e.target.value })}
              value={formdata.value}
              required
            />
          </Form.Group>

          <button className="btn btn-primary" type="submit" disabled={loader}>
            {loader ? 'Please wait...' : 'Update'}
          </button>
        </form>
      </ModalPopup>

      <div className="card">
        <p className="card-header">List of Rewards</p>
        <div className="card-body">
          <DataTableBase
            columns={columns}
            pagination={false}
            data={data}
            loader={isloading}
          />
        </div>
      </div>
    </>
  )
}

export { Rewards }