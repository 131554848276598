import * as types from "../constants/constants";

export const message = (data) => {
  return (dispatch) => {
    dispatch(addMessage(data));
  };
};

export const clearMessage = () => {
  return { type: types.TOASTMESSAGE_DATA_CLEAR };
};
export const addMessage = (data) => {
  return { type: types.TOASTMESSAGE_DATA, data };
};