export const LOGIN_DATA = "LOGIN_DATA";
export const LOGIN_DATA_SUCCESS = "LOGIN_DATA_SUCCESS";
export const LOGIN_DATA_FAIL = "LOGIN_DATA_FAIL";
export const LOGIN_DATA_CLEAR = "LOGIN_DATA_CLEAR";
export const LOGIN_DATA_OVERRIDE = "LOGIN_DATA_OVERRIDE";

export const FORGOTPASSWORD_DATA = "FORGOTPASSWORD_DATA";
export const FORGOTPASSWORD_DATA_SUCCESS = "FORGOTPASSWORD_DATA_SUCCESS";
export const FORGOTPASSWORD_DATA_FAIL = "FORGOTPASSWORD_DATA_FAIL";
export const FORGOTPASSWORD_DATA_CLEAR = "FORGOTPASSWORD_DATA_CLEAR";

export const TOASTMESSAGE_DATA = "TOASTMESSAGE_DATA";
export const TOASTMESSAGE_DATA_CLEAR = "TOASTMESSAGE_DATA_CLEAR";

export const CHATLIST = "CHATLIST";
export const CHATLISTLOADING = "CHATLISTLOADING";
