/* eslint-disable react/prop-types */
import { Modal } from 'react-bootstrap';
import '../../styles/Modal.scss';

function ModalPopup(props) {
  return (
    <Modal
      centered
      // backdrop="static"
      // keyboard={false}
      {...props}
    >
      <Modal.Header closeButton>
        <Modal.Title>{props.heading}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {props.children}
      </Modal.Body>
    </Modal>
  );
}


export default ModalPopup;
