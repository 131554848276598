/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react'
import DataTableBase from '../../common/DataTableBase'
import { Postdata } from '../../../utils/api.service';
import { Collapse, Form } from 'react-bootstrap';
import '../../../styles/Profile.scss'
import HttpService from '../../../utils/http.service';
import { useDispatch } from 'react-redux';
import { message } from '../../../redux/actions/toastActions';
import { ConvertToDMYHIS } from '../../../utils/Helper';
import Link from '../../common/Link';
import ModalPopup from '../../common/Modal';
import Loader from '../../common/Loader';

const AgentRequest = () => {
  const [open, setOpen] = useState(false);
  const [isloading, setIsloading] = useState(true);
  const [data, setData] = useState([]);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setperPage] = useState(10);
  const [currentPage, setcurrentPage] = useState(1);
  const dispatch = useDispatch();
  const [search, setSearch] = useState(false);
  const [refresh, setRefresh] = useState(true);

  const [databank, setDataBank] = useState([]);
  const [isbankloading, setBankIsloading] = useState(true);

  const [modalswitch, setmodalswitch] = useState(false);
  const [modalswitchcontent, setmodalswitchcontent] = useState(false);
  const [agent_role_id, setagent_role_id] = useState(false);
  const [formdata, setFormdata] = useState({});
  const columns = [
    {
      name: 'Full Name',
      selector: row => row.name,
    },
    {
      name: 'Mobile No.',
      selector: row => row.mobile,
    },
    {
      name: 'Email',
      selector: row => row.email,
    },
    {
      name: 'Location',
      selector: row => row.location,
    },
    {
      name: 'Description',
      selector: row => row.description,
    },
    {
      name: 'Requested At',
      selector: row => ConvertToDMYHIS(row.createdAt),
    },
    {
      name: 'Action',
      id: 'actions',
      //   hide: (!CheckPermission(permission, '/request-management/edit') && 999999),
      cell: (row) =>
        <span className="actions">
          {row.status === 'pending' ?
            <Link permission="/agentrequest/edit"><i onClick={() => handleStatusChange(row)} className="mdi mdi-eye" /></Link>
            : ''}
        </span>,
      button: true,
    }
  ];

  useEffect(() => {
    setFormdata({ ...formdata, status: false });
    setDataBank([]);
    setBankIsloading(true);
    if (modalswitch) {
      Postdata(HttpService.Agent_list, {
        page: 1,
        perpage: 99999,
        role_id: agent_role_id,
      }).then((res) => {
        if (res.status === 200) {
          setDataBank(res.data.users);
        }
        setBankIsloading(false);
      }).catch(() => {
        setBankIsloading(false);
      })
    }
  }, [modalswitch]);

  const handleStatusChange = (data) => {
    setmodalswitchcontent(data);
    setmodalswitch(true);
  };

  const handleStatusChangeAction = (event) => {
    event.preventDefault();
    setmodalswitch(false);
    setIsloading(true);
    Postdata(HttpService.Agent_request_update, {
      ...formdata,
      id: modalswitchcontent._id
    }).then((res) => {
      setRefresh(!refresh);
      dispatch(message(res.message));
    }).catch((res) => {
      setRefresh(!refresh);
      dispatch(message(res.message));
    })
  }

  const handlePerRowsChange = async (newPerPage, page) => {
    setperPage(newPerPage);
    setcurrentPage(page);
  };

  const handlePageChange = (page) => {
    setcurrentPage(page);
  };

  useEffect(() => {
    if (search !== false) {
      const timeOutId = setTimeout(() => setRefresh(!refresh), 500);
      return () => clearTimeout(timeOutId);
    }
  }, [search]);

  useEffect(() => {
    setIsloading(true);
    Postdata(HttpService.Agent_request, {
      page: currentPage,
      perpage: perPage,
      search: (search ? search : ''),
    }).then((response) => {
      if (response.status === 200) {
        setData(response.data.users);
        setTotalRows(response.data.count);
        setagent_role_id(response.data.search_roles.filter((e) => e.name === 'Agent')[0]._id);
      } else {
        dispatch(message(response.message));
      }
      setIsloading(false);
    }).catch((e) => {
      setIsloading(false);
      dispatch(message(e.message));
    });
  }, [currentPage, perPage, refresh]);

  useEffect(() => {
    if (agent_role_id) {
      setFormdata({
        ...formdata,
        name: modalswitchcontent.name,
        password: "123456",
        email: modalswitchcontent.email,
        role_id: agent_role_id
      });
    }
  }, [agent_role_id, modalswitchcontent]);

  return (
    <div className='card-body filters'>

      <ModalPopup
        className="alert-box-popup"
        show={modalswitch}
        size="md"
        onHide={() => setmodalswitch(false)}
        heading="Update Request?"
      >
        <div className='actions mt-0'>
          <form onSubmit={handleStatusChangeAction} className="text-start" style={{ width: '100%' }}>
            {
              isbankloading ?
                <Loader />
                :
                <>
                  <Form.Group className="form-group">
                    <Form.Label>Parent User / Member{formdata.status === 'rejected' ? '' : '*'}</Form.Label>
                    <Form.Select
                      required={formdata.status === 'rejected' ? false : true}
                      onChange={e => setFormdata({ ...formdata, parent_user_id: e.target.value })}
                    >
                      <option value="" hidden> </option>
                      <AgentList data={databank} type={agent_role_id} />
                    </Form.Select>
                  </Form.Group >

                  <button className="btn btn-primary" onClick={() => setFormdata({ ...formdata, status: 'approved' })}>Approve</button>
                  <button className="btn btn-danger" onClick={() => setFormdata({ ...formdata, status: 'rejected' })}>Reject</button>
                </>
            }
          </form>
        </div>
      </ModalPopup>

      <div className="row searchaction">
        <div className="col-lg-4">
          <h5>Agent Request</h5>
        </div>
        <div className="col-lg-8 text-end">
          <button className="btn btn-danger" onClick={() => setOpen(!open)}>Search</button>
        </div>
      </div>

      <Collapse in={open}>
        <div>
          <div className="card">
            <p className="card-header">Search</p>
            <div className="card-body">
              <Form.Group className="form-group mb-0">
                <Form.Label>Search</Form.Label>
                <Form.Control
                  type="text"
                  onChange={e => setSearch(e.target.value)}
                  required
                />
              </Form.Group>
            </div>
          </div>
        </div>
      </Collapse>

      <DataTableBase
        columns={columns}
        loader={isloading}
        data={data}
        paginationTotalRows={totalRows}
        onChangeRowsPerPage={handlePerRowsChange}
        onChangePage={handlePageChange}
      />
    </div>
  )
}

const AgentList = (props) => {
  return (
    (props.data)?.map((e) => (
      <option value={e._id} key={e._id}>{e.username}</option>
    ))
  )
}

export { AgentRequest }