/* eslint-disable no-unused-vars */
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

import '../../../styles/Profile.scss';
import { useEffect, useState } from 'react';
import DataTableBase from '../../common/DataTableBase';
import { Form } from 'react-bootstrap';
import { Get, Postdata } from '../../../utils/api.service';
import HttpService from '../../../utils/http.service';
import { useDispatch, useSelector } from 'react-redux';
import { message } from '../../../redux/actions/toastActions';
import Link from '../../common/Link';
import { CheckPermission } from '../../../utils/Helper';
import { isPermission } from '../../../redux/selectors/authSelector';


const CMSSetting = () => {
  const [edit, setEdit] = useState({});
  const [editorData, setEditorData] = useState('');
  const [loader, setLoader] = useState(false);

  const [isloading, setIsloading] = useState(false);
  const [data, setData] = useState([]);
  const dispatch = useDispatch();
  const permission = useSelector(isPermission);

  const columns = [
    {
      name: 'Page Name',
      selector: row => row.title,
    },
    {
      name: 'Action',
      id: 'actions',
      hide: (!CheckPermission(permission, '/cms/edit') && 999999),
      cell: (row) =>
        <span className="actions">
          <Link permission="/cms/edit"><i className="mdi mdi-pen" onClick={() => { setEdit({ ...row, refresh: Math.random() }); setEditorData(row.content) }} /></Link>
        </span>,
      button: true,
    }
  ];

  const fetchCMS = () => {
    setIsloading(true);
    Get(HttpService.CMS_list).then((response) => {
      if (response.status === 200) {
        setData(response.data);
        setIsloading(false);
      } else {
        dispatch(message(response.message));
        setIsloading(false);
      }
    }).catch((e) => {
      setIsloading(false);
      dispatch(message(e.message));
    });
  }

  useEffect(() => {
    fetchCMS();
  }, []);


  const onUpdateCMSSetting = (event) => {
    event.preventDefault();
    setLoader(true);
    Postdata(HttpService.CMS_add, {
      id: edit._id,
      title: edit.title,
      content: edit.content,
    }).then((res) => {
      setLoader(false);
      setEdit({});
      fetchCMS();
      dispatch(message(res.message));
    }).catch((e) => {
      setLoader(false);
      dispatch(message(e.message));
    });
  }

  useEffect(() => {
    setEdit({ ...edit, content: editorData })
  }, [editorData]);

  const Editormodules = {
    toolbar: [
      ['bold', 'italic', 'underline', 'strike', 'blockquote'],
      [{ 'list': 'ordered' }, { 'list': 'bullet' }],
      [{ 'header': '1' }, { 'header': '2' }, { size: [] }],
      // ['link', 'image', 'video'],
      ['link', 'clean'],
    ],
    clipboard: {
      matchVisual: false,
    }
  }

  return (
    <>
      <div className="card-body">
        <DataTableBase
          columns={columns}
          pagination={false}
          loader={isloading}
          data={data}
        />

        {edit._id ?
          <>
            <hr />
            <h5>{edit.title}</h5>
            <form onSubmit={onUpdateCMSSetting}>
              <Form.Group className="form-group">
                <Form.Label>Name*</Form.Label>
                <Form.Control
                  type="text"
                  value={edit.title}
                  onChange={e => setEdit({ ...edit, title: e.target.value })}
                  required
                />
              </Form.Group>

              <Form.Group className="form-group">
                <Form.Label>Content*</Form.Label>
                <ReactQuill
                  value={editorData}
                  modules={Editormodules}
                  onChange={setEditorData}
                />
              </Form.Group>

              <button className="btn btn-primary" type="submit" disabled={loader}>
                {loader ? 'Please wait...' : 'Update'}
              </button>

              <button className="btn btn-danger" type="button" onClick={e => setEdit({})}>Cancel</button>
            </form>
          </>
          : ''
        }
      </div>
    </>
  )
}

export { CMSSetting }