/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import { useEffect, useState } from 'react';
import '../../../styles/Profile.scss';
import DataTableBase from '../../common/DataTableBase';
import { Postdata } from '../../../utils/api.service';
import HttpService from '../../../utils/http.service';
import Loader from '../../common/Loader';
import { ConvertToDMYHIS } from '../../../utils/Helper';
import ModalPopup from '../../common/Modal';
import { useDispatch, useSelector } from 'react-redux';
import { message } from '../../../redux/actions/toastActions';
import { Form } from 'react-bootstrap'
import { isData } from "../../../redux/selectors/authSelector";
import CSV from '../../common/CSV'

const BankDetails = (props) => {
  const [data, setData] = useState([]);
  const [isloading, setIsloading] = useState(true);
  const [refresh, setrefresh] = useState(true);
  const dispatch = useDispatch();
  const localData = useSelector(isData);
  const [loader, setloader] = useState(false);
  const [registerData, setregisterData] = useState({ upi: '', account_number: '', confirm_account: '', ifsc: '', account_name: '', bank_name: '' });

  const [modalswitch, setmodalswitch] = useState(false);
  const [modalswitchcontent, setmodalswitchcontent] = useState([0, 0]);

  const [editBankModal, setEditBankModal] = useState(false)
  const [bankDetails, setBankDetails] = useState({})

  const [addBankDetails, setAddBankDetails] = useState(false)
  const [radioValue, setradioValue] = useState(1);

  useEffect(() => {
    Postdata(HttpService.Bankdetails_show, { id: props.id }).then((response) => {
      setData(response.data);
      setIsloading(false);
    }).catch((e) => {
      setIsloading(false);
      dispatch(message(e.message));
    });
  }, [refresh]);

  const deleteDetails = (id) => {
    setmodalswitch(true);
    setmodalswitchcontent([id, Math.random()]);
  }

  const handleStatusChangeAction = () => {
    setmodalswitch(false);
    setIsloading(true);
    Postdata(HttpService.Bankdetails_delete, { id: modalswitchcontent[0] }).then((e) => {
      dispatch(message(e.message));
      setrefresh(!refresh);
    });
  }

  const columns = [
    {
      name: 'Sr. No.',
      selector: (row, i) => i + 1,
      width: '70px'
    },
    {
      name: 'Transfer Mode',
      selector: row => (row.type == 1) ? 'UPI' : 'Bank',
    },
    {
      name: 'Account Holder Name',
      selector: row => (row.account_name) ? row.account_name : '-',
    },
    {
      name: 'Account Number',
      selector: row => (row.account_number) ? row.account_number : '-',
    },
    {
      name: 'IFSC Code',
      selector: row => (row.ifsc) ? row.ifsc : '-',
    },
    {
      name: 'Bank Name',
      selector: row => (row.bank_name) ? row.bank_name : '-',
    },
    {
      name: 'UPI',
      selector: row => (row.upi) ? row.upi : '-',
    },
    {
      name: 'Added On',
      selector: row => ConvertToDMYHIS(row.createdAt),
    },
    {
      name: 'Action',
      id: 'actions',
      cell: (row) =>
        <span className="actions">
          {localData.user.role_id?.name == 'Admin' ?
            <i className="mdi mdi-pen" onClick={() => {
              setBankDetails({ ...row })
              setEditBankModal(true)
            }} />
            : ''}
          <i className="mdi mdi-delete" onClick={() => deleteDetails(row._id)} />
        </span>,
      button: true,
    }
  ];

  const editBankDetailsOfUser = () => {
    Postdata(HttpService.Bankdetails_edit, {
      ...bankDetails,
      user_id: props.id
    }).then((res) => {
      if (res.status === 200) {
        dispatch(message(res.message));
        setTimeout(() => {
          setEditBankModal(false)
          setrefresh(!refresh);
        }, 2000);
      } else {
        dispatch(message(res.message));
      }
    }).catch((e) => {
      dispatch(message(e));
      setTimeout(() => {
        setEditBankModal(false)
        setrefresh(!refresh);
      }, 2000);
    });
  }

  const onAddPayment = (event) => {
    event.preventDefault();
    let bankPayload = { type: radioValue, upi: registerData.upi };
    if (radioValue !== 1) {
      if (registerData.confirm_account !== registerData.account_number) {
        alert('Account Number and Confirm Account Number does not match.');
        setregisterData({ ...registerData, confirm_account: '' });
        return false;
      }
      bankPayload = { ...registerData, type: radioValue };
      delete bankPayload.upi;
      delete bankPayload.confirm_account;
    }
    // setloader(true);
    Postdata(HttpService.Bankdetails_user_store, {
      ...bankPayload,
      user_id: props.id
    }, dispatch).then((res) => {
      if (res.status === 200) {
        setAddBankDetails(false);
        setrefresh(!refresh);
      }
      dispatch(message(res.message));
      setloader(false);
    }).catch((res) => {
      dispatch(message(res.message));
      setloader(false);
    })
  };

  return (
    <>
      <div className="card-body filters">

        <ModalPopup
          className="alert-box-popup"
          show={modalswitch}
          size="sm"
          onHide={() => setmodalswitch(false)}
          heading="Are you sure?"
        >
          <i className="mdi mdi-check"></i>
          <p>You want to delete this bank details!</p>
          <div className='actions'>
            <button className="btn btn-primary" onClick={() => handleStatusChangeAction()}>Yes, Delete</button>
            <button className="btn btn-danger" onClick={() => setmodalswitch(false)}>Cancel</button>
          </div>
        </ModalPopup>

        <ModalPopup
          className="alert-box-popup"
          show={editBankModal}
          size="md"
          onHide={() => setEditBankModal(false)}
          heading="Edit Bank Details"
        >
          <div className='flex text-start'>
            {bankDetails.type == 1 ? <>
              {/* UPI  */}
              <div>
                <Form.Group className="form-group">
                  <Form.Label>UPI</Form.Label>
                  <Form.Control
                    type="text"
                    value={bankDetails.upi}
                    onChange={e => setBankDetails({ ...bankDetails, upi: e.target.value })}
                  />
                </Form.Group>
              </div>
            </> : <>
              {/* Bank  */}
              <div>
                <Form.Group className="form-group">
                  <Form.Label>Account Name</Form.Label>
                  <Form.Control
                    type="text"
                    value={bankDetails.account_name}
                    onChange={e => setBankDetails({ ...bankDetails, account_name: e.target.value })}
                  />
                </Form.Group>
                <Form.Group className="form-group">
                  <Form.Label>Account Number</Form.Label>
                  <Form.Control
                    type="text"
                    value={bankDetails.account_number}
                    onChange={e => setBankDetails({ ...bankDetails, account_number: e.target.value })}
                  />
                </Form.Group>
                <Form.Group className="form-group">
                  <Form.Label>Bank Name</Form.Label>
                  <Form.Control
                    type="text"
                    value={bankDetails.bank_name}
                    onChange={e => setBankDetails({ ...bankDetails, bank_name: e.target.value })}
                  />
                </Form.Group>
                <Form.Group className="form-group">
                  <Form.Label>IFSC Code</Form.Label>
                  <Form.Control
                    type="text"
                    value={bankDetails.ifsc}
                    onChange={e => setBankDetails({ ...bankDetails, ifsc: e.target.value })}
                  />
                </Form.Group>
              </div>
            </>}
            <button className="btn btn-success" onClick={editBankDetailsOfUser}>Edit</button>
            <button className="btn btn-danger" onClick={() => setEditBankModal(false)}>Cancel</button>
          </div>
        </ModalPopup>

        <ModalPopup
          show={addBankDetails}
          onHide={() => setAddBankDetails(false)}
          heading="Add Bank Details"
        >
          <form autoComplete="off" method='get' action="/" onSubmit={onAddPayment}>
            <div className='payment-type mb-3'>
              <b style={{ marginRight: '15px' }}>Select Type</b>
              <Form.Check
                inline
                label="UPI"
                name="group1"
                checked={radioValue === 1}
                onChange={(e) => setradioValue(e.target.checked ? 1 : 2)}
                type='radio'
                id='inline-radio-1'
              />
              <Form.Check
                inline
                label="Bank"
                name="group1"
                checked={radioValue === 2}
                onChange={(e) => setradioValue(e.target.checked ? 2 : 1)}
                type='radio'
                id='inline-radio-2'
              />
            </div>

            {(radioValue === 1) ?
              <Form.Group controlId="Form.Control.UPI" className="form-group">
                <Form.Label>Enter UPI</Form.Label>
                <Form.Control
                  type="text"
                  // pattern="[a-zA-Z0-9_-]{3,}@[a-zA-Z]{3,}"
                  // onInvalid={e => e.target.setCustomValidity(registerData.upi.length ? 'Entered UPI ID is invalid' : 'UPI ID is required')}
                  // onInput={e => e.target.setCustomValidity('')}
                  value={registerData.upi}
                  onChange={(e) => setregisterData({ ...registerData, upi: e.target.value })}
                  autoFocus
                  required
                />
              </Form.Group>
              :
              <>
                <div className='row'>
                  <div className='col-md-6'>
                    <Form.Group controlId="Form.Control.Payment.1" className="form-group">
                      <Form.Label>Account Number</Form.Label>
                      <Form.Control
                        type="text"
                        autoFocus
                        onInvalid={e => e.target.setCustomValidity('Account Number must be between 9 to 18 characters')}
                        onInput={e => e.target.setCustomValidity('')}
                        pattern="[0-9]{9,18}"
                        value={registerData.account_number}
                        onChange={(e) => setregisterData({ ...registerData, account_number: ((e.target.value).toString().replace(/[^0-9]+/, "").trim()) })}
                        required
                      />
                    </Form.Group>
                  </div>
                  <div className='col-md-6'>
                    <Form.Group controlId="Form.Control.Payment.2" className="form-group">
                      <Form.Label>Confirm Account Number</Form.Label>
                      <Form.Control
                        type="text"
                        onInvalid={e => e.target.setCustomValidity('Confirm Account Number must be between 9 to 18 characters')}
                        onInput={e => e.target.setCustomValidity('')}
                        pattern="[0-9]{9,18}"
                        value={registerData.confirm_account}
                        onChange={(e) => setregisterData({ ...registerData, confirm_account: ((e.target.value).toString().replace(/[^0-9]+/, "").trim()) })}
                        required
                      />
                    </Form.Group>
                  </div>
                </div>
                <div className='row'>
                  <div className='col-md-6'>
                    <Form.Group controlId="Form.Control.Payment.3" className="form-group">
                      <Form.Label>IFSC Code</Form.Label>
                      <Form.Control
                        type="text"
                        onInvalid={e => e.target.setCustomValidity('IFSC Code is required. Ex. SBIN0123456')}
                        onInput={e => e.target.setCustomValidity('')}
                        pattern="[a-zA-Z]{4}0[A-Za-zA-Z0-9]{6}"
                        style={{ textTransform: 'uppercase' }}
                        value={registerData.ifsc}
                        onChange={(e) => setregisterData({ ...registerData, ifsc: e.target.value })}
                        required
                      />
                    </Form.Group>
                  </div>
                  <div className='col-md-6'>
                    <Form.Group controlId="Form.Control.Payment.4" className="form-group">
                      <Form.Label>Account Holder Name</Form.Label>
                      <Form.Control
                        type="text"
                        onInvalid={e => e.target.setCustomValidity('Account Holder Name is required.')}
                        onInput={e => e.target.setCustomValidity('')}
                        value={registerData.account_name}
                        onChange={(e) => setregisterData({ ...registerData, account_name: e.target.value })}
                        required
                      />
                    </Form.Group>
                  </div>
                </div>
                <div className='row'>
                  <div className='col-md-12'>
                    <Form.Group controlId="Form.Control.Payment.3" className="form-group">
                      <Form.Label>Bank Name</Form.Label>
                      <Form.Control
                        type="text"
                        value={registerData.bank_name}
                        onChange={(e) => setregisterData({ ...registerData, bank_name: e.target.value })}
                        required
                      />
                    </Form.Group>
                  </div>
                </div>
              </>
            }

            <button type='submit' className='btn btn-primary' disabled={loader}>{loader ? 'Please wait...' : 'Submit'}</button>
            <button type='button' className="btn btn-danger" onClick={() => setAddBankDetails(false)}>Cancel</button>
          </form>
        </ModalPopup>

        {isloading ?
          <Loader />
          : <>
            <div className="row searchaction">
              <div className="col-lg-4">
                <h5>Bank Details</h5>
              </div>
              <div className="col-lg-8 text-end">
              <CSV
              link={HttpService.Bankdetails_show}
              extra={{
                id: props.id
              }}
              name="User : Bank Details"
              headers={[
                { label: "ID", key: "_id" },
                { label: "Transfer Mode", key: "type" },
                { label: "Account Holder Name", key: "account_name" },
                { label: "Account Number", key: "account_number" },
                { label: "IFSC Code", key: "ifsc" },
                { label: "Bank Name", key: "bank_name" },
                { label: "UPI", key: "upi" },
              ]} />

              {localData.user.role_id?.name == 'Admin' ? 
                <button className='btn btn-primary' onClick={() => setAddBankDetails(true)}>Add Bank Details</button>
               : null}
               </div>
            </div>

            <DataTableBase
              columns={columns}
              pagination={false}
              data={data}
            />
          </>
        }
      </div>
    </>
  )
}

export { BankDetails }