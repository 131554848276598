import 'bootstrap/dist/css/bootstrap.min.css';
import './styles/App.scss';
import DefaultRoutes from "./routes/routes";
// import { Socket } from "./components/Socket";

function App() {
  return (
    <div className="App">
      <DefaultRoutes />
      {/* <Socket /> */}
    </div>
  );
}

export default App;
