/* eslint-disable no-unused-vars */
import { Form } from 'react-bootstrap';
import '../../../styles/Profile.scss';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isData } from '../../../redux/selectors/authSelector';
import { Postdata } from '../../../utils/api.service';
import HttpService from '../../../utils/http.service';
import { message } from '../../../redux/actions/toastActions';
import { loginOverride, verifyToken } from '../../../redux/actions/authActions';

const EditProfile = () => {
  const [loader, setLoader] = useState(false);
  const data = useSelector(isData);
  const dispatch = useDispatch();

  const [image, setImage] = useState();
  const [formdata, setFormdata] = useState({
    name: data.user.name,
    email: data.user.email,
    mobile: data.user.mobile,
  });

  const handleImagePreview = (e) => {
    const image_as_files = e.target.files[0];
    setImage(URL.createObjectURL(e.target.files[0]))
    setFormdata({ ...formdata, profile_pic: image_as_files })
  }

  const onUpdateProfile = (event) => {
    event.preventDefault();
    setLoader(true);
    Postdata(HttpService.Admin_profile_update, formdata).then((res) => {
      if (res.status === 200) {
        // dispatch(loginOverride(res.data));
        dispatch(verifyToken());
      }
      setLoader(false);
      dispatch(message(res.message));
    }).catch((e) => {
      setLoader(false);
      dispatch(message(e.message));
    });
  }

  useEffect(() => {
    setImage(data.user.profile_pic);
  }, [data])

  return (
    <>
      <div className="card-body">
        <div className="row">
          <div className="col-lg-12">
            <form onSubmit={onUpdateProfile}>
              <Form.Group className="form-group">
                <Form.Label>Full Name*</Form.Label>
                <Form.Control
                  type="text"
                  value={formdata.name}
                  onChange={e => setFormdata({ ...formdata, name: e.target.value })}
                  required
                />
              </Form.Group>

              <Form.Group className="form-group">
                <Form.Label>Email*</Form.Label>
                <Form.Control
                  type="email"
                  value={formdata.email}
                  onChange={e => setFormdata({ ...formdata, email: e.target.value })}
                  required
                />
              </Form.Group>

              <Form.Group className="form-group">
                <Form.Label>Mobile</Form.Label>
                <Form.Control
                  type="text"
                  pattern="[0-9]{9,15}"
                  onInvalid={e => e.target.setCustomValidity('Please enter valid mobile number')}
                  onInput={e => e.target.setCustomValidity('')}
                  value={formdata.mobile}
                  onChange={e => setFormdata({ ...formdata, mobile: ((e.target.value).toString().replace(/[^0-9]+/, "").trim()) })}
                  required={Boolean(formdata.mobile.length)}
                />
              </Form.Group>

              <Form.Group className="form-group">
                <Form.Label>Profile</Form.Label>
                <Form.Control
                  type="file"
                  accept=".jpg,.png,.jpeg,.gif"
                  onChange={handleImagePreview}
                />
                {image && <>
                  <img className="preview" src={image} />
                </>}
              </Form.Group>


              <button className="btn btn-primary" type="submit" disabled={loader}>
                {loader ? 'Please wait...' : 'Update'}
              </button>
            </form>
          </div>
        </div>
      </div>
    </>
  )
}

export { EditProfile }