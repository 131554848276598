import * as types from "../constants/constants";

let initialState = {
  message: "",
  show: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case types.TOASTMESSAGE_DATA:
      return {
        ...state,
        message: action.data,
        show: true,
      };
    case types.TOASTMESSAGE_DATA_CLEAR:
      return {
        ...state,
        show: false,
      };
    default:
      return state;
  }
};