/* eslint-disable react/prop-types */
import { useEffect, useState } from "react";
import { Collapse, Form } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { message } from "../../../redux/actions/toastActions";
import { Postdata } from "../../../utils/api.service";
import { ConvertToDMY } from "../../../utils/Helper";
import HttpService from "../../../utils/http.service";
import DataTableBase from "../../common/DataTableBase"
import Link from "../../common/Link";
import CSV from "../../common/CSV"

const UserviewAgents = (props) => {
  const [open, setOpen] = useState(false);
  const [search, setSearch] = useState(false);
  const [refresh, setRefresh] = useState(false);

  const [tabledata, settableData] = useState([]);
  const [istableloading, settableIsloading] = useState(true);

  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setperPage] = useState(process.env.REACT_APP_RecordPerPage);
  const [currentPage, setcurrentPage] = useState(1);
  const dispatch = useDispatch();

  useEffect(() => {
    if (search !== false) {
      const timeOutId = setTimeout(() => setRefresh(!refresh), 500);
      return () => clearTimeout(timeOutId);
    }
  }, [search]);

  useEffect(() => {
    settableIsloading(true);
    if (props.id) {
      const postBody = {
        page: currentPage,
        perpage: perPage,
        search_by_agent: props.id,
        search: (search ? search : ''),
      };
      Postdata(HttpService.User_list, postBody).then((response) => {
        if (response.status === 200) {
          settableData(response.data.users);
          setTotalRows(response.data.count);
          settableIsloading(false);
        } else {
          settableIsloading(false);
          dispatch(message(response.message));
        }
      }).catch((e) => {
        settableIsloading(false);
        dispatch(message(e.message));
      });
    }
  }, [currentPage, perPage, refresh]);

  const handlePerRowsChange = async (newPerPage, page) => {
    setperPage(newPerPage);
    setcurrentPage(page);
  };

  const handlePageChange = (page) => {
    setcurrentPage(page);
  };

  const columns = [
    {
      name: '#',
      // selector: row => row.user_id,
      width: "55px",
      selector: (row, i) => (currentPage * perPage + (i + 1) - perPage),
    },
    {
      name: 'Profile',
      cell: (row) =>
      <Link to={`/user-management/view/${row._id}`}>
        <div className="avatar">
          <img
            alt={row.user_id}
            src={row.profile_pic}
          />
        </div>
       </Link>,
      width: "75px",
    },
    {
      name: 'Role',
      selector: row => row?.role_id?.name || 'Player',
    },
    {
      name: 'Username',
      // selector: row => row.username,
      cell : (row) => 
      <>
      <Link to={`/user-management/view/${row._id}`}>
        {row.username}
      </Link>
      </>
    },
    {
      name: 'Mobile Number',
      selector: row => row.mobile,
    },
    {
      name: 'Total Played Game',
      selector: row => row.total_played_games,
    },
    {
      name: 'Email',
      selector: row => row.email,
    },
    {
      name: 'Coins',
      selector: row => row.usable_coin_balance,
    },
    {
      name: 'Registration Date',
      selector: row => ConvertToDMY(row.createdAt),
    },
    {
      name: 'Registered By',
      selector: row => row?.registered_by?.username || '-',
    },
    {
      name: 'Action',
      id: 'actions',
      cell: (row) =>
        <span className="actions">
             <Link to={`/user-management/edit/${row._id}`}><i className="mdi mdi-pen" /></Link>
          <Link to={`/user-management/view/${row._id}`}><i className="mdi mdi-eye" /></Link>
        </span>,
      button: true,
    }
  ];

  return (
    <>
      <div className="card-body filters">
        <div className="row searchaction">
          <div className="col-lg-6">
            <h5>List of Users</h5>
          </div>
          <div className="col-lg-6 text-end">
          <CSV
            link={HttpService.User_list}
            name="Agent : User List"
            response="users"
            headers={[
              { label: "ID", key: "user_id" },
              { label: "Role", key: "role_id.name" },
              { label: "Username", key: "username" },
              { label: "Mobile Number", key: "mobile" },
              { label: "Total Played Game", key: "total_played_games" },
              { label: "Email", key: "email" },
              { label: "Coins", key: "usable_coin_balance" },
              { label: "Registration Date", key: "createdAt" },
              { label: "Registered By", key: "registered_by.username" },
            ]}
            extra={{
              page: currentPage,
              perpage: perPage,
              search_by_agent: props.id,
              search: (search ? search : ''),
            }}
          />
            <button className="btn btn-danger" onClick={() => setOpen(!open)}>Search</button>
          </div>
        </div>

        <Collapse in={open}>
          <div>
            <div className="card">
              <p className="card-header">Search</p>
              <div className="card-body">
                <Form.Group className="form-group mb-0">
                  <Form.Label>Search</Form.Label>
                  <Form.Control
                    type="text"
                    onChange={e => setSearch(e.target.value)}
                    required
                  />
                </Form.Group>
              </div>
            </div>
          </div>
        </Collapse>

        <DataTableBase
          columns={columns}
          loader={istableloading}
          data={tabledata}
          paginationTotalRows={totalRows}
          onChangeRowsPerPage={handlePerRowsChange}
          onChangePage={handlePageChange}
        />
      </div>
    </>
  )
}
export { UserviewAgents }