import Title from "../../common/Title";
import { Form } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import Link from "../../common/Link";
import { useEffect, useState } from "react";
import HttpService from "../../../utils/http.service";
import { Postdata } from "../../../utils/api.service";
import { ConvertToYMD } from "../../../utils/Helper";
import { useDispatch } from "react-redux";
import { message } from "../../../redux/actions/toastActions";
import Loader from "../../common/Loader";
import Breadcrumb from "../../common/Breadcrumb";

const BannerManagementAdd = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const navigate = useNavigate();
  const [loader, setLoader] = useState(false);
  const [image, setImage] = useState('');
  const [apiloader, setapiloader] = useState(false);
  const [formdata, setFormdata] = useState({
    title: '',
    image: '',
    target_link: '',
    start_at: '',
    end_at: '',
    status: 0,
  });

  useEffect(() => {
    async function fetchData(id) {
      const response = await Postdata(HttpService.Banner_show, {
        id: id
      });
      if (response.status !== 200) {
        navigate("/banner-management")
      }
      setImage(response.data.image);
      setapiloader(false);
      setFormdata({
        id: params.id,
        title: response.data.title,
        target_link: response.data.target_link,
        start_at: ConvertToYMD(response.data.start_at),
        end_at: ConvertToYMD(response.data.end_at),
        status: response.data.status,
      })
    }
    if (params.id) {
      setapiloader(true);
      fetchData(params.id);
    }
  }, [])

  const handleImagePreview = (e) => {
    const image_as_files = e.target.files[0];
    setImage(URL.createObjectURL(e.target.files[0]))
    setFormdata({ ...formdata, image: image_as_files })
  }

  const onAddBannerAction = (event) => {
    event.preventDefault();
    setLoader(true);
    Postdata(HttpService.Banner_add, formdata).then((res) => {
      if (res.status === 200) {
        navigate("/banner-management");
      }
      dispatch(message(res.message));
    });
  };

  return (
    <>
      <Title name='Banner Management - Add' />
      <div className="row">
        <div className="col-lg-6">
          <h4>Banner Management</h4>
          <Breadcrumb
            pages={[
              { title: 'Banner Management', link: 'banner-management' }
            ]}
            active={`${params.id ? 'Edit' : 'Add'} Banner`}
          />
        </div>
      </div>

      <div className="row">
        <div className="col-lg-12">
          <div className="card">
            <p className="card-header">{params.id ? 'Edit' : 'Add'} Banner</p>
            <div className="card-body">
              {apiloader ?
                <Loader /> :
                <form onSubmit={onAddBannerAction}>
                  <Form.Group className="form-group">
                    <Form.Label>Title*</Form.Label>
                    <Form.Control
                      type="text"
                      value={formdata.title}
                      onChange={e => setFormdata({ ...formdata, title: e.target.value })}
                      required
                    />
                  </Form.Group>

                  <Form.Group className="form-group">
                    <Form.Label>Image{!formdata.id && '*'}</Form.Label>
                    <Form.Control
                      type="file"
                      accept=".jpg,.png,.jpeg,.gif"
                      required={!formdata.id}
                      onChange={handleImagePreview}
                    />
                    <small>Photo need to be 1170 X 300 pixels.</small>
                    {image && <>
                      <img className="preview" src={image} style={{ width: 'auto' }} />
                    </>}
                  </Form.Group>

                  <Form.Group className="form-group">
                    <Form.Label>Target Link</Form.Label>
                    <Form.Control
                      type="url"
                      value={formdata.target_link}
                      onChange={e => setFormdata({ ...formdata, target_link: e.target.value })}
                    />
                  </Form.Group>

                  <Form.Group className="form-group">
                    <Form.Label>Start Date*</Form.Label>
                    <Form.Control
                      required
                      type="date"
                      min={params.id ? '1970-01-01' : ConvertToYMD(new Date().toISOString().split("T")[0])}
                      value={formdata.start_at}
                      onChange={e => setFormdata({ ...formdata, start_at: e.target.value })}
                    />
                  </Form.Group>

                  <Form.Group className="form-group">
                    <Form.Label>End Date*</Form.Label>
                    <Form.Control
                      required
                      type="date"
                      min={formdata.start_at}
                      value={formdata.end_at}
                      onChange={e => setFormdata({ ...formdata, end_at: e.target.value })}
                    />
                  </Form.Group>

                  <Form.Group className="form-group">
                    <Form.Label>Status</Form.Label>
                    <Form.Check
                      type="switch"
                      checked={formdata.status}
                      onChange={e => setFormdata({ ...formdata, status: Number(e.target.checked) })}
                    />
                  </Form.Group>

                  <button className="btn btn-primary" type="submit" disabled={loader}>
                    {loader ? 'Please wait...' : (formdata.id ? 'Update' : 'Submit')}
                  </button>
                  <Link className="btn btn-danger" to={'/banner-management'}>Cancel</Link>
                </form>
              }
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export { BannerManagementAdd }