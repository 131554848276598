/* eslint-disable no-unused-vars */
import Title from "../common/Title";
import Link from "../common/Link";
import DataTableBase from "../common/DataTableBase";
import { Form } from "react-bootstrap";
import { useState, useEffect } from "react";
import { Get, Postdata } from '../../utils/api.service';
import Loader from "../common/Loader";
import HttpService from "../../utils/http.service";
import { useDispatch, useSelector } from "react-redux";
import { message } from "../../redux/actions/toastActions";
import { CheckPermission, ConvertToDMY } from "../../utils/Helper";
import Breadcrumb from "../common/Breadcrumb";
import { isPermission } from "../../redux/selectors/authSelector";

const Dashboard = () => {
  const [data, setData] = useState([]);
  const [isloading, setIsloading] = useState(true);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setperPage] = useState(process.env.REACT_APP_RecordPerPage);
  const [currentPage, setcurrentPage] = useState(1);
  const permission = useSelector(isPermission);
  const [refresh, setRefresh] = useState(false);
  const dispatch = useDispatch();

  const [datacount, setDatacount] = useState({
    users: 0,
    games: 0,
    agents: 0,
    contest: 0,
    commission: 0,
    netRevenue: 0
  });
  const [iscountloading, setIscountloading] = useState(true);

  const columns = [
    {
      name: '#',
      selector: (row, i) => (i + 1),
      width: "55px",
    },
    {
      name: 'Profile',
      cell: (row) =>
        <div className="avatar">
          <img
            alt={row.user_id}
            src={row.profile_pic}
          />
        </div>,
      width: "75px",
    },
    {
      name: 'Username',
      selector: row => row.username,
    },
    // {
    //   name: 'Mobile Number',
    //   selector: row => row.mobile,
    // },
    // {
    //   name: 'Total Played Game',
    //   selector: row => row.total_played_games,
    // },
    // {
    //   name: 'Email',
    //   selector: row => row.email,
    // },
    {
      name: 'Registration Date',
      selector: row => ConvertToDMY(row.createdAt),
    },
    // {
    //   name: 'Status',
    //   cell: (row) =>
    //     <Form.Check
    //       type="switch"
    //       checked={row.status}
    //       onChange={e => handleStatusChange(row._id, e.target.checked)}
    //     />,
    //   button: true,
    // },
    {
      name: 'Action',
      id: 'actions',
      cell: (row) =>
        <span className="actions">
          <Link to={`/user-management/view/${row._id}`}><i className="mdi mdi-eye" /></Link>
        </span>,
      button: true,
    }
  ];

  const handleStatusChange = (id, status) => {
    setIsloading(true);
    Postdata(HttpService.User_status, {
      id: id,
      status: Number(status)
    }).then((res) => {
      setRefresh(!refresh);
      dispatch(message(res.message));
    })
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    setperPage(newPerPage);
    setcurrentPage(page);
  };

  const handlePageChange = (page) => {
    setcurrentPage(page);
  };

  useEffect(() => {
    setIscountloading(true);
    async function fetchCountData() {

      Postdata(HttpService.Dashboard, {
        page: currentPage,
        perpage: perPage,
      }).then((response) => {
        if (response.status === 200) {
          setDatacount(response.data);
        }
        setIscountloading(false);
      }).catch(e => {
        setIscountloading(false);
      });
    }
    fetchCountData();
  }, []);

  useEffect(() => {
    if (CheckPermission(permission, '/user-management')) {
      setIsloading(true);
      Postdata(HttpService.User_list, {
        page: currentPage,
        search_by_role: 'user',
        perpage: perPage,
      }).then((response) => {
        if (response.status === 200) {
          setData(response.data.users);
          setTotalRows(response.data.count);
          setIsloading(false);
        } else {
          setIsloading(false);
          dispatch(message(response.message));
        }
      }).catch((e) => {
        setIsloading(false);
        dispatch(message(e.message));
      });
    }
  }, [currentPage, perPage, refresh]);

  return (
    <>
      <Title name='Dashboard' />
      <div className="row">
        <div className="col-lg-4">
          <h4>Dashboard</h4>
          <Breadcrumb
            active="Dashboard"
          />
        </div>
      </div>

      <div className="row mb-4 dashboardCount">
        <div className="col-lg-4">
          <div className="card">
            <p className="card-header">Total Registered Users</p>
            {iscountloading ? <Loader isClass="load_dashboard" /> : <b>{datacount.users}&nbsp;</b>}
            <i className="mdi mdi-cube-outline"></i>
          </div>
        </div>
        <div className="col-lg-4">
          <div className="card">
            <p className="card-header">Total Played Games</p>
            {iscountloading ? <Loader isClass="load_dashboard" /> : <b>{datacount.games}&nbsp;</b>}
            <i className="mdi mdi-buffer"></i>
          </div>
        </div>
        <div className="col-lg-4">
          <div className="card">
            <p className="card-header">Total Contest</p>
            {iscountloading ? <Loader isClass="load_dashboard" /> : <b>{datacount.contest}&nbsp;</b>}
            <i className="mdi mdi-tag-text-outline"></i>
          </div>
        </div>

      </div>

      <div className="row mb-4 dashboardCount">
        <div className="col-lg-4">
          <div className="card">
            <p className="card-header">Total Agents</p>
            {iscountloading ? <Loader isClass="load_dashboard" /> : <b>{datacount.agents}&nbsp;</b>}
            <i className="mdi mdi-briefcase-check"></i>
          </div>
        </div>
        <div className="col-lg-4">
          <div className="card">
            <p className="card-header">Total Commission</p>
            {iscountloading ? <Loader isClass="load_dashboard" /> : <b>{parseInt(datacount.commission)}&nbsp;</b>}
            <i className="mdi mdi-coin"></i>
          </div>
        </div>
        <div className="col-lg-4">
          <div className="card">
            <p className="card-header">Net Revenue</p>
            {iscountloading ? <Loader isClass="load_dashboard" /> : <b>{parseInt(datacount.netRevenue)}&nbsp;</b>}
            <i className="mdi  mdi-cash-multiple"></i>
          </div>
        </div>
      </div>

      {CheckPermission(permission, '/user-management') ?
        <>
          <div className="row">
            <div className="col-lg-4">
              <h4>Latest Users</h4>
            </div>
            <div className="col-lg-8 text-end">
              <Link to={'/user-management'} className="btn btn-primary">View All</Link>
            </div>
          </div>

          <div className="card">
            <p className="card-header">List of Latest Users</p>
            <div className="card-body">
              <DataTableBase
                columns={columns}
                loader={isloading}
                data={data}
                paginationTotalRows={10}
                onChangeRowsPerPage={handlePerRowsChange}
                onChangePage={handlePageChange}
                pagination={false}
              />
            </div>
          </div> </> : ''}
    </>
  )
}

export { Dashboard }