/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import { Toast as CustomMsg } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { clearMessage } from '../../redux/actions/toastActions';

function Toast(props) {
  const dispatch = useDispatch();
  return (
    <>
      <CustomMsg
        autohide={true}
        delay={4000}
        onClose={() => {
          dispatch(clearMessage());
        }}
        className='toast-fix'
        show={props.show}
      >
        <CustomMsg.Body>{props.message}</CustomMsg.Body>
      </CustomMsg>
    </>
  );
}

Toast.defaultProps = {
  show: false,
  message: ''
}

export default Toast;
