import Title from "../../common/Title";
import { Form } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import Link from "../../common/Link";
import { useEffect, useState } from "react";
import HttpService from "../../../utils/http.service";
import { Postdata } from "../../../utils/api.service";
import { useDispatch } from "react-redux";
import { message } from "../../../redux/actions/toastActions";
import Loader from "../../common/Loader";
import Breadcrumb from "../../common/Breadcrumb";
import ModalPopup from "../../common/Modal";

const ContestAdd = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const navigate = useNavigate();
  const [disable, setDisable] = useState(false);
  const [loader, setLoader] = useState(false);
  const [apiloader, setapiloader] = useState(false);

  const [modalswitch, setmodalswitch] = useState(false);
  const [popup, setpopup] = useState({
    from: '',
    to: '',
    price: '',
  });
  const [winnings, setwinnings] = useState({});
  const [formdata, setFormdata] = useState({
    title: '',
    game_type: '',
    type: 'default',
    no_of_winners: '',
    total_team: '',
    prize_pool: '',
    entry_fee: '',
    tagline: '',
    description: '',
    status: 0,
    price: [],
  });

  useEffect(() => {
    async function fetchData(id) {
      const response = await Postdata(HttpService.Contest_show, {
        id: id
      });
      if (response.status !== 200) {
        navigate("/contest")
      }
      setapiloader(false);
      setFormdata({
        id: params.id,
        title: response.data.title,
        game_type: response.data.game_type,
        type: response.data.type,
        no_of_winners: response.data.no_of_winners,
        total_team: response.data.total_team,
        prize_pool: response.data.prize_pool,
        entry_fee: response.data.entry_fee,
        tagline: response.data.tagline,
        description: response.data.description,
        status: response.data.status,

        price: response.price,
      });

      if (response.price.length) {
        setTimeout(() => {
          const obj = winnings;
          response.price.map(popup => {
            for (let index = parseFloat(popup.from); index <= parseFloat(popup.to); index++) obj["key_" + index] = popup.price;
          });
          setwinnings(obj);
        }, 1000);
      }
    }
    if (params.id) {
      setapiloader(true);
      fetchData(params.id);
    }
  }, [])

  useEffect(() => {
    setDisable(false);
    if (parseFloat(formdata.total_team) < parseFloat(formdata.no_of_winners)) setDisable(true);
    if (!formdata.price.length) setDisable(true);
    formdata.price.map(e => {
      if (!e.price) setDisable(true);
    });
  }, [formdata])

  useEffect(() => {
    const obj = {};
    if (parseFloat(formdata.no_of_winners)) {
      for (let index = 1; index <= parseFloat(formdata.no_of_winners); index++) obj["key_" + index] = '';
    }
    setwinnings(obj);
  }, [formdata.no_of_winners])

  const onUpdatePrize = (event) => {
    event.preventDefault();
    const obj = winnings;
    for (let index = parseFloat(popup.from); index <= parseFloat(popup.to); index++) obj["key_" + index] = popup.price;
    setwinnings(obj);
    setmodalswitch(false);
    setpopup({
      from: '',
      to: '',
      price: '',
    });

    const priceRange = [];
    let lastprice = false;
    for (let index = 1; index <= parseFloat(formdata.no_of_winners); index++) {
      if (winnings["key_" + index] !== lastprice) {
        lastprice = winnings["key_" + index];
        priceRange.push({ from: index, to: 0, price: lastprice });
      }
    }
    priceRange.forEach((e, i) => {
      priceRange[i].to = priceRange[i + 1] ? (priceRange[i + 1].from - 1) : formdata.no_of_winners;
    })

    setFormdata({ ...formdata, price: priceRange })
  }

  const onAddContestAction = (event) => {
    event.preventDefault();
    setLoader(true);
    Postdata(HttpService.Contest_add, { ...formdata, price: JSON.stringify(formdata.price) }).then((res) => {
      if (res.status === 200) {
        navigate("/contest");
        setLoader(false);
      } else {
        setLoader(false);
      }
      dispatch(message(res.message));
    });
  };

  return (
    <>
      <Title name='Default Contest - Add' />
      <div className="row">
        <div className="col-lg-6">
          <h4>Default Contest</h4>
          <Breadcrumb
            pages={[
              { title: 'Default Contest', link: 'contest' }
            ]}
            active={`${params.id ? 'Edit' : 'Add'} Contest`}
          />
        </div>
      </div>

      <ModalPopup
        show={modalswitch}
        size="sm"
        onHide={() => setmodalswitch(false)}
        heading="Add Price"
      >
        <form onSubmit={onUpdatePrize}>
          <Form.Group className="form-group">
            <Form.Label>From Rank*</Form.Label>
            <Form.Control
              min='1'
              autoFocus
              max={formdata.no_of_winners ? formdata.no_of_winners : 0}
              type="number"
              value={popup.from}
              onChange={e => setpopup({ ...popup, from: e.target.value, to: e.target.value })}
              required
            />
          </Form.Group>

          <Form.Group className="form-group">
            <Form.Label>To Rank*</Form.Label>
            <Form.Control
              min={popup.from}
              max={formdata.no_of_winners ? formdata.no_of_winners : 0}
              type="number"
              value={popup.to}
              onChange={e => setpopup({ ...popup, to: e.target.value })}
              required
            />
          </Form.Group>

          <Form.Group className="form-group">
            <Form.Label>Price*</Form.Label>
            <Form.Control
              min='1'
              type="number"
              value={popup.price}
              onChange={e => setpopup({ ...popup, price: e.target.value })}
              required
            />
          </Form.Group>

          <button className="btn btn-primary" type="submit">Save</button>
          <button type="button" className="btn btn-danger" onClick={() => setmodalswitch(false)}>Cancel</button>
        </form>
      </ModalPopup>

      <div className="row">
        <div className={`col-lg-${formdata.no_of_winners > 0 ? 9 : 12}`}>
          <div className="card">
            <p className="card-header">{params.id ? 'Edit' : 'Add'} Contest</p>
            <div className="card-body">
              {apiloader ?
                <Loader /> :
                <form onSubmit={onAddContestAction}>
                  <Form.Group className="form-group">
                    <Form.Label>Title*</Form.Label>
                    <Form.Control
                      type="text"
                      value={formdata.title}
                      onChange={e => setFormdata({ ...formdata, title: e.target.value })}
                      required
                    />
                  </Form.Group>

                  <Form.Group className="form-group">
                    <Form.Label>Game Type*</Form.Label>
                    <Form.Select
                      required
                      value={formdata.game_type}
                      onChange={e => setFormdata({ ...formdata, game_type: e.target.value })}
                      // disabled={params.id ? true : false}
                    >
                      <option hidden value=''></option>
                      <option value='cricket'>Cricket</option>
                      <option value='football'>Football</option>
                    </Form.Select>
                  </Form.Group>

                  {/* <Form.Group className="form-group">
                    <Form.Label>Contest Type*</Form.Label>
                    <Form.Select
                      required
                      value={formdata.type}
                      onChange={e => setFormdata({ ...formdata, type: e.target.value })}
                    >
                      <option hidden value=''></option>
                      <option value='default'>Default</option>
                      <option value='manual'>Manual</option>
                    </Form.Select>
                  </Form.Group> */}

                  <Form.Group className="form-group">
                    <Form.Label>No. of Winners*</Form.Label>
                    <Form.Control
                      min='1'
                      type="number"
                      value={formdata.no_of_winners}
                      onChange={e => setFormdata({ ...formdata, no_of_winners: e.target.value, price: [] })}
                      // disabled={params.id ? true : false}
                      required
                    />
                  </Form.Group>

                  <Form.Group className="form-group">
                    <Form.Label>Total Team*</Form.Label>
                    <Form.Control
                      min={formdata.no_of_winners}
                      type="number"
                      value={formdata.total_team}
                      onChange={e => setFormdata({ ...formdata, total_team: e.target.value })}
                      // disabled={params.id ? true : false}
                      required
                    />
                    {disable && (parseFloat(formdata.total_team) < parseFloat(formdata.no_of_winners)) && <small>Total Team should not be less than No. of Winners</small>}
                  </Form.Group>

                  <Form.Group className="form-group">
                    <Form.Label>Prize Pool*</Form.Label>
                    <Form.Control
                      min='1'
                      type="number"
                      value={formdata.prize_pool}
                      onChange={e => setFormdata({ ...formdata, prize_pool: e.target.value })}
                      // disabled={params.id ? true : false}
                      required
                    />
                  </Form.Group>

                  <Form.Group className="form-group">
                    <Form.Label>Entry Fee*</Form.Label>
                    <Form.Control
                      min='1'
                      type="number"
                      value={formdata.entry_fee}
                      onChange={e => setFormdata({ ...formdata, entry_fee: e.target.value })}
                      // disabled={params.id ? true : false}
                      required
                    />
                  </Form.Group>

                  <Form.Group className="form-group">
                    <Form.Label>Tagline</Form.Label>
                    <Form.Control
                      type="text"
                      value={formdata.tagline}
                      onChange={e => setFormdata({ ...formdata, tagline: e.target.value })}
                      // disabled={params.id ? true : false}
                    />
                  </Form.Group>

                  <Form.Group className="form-group">
                    <Form.Label>Description</Form.Label>
                    <Form.Control
                      as="textarea"
                      value={formdata.description}
                      onChange={e => setFormdata({ ...formdata, description: e.target.value })}
                      // disabled={params.id ? true : false}
                    />
                  </Form.Group>

                  <Form.Group className="form-group">
                    <Form.Label>Status</Form.Label>
                    <Form.Check
                      type="switch"
                      checked={formdata.status}
                      onChange={e => setFormdata({ ...formdata, status: Number(e.target.checked) })}
                      // disabled={params.id ? true : false}
                    />
                  </Form.Group>

                  <button className="btn btn-primary" type="submit" disabled={loader || disable}>
                    {loader ? 'Please wait...' : (formdata.id ? 'Update' : 'Submit')}
                  </button>
                  <Link className="btn btn-danger" to={'/contest'}>Cancel</Link>
                </form>
              }
            </div>
          </div>
        </div>

        <div className="col-lg-3">
          {formdata.no_of_winners ?
            <div className="card">
              <p className="card-header">Winning Prize</p>
              <div className="card-body">
                <>
                  <button className="btn btn-danger" onClick={() => setmodalswitch(true)} 
                  // disabled={params.id ? true : false} 
                  >Add Price</button>

                  <div className="price_panel">
                    {Object.entries(winnings).map((e, i) => {
                      return <p key={e[0]}><span>Rank {i + 1}</span><b>{e[1]}</b></p>
                    })}
                  </div>
                </>
              </div>
            </div>
            : ''}
        </div>
      </div>
    </>
  )
}

export { ContestAdd }