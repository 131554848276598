/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import '../../../styles/Profile.scss'
import Breadcrumb from '../../common/Breadcrumb'
import Title from '../../common/Title'
import React, { useState, useEffect } from 'react'
import { ConvertToDMY } from '../../../utils/Helper'
import DataTableBase from '../../common/DataTableBase'
import { Postdata } from '../../../utils/api.service'
import { useDispatch } from "react-redux";
import HttpService from '../../../utils/http.service';
import { message } from '../../../redux/actions/toastActions'
import { Form } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import Loader from '../../common/Loader'

function Spin_Wheel_List() {
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setperPage] = useState(process.env.REACT_APP_RecordPerPage);
  const [currentPage, setcurrentPage] = useState(1);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false)
  const [refresh, setRefresh] = useState(false)
  const [probSum, setProbSum] = useState(0)

  const dispatch = useDispatch()

  const handleStatusChange = async (row, value) => {
    setLoading(true)
    Postdata(HttpService.Spin_wheel_status_update, {
      id: row._id,
      status: (value == false ? 0 : 1)
    }).then((response) => {
      if (response.status === 200) {
        setRefresh(!refresh)
        dispatch(message(response.message))
      } else {
        setRefresh(!refresh)
        dispatch(message(response.message));
      }
    }).catch((e) => {
      setRefresh(!refresh)
      dispatch(message(e.message));
    });
  }

  const handleSpinWheelStatusChange = async (row, value) => {
    setLoading(true)
    Postdata(HttpService.Spin_wheel_update_reward_for_user, {
      id: row._id,
      status: (value == false ? 0 : 1)
    }).then((response) => {
      if (response.status === 200) {
        setRefresh(!refresh)
        dispatch(message(response.message))
      } else {
        setRefresh(!refresh)
        dispatch(message(response.message));
      }
    }).catch((e) => {
      setRefresh(!refresh)
      dispatch(message(e.message));
    });
  }

  const columns = [
    {
      name: '#',
      width: "55px",
      selector: (row, i) => (currentPage * perPage + (i + 1) - perPage),
      center: true
    },
    {
      name: 'Reward Type',
      selector: row => (row.type === "amount" ? "Amount" : (row.type === "coupon" ? "Coupon" : "Prize")),
      center: true
    },
    {
      name: 'Reward',
      cell: (row) =>
        <div>
          {row.type === "amount" ? `${row.reward_amount} Coins` : null}
          {row.type === "coupon" ? `${row.coupon} Coupon` : null}
        </div>,
      center: true
    },
    // {
    //   name: 'Image',
    //   cell: (row) =>
    //     <div className="avatar auto">
    //       <img
    //         alt={row._id}
    //         src={row.image}
    //       />
    //     </div>,
    //   center : true
    // },
    {
      name: 'Probility of reward',
      selector: row => row.probability,
      center: true
    },
    {
      name: 'Last Updated Date',
      selector: row => ConvertToDMY(row.updatedAt),
      center: true
    },
    // {
    //   name: 'Reward To User',
    //   width: "150px",
    //   cell: (row) =>
    //     <Form.Check
    //       type="switch"
    //       checked={row.open_in_spin_wheel}
    //     onChange={e => handleSpinWheelStatusChange(row, e.target.checked)}
    //     />,
    //   button: true,
    //   center: true
    // },
    // {
    //   name: 'Status',
    //   cell: (row) =>
    //     <Form.Check
    //       type="switch"
    //       checked={row.status}
    //       disabled={true}
    //       onChange={e => handleStatusChange(row, e.target.checked)}
    //     />,
    //   button: true,
    //   center: true
    // },
    {
      name: 'Action',
      id: 'actions',
      cell: (row) =>
        <span className="actions">
          <Link to={`/spin-wheel/edit/${row._id}`}><i className="mdi mdi-pen" /></Link>
        </span>,
      button: true,
      center: true
    }
  ];

  const handlePerRowsChange = async (newPerPage, page) => {
    setperPage(newPerPage);
    setcurrentPage(page);
  };

  const handlePageChange = (page) => {
    setcurrentPage(page);
  };

  useEffect(() => {
    Postdata(HttpService.Spin_wheel_fetch).then((response) => {
      if (response.status === 200) {
        setData(response.data.spinWheels)
        setProbSum(response.data.sum)
        setLoading(false)
      } else {
        setLoading(false)
        dispatch(message(response.message));
      }
    }).catch((e) => {
      setLoading(false)
      dispatch(message(e.message));
    });
    setLoading(false)
  }, [currentPage, perPage, loading, refresh]);

  return (
    <>
      <Title name="Spin Wheel Management" />
      <div className='row'>
        <div className='col-lg-8'>
          <h4>Spin Wheel Management</h4>
          <Breadcrumb
            active="Spin Wheel Management"
          />
        </div>
      </div>
      <div className='card'>
        <p className='card-header'>List Of rewards</p>
        <div className='card-body'>
          {loading ? <Loader /> : (
            <DataTableBase
              columns={columns}
              data={data}
              paginationTotalRows={totalRows}
              onChangeRowsPerPage={handlePerRowsChange}
              onChangePage={handlePageChange}
              loader={loading}
            />
          )}
          <small className='my-1'>Note : Probability of rewards will be calculcated on the basis of 100 spins. Kindly make sure to make sum of all probabilities equal to 100. Current total : <bold>{probSum}</bold></small>
        </div>

      </div>
    </>
  )
}

export { Spin_Wheel_List }