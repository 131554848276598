/* eslint-disable react/prop-types */
import Title from "../../common/Title";
import { Form, Collapse, Tabs, Tab } from "react-bootstrap";
import { useState, useEffect } from "react";
import { Postdata } from '../../../utils/api.service';
import DataTableBase from "../../common/DataTableBase";
import Link from "../../common/Link";
import HttpService from "../../../utils/http.service";
import { ConvertToDMYHIS } from "../../../utils/Helper";
import { useDispatch } from "react-redux";
import { message } from "../../../redux/actions/toastActions";
import Breadcrumb from "../../common/Breadcrumb";
import ModalPopup from "../../common/Modal";
import CSV from '../../common/CSV'

const Players = ({ data }) => {
  const columns = [
    {
      name: '#',
      width: "45px",
      selector: (row, i) => i + 1,
    },
    {
      name: 'Profile',
      width: "65px",
      selector: row => <div className="avatar">
        <img
          alt={row.title}
          src={row.thumb_url}
        />
      </div>,
    },
    {
      name: 'Name',
      selector: row => row.title,
    },
    {
      name: 'Role',
      selector: row => <span style={{ textTransform: 'uppercase' }}>{row.playing_role}</span>,
    },
    {
      name: 'Points',
      width: "55px",
      selector: row => row.fantasy_player_rating,
    },
  ];

  return <DataTableBase
    columns={columns}
    pagination={false}
    data={data.players.sort((b, a) => a.fantasy_player_rating - b.fantasy_player_rating)}
  />
}

const Match = () => {
  const [data, setData] = useState([]);
  const [isloading, setIsloading] = useState(true);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setperPage] = useState(process.env.REACT_APP_RecordPerPage);
  const [currentPage, setcurrentPage] = useState(1);
  const [refresh, setRefresh] = useState(false);
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [search, setSearch] = useState(false);

  const [modalswitch, setmodalswitch] = useState(false);
  const [modalswitchcontent, setmodalswitchcontent] = useState([0, 0, 0]);

  const columns = [
    {
      name: '#',
      width: "55px",
      selector: (row, i) => (currentPage * perPage + (i + 1) - perPage),
    },
    {
      name: 'Sport',
      selector: row => <span className="text-cap">{row.sports}</span>,
    },
    {
      name: 'Teams',
      selector: row => row.short_title,
    },
    {
      name: 'Team A',
      selector: row => <><div className="avatar team">
        <img
          alt={row.teama.name}
          src={row.teama.logo_url}
        />
      </div><b>{row.teama.name}</b></>,
    },
    {
      name: 'Team B',
      selector: row => <><div className="avatar team">
        <img
          alt={row.teamb.name}
          src={row.teamb.logo_url}
        />
      </div><b>{row.teamb.name}</b></>,
    },
    {
      name: 'Match Time',
      selector: row => ConvertToDMYHIS(row.date_start_ist),
    },
    {
      name: 'Match Type',
      selector: row => row.format_str,
    },
    {
      name: 'Status',
      selector: row => row.status_note,
    },
    {
      name: 'Action',
      id: 'actions',
      cell: (row) =>
        <span className="actions">
          <i onClick={() => handleStatusChange(row.teama, row.teamb, row.short_title)} className="mdi mdi-account-multiple" />
          <Link to={`/match/contest/${row._id}`}><i className="mdi mdi-eye" /></Link>
        </span>,
      button: true,
    }
  ];

  const handleStatusChange = (a, b, c) => {
    setmodalswitchcontent([a, b, c]);
    setmodalswitch(true);
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    setperPage(newPerPage);
    setcurrentPage(page);
  };

  const handlePageChange = (page) => {
    setcurrentPage(page);
  };

  useEffect(() => {
    if (search !== false) {
      const timeOutId = setTimeout(() => setRefresh(!refresh), 100);
      return () => clearTimeout(timeOutId);
    }
  }, [search]);

  useEffect(() => {
    setIsloading(true);
    const postBody = Object.fromEntries(Object.entries({
      ...search,
      page: currentPage,
      perpage: perPage,
    }).filter(([, value]) => value !== ''));
    Postdata(HttpService.Match_list, postBody).then((response) => {
      console.log(response)
      if (response.status === 200) {
        setData(response.data.matches);
        setTotalRows(response.data.count);
        setIsloading(false);
      } else {
        setIsloading(false);
        dispatch(message(response.message));
      }
    }).catch((e) => {
      setIsloading(false);
      dispatch(message(e.message));
    });
  }, [currentPage, perPage, refresh]);

  return (
    <>
      <Title name='Match Management' />

      <ModalPopup
        className="alert-box-popup"
        show={modalswitch}
        size="md"
        onHide={() => setmodalswitch(false)}
        heading={`Players: ${modalswitchcontent[2]}`}
      >
        <div className="tabspages">
          <Tabs>
            <Tab eventKey="teama" title={modalswitchcontent[0].name || '1'}>
              <Players data={modalswitchcontent[0]} />
            </Tab>
            <Tab eventKey="teamb" title={modalswitchcontent[1].name || '2'}>
              <Players data={modalswitchcontent[1]} />
            </Tab>
          </Tabs>
        </div>
      </ModalPopup>

      <div className="row">
        <div className="col-lg-4">
          <h4>Match Management</h4>
          <Breadcrumb
            active="Match Management"
          />
        </div>
        <div className="col-lg-8 text-end">
          <button className="btn btn-danger" onClick={() => setOpen(!open)}>Filters</button>
          <CSV
              link={HttpService.Match_list}
              response="matches"
              name="Match Management"
              headers={[
                { label: "ID", key: "_id" },
                { label: "Sports", key: "sports" },
                { label: "Teams", key: "short_title" },
                { label: "Match Type", key: "format_str" },
                { label: "status", key: "status" },
              ]}
              extra={{
                ...search,
                page: currentPage,
                perpage: perPage,
            }}
            />
        </div>
      </div>

      <Collapse in={open}>
        <div>
          <div className="card">
            <p className="card-header">Filters</p>
            <div className="card-body">
              <div className="row">
                <div className="col-lg-4">
                  <Form.Group className="form-group">
                    <Form.Label>Sports</Form.Label>
                    <Form.Select onChange={e => setSearch({ ...search, sports: (e.target.value == '') ? '' : (e.target.value) })}>
                      <option value="">All</option>
                      <option value="cricket">Cricket</option>
                      <option value="football">Football</option>
                    </Form.Select>
                  </Form.Group >
                </div>
                <div className="col-lg-4">
                  <Form.Group className="form-group">
                    <Form.Label>Match Type</Form.Label>
                    <Form.Select onChange={e => setSearch({ ...search, type: (e.target.value == '') ? '' : Number(e.target.value) })}>
                      <option value="">All</option>
                      <option value="1">Fixture</option>
                      <option value="3">Live</option>
                      <option value="2">Result</option>
                    </Form.Select>
                  </Form.Group >
                </div>
                <div className="col-lg-4">
                  <Form.Group className="form-group mb-0">
                    <Form.Label>Search</Form.Label>
                    <Form.Control
                      type="text"
                      onChange={e => setSearch({ ...search, search: e.target.value })}
                      required
                    />
                  </Form.Group>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Collapse>

      <div className="card">
        <p className="card-header">List of Matches</p>
        <div className="card-body">
          <DataTableBase
            columns={columns}
            loader={isloading}
            data={data}
            paginationTotalRows={totalRows}
            onChangeRowsPerPage={handlePerRowsChange}
            onChangePage={handlePageChange}
          />
        </div>
      </div>
    </>
  )
}

export { Match }