import Title from "../../common/Title";
import { Form, Collapse } from "react-bootstrap";
import { useState, useEffect } from "react";
import { Postdata } from '../../../utils/api.service';
import DataTableBase from "../../common/DataTableBase";
import Link from "../../common/Link";
import HttpService from "../../../utils/http.service";
import { CheckPermission, ConvertToDMYHIS } from "../../../utils/Helper";
import { useDispatch, useSelector } from "react-redux";
import { message } from "../../../redux/actions/toastActions";
import Breadcrumb from "../../common/Breadcrumb";
import ModalPopup from "../../common/Modal";
import { isPermission } from "../../../redux/selectors/authSelector";


const Contest = () => {
  const [data, setData] = useState([]);
  const [isloading, setIsloading] = useState(true);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setperPage] = useState(process.env.REACT_APP_RecordPerPage);
  const [currentPage, setcurrentPage] = useState(1);
  const [refresh, setRefresh] = useState(false);
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [search, setSearch] = useState(false);

  const [modalswitch, setmodalswitch] = useState(false);
  const [modalswitchcontent, setmodalswitchcontent] = useState([0, 0]);
  const permission = useSelector(isPermission);

  const columns = [
    {
      name: '#',
      width: "55px",
      selector: (row, i) => (currentPage * perPage + (i + 1) - perPage),
    },
    {
      name: 'Title',
      selector: row => row.title,
    },
    {
      name: 'Game Type',
      selector: row => <span className="text-cap">{row.game_type}</span>,
    },
    // {
    //   name: 'Contest Type',
    //   selector: row => <span className="text-cap">{row.type}</span>,
    // },
    {
      name: 'No. of Winners',
      selector: row => row.no_of_winners,
    },
    {
      name: 'Total Team',
      selector: row => row.total_team,
    },
    {
      name: 'Prize Pool',
      selector: row => row.prize_pool,
    },
    {
      name: 'Entry Fee',
      selector: row => row.entry_fee,
    },
    {
      name: 'Updated',
      selector: row => ConvertToDMYHIS(row.updatedAt),
    },
    {
      name: 'Status',
      cell: (row) =>
        <Link permission={'/contest/add'} status={row.status}>
          <Form.Check
            type="switch"
            checked={row.status}
            onChange={e => handleStatusChange(row._id, e.target.checked)}
          />
        </Link>,
      button: true,
    },
    {
      name: 'Action',
      id: 'actions',
      hide: (!CheckPermission(permission, '/contest/add') && 999999),
      cell: (row) =>
        <span className="actions">
          <Link to={`/contest/edit/${row._id}`}><i className="mdi mdi-pen" /></Link>
        </span>,
      button: true,
    }
  ];

  const handleStatusChange = (id, status) => {
    setmodalswitchcontent([id, status]);
    setmodalswitch(true);
  };

  const handleStatusChangeAction = () => {
    setmodalswitch(false);
    setIsloading(true);
    Postdata(HttpService.Contest_add, {
      id: modalswitchcontent[0],
      status: Number(modalswitchcontent[1])
    }).then((res) => {
      setRefresh(!refresh);
      dispatch(message(res.message));
    })
  }

  const handlePerRowsChange = async (newPerPage, page) => {
    setperPage(newPerPage);
    setcurrentPage(page);
  };

  const handlePageChange = (page) => {
    setcurrentPage(page);
  };

  useEffect(() => {
    if (search !== false) {
      const timeOutId = setTimeout(() => setRefresh(!refresh), 500);
      return () => clearTimeout(timeOutId);
    }
  }, [search]);

  useEffect(() => {
    setIsloading(true);
    Postdata(HttpService.Contest_list, {
      page: currentPage,
      perpage: perPage,
      search: (search ? search : ''),
    }).then((response) => {
      if (response.status === 200) {
        setData(response.data.contests);
        setTotalRows(response.data.count);
        setIsloading(false);
      } else {
        setIsloading(false);
        dispatch(message(response.message));
      }
    }).catch((e) => {
      setIsloading(false);
      dispatch(message(e.message));
    });
  }, [currentPage, perPage, refresh]);

  return (
    <>
      <Title name='Default Contest' />

      <ModalPopup
        className="alert-box-popup"
        show={modalswitch}
        size="sm"
        onHide={() => setmodalswitch(false)}
        heading="Are you sure?"
      >
        <i className="mdi mdi-check"></i>
        <p>You want to change this status!</p>
        <div className='actions'>
          <button className="btn btn-primary" onClick={() => handleStatusChangeAction()}>Yes, Change Status</button>
          <button className="btn btn-danger" onClick={() => setmodalswitch(false)}>Cancel</button>
        </div>
      </ModalPopup>

      <div className="row">
        <div className="col-lg-4">
          <h4>Default Contest</h4>
          <Breadcrumb
            active="Default Contest"
          />
        </div>
        <div className="col-lg-8 text-end">
          <Link to={'/contest/add'} className="btn btn-primary">Add New Contest</Link>
          <button className="btn btn-danger" onClick={() => setOpen(!open)}>Search</button>
        </div>
      </div>

      <Collapse in={open}>
        <div>
          <div className="card">
            <p className="card-header">Search</p>
            <div className="card-body">
              <Form.Group className="form-group mb-0">
                <Form.Label>Search</Form.Label>
                <Form.Control
                  type="text"
                  onChange={e => setSearch(e.target.value)}
                  required
                />
              </Form.Group>
            </div>
          </div>
        </div>
      </Collapse>

      <div className="card">
        <p className="card-header">List of Contests</p>
        <div className="card-body">
          <DataTableBase
            columns={columns}
            loader={isloading}
            data={data}
            paginationTotalRows={totalRows}
            onChangeRowsPerPage={handlePerRowsChange}
            onChangePage={handlePageChange}
          />
        </div>
      </div>
    </>
  )
}

export { Contest }