/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import Title from "../../common/Title";
import { Form, Collapse } from "react-bootstrap";
import { useState, useEffect } from "react";
import { Postdata } from '../../../utils/api.service';
import DataTableBase from "../../common/DataTableBase";
import HttpService from "../../../utils/http.service";
import { useDispatch } from "react-redux";
import { message } from "../../../redux/actions/toastActions";
import Breadcrumb from "../../common/Breadcrumb";
// import CSV from "../../common/CSV";
import { ConvertToDMY } from "../../../utils/Helper";
import { Link } from 'react-router-dom'

function Bet_Report() {
  const [data, setData] = useState([]);
  const [isloading, setIsloading] = useState(true);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setperPage] = useState(process.env.REACT_APP_RecordPerPage);
  const [currentPage, setcurrentPage] = useState(1);
  const [refresh, setRefresh] = useState(false);
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [search, setSearch] = useState('');
  const [sortBy, setSortBy] = useState('username')
  const [sortOrder, setSortOrder] = useState('asc')
  const [searchByDate, setSearchByDate] = useState('')
  const [fromDate, setFromDate] = useState('')
  const [toDate, setToDate] = useState('')
  
  const handlePerRowsChange = async (newPerPage, page) => {
    setperPage(newPerPage);
    setcurrentPage(page);
  };

  const handlePageChange = (page) => {
    setcurrentPage(page);
  };

  const handleSubmit = () => {
    setRefresh(prev => !prev); // Trigger the API call
  };

  const handleReset = () => {
    setSearch('');
    setSortBy('username');
    setSortOrder('asc');
    setSearchByDate('');
    setFromDate('');
    setToDate('');
    setcurrentPage(1);
    setRefresh(prev => !prev); // Trigger the API call with reset values
  };

  const columns = [
    {
      name: '#',
      width: "55px",
      selector: (row, i) => (currentPage * perPage + (i + 1) - perPage),
    },
    {
      name: 'Profile',
      cell: (row) =>
        <Link to={`/user-management/view/${row._id}`}>
          <div className="avatar">
            <img
              alt={row.user_id}
              src={row.profile_pic}
            />
          </div>
        </Link>,
      width: "75px",
    },
    {
      name: 'Username',
      cell: (row) =>
        <Link to={`/user-management/view/${row._id}`} style={{ textDecoration: 'none' }}>
          {row.username}
        </Link>
    },
    {
      name: 'Registration Date',
      selector: row => ConvertToDMY(row.createdAt),
    },
    {
      name: 'Profit/Loss',
      selector: row => row.profitLoss
    },
    {
      name: 'Action',
      id: 'actions',
      cell: (row) =>
        <span className="actions">
          <Link to={`/report/user_bet_report/${row._id}`}>
            <i className="mdi mdi-eye" />
          </Link>
        </span>,
      button: true,
    }
  ];

  useEffect(() => {
    setIsloading(true);
    Postdata(HttpService.Reports_bet_report, {
      page: currentPage,
      perpage: perPage,
      search: search || "",
      sort_key: sortBy || "",
      sort_order: sortOrder || "",
      search_by_date: searchByDate || "",
      from_date: fromDate || "",
      to_date: toDate || ''
      // search: search ? search : "",
      // sort_key: sortBy ? sortBy : "",
      // sort_order: sortOrder ? sortOrder : "",
      // search_by_date: searchByDate ? searchByDate : "",
      // from_date: fromDate ? fromDate : "",
      // to_date: (toDate ? toDate : '')

    })
      .then((response) => {
        if (response.status === 200) {
          console.log(response);
          setData(response.data.data);
          setTotalRows(response.data.count);
          setIsloading(false);
        } else {
          setIsloading(false);
          dispatch(message(response.message));
        }
      })
      .catch((e) => {
        setIsloading(false);
        dispatch(message(e.message));
      });
  }, [currentPage, perPage, refresh]);


  return (
    <>
      <Title name="Bet Report" />

      <div className="row">
        <div className="col-lg-4">
          <h4>Bet Report</h4>
          <Breadcrumb
            pages={[{ title: "Reports", link: "" }]}
            active="Bet Report"
          />
        </div>
        <div className="col-lg-8 text-end">
          <button className="btn btn-danger" onClick={() => setOpen(!open)}>
            Search
          </button>
        </div>
      </div>

      <Collapse in={open}>
        <div>
          <div className="card">
            <p className="card-header">Search</p>
            <div className="card-body">
              <Form.Group className="form-group mb-0">
                <div className="row">
                  <div className="col-lg-6">
                    <Form.Label>Search</Form.Label>
                    <Form.Control
                      type="text"
                      value={search}
                      onChange={(e) => setSearch(e.target.value)}
                      required
                    />
                  </div>
                  <div className="col-lg-3">
                    <Form.Label>Sort By</Form.Label>
                    <Form.Select 
                      value={sortBy}
                      onChange={(e) => setSortBy(e.target.value)}
                    >
                      <option value="username">Username</option>
                      <option value="createdAt">Registration Date</option>
                      <option value="profitLoss">Profit/Loss</option>
                    </Form.Select>
                  </div>
                  <div className="col-lg-3">
                    <Form.Label>Sort Order</Form.Label>
                    <Form.Select 
                      value={sortOrder}
                      onChange={(e) => setSortOrder(e.target.value)}>
                      <option value="asc">Ascending</option>
                      <option value="desc">Descending</option>
                    </Form.Select>
                  </div>
                  <div className="col-lg-3">
                    <Form.Group className="form-group mb-0">
                      <Form.Label>Transaction Date</Form.Label>
                      <Form.Select
                        value={searchByDate}
                        onChange={(e) => setSearchByDate(e.target.value)}
                      >
                        <option value="">All</option>
                        <option value="today">Today</option>
                        <option value="week">Last Week</option>
                        <option value="month">Last Month</option>
                        <option value="year">Last Year</option>
                        <option value="range">Select Date</option>
                      </Form.Select>
                    </Form.Group>
                  </div>
                  {searchByDate === "range" ? (
                    <>
                      <div className="col-lg-3">
                        <Form.Group className="form-group mb-0">
                          <Form.Label>From Date</Form.Label>
                          <Form.Control
                            type="date"
                            value={fromDate}
                            onChange={(e) => setFromDate(e.target.value)}
                          />
                        </Form.Group>
                      </div>
                      <div className="col-lg-3">
                        <Form.Group className="form-group mb-0">
                          <Form.Label>To Date</Form.Label>
                          <Form.Control
                            type="date"
                            value={toDate}
                            onChange={(e) => setToDate(e.target.value)}
                          />
                        </Form.Group>
                      </div>
                    </>
                  ) : (
                    ""
                  )}
                </div>
              </Form.Group>

              <div className="col-lg-3">
                <button style={{marginTop : '20px'}} 
                        className="btn btn-success" 
                        onClick={handleSubmit}
                        > Submit
                </button>
                <button 
                    style={{marginTop : '20px'}} 
                    className="btn btn-danger" 
                    onClick={() => { 
                handleReset(); // Trigger reset and search
                }}>Reset</button>
              </div>

            </div>
          </div>
        </div>
      </Collapse>

      <div className="card">
        <p className="card-header">Bet Report</p>
        <div className="card-body">
          <DataTableBase
            columns={columns}
            loader={isloading}
            data={data}
            paginationTotalRows={totalRows}
            onChangeRowsPerPage={handlePerRowsChange}
            onChangePage={handlePageChange}
          />
        </div>
      </div>
    </>
  );
}

export { Bet_Report }