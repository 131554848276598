import * as types from "../constants/constants";

let initialState = {
  chat_loading: true,
  chat_list: {
    admin: [],
    users: [],
  },
};

export default (state = initialState, action) => {
  switch (action.type) {
    case types.CHATLISTLOADING:
      return {
        ...state,
        chat_loading: true,
      };
    case types.CHATLIST:
      return {
        ...state,
        chat_loading: false,
        chat_list: action.data,
      };
    default:
      return state;
  }
};
