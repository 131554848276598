/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React from 'react'
import { useEffect, useState } from 'react';
import { Postdata } from '../../../utils/api.service';
import HttpService from '../../../utils/http.service';
import Loader from '../../common/Loader';
import { Table } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { message } from '../../../redux/actions/toastActions';
import { ConvertToDMYHIS } from '../../../utils/Helper';

function KYC(props) {
    const dispatch = useDispatch()
    const [data, setData] = useState({});
    const [isloading, setIsloading] = useState(true);

    useEffect(() => {
        setIsloading(true);
        Postdata(HttpService.User_kyc, {
            id: props.id
        }).then((response) => {
            if (response.status === 200) {
                // console.log(response.data)
                setData(response.data);
                setIsloading(false);
            } else {
                setIsloading(false);
                dispatch(message(response.message));
            }
        }).catch((e) => {
            setIsloading(false);
            dispatch(message(e.message));
        });
    }, []);

    return (
        <>
            <div className="card-body PersonalDetails">
                {
                    isloading ? <Loader /> : <>
                        <div className="row">
                            <div className="col-lg-12 mb-4">
                                <h5>KYC Details</h5>
                                <Table>
                                    <tbody>
                                        <tr><th width="200">Status</th><td>{data.message} &nbsp;</td></tr>
                                        {(data.is_kyc_verified == 1 || data.is_kyc_verified == 2) && (data.kyc_files.length == 2) ?
                                            <>
                                                <tr><th width="200">Date</th><td>{ConvertToDMYHIS(data.kyc_date)}</td></tr>
                                                {data.kyc_files.map((file, index) => {
                                                    return (
                                                        <tr key={`user_kyc_${index}`}><th width="200">Document {index + 1}</th><td>
                                                            <a href={file.document} target="_blank" rel="noopener noreferrer">
                                                                View Document
                                                            </a>
                                                        </td></tr>
                                                    )
                                                })}
                                            </>
                                            : null}
                                    </tbody>
                                </Table>
                            </div>
                        </div>
                    </>
                }
            </div>
        </>
    )
}

export { KYC }