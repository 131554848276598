/* eslint-disable react/prop-types */
import { Navigate } from 'react-router-dom';
import { Topbar } from '../components/include/Topbar';
import { Sidebar } from '../components/include/Sidebar';
import { Footer } from '../components/include/Footer';
import { isAuthenticated } from "../redux/selectors/authSelector";
import { toastShow, toastMessage } from "../redux/selectors/toastSelector";
import { useDispatch, useSelector } from 'react-redux';
import { verifyToken } from "../redux/actions/authActions";
import Toast from '../components/common/Toast';
import Loader from '../components/common/Loader';
import { useEffect } from 'react';

const PrivateRoute = ({ Component }) => {
  const dispatch = useDispatch();
  const auth = useSelector(isAuthenticated);
  const toast_show = useSelector(toastShow);
  const toast_msg = useSelector(toastMessage);

  useEffect(() => {
    const intervalId = setInterval(() => {
      dispatch(verifyToken());
    }, 10000);
    return () => clearInterval(intervalId);
  }, []);

  if (!auth && window.localStorage.getItem('authorizationToken')) {
    dispatch(verifyToken());
    return <Loader isClass="loader_fullscreen" />;
  }

  return auth ?
    <>
      <Topbar />
      <div className='page-hover'>
        <Sidebar />
        <Toast
          show={toast_show}
          message={toast_msg}
        />
        <div className='page-content' key={window.location.pathname}>
          <Component />
          <Footer innerFooter={true} />
        </div>
      </div>
    </>
    : <Navigate to="/" />
}

export default PrivateRoute;
