/* eslint-disable no-unused-vars */
import Title from "../../common/Title";
import Breadcrumb from "../../common/Breadcrumb";
import { Form, Collapse } from "react-bootstrap";
import { useState, useEffect } from "react";
import { AsyncTypeahead } from "react-bootstrap-typeahead";
import { Postdata } from '../../../utils/api.service'
import { useDispatch } from "react-redux";
import HttpService from '../../../utils/http.service';
import { message } from '../../../redux/actions/toastActions'
import Loader from "../../common/Loader";
import { useParams, Link, useNavigate } from 'react-router-dom'

function Spin_Wheel_Add() {
  const navigate = useNavigate();
  const dispatch = useDispatch()
  const params = useParams();
  const [rewardType, setRewardType] = useState({
    amount: false,
    prize: false,
    coupon: false
  })
  const [data, setData] = useState({})
  const [loading, setLoading] = useState(true)
  const [image, setImage] = useState('')

  const [options, setOptions] = useState([])
  const [isSearchLoading, setIsSearchLoading] = useState(false)
  const [selectedUsers, setSelectedUsers] = useState([])



  const setRadioInput = (type, e) => {
    setData({
      ...data,
      type
    })
  }

  const handleImagePreview = (e) => {
    const image_as_files = e.target.files[0];
    setImage(URL.createObjectURL(e.target.files[0]))
    setData({ ...data, image: image_as_files })
  }


  const handleUserSearch = (query) => {
    setIsSearchLoading(true)
    Postdata(HttpService.Promocode_valid, {
      page: 1,
      perpage: 50,
      code: query
    }).then((response) => {
      if (response.status === 200) {
        const newUsers = response.data.map((user) => ({
          ...user,
          label: user.code
        }))
        setOptions(newUsers)
        // setOptions(response.data)
      }
      setIsSearchLoading(false)
    }).catch((e) => {
      dispatch(message(e.message));
      setIsSearchLoading(false)
    });
  }


  const updateReward = async () => {
    setLoading(true)
    Postdata(HttpService.Spin_wheel_update, {
      ...data
    }).then((response) => {
      if (response.status === 200) {
        navigate("/spin-wheel")
      } else {
        setLoading(false)
        dispatch(message(response.message));
      }
    }).catch((e) => {
      setLoading(false)
      dispatch(message(e.message));
    });
  }


  useEffect(() => {
    Postdata(HttpService.Spin_wheel_get_by_id, {
      id: params.id
    }).then((response) => {
      if (response.status === 200) {
        setData(response.data)
        setImage(response.data.image)
        setLoading(false)
      } else {
        setLoading(false)
        dispatch(message(response.message));
      }
    }).catch((e) => {
      setLoading(false)
      dispatch(message(e.message));
    });
  }, [loading]);

  return (
    <>
      <Title name='Spin Wheel Management' />
      <div className="row">
        <div className="col-lg-6">
          <h4>Edit Spin Wheel Reward</h4>
          <Breadcrumb
            pages={[
              { title: 'Spin Wheel Management', link: 'spin-wheel' }
            ]}
            active='Edit Spin Wheel Reward'
          />
        </div>
      </div>

      <div>
        <div className="card">
          <p className="card-header">Edit Spin Wheel Reward</p>
          <div className="card-body">
            {loading ? <Loader /> : (
              <>
                <Form.Group className="form-group">
                  <Form.Label>Reward Type</Form.Label>
                  <Form.Check
                    type="radio"
                    label="Amount"
                    id="radio-1"
                    onChange={e => setRadioInput("amount", e)}
                    checked={data.type === "amount"}
                  />
                  {/* <Form.Check
                    type="radio"
                    label="Prize"
                    id="radio-2"
                    disabled={true}
                    onChange={e => setRadioInput("prize", e)}
                    checked={data.type === "prize"}
                  /> */}
                  <Form.Check
                    type="radio"
                    label="Coupon"
                    id="radio-3"
                    onChange={e => setRadioInput("coupon", e)}
                    checked={data.type === "coupon"}
                  />
                </Form.Group>

                <Collapse in={data.type === "amount"}>
                  <div className="row">
                    <div className="col-lg-4">
                      <Form.Group className="form-group">
                        <Form.Label>Enter Amount</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Enter Number of Coins to be offered as reward"
                          value={data.reward_amount}
                          onChange={e => {
                            const re = /^[0-9\b]+$/
                            if ((e.target.value === '' || re.test(e.target.value)) && e.target.value <= 1000000) {
                              setData({
                                ...data,
                                reward_amount: e.target.value
                              })
                            }
                          }}
                        />
                      </Form.Group>
                    </div>
                    <div className="col-lg-4">
                      <Form.Group className="form-group">
                        <Form.Label>Enter Probability <small>(Between 0-100)</small></Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Enter Number of Coins to be offered as reward"
                          value={data.probability}
                          onChange={e => {
                            const re = /^[0-9\b]+$/
                            if ((e.target.value === '' || re.test(e.target.value)) && (e.target.value <= 100 && e.target.value >= 0)) {
                              setData({
                                ...data,
                                probability: e.target.value
                              })
                            }
                          }}
                        />
                      </Form.Group>
                    </div>
                  </div>
                </Collapse>

                <Collapse in={data.type === "coupon"}>
                  <div className="row">
                  <div className="col-lg-4">
                    <Form.Group className="form-group">
                      <Form.Label>Select Coupon</Form.Label>
                      <AsyncTypeahead
                        id="select-coupon"
                        placeholder="Write to start searching available coupons..."
                        options={options}
                        onSearch={handleUserSearch}
                        onChange={(selected) => {
                          setData({
                            ...data,
                            coupon_id: selected[0]?._id,
                            coupon: selected[0]?.code
                          })
                        }}
                        isLoading={isSearchLoading}
                        renderMenuItemChildren={(option) => (
                          <>
                            {option.code}
                          </>
                        )}
                      />
                    </Form.Group>
                  </div>
                  <div className="col-lg-4">
                    <Form.Group className="form-group">
                      <Form.Label>Enter Probability <small>(Between 0-100)</small></Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter Number of Coins to be offered as reward"
                        value={data.probability}
                        onChange={e => {
                          const re = /^[0-9\b]+$/
                          if ((e.target.value === '' || re.test(e.target.value)) && (e.target.value <= 100 && e.target.value >= 0)) {
                            setData({
                              ...data,
                              probability: e.target.value
                            })
                          }
                        }}
                      />
                    </Form.Group>
                  </div>
                </div>
              </Collapse>

                {/* <Form.Group className="form-group">
                  <Form.Label>Image</Form.Label>
                  <Form.Control
                    type="file"
                    accept=".jpg,.png,.jpeg,.gif"
                    onChange={handleImagePreview}
                  />
                  {image && <>
                    <img className="preview" src={image} style={{ width: 'auto' }} />
                  </>}
                </Form.Group>

                <Form.Check
                  type="switch"
                  id="private-switch"
                  label="Status"
                  disabled={true}
                  checked={data.status}
                  onChange={e => {
                    setData({
                      ...data,
                      status: (data.status ? 0 : 1)
                    })
                  }}
                /> */}

            {/* </Form.Group> */}
            <div className="my-3">

              <button className="btn btn-primary" type="submit" onClick={updateReward} >Update</button>

              <Link to="/spin-wheel">
                <button className="btn btn-danger" type="submit" >Cancel
                </button>
              </Link>

            </div>
          </>
            )}
        </div>
      </div>
    </div>
    </>
  )
}

export { Spin_Wheel_Add }