/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import '../../../styles/Profile.scss';
import DataTableBase from '../../common/DataTableBase';
import { Collapse, Form } from 'react-bootstrap';
import React, { useState, useEffect } from 'react';
import { Postdata } from '../../../utils/api.service';
import HttpService from '../../../utils/http.service';
import { useDispatch } from 'react-redux';
import { message } from '../../../redux/actions/toastActions';
import { ConvertToDMYHIS } from '../../../utils/Helper';
import CSV from "../../common/CSV";

const GamePlayed = (props) => {
  const [open, setOpen] = useState(false);
  const [isloading, setIsloading] = useState(true);
  const [data, setData] = useState([]);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setperPage] = useState(10);
  const [currentPage, setcurrentPage] = useState(1);

  const [search, setSearch] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const dispatch = useDispatch();

  const columns = [
    {
      name: '#',
      width: "55px",
      selector: (row, i) => (currentPage * perPage + (i + 1) - perPage),
    },
    {
      name: 'Bet ID',
      selector: row => row._id.toString(),
    },
    {
      name: 'Date/Time',
      selector: row => ConvertToDMYHIS(row.createdAt),
    },
    {
      name: 'Game Name',
      selector: row => row.transfer_type,
    },
    {
      name: 'Participate With',
      selector: row => row.prev_balance + ' Coin',
    },
    {
      name: 'Total Win/Loss',
      selector: row => (row.history_type === 'transfer_out' ? '- ' : '+ ') + row.coins + ' Coin',
    },
    {
      name: 'Closing Balance',
      selector: row => row.new_balance + ' Coin',
    },
  ];


  const handlePerRowsChange = async (newPerPage, page) => {
    setperPage(newPerPage);
    setcurrentPage(page);
  };

  const handlePageChange = (page) => {
    setcurrentPage(page);
  };

  useEffect(() => {
    if (search !== false) {
      const timeOutId = setTimeout(() => setRefresh(!refresh), 500);
      return () => clearTimeout(timeOutId);
    }
  }, [search]);

  useEffect(() => {
    setIsloading(true);
    Postdata(HttpService.User_game_played, {
      page: currentPage,
      perpage: perPage,
      id: props.id,
      search: (search ? search : ''),
    }).then((response) => {
      if (response.status === 200) {
        setData(response.data.records);
        setTotalRows(response.data.total);
        setIsloading(false);
      } else {
        setIsloading(false);
        dispatch(message(response.message));
      }
    }).catch((e) => {
      setIsloading(false);
      dispatch(message(e.message));
    });
  }, [currentPage, perPage, refresh]);

  return (
    <>
      <div className="card-body filters">
        <div className="row searchaction">
          <div className="col-lg-6">
            <h5>Game Played</h5>
          </div>
          <div className="col-lg-6 text-end">
            <CSV
              link={HttpService.User_game_played}
              extra={{
                page: currentPage,
                perpage: perPage,
                id: props.id,
                search: (search ? search : ''),
              }}
              name="User : Game Played"
              response="records"
              headers={[
                { label: "Bet ID", key: "_id" },
                { label: "Date", key: "createdAt" },
                { label: "Game Name", key: "transfer_type" },
                { label: "Participate With", key: "prev_balance" },
                { label: "Total Win/Loss", key: "coins" },
                { label: "Closing Balance", key: "new_balance" },
              ]}
            />
            {/* <button className="btn btn-primary">Export</button> */}
            {/* <button className="btn btn-danger" onClick={() => setOpen(!open)}>Search</button> */}
          </div>
        </div>

        <Collapse in={open}>
          <div>
            <div className="card">
              <p className="card-header">Search</p>
              <div className="card-body">
                <Form.Group className="form-group mb-0">
                  <Form.Label>Search</Form.Label>
                  <Form.Control
                    type="text"
                    onChange={e => setSearch(e.target.value)}
                    required
                  />
                </Form.Group>
              </div>
            </div>
          </div>
        </Collapse>

        <DataTableBase
          columns={columns}
          loader={isloading}
          data={data}
          paginationTotalRows={totalRows}
          onChangeRowsPerPage={handlePerRowsChange}
          onChangePage={handlePageChange}
        />
      </div>
    </>
  )
}

export { GamePlayed }