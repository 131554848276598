/* eslint-disable react/prop-types */
import { Form, Collapse } from "react-bootstrap";
import { useState, useEffect } from "react";
import { Postdata } from '../../../utils/api.service';
import DataTableBase from "../../common/DataTableBase";
import HttpService from "../../../utils/http.service";
import { ConvertToDMY, ConvertToDMYHIS, CheckPermission } from "../../../utils/Helper";
import { useDispatch, useSelector } from "react-redux";
import { message } from "../../../redux/actions/toastActions";
import ModalPopup from "../../common/Modal";
import '../../../styles/Profile.scss'
import CSV from "../../common/CSV";
import Link from "../../common/Link";
import { isPermission } from "../../../redux/selectors/authSelector";

const GameManagement = () => {
  const [data, setData] = useState([]);
  const [isloading, setIsloading] = useState(true);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setperPage] = useState(process.env.REACT_APP_RecordPerPage);
  const [currentPage, setcurrentPage] = useState(1);
  const [refresh, setRefresh] = useState(true);
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [search, setSearch] = useState(false);

  const [modalswitch, setmodalswitch] = useState(false);
  const [modalswitchcontent, setmodalswitchcontent] = useState([0, 0]);
  const permission = useSelector(isPermission);

  const columns = [
    {
      name: '#',
      width: "55px",
      selector: (row, i) => (currentPage * perPage + (i + 1) - perPage),
    },
    {
      name: 'Image',
      cell: (row) =>
        <div className="avatar auto">
          <img
            alt={row._id}
            src={row.image}
          />
        </div>,
    },
    {
      name: 'Title',
      selector: row => row.title,
    },
    {
      name: 'Game Type',
      selector: row => row?.gametype_id?.name,
    },
    {
      name: 'Link',
      selector: row => row.target_link,
    },
    {
      name: 'Launch Date',
      selector: row => ConvertToDMY(row.launch_date),
    },
    {
      name: 'Total Played',
      selector: row => row.total_played ? row.total_played : 0,
    },
    {
      name: 'Updated',
      selector: row => ConvertToDMYHIS(row.updatedAt),
    },
    {
      name: 'Trending',
      cell: (row) =>
        <Link permission={'/settings/game/add'} status={row.status}>
          <Form.Check
            type="switch"
            checked={row.is_trending}
            onChange={e => handleTrendingStatusChange(row._id, e.target.checked)}
          />
        </Link>,
      button: true,
    },
    {
      name: 'Status',
      cell: (row) =>
        <Link permission={'/settings/game/add'} status={row.status}>
          <Form.Check
            type="switch"
            checked={row.status}
            onChange={e => handleStatusChange(row._id, e.target.checked)}
          />
        </Link>,
      button: true,
    },
    {
      name: 'Action',
      id: 'actions',
      hide: (!CheckPermission(permission, '/settings/game/add') && 999999),
      cell: (row) =>
        <span className="actions">
          <Link to={`/settings/game/edit/${row._id}`}><i className="mdi mdi-pen" /></Link>
          <Link to={`/settings/game/view/${row._id}`}><i className="mdi mdi-eye" /></Link>
        </span>,
      button: true,
    }
  ];

  const handleStatusChange = (id, status) => {
    setmodalswitchcontent([id, status]);
    setmodalswitch(true);
  };

  const handleTrendingStatusChange = (id, status) => {
    Postdata(HttpService.Game_add, {
      id,
      is_trending: Number(status)
    }).then((res) => {
      setRefresh(!refresh);
      dispatch(message(res.message));
    })
  };

  const handleStatusChangeAction = () => {
    setmodalswitch(false);
    setIsloading(true);
    Postdata(HttpService.Game_add, {
      id: modalswitchcontent[0],
      status: Number(modalswitchcontent[1])
    }).then((res) => {
      setRefresh(!refresh);
      dispatch(message(res.message));
    })
  }

  const handlePerRowsChange = async (newPerPage, page) => {
    setperPage(newPerPage);
    setcurrentPage(page);
  };

  const handlePageChange = (page) => {
    setcurrentPage(page);
  };

  useEffect(() => {
    if (search !== false) {
      const timeOutId = setTimeout(() => setRefresh(!refresh), 500);
      return () => clearTimeout(timeOutId);
    }
  }, [search]);

  useEffect(() => {
    setIsloading(true);
    Postdata(HttpService.Game_list, {
      page: currentPage,
      perpage: perPage,
      search: (search ? search : ''),
    }).then((response) => {
      console.log(response)
      if (response.status === 200) {
        setData(response.data.games);
        setTotalRows(response.data.count);
        setIsloading(false);
      } else {
        setIsloading(false);
        dispatch(message(response.message));
      }
    }).catch((e) => {
      setIsloading(false);
      dispatch(message(e.message));
    });
  }, [currentPage, perPage, refresh]);

  return (
    <>

      <ModalPopup
        className="alert-box-popup"
        show={modalswitch}
        size="sm"
        onHide={() => setmodalswitch(false)}
        heading="Are you sure?"
      >
        <i className="mdi mdi-check"></i>
        <p>You want to change this status!</p>
        <div className='actions'>
          <button className="btn btn-primary" onClick={() => handleStatusChangeAction()}>Yes, Change Status</button>
          <button className="btn btn-danger" onClick={() => setmodalswitch(false)}>Cancel</button>
        </div>
      </ModalPopup>

      <div className="card-body filters">
        <div className="row searchaction">
          <div className="col-lg-6">
            <h5>
              Game Management
            </h5>
          </div>

          <div className="col-lg-6 text-end">
            <Link to={'/settings/game/add'} className="btn btn-primary">Add New Game</Link>
            <button className="btn btn-danger" onClick={() => setOpen(!open)}>Search</button>
            <CSV
              link={HttpService.Game_list}
              name="Game Management"
              response="games"
              headers={[
                { label: "ID", key: "_id" },
                { label: "Title", key: "title" },
                { label: "Game Type", key: "gametype_id.name" },
                { label: "Link", key: "target_link" },
                { label: "Launch Date", key: "launch_date" },
                { label: "Total Played", key: "total_played" },
                { label: "Updated Date", key: "updatedAt" },
              ]}
            />
          </div>
        </div>

        <Collapse in={open}>
          <div>
            <div className="card">
              <p className="card-header">Search</p>
              <div className="card-body">
                <Form.Group className="form-group mb-0">
                  <Form.Label>Search</Form.Label>
                  <Form.Control
                    type="text"
                    onChange={e => setSearch(e.target.value)}
                    required
                  />
                </Form.Group>
              </div>
            </div>
          </div>
        </Collapse>

        <DataTableBase
          columns={columns}
          loader={isloading}
          data={data}
          paginationTotalRows={totalRows}
          onChangeRowsPerPage={handlePerRowsChange}
          onChangePage={handlePageChange}
        />
      </div>
    </>
  )
}

export { GameManagement }