/* eslint-disable react/prop-types */
import DataTable from 'react-data-table-component';
import Loader from './Loader';

const paginationComponentOptions = {
  rowsPerPageText: 'No. of Records',
  rangeSeparatorText: 'of',
};

const noRecordsMsg = () => {
  return (
    <div className='no_records'>
      <i className="mdi mdi-information-outline"></i>
      <p>There are no data records to display...</p>
    </div>
  );
};

function DataTableBase(props) {
  return (
    <DataTable
      paginationPerPage={process.env.REACT_APP_RecordPerPage}
      paginationRowsPerPageOptions={[10, 20, 50, 100]}
      pagination
      paginationServer
      progressPending={props.loader}
      progressComponent={<Loader isClass="table_loader" />}
      noDataComponent={noRecordsMsg()}
      paginationComponentOptions={paginationComponentOptions}
      {...props}
    />
  );
}

export default DataTableBase;
