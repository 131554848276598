import React, { useState, useEffect } from 'react'
import DataTableBase from '../../common/DataTableBase'
import Link from '../../common/Link';
import { Get } from '../../../utils/api.service';
import { Collapse, Form } from 'react-bootstrap';
import '../../../styles/Profile.scss'

const InAppPurchase = () => {
    const [open, setOpen] = useState(false);
    const [isloading, setIsloading] = useState(true);
    const [data, setData] = useState([]);
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setperPage] = useState(10);
    const [currentPage, setcurrentPage] = useState(1);

    const columns = [
        {
            name: 'No.',
            selector: row => row.id,
            width: '50px'
        },
        {
            name: 'Transaction',
            cell: () => 'TP' + (Math.random()).toString().slice(-3)
        },
        {
            name: 'Status',
            cell: () => 'Success',
        },
        {
            name: 'Username',
            selector: row => row.first_name,
        },
        {
            name: 'Agent Name',
            selector: row => row.last_name,
        },
        {
            name: 'Paid Amount',
            cell: () => 100,
        },
        {
            name: 'Coins',
            cell: () => 50,
        },
        {
            name: 'Purchased On',
            cell: () => '13/05/2022 15:30'
        },
        {
            name: 'Action',
            id: 'actions',
            cell: () =>
                <span className="actions">
                    <Link to={``}><i className="mdi mdi-eye" /></Link>
                </span>,
            button: true,
        }
    ];

    const handlePerRowsChange = async (newPerPage, page) => {
        setperPage(newPerPage);
        setcurrentPage(page);
    };

    const handlePageChange = (page) => {
        setcurrentPage(page);
    };

    useEffect(() => {
        setIsloading(true);
        async function fetchData() {
            const response = await Get(`https://reqres.in/api/users?page=${currentPage}&per_page=${perPage}&delay=0&_${Date.now()}`);
            setData(response.data);
            setTotalRows(response.total);
            setIsloading(false);
        }
        fetchData();
    }, [currentPage, perPage]);

    return (
        <div className='card-body filters'>
            <div className="row searchaction">
                <div className="col-lg-4">
                    <h5>In-App Purchase</h5>
                </div>
                <div className="col-lg-8 text-end">
                    <button className="btn btn-danger" onClick={() => setOpen(!open)}>Search</button>
                </div>
            </div>

            <Collapse in={open}>
                <div>
                    <div className="card">
                        <p className="card-header">Search</p>
                        <div className="card-body">
                            <Form.Group className="form-group mb-0">
                                <Form.Label>Search</Form.Label>
                                <Form.Control
                                    type="text"
                                    required
                                />
                            </Form.Group>
                        </div>
                    </div>
                </div>
            </Collapse>

            <DataTableBase
                columns={columns}
                loader={isloading}
                data={data}
                paginationTotalRows={totalRows}
                onChangeRowsPerPage={handlePerRowsChange}
                onChangePage={handlePageChange}
            />
        </div>
    )
}

export { InAppPurchase }