/* eslint-disable react/prop-types */
import { useRef, useState } from 'react';
import { CSVLink } from 'react-csv';
import { Postdata, Get } from '../../utils/api.service';


function CSV(props) {
  const [Data, setData] = useState([]);
  const [loading, setloading] = useState(false);
  const csvInstance = useRef();
  const response = props.response;
  let postArray = {};
  if (props.extra) {
    postArray = props.extra;
  }
  postArray.page = 1;
  postArray.perpage = 99999;

  let callAPI = Postdata;
  if (props.action === 'get') callAPI = Get;

  const getapidata = () => {
    if (!loading) {
      setloading(true);
      callAPI(props.link, postArray).then((res) => {
        if (res.status === 200) {
          if (response) {
            setData(res.data[response]);
          } else {
            setData(res.data);
          }
          setTimeout(() => {
            csvInstance.current.link.click();
            setloading(false);
          }, 10);
        } else {
          alert(res.message);
          setloading(false);
        }
      }).catch((e) => {
        alert(e.message);
        setloading(false);
      });
    }
  }

  return (
    <>
      <button
        disabled={loading}
        className={`btn btn-success ${loading && `disabled`}`}
        onClick={async () => {
          if (loading) {
            return;
          }
          getapidata();
        }}
      >
        {loading ? 'Loading...' : 'Export'}
      </button>
      {Data ?
        <CSVLink
          {...props}
          data={Data}
          filename={`${props.name}.csv`}
          ref={csvInstance}
        />
        : ''}
    </>
  );
}


export default CSV;
