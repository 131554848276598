/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import '../../../styles/Profile.scss'
import Breadcrumb from '../../common/Breadcrumb'
import Title from '../../common/Title'
import React, { useState, useEffect } from 'react'
import { Link, useParams } from 'react-router-dom'
import { Form } from "react-bootstrap";
import { useDispatch } from "react-redux"
import HttpService from '../../../utils/http.service';
import { Postdata } from '../../../utils/api.service'
import Loader from '../../common/Loader'
import { message } from '../../../redux/actions/toastActions'

function Notification_View() {

    const dispatch = useDispatch();
    const params = useParams();
    const [data, setData] = useState({
        title: "",
        type: "",
        user_type: "",
        sendedToUsers: [],
        message: "",
        image: "",
        createdAt: ""
    })
    const [image, setImage] = useState('');
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        async function fetchData(id) {
            const response = await Postdata(HttpService.Notification_getById, {
                id: id
            });
            if (response.status !== 200) {
                message(dispatch(response.data.message))
            }
            let image = response.data.image

            setImage(response.data.image);
            setData(response.data)
            setLoading(false)
        }
        if (params.id) {
            fetchData(params.id);
        }
    }, [])

    return (
        <>
            <Title name='View Notification' />
            <div className='row'>
                <div className='col-lg-4'>
                    <h4>View Notification</h4>
                    <Breadcrumb
                        pages={[
                            { title: 'Notifications', link: 'notification' }
                        ]}
                        active="View Notification"
                    />
                </div>
            </div>
            {loading ? <Loader /> : (
                <>
                    <div className='card'>
                        <p className='card-header' >View Notification</p>
                        <div className='card-body'>
                            <Form.Group className="form-group">
                                <Form.Label>Type</Form.Label>
                                <Form.Control type="text" value={((data.type || "") === "inApp" ? "In-App" : "Push") + " Notification"} disabled={true} />
                            </Form.Group>
                            <Form.Group className="form-group">
                                <Form.Label>Sended To</Form.Label>
                                <Form.Control type="text" value={((data.user_type || "") === "all" ? "All Users" : (data.user_type === "vipUsers" ? "Only Vip Users" : "Selected Users"))} disabled={true} />
                            </Form.Group>
                            {data.sendedToUsers.length > 0 ? (
                                <>
                                    <Form.Group className="form-group">
                                        <Form.Label>List Of Users</Form.Label>
                                    </Form.Group>
                                    {data.sendedToUsers.map((value) => {
                                        return (
                                            <div className='row my-3' key={`${value._id}`}>
                                                <div className='col-lg-1'>
                                                    <img src={value.profile_pic} style={{ width: "30px" }} />
                                                </div>
                                                <div className='col-lg-6'>
                                                    {value.username}
                                                </div>
                                            </div>
                                        )
                                    })}
                                </>
                            ) : null}
                            <Form.Group className="form-group">
                                <Form.Label>Title</Form.Label>
                                <Form.Control type="text" value={data.title || ""} disabled={true} />
                            </Form.Group>
                            <Form.Group className="form-group">
                                <Form.Label>Message</Form.Label>
                                <Form.Control type="text" value={data.message || ""} disabled={true} />
                            </Form.Group>
                            {/* <Form.Group className="form-group">
                                <Form.Label style={{ marginRight: "10px" }}>Image</Form.Label>
                                <img src={data.image} style={{ width: "100px", objectFit: "contain" }} />
                            </Form.Group> */}
                            <Link to={`/notification/edit-and-send/${data._id}`}>
                                <button className='btn btn-primary'>
                                    Edit And Resend
                                </button>
                            </Link>

                        </div>

                    </div>
                </>
            )}

        </>
    )
}

export { Notification_View }