import * as types from "../constants/constants";

let initialState = {
  data: [],
  permissions: [],
  error: "",
  message: "",
  role: "",
  role_id: "",
  isAuthenticated: false,
  loading: false,
};

function overRidePermissions(data) {
  let permission = [];
  if (data?.permissions?.length) data.permissions.map(e => e.permission_id?.frontend_routes?.map(v => permission.push(v)));

  let addonpermission = [
    '/dashboard',
    '/profile',
    '/profile/edit',
    '/profile/password',
    '/profile/bankdetails',
  ];
  addonpermission.map(e => permission.push(e))

  if (data?.role_id?.type === 'admin') return [];
  return permission;
}

export default (state = initialState, action) => {
  switch (action.type) {
    case types.LOGIN_DATA:
      return {
        ...state,
        error: "",
        message: "",
        isAuthenticated: false,
        loading: true
      };
    case types.LOGIN_DATA_SUCCESS:
      return {
        ...state,
        data: action.data.data,
        role: action.data.data.user.role,
        role_id: action?.data?.data?.user?.role_id || '',
        permissions: overRidePermissions(action.data.data.user),
        error: "",
        message: action.data.message,
        isAuthenticated: true,
        loading: false
      };
    case types.LOGIN_DATA_FAIL:
      return {
        ...state,
        data: [],
        error: action.data,
        message: "",
        isAuthenticated: false,
        loading: false
      };
    case types.LOGIN_DATA_CLEAR:
      return {
        ...state,
        data: [],
        error: "",
        message: "",
        isAuthenticated: false,
        loading: false
      };
    case types.LOGIN_DATA_OVERRIDE:
      return {
        ...state,
        data: { user: action.data },
        role: action.data.role,
        role_id: action?.data?.role_id || '',
        permissions: overRidePermissions(action.data),
      };
    default:
      return state;
  }
};