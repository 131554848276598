//************************************************************************
//This component - fetch and displays the games in a table
//************************************************************************

import React,{useState,useEffect} from 'react'
import {useParams} from 'react-router-dom'
import { useDispatch } from "react-redux";
import { Table } from 'react-bootstrap';


import { Post } from '../../../../utils/api.service';
import HttpService from '../../../../utils/http.service'
import { message } from "../../../../redux/actions/toastActions";
import Title from "../../../common/Title";
import Breadcrumb from "../../../common/Breadcrumb";
import Loader from '../../../common/Loader'

const DigitainGameView = () => {
  //Find the useParams to fetch the category from url
  const {id} = useParams()
  //useState
  const [isloading, setIsloading] = useState(true);
  const [data, setData] = useState([]);
  const [categoryName,setCategoryName]= useState([])

  //dispatch
  const dispatch = useDispatch();

  //********************************************************************
  // Post API - fetches game information with the category id
  //********************************************************************
  useEffect(() => {
    // setIsloading(true);
    const payload = {categoryId: Number(id) }

    Post(HttpService.Digitain_fetch_games_by_id,payload).then((response) => {
      if (response.status === 200) {
        // Use nullish coalescing to default to an empty array
        setData(response.data.sortedGameData ?? []); // Ensure 'data' is defined and an array, 
        setCategoryName(response.data.categoryName);
        setIsloading(false);
        
      } else {  
        setIsloading(false);
        dispatch(message(response.message));
        console.error('Failed to fetch category games:', response);
      }
    }).catch((e) => {
      setIsloading(false);
      dispatch(message(e.message));
      console.error('Error making API call:', e);
    });

  },[]);


  return (
    <>
      <Title name='Game Management - Add' />
      <div className="row">
        <div className="col-lg-6">
          <h4>Games View</h4>
          <Breadcrumb
            pages={[
              { title: 'Evenbet Settings', link: 'evenbet-game-settings' },
            ]}
            active = 'Games view'
          />
        </div>
      </div>

      <div className="row">
        <div className="col-lg-12">
            <div className="card">
                <p className="card-header"> View games of {categoryName}</p>
                <div className="card-body">

                  <div className="row mb-3">
                    <div className="col-lg-6">
                      <h5>Category Name : {categoryName}</h5>
                    </div>
                  </div>
                  
                    <Table striped bordered hover>
                      <thead>
                        <tr>
                          <th>Re-order</th>
                            <th>Game ID</th>
                            <th>Game Name</th>
                            <th>Provider Name</th>
                          </tr>
                      </thead>
                      {isloading? <Loader/> : " "}
                      <tbody>
                        
                        { Array.isArray(data) && data.length === 0 ? 
                          <p>No Games available for this category</p>
                        :
                        data.map( (game) => (
                          <>
                            <tr>
                              <td>::</td>
                              <td>{game.gameId}</td>
                              <td>{game.gameName}</td>
                              <td>{game.provider.name}</td>
                            </tr>
                          </> 
                        )) 
                        }
                      </tbody>
                    </Table>
                      
                </div>
            </div>
        </div>
      </div>
    </>
  )
}

export default DigitainGameView



