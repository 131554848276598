/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react'
import '../../../styles/Profile.scss'
import Breadcrumb from '../../common/Breadcrumb'
import Title from '../../common/Title'
import { Link } from 'react-router-dom'
import { Form, Collapse } from "react-bootstrap";
import { ConvertToDMY } from '../../../utils/Helper'
import DataTableBase from '../../common/DataTableBase'
import HttpService from '../../../utils/http.service';
import { Postdata } from '../../../utils/api.service'
import { useDispatch } from "react-redux";
import { message } from '../../../redux/actions/toastActions'
import ModalPopup from '../../common/Modal'


function KycList() {
  const [data, setData] = useState([]);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setperPage] = useState(process.env.REACT_APP_RecordPerPage);
  const [currentPage, setcurrentPage] = useState(1);
  const [isloading, setIsloading] = useState(true);
  const [documentViewModal, setDocumentViewModal] = useState(false)
  const [viewData, setViewData] = useState([])
  const [refresh, setRefresh] = useState(false)
  const [openSearch, setOpenSearch] = useState(false)
  const [search, setSearch] = useState('')
  const [adminActionModal, setAdminActionModal] = useState(false)
  const [updateDetails, setUpdateDetails] = useState({})

  const dispatch = useDispatch()

  const openDocumentsView = (files) => {
    setViewData(files)
    setDocumentViewModal(true)
  }

  const onAdminAction = (id, status) => {
    setAdminActionModal(true)
    setUpdateDetails({
      id, status
    })
  }

  const updateUserKycStatus = (id, status) => {
    Postdata(HttpService.Update_kyc_request, {
      id,
      status
    }).then((response) => {
      if (response.status === 200) {
        dispatch(message(response.message));
        setRefresh(!refresh)
      } else {
        setRefresh(!refresh)
      }
      setAdminActionModal(false)
    }).catch((e) => {
      dispatch(message(e.message));
      setRefresh(!refresh)
      setAdminActionModal(false)

    });
  }

  const columns = [
    {
      name: '#',
      width: "55px",
      selector: (row, i) => (currentPage * perPage + (i + 1) - perPage),
      center: true
    },
    {
      name: 'Profile',
      cell: (row) =>
        <div className="avatar">
          <img
            alt={row.user_id}
            src={row.profile_pic}
          />
        </div>,
      width: "75px",
      center: true
    },
    {
      name: 'Username',
      selector: row => row.username,
      center: true
    },
    {
      name: 'Email',
      selector: row => row.email,
      center: true
    },
    {
      name: 'No. of Documents',
      cell: (row) =>
        <div>
          {row.kyc_files.length}
        </div>,
      center: true
    },
    {
      name: 'Documents',
      cell: (row) =>
        <div className='d-flex' style={{ pointer: "cursor" }} >
          {row.kyc_files.map((file, index) => {
            if (file.document.match(/\.(pdf|PDF)$/) != null) {
              // pdf
              return (
                <a
                  key={row._id + index}
                  className="d-flex flex-column text-center"
                  href={file.document}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <embed
                    src={file.document}
                    style={{ width: "100px", height: "100px", margin: "5px" }}
                  />
                  View
                </a>
              );
            } else {
              //image
              return (
                <a
                  key={row._id + index}
                  className="d-flex flex-column text-center"
                  href={file.document}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src={file.document}
                    style={{ width: "100px", height: "100px", margin: "5px" }}
                  />
                  View
                </a>
              );
            }
          })}
        </div>,
      width: "300px",
      center: true
    },
    {
      name: 'Action (Verify KYC)',
      cell: (row) =>
        <span className="actions">
          <i className="mdi mdi-checkbox-marked-circle-outline" style={{ color: "green" }} onClick={e => onAdminAction(row._id, 1)} />
          <i className="mdi mdi-close-circle-outline" style={{ color: "red" }} onClick={e => onAdminAction(row._id, 0)} />
        </span>,
      button: true,
      center: true
    }
  ];

  const handlePerRowsChange = async (newPerPage, page) => {
    setperPage(newPerPage);
    setcurrentPage(page);
  };

  const handlePageChange = (page) => {
    setcurrentPage(page);
  };

  useEffect(() => {
    setIsloading(true);
    Postdata(HttpService.Kyc_user_requests, {
      search
    }).then((response) => {
      if (response.status === 200) {
        setData(response.data.records);
        setTotalRows(response.data.count);
        setIsloading(false);
      } else {
        setIsloading(false);
        dispatch(message(response.message));
      }
    }).catch((e) => {
      setIsloading(false);
      dispatch(message(e.message));
    });
  }, [currentPage, perPage, refresh, search]);

  return (
    <>
      <Title name='KYC Management' />
      <div className='row'>
        <div className='col-lg-4'>
          <h4>KYC Management</h4>
          <Breadcrumb
            active="KYC Management"
          />
        </div>
        <div className="col-lg-8 text-end">
          <button className="btn btn-danger" onClick={() => setOpenSearch(!openSearch)}>Search</button>
        </div>
      </div>

      <Collapse in={openSearch}>
        <div>
          <div className="card">
            <p className="card-header">Search</p>
            <div className="card-body">
              <div className='row'>
                <div className='col-lg-4'>
                  <Form.Group className="form-group mb-0">
                    <Form.Label>Username</Form.Label>
                    <Form.Control
                      type="text"
                      onChange={e => setSearch(e.target.value)}
                    />
                  </Form.Group>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Collapse>

      <ModalPopup
        show={documentViewModal}
        size="lg"
        onHide={() => setDocumentViewModal(false)}
        heading="User's KYC Documents"
      >
        <div className='row'>
          {viewData.length ? (<>
            {viewData.map((file, index) => {
              return (
                <div className="col-lg-6 text-center" key={index + 'user_documents'}>
                  {file.document.match(/\.(pdf|PDF)$/) == null ? (
                    <img key={"image" + index} src={file.document} style={{ height: "500px", width: "300px" }} />
                  ) : (
                    <embed key={"documents" + index} src={file.document} style={{ height: "500px", width: "300px" }} />)}
                </div>
              )
            })}
          </>) : null}

        </div>
      </ModalPopup>

      <ModalPopup
        className="alert-box-popup"
        show={adminActionModal}
        size="md"
        onHide={() => setAdminActionModal(false)}
        heading="Are you sure?"
      >
        <i className="mdi mdi-check"></i>
        <p>You want to change this status!</p>
        <div className='actions'>
          <button className="btn btn-primary" onClick={() => updateUserKycStatus(updateDetails.id, updateDetails.status)}>Yes, Change Status</button>
          <button className="btn btn-danger" onClick={() => setAdminActionModal(false)}>Cancel</button>
        </div>
      </ModalPopup>

      <div className='card'>
        <p className='card-header'>List of KYC Request</p>
        <div className='card-body'>
          <DataTableBase
            columns={columns}
            data={data}
            paginationTotalRows={totalRows}
            onChangeRowsPerPage={handlePerRowsChange}
            onChangePage={handlePageChange}
          />
        </div>
      </div>
    </>
  )
}

export { KycList }