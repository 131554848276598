/* eslint-disable react/no-unescaped-entities */
import { InputGroup, FormControl, Alert } from "react-bootstrap"
import Logo from '../assets/igame-logo.webp';
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useState, useEffect } from "react";
import { Footer } from "./include/Footer";
import { forgotpassword, forgotpasswordClear } from "../redux/actions/forgotpasswordActions";
import { isLoading, isError, isSuccess } from "../redux/selectors/forgotpasswordSelector";
import Title from "./common/Title";

const ForgotPassword = () => {
  const [email, setemail] = useState('');
  const loader = useSelector(isLoading);
  const errorMsg = useSelector(isError);
  const successMsg = useSelector(isSuccess);
  const dispatch = useDispatch();

  const onForgotPasswordAction = (event) => {
    event.preventDefault();
    dispatch(forgotpassword({ email: email }));
  };

  useEffect(() => {
    dispatch(forgotpasswordClear());
  }, []);

  useEffect(() => {
    if (successMsg) setemail('');
  }, [successMsg]);

  return (
    <>
      <Title name="Forgot Password" />
      <div className="account-pages my-5 pt-sm-5">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-8 col-lg-6 col-xl-5">
              {errorMsg &&
                <Alert variant="danger">
                  {errorMsg}
                </Alert>
              }
              {successMsg &&
                <Alert variant="success">
                  {successMsg}
                </Alert>
              }
              <div className="card overflow-hidden">
                <div className="card-body pt-0">
                  <h3 className="text-center mt-4">
                    <a href="/" className="logo logo-admin"><img src={Logo}
                      height="100" alt="logo" /></a>
                  </h3>
                  <div className="p-3">
                  <p className="text-center">
                      Please enter your associated email address to get details of your username and password reset link.
                    </p>
                    <form className="form-horizontal mt-4" action="/" onSubmit={onForgotPasswordAction}>
                      <InputGroup className="mb-3">
                        <InputGroup.Text><i className="mdi mdi-account" /></InputGroup.Text>
                        <FormControl
                          type="email"
                          value={email}
                          onChange={(e) => setemail(e.target.value)}
                          placeholder="Enter Email Address"
                          required
                        />
                      </InputGroup>
                      <div className="form-group row mt-4">
                        <div className="col-12 text-center">
                          <button
                            className="btn btn-primary w-md waves-effect waves-light"
                            type="submit"
                            disabled={loader}
                          >
                            {loader ? 'Please wait...' : 'Reset Password'}
                          </button>
                        </div>
                      </div>
                      <div className="form-group mb-0 row">
                        <div className="col-12 mt-4">
                          <Link to="/" className="text-muted"><i className="mdi mdi-account"></i> Login?</Link>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
              <div className="mt-5 text-center">
                <Footer />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export { ForgotPassword }