/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { useEffect, useRef, useState } from "react";
import { Form } from "react-bootstrap"
import io from 'socket.io-client';
import { isData } from "../../redux/selectors/authSelector";
import '../../styles/Chat.scss';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from "react-router-dom";
import { Postdata } from "../../utils/api.service";
import HttpService from "../../utils/http.service";
import { chatTypes, ConvertDatewithAGO, ConvertOnlyTime, ConvertYMD } from "../../utils/Helper";
// import { createCall } from "../../redux/actions/callActions";
import Loader from "../common/Loader";
import ModalPopup from "../common/Modal";
import Breadcrumb from "../common/Breadcrumb";
import Title from "../common/Title";
import { message } from "../../redux/actions/toastActions";
import { isChatList, isChatLoading } from "../../redux/selectors/chatSelector";

// https://tsh.io/blog/socket-io-tutorial-real-time-communication/
// https://developer.okta.com/blog/2021/07/14/socket-io-react-tutorial

const SupportChat = () => {
  let { room, userid } = useParams();
  const [roomid, setroomid] = useState(room);
  const [username, setusername] = useState(userid);

  const [socket, setSocket] = useState(null);
  const [isconnected, setisconnected] = useState(false);
  const [mgloader, setmgloader] = useState(true);
  const ProfileData = useSelector(isData);
  const [ischat, setischat] = useState({});
  // const [data, setData] = useState(false);
  const [admin_loader, setadmin_loader] = useState(true);
  const [admins, setadmins] = useState([]);
  const [friends, setfriends] = useState([]);
  const dispatch = useDispatch();

  const ischatlist = useSelector(isChatList);
  const ischatloading = useSelector(isChatLoading);

  useEffect(() => {
    setadmin_loader(ischatloading);
  }, [ischatloading]);

  useEffect(() => {
    setadmins(ischatlist.admin);
    setfriends(ischatlist.users);
  }, [ischatlist]);

  useEffect(() => {
    setroomid(room);
    setusername(userid);
  }, [room, userid]);

  // useEffect(() => {
  //   Postdata(HttpService.User_list, { page: 1, perpage: 99999, type: 'chat', search_by_role: 'user' }, dispatch).then((res) => {
  //     if (res.status === 200) {
  //       setfriends(res.data.users);
  //     } else {
  //       dispatch(message(res.message));
  //     }
  //     setmgloader(false);
  //   }).catch(e => {
  //     dispatch(message(e.message));
  //   });
  // }, []);

  useEffect(() => {
    if (ischat._id && ischat._id !== username) {
      // setData(false);
      setroomid(ischat.group_id);
      setusername(ischat._id);
    }
  }, [ischat, username]);

  useEffect(() => {
    if (roomid) {
      const newSocket = io(process.env.REACT_APP_SOCKET_URL);
      setSocket(newSocket);
      // Postdata(HttpService.User_get_profile, { user_id: username }).
      //   then((res) => {
      //     if (res.status === 200) {
      //       // setData(res.data);
      //       setischat(res.data);
      //     }
      //   });
      return () => newSocket.close();
    }
  }, [roomid]);

  useEffect(() => {
    if (socket) {
      socket.on('connect', function () {
        socket.emit('join', {
          roomid: roomid
        });
        setisconnected(socket.connected);
      });
      socket.on('admin exists', function (data) {
        setischat({});
        setroomid('');
        setusername('');
        dispatch(message(data.message));
      });
      socket.on('disconnect', function () {
        setisconnected(socket.connected);
      });
    }
  }, [socket]);

  const chatList_ = (i, type, y) => {
    return <div key={i._id} className={`users ${i._id === ischat._id ? 'isactive' : ''}`} onClick={() => setischat(i)}>
      <div className={`photo ${type ? `system` : ``}`}>
        <i className="p-r">
          <img src={i.profile_pic} />
          <b className={`socket con-${i.socket_id ? true : false}`} />
        </i>
        <span>{i.username}</span>
      </div>
      <div className='action'>
        {y && <b>Group</b>}
        {type && <b>{type}</b>}
        {i.unread_count !== 0 && <em>{i.unread_count}</em>}
      </div>
    </div>
  }

  const startCall = (type, id) => {
    // dispatch(createCall({
    //   ...ischat,
    //   type: type,
    //   connect_id: id,
    //   group: ischat.group_id,
    //   user_id: ischat.user_id
    // }));
  }

  return (
    <>
      <Title name='Support Chat' />
      <div className="row">
        <div className="col-lg-6">
          <h4>Support Chat</h4>
          <Breadcrumb
            active="Support Chat"
          />
        </div>
        <div className="col-lg-6">
        </div>
      </div>

      <div className={`card supportchat ${ProfileData?.user?.role_id?.type == 'admin' ? 'adminchat' : ''}`}>
        <div className="card-body" style={{ padding: 0 }}>
          <div className={`chat-board ${ischat.username ? 'isactive' : ''}`}>
            <div className={`user-list ${!ischat.username ? 'isactive' : ''}`}>
              <h3><span>User / Group</span> </h3>
              {/* {data._id && (data.role != 'admin') && chatList_(data)} */}
              {/* {mgloader && <Loader />} */}
              {/* {friends.map((i) => {
                // if (i._id !== ischat._id && i._id !== data._id) return chatList_(i);
                return chatList_(i);
              })} */}

              {friends.length ?
                friends.map((i) => {
                  // if (i._id !== ischat._id && i._id !== data._id) return chatList_(i);
                  return chatList_(i);
                })
                : (admin_loader ? <Loader /> : <p className='nofound'>No Record found...</p>)}
            </div>
            <div className={`chat-list ${ischat.username ? 'isactive' : ''}`}>
              {ischat.username ?
                <>
                  <div className="chat-top">
                    <div className="chat-actions isleftbar">
                      <span className="mdi mdi-arrow-left" onClick={() => setischat({})} />
                      <span className="mdi mdi-arrow-left hidden" onClick={() => setischat({})} />
                    </div>
                    <div className="chat-profile">
                      <i className='p-r'>
                        <img src={ischat.profile_pic} />
                        <b className={`socket con-${ischat.socket_id ? true : false}`} />
                      </i>
                      <span>{ischat.role != 'admin' ? ischat.username : ischat.name}</span>
                    </div>
                    <div className="chat-actions">
                      {/* {ischat.socket_id ?
                        <>
                          <span onClick={() => startCall('audio', ischat.socket_id)} className="material-icons">call</span>
                          <span onClick={() => startCall('video', ischat.socket_id)} className="material-icons">videocam</span>
                        </>
                        : <>
                          <span className="material-icons" style={{ opacity: 0.2 }}>call</span>
                          <span className="material-icons" style={{ opacity: 0.2 }}>videocam</span>
                        </>
                      } */}
                      <span className="mdi mdi-close iscloser" onClick={() => setischat({})} />
                    </div>
                  </div>

                  {isconnected && ProfileData.user ? (
                    <div className="chat-container">
                      <Messages socket={socket} roomid={roomid} userid={username} ischat={ischat} />
                      <MessageInput socket={socket} roomid={roomid} ischat={ischat} />
                    </div>
                  ) : (
                    <div className="chat-container">
                      <div className="chat-info">
                        <small><Loader /></small>
                      </div>
                    </div>
                  )}
                </>
                : null
              }
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

const MessageInput = ({ socket, roomid }) => {
  const [validated, setValidated] = useState(false);
  const [value, setValue] = useState('');
  const [attachment, setAttachment] = useState('');
  const [istyping, setistyping] = useState(false);
  const [loader, setLoader] = useState(false);
  const ProfileData = useSelector(isData);
  const [image, setImage] = useState([]);
  const myname = ProfileData.user._id;
  const myusername = ProfileData.user.username;

  useEffect(() => {
    socket.on('private', function (data) {
      console.log('private', data);
    });
    socket.on('typing', function (data) {
      setistyping(data.username);
    });
    return () => {
      socket.off('private', null);
      socket.off('typing', null);
    };
  }, [socket]);

  useEffect(() => {
    if (value.length) {
      socket.emit("typing", {
        username: myusername,
        roomid: roomid,
      });
    } else {
      socket.emit("typing", {
        username: '',
        roomid: roomid,
      });
    }
  }, [value]);

  const submitForm = (event) => {
    const form = event.currentTarget;
    event.preventDefault();
    event.stopPropagation();
    document.getElementById("Chat.Control.img").value = "";
    if (form.checkValidity() === true) {
      if (value.toString().trim()) {
        socket.emit("chat message", {
          message: value,
          _id: myname,
          roomid: roomid
        });
        setValue('');
        setImage([]);
        setValidated(false);
      }
      if (attachment.length) {
        setLoader(true);
        Postdata(HttpService.Chat_uploader, { files: attachment[0] }).then((res) => {
          if (res.status === 200) {
            if (res.data.length) {
              res.data.map(e => {
                socket.emit("chat message", {
                  message: e,
                  type: "file",
                  _id: myname,
                  roomid: roomid
                });
              });
            }
          }
          setLoader(false);
        }).catch(() => {
          setLoader(false);
        });
        setImage([]);
        setAttachment('');
        setValidated(false);
      }
    }
    setValidated(true);
  };

  const clearFiles = () => {
    setImage([]);
    setAttachment('');
    document.getElementById("Chat.Control.img").value = "";
  }

  const handleImagePreview = (e) => {
    const setImagesPath = [];
    const setImagesValue = [];
    if (e.target.files.length) {
      for (let i = 0; i < e.target.files.length; i++) {
        const image_as_files = e.target.files[i];
        // if (image_as_files.name.match(/\.(jpg|JPG|jpeg|JPEG|png|PNG|gif|GIF|doc|docx|pdf|txt|DOC|DOCX|PDF|TXT|mp3|mp4|mkv|MP3|MP4|MKV)$/)) {
        if (image_as_files.name.match(/\.(jpg|JPG|jpeg|JPEG|png|PNG|gif|GIF)$/)) {
          setImagesPath.push({
            src: URL.createObjectURL(image_as_files),
            name: image_as_files.name
          });
          setImagesValue.push(image_as_files);
        }
      }
    }
    setImage(setImagesPath);
    setAttachment(setImagesValue);
  }

  return (
    <div className="chat-bottom">
      <small>{istyping && (istyping !== myusername) ? 'typing...' : ''}</small>

      {image.length ?
        <>
          <i onClick={() => clearFiles()} className="clearfiles material-icons">cancel</i>
          <div className="img-preview">
            {image.map((i, k) => {
              return (
                <div key={k}>
                  {(i.name.match(/\.(jpg|jpeg|png|gif)$/))
                    ? <img src={i.src} /> : ''}
                  <p>{i.name}</p>
                </div>
              );
            })}
          </div>
        </>
        : ''}

      <Form noValidate validated={validated} autoComplete="off" method='get' action="/" onSubmit={submitForm}>
        <input
          id="Chat.Control.img"
          type="file"
          // multiple
          className="file-selector"
          // accept=".jpg,.JPG,.jpeg,.JPEG,.png,.PNG,.gif,.GIF,.doc,.docx,.pdf,.txt,.DOC,.DOCX,.PDF,.TXT,.mp3,.mp4,.mkv,.MP3,.MP4,.MKV"
          accept=".jpg,.JPG,.jpeg,.JPEG,.png,.PNG,.gif,.GIF"
          onChange={handleImagePreview}
        />

        <Form.Group controlId="Chat.Control.1" className="custom-input mb-0">
          <Form.Control
            // autoFocus
            placeholder="Write message here..."
            value={value}
            onChange={(e) => {
              setValue(e.currentTarget.value);
            }}
          />
          {/* <b onClick={() => document.getElementById('Chat.Control.img').click()}><span className="mdi mdi-attachment" /></b> */}
        </Form.Group>

        <button
          disabled={loader}
        >
          {loader ? <Loader fullscreen="forms" /> : <span className="mdi mdi-send" />}
        </button>
      </Form>
    </div>
  );
};

function Messages({ socket, ischat, roomid, userid }) {
  const [messages, setMessages] = useState([]);
  const [modalPreviewShow, setmodalPreviewShow] = useState(false);
  const [scrolldown, setscrolldown] = useState(true);
  const mydate = [];
  const messagesEndRef = useRef(null);
  const ProfileData = useSelector(isData);
  const myusername = ProfileData.user.name;
  const myname = ProfileData.user._id;
  const mypic = ProfileData.user.profile_pic;

  useEffect(() => {
    if (scrolldown === 'smooth') {
      messagesEndRef.current?.scrollIntoView({ behavior: "smooth", block: "end", inline: "nearest" });
    } else if (scrolldown) {
      // messagesEndRef.current?.scrollIntoView({ behavior: "smooth", block: "end", inline: "nearest" });
      messagesEndRef.current?.scrollIntoView({ block: "end", inline: "nearest" });
      // const ddds = setTimeout(() => {
      setTimeout(() => {
        messagesEndRef.current?.scrollIntoView({ block: "end", inline: "nearest" });
      }, 500);
      // return () => clearTimeout(ddds);
    }
  }, [scrolldown, messages])

  useEffect(() => {
    let page = 1;

    const messageListenerGroup = (data) => {
      setMessages((prevMessages) => {
        const newMessages = [...data, ...prevMessages];
        return newMessages;
      });
      setscrolldown(false);
    };

    const messageListener = (data) => {
      setMessages((prevMessages) => {
        const newMessages = [...prevMessages, data];
        return newMessages;
      });
      setscrolldown('smooth');
    };

    function onScroll() {
      setscrolldown(false);
      if (document.getElementsByClassName("chat-info")[0].scrollTop === 0) {
        document.getElementsByClassName("chat-info")[0].scroll({ top: 1 });
        page = page + 1;
        socket.emit("get messages", {
          _id: myname,
          roomid: roomid,
          page: page,
        });
      }
    }

    socket.emit("get messages", {
      _id: myname,
      roomid: roomid,
      page: page,
    });

    socket.on('chat message', messageListener);
    socket.on('get messages', messageListenerGroup);
    document.getElementsByClassName("chat-info")[0].addEventListener("scroll", onScroll);
    return () => {
      socket.off('chat message', messageListener);
      socket.off('get messages', messageListenerGroup);
    };
  }, [socket]);

  const preview = (src) => {
    setmodalPreviewShow(src);
  }

  return (
    <>
      <ModalPopup
        show={modalPreviewShow ? true : false}
        onHide={() => setmodalPreviewShow(false)}
      >
        <div className="preview-chat">
          {modalPreviewShow && <img src={modalPreviewShow} />}
        </div>
      </ModalPopup>
      <div className="chat-info">
        {[...Object.values(messages)]
          // .sort((a, b) => a.updatedAt - b.updatedAt)
          .map((i) => (
            <React.Fragment key={i._id}>
              {!mydate.includes(ConvertYMD(i.createdAt)) ?
                <div className="chat-times" key={Math.random()}>
                  <i>{mydate.push(ConvertYMD(i.createdAt))}</i>
                  {ConvertDatewithAGO(i.createdAt)}
                </div>
                : ''}
              <div className={i.user_id !== userid ? 'outgoing' : 'incoming'}>
                {/* {i.user_id !== myname && <div className="user-img"><img src={ischat.profile_pic} /></div>} */}
                <div className="bubbles">
                  {chatTypes(i, preview)}
                  <div className="user-info">
                    <b>{i.user_id !== userid ? 'admin' : ischat.username}</b>
                    <i>{ConvertOnlyTime(i.createdAt)}</i>
                  </div>
                </div>
                {/* {i.user_id === myname && <div className="user-img"><img src={mypic} /></div>} */}
              </div>
            </React.Fragment>
          ))
        }
        <div ref={messagesEndRef} />
      </div>
    </>
  );
}


export { SupportChat };