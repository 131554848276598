import Logo from '../../assets/igame-logo.webp';
import LogoSmall from '../../assets/igame-small-logo.webp';
import Person from '../../assets/user.jpg';
import '../../styles/Topbar.scss'
import { Dropdown } from 'react-bootstrap';
import Link from '../common/Link';
import { useDispatch, useSelector } from 'react-redux';
import { loginClear } from '../../redux/actions/authActions';
import { isData } from '../../redux/selectors/authSelector';
import { useEffect, useState } from 'react';
import ModalPopup from '../common/Modal';

const Topbar = () => {
  const dispatch = useDispatch();
  const data = useSelector(isData);
  const [image, setimage] = useState(Person);
  const [modalLogout, setmodalLogout] = useState(false);

  const FullscreenToggler = () => {
    const elem = document.documentElement;
    if ((document.fullScreenElement !== undefined && document.fullScreenElement === null) || (document.msFullscreenElement !== undefined && document.msFullscreenElement === null) || (document.mozFullScreen !== undefined && !document.mozFullScreen) || (document.webkitIsFullScreen !== undefined && !document.webkitIsFullScreen)) {
      if (elem.requestFullScreen) {
        elem.requestFullScreen();
      } else if (elem.mozRequestFullScreen) {
        elem.mozRequestFullScreen();
      } else if (elem.webkitRequestFullScreen) {
        elem.webkitRequestFullScreen(Element.ALLOW_KEYBOARD_INPUT);
      } else if (elem.msRequestFullscreen) {
        elem.msRequestFullscreen();
      }
    } else {
      if (document.cancelFullScreen) {
        document.cancelFullScreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitCancelFullScreen) {
        document.webkitCancelFullScreen();
      } else if (document.msExitFullscreen) {
        document.msExitFullscreen();
      }
    }
  };

  const SideBarToggler = () => {
    document.body.classList.toggle('sidebarHide');
  };

  const LogoutCallApi = () => {
    dispatch(loginClear());
    return false;
  };

  useEffect(() => {
    setimage(data?.user?.profile_pic);
  }, [data]);

  return (
    <>
      <div className='topbar'>

        <ModalPopup
          className="alert-box-popup"
          show={modalLogout}
          size="sm"
          onHide={() => setmodalLogout(false)}
          heading="Are you sure?"
        >
          <i className="mdi mdi-information-outline"></i>
          <div className='actions'>
            <button className="btn btn-primary" onClick={() => LogoutCallApi()}>Logout</button>
            <button className="btn btn-danger" onClick={() => setmodalLogout(false)}>Cancel</button>
          </div>
        </ModalPopup>

        <div className='d-flex'>
          <div className='sideMenuTop'>
            <img src={Logo} className="logo-large" />
            <img src={LogoSmall} className="logo-small" />
          </div>

          <div className='sideMenuToggle' onClick={SideBarToggler}>
            <i className="mdi mdi-menu"></i>
          </div>

          {process.env.NODE_ENV !== 'production' && <span className='development'>You are in development mode.</span>}
        </div>
        <div className='d-flex'>
          {data.user.role_id?.name !== 'Admin' ?
            <>
              <div className="d-flex">
                <div className='coins'>
                  <div className='coin-list'>
                    <p>
                      <img src={require('../../assets/Coin.png')} />
                      {(data.user.usable_coin_balance)}
                    </p>
                    <p>
                      <img className='award' src={require('../../assets/awards.png')} />
                      {(data.user.withdrawable_coin_balance)}
                    </p>
                  </div>
                  {/* <div>
                    <Link to={'/coins'}>
                      <button>
                        <span className="material-icons">add</span>
                      </button>
                    </Link>
                  </div> */}
                </div>
              </div>
            </> : null}
            <i className='mdi mdi-fullscreen' onClick={FullscreenToggler} />
            <Dropdown className='profile'>
              <Dropdown.Toggle >
                <img src={image} alt="Profile" />
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <Dropdown.Item as={Link} to="/profile"><i className="mdi mdi-account-circle"></i> Profile</Dropdown.Item>
                <Dropdown.Item onClick={() => setmodalLogout(true)} href="#"><i className="mdi mdi-power"></i> Logout</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
          </div>
      </>
      )
}

      export {Topbar}