/* eslint-disable react/prop-types */
import '../../../styles/Profile.scss';
import DataTableBase from '../../common/DataTableBase';
import { Collapse, Form } from 'react-bootstrap';
import React, { useState, useEffect } from 'react';
import { Postdata } from '../../../utils/api.service';
import HttpService from '../../../utils/http.service';
import { useDispatch } from 'react-redux';
import { message } from '../../../redux/actions/toastActions';
import CSV from '../../common/CSV'

const Friends = (props) => {
  const [open, setOpen] = useState(false);
  const [isloading, setIsloading] = useState(true);
  const [data, setData] = useState([]);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setperPage] = useState(10);
  const [currentPage, setcurrentPage] = useState(1);

  const [search, setSearch] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const dispatch = useDispatch();

  const columns = [
    {
      name: '#',
      selector: row => row.user_id
    },
    {
      name: 'Profile',
      cell: (row) =>
        <div className="avatar">
          <img
            alt={row.user_id}
            src={row.profile_pic}
          />
        </div>,
    },
    {
      name: 'Name',
      selector: row => row.username,
    },
    {
      name: 'Email',
      selector: row => row.email,
    },
    {
      name: 'Status',
      selector: row => (row.status) ? 'Active' : 'Deactive',
      width: '100px'
    },
  ];


  const handlePerRowsChange = async (newPerPage, page) => {
    setperPage(newPerPage);
    setcurrentPage(page);
  };

  const handlePageChange = (page) => {
    setcurrentPage(page);
  };

  useEffect(() => {
    if (search !== false) {
      const timeOutId = setTimeout(() => setRefresh(!refresh), 500);
      return () => clearTimeout(timeOutId);
    }
  }, [search]);

  useEffect(() => {
    setIsloading(true);
    Postdata(HttpService.User_friends, {
      page: currentPage,
      perpage: perPage,
      id: props.id,
      search: (search ? search : ''),
    }).then((response) => {
      if (response.status === 200) {
        setData(response.data.users);
        setTotalRows(response.data.count);
        setIsloading(false);
      } else {
        setIsloading(false);
        dispatch(message(response.message));
      }
    }).catch((e) => {
      setIsloading(false);
      dispatch(message(e.message));
    });
  }, [currentPage, perPage, refresh]);

  return (
    <>
      <div className="card-body filters">
        <div className="row searchaction">
          <div className="col-lg-4">
            <h5>Friends</h5>
          </div>
          <div className="col-lg-8 text-end">
            <CSV
              link={HttpService.User_friends}
              extra={{
                page: currentPage,
                perpage: perPage,
                id: props.id,
                search: (search ? search : ''),
              }}
              response="users"
              name="User : Friends"
              headers={[
                { label: "ID", key: "_id" },
                { label: "Name", key: "username" },
                { label: "Email", key: "email" },
                { label: "Status", key: "status" },
              ]} />
            <button className="btn btn-danger" onClick={() => setOpen(!open)}>Search</button>
          </div>
        </div>

        <Collapse in={open}>
          <div>
            <div className="card">
              <p className="card-header">Search</p>
              <div className="card-body">
                <Form.Group className="form-group mb-0">
                  <Form.Label>Search</Form.Label>
                  <Form.Control
                    type="text"
                    onChange={e => setSearch(e.target.value)}
                    required
                  />
                </Form.Group>
              </div>
            </div>
          </div>
        </Collapse>

        <DataTableBase
          columns={columns}
          loader={isloading}
          data={data}
          paginationTotalRows={totalRows}
          onChangeRowsPerPage={handlePerRowsChange}
          onChangePage={handlePageChange}
        />
      </div>
    </>
  )
}

export { Friends }