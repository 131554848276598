/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import '../../../styles/Profile.scss';
import DataTableBase from '../../common/DataTableBase';
import { Collapse, Form } from 'react-bootstrap';
import React, { useState, useEffect } from 'react';
import { Postdata } from '../../../utils/api.service';
import HttpService from '../../../utils/http.service';
import { useDispatch } from 'react-redux';
import { message } from '../../../redux/actions/toastActions';
import { ConvertToDMYHIS } from '../../../utils/Helper';
import CSV from '../../common/CSV';

const ReferHistory = (props) => {
  const [open, setOpen] = useState(false);
  const [isloading, setIsloading] = useState(true);
  const [data, setData] = useState([]);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setperPage] = useState(10);
  const [currentPage, setcurrentPage] = useState(1);

  const [search, setSearch] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const dispatch = useDispatch();

  const columns = [
    {
      name: '#',
      selector: row => row.references[0].user_id
    },
    {
      name: 'Username',
      selector: row => row.references[0].username,
    },
    {
      name: 'Referred At',
      selector: row => ConvertToDMYHIS(row.updatedAt),
    },
  ];


  const handlePerRowsChange = async (newPerPage, page) => {
    setperPage(newPerPage);
    setcurrentPage(page);
  };

  const handlePageChange = (page) => {
    setcurrentPage(page);
  };

  useEffect(() => {
    if (search !== false) {
      const timeOutId = setTimeout(() => setRefresh(!refresh), 500);
      return () => clearTimeout(timeOutId);
    }
  }, [search]);

  useEffect(() => {
    setIsloading(true);
    Postdata(HttpService.User_refer, {
      page: currentPage,
      perpage: perPage,
      id: props.id,
      search: (search ? search : ''),
    }).then((response) => {
      if (response.status === 200) {
        setData(response.data.refers);
        setTotalRows(response.data.count);
        setIsloading(false);
      } else {
        setIsloading(false);
        dispatch(message(response.message));
      }
    }).catch((e) => {
      setIsloading(false);
      dispatch(message(e.message));
    });
  }, [currentPage, perPage, refresh]);

  return (
    <>
      <div className="card-body filters">
        <div className="row searchaction">
          <div className="col-lg-6">
            <h5>Refer History</h5>
          </div>
          <div className="col-lg-6 text-end">
          <CSV
              link={HttpService.User_refer}
              extra={{
                promocode_id: props.id
              }}
              name="User : Refer History"
              response="refers"
              headers={[
                { label: "User ID", key: "user_id" },
                { label: "Username", key: "username" },
                { label: "Referred At", key: "updatedAt" },
              ]}
            />
            {/* <button className="btn btn-primary">Export</button> */}
            {/* <button className="btn btn-danger" onClick={() => setOpen(!open)}>Search</button> */}
          </div>
        </div>

        <Collapse in={open}>
          <div>
            <div className="card">
              <p className="card-header">Search</p>
              <div className="card-body">
                <Form.Group className="form-group mb-0">
                  <Form.Label>Search</Form.Label>
                  <Form.Control
                    type="text"
                    onChange={e => setSearch(e.target.value)}
                    required
                  />
                </Form.Group>
              </div>
            </div>
          </div>
        </Collapse>

        <DataTableBase
          columns={columns}
          loader={isloading}
          data={data}
          paginationTotalRows={totalRows}
          onChangeRowsPerPage={handlePerRowsChange}
          onChangePage={handlePageChange}
        />
      </div>
    </>
  )
}

export { ReferHistory }