/* eslint-disable react/prop-types */
import { useEffect, useState } from 'react';
import '../../../styles/Profile.scss';
import DataTableBase from '../../common/DataTableBase';
import { Postdata } from '../../../utils/api.service';
import HttpService from '../../../utils/http.service';
import ModalPopup from '../../common/Modal';
import { useDispatch } from 'react-redux';
import { message } from '../../../redux/actions/toastActions';
import { Form } from 'react-bootstrap';
import CSV from '../../common/CSV';
import Link from '../../common/Link';

const GameType = () => {
  const [data, setData] = useState([]);
  const [isloading, setIsloading] = useState(true);
  const [refresh, setrefresh] = useState(true);
  const dispatch = useDispatch();

  const [modalswitch, setmodalswitch] = useState(false);
  const [modalswitchcontent, setmodalswitchcontent] = useState([0, 0]);

  const [modalAddUser, setmodalAddUser] = useState(false);
  const [loader, setLoader] = useState(false);
  const [gametype, setgametype] = useState('');

  useEffect(() => {
    setIsloading(true);
    Postdata(HttpService.Gametype_show, {}).then((response) => {
      setData(response.data);
      setIsloading(false);
    }).catch((e) => {
      setIsloading(false);
      dispatch(message(e.message));
    });
  }, [refresh]);

  const handleStatusChange = (id, status) => {
    setmodalswitch(true);
    setmodalswitchcontent([id, status]);
  }

  const handleStatusChangeAction = () => {
    setmodalswitch(false);
    setIsloading(true);
    Postdata(HttpService.Gametype_save, {
      id: modalswitchcontent[0],
      status: Number(modalswitchcontent[1])
    }).then((res) => {
      setrefresh(!refresh);
      dispatch(message(res.message));
    });
  }

  const onAddUserAction = (event) => {
    event.preventDefault();
    setLoader(true);
    Postdata(HttpService.Gametype_save, { name: gametype }).then((res) => {
      dispatch(message(res.message));
      setLoader(false);
      if (res.status === 200) {
        setmodalAddUser(false);
        setrefresh(!refresh);
      }
    });
  };

  const columns = [
    {
      name: 'Sr. No.',
      selector: (row, i) => i + 1,
      width: '70px'
    },
    {
      name: 'Game Type',
      selector: row => row.name,
    },
    {
      name: 'Status',
      cell: (row) =>
        <Link permission={'/gametype/add'} status={row.status}>
          <Form.Check
            type="switch"
            checked={row.status}
            onChange={e => handleStatusChange(row._id, e.target.checked)}
          />
        </Link>,
      button: true,
    },
  ];

  return (
    <>
      <div className="card-body filters">

        <ModalPopup
          className="alert-box-popup"
          show={modalswitch}
          size="sm"
          onHide={() => setmodalswitch(false)}
          heading="Are you sure?"
        >
          <i className="mdi mdi-check"></i>
          <p>You want to change this status!</p>
          <div className='actions'>
            <button className="btn btn-primary" onClick={() => handleStatusChangeAction()}>Yes, Change Status</button>
            <button className="btn btn-danger" onClick={() => setmodalswitch(false)}>Cancel</button>
          </div>
        </ModalPopup>

        <ModalPopup
          show={modalAddUser}
          size="md"
          onHide={() => setmodalAddUser(false)}
          heading="Add New Game Type"
        >
          <form onSubmit={onAddUserAction}>
            <Form.Group className="form-group">
              <Form.Label>Game Type*</Form.Label>
              <Form.Control
                autoFocus
                type="text"
                onChange={(e) => setgametype(e.target.value)}
                value={gametype}
                required
              />
            </Form.Group>

            <button className="btn btn-primary" type="submit" disabled={loader}>
              {loader ? 'Please wait...' : 'Create'}
            </button>
          </form>
        </ModalPopup>

        <div className="row searchaction">
          <div className="col-lg-6">
            <h5>Game Type</h5>
          </div>
          <div className="col-lg-6 text-end">
            <Link permission={'/gametype/add'} className="btn btn-primary" onClick={() => { setmodalAddUser(true); setgametype('') }}>Add New Game Type</Link>
            <CSV
              link={HttpService.Gametype_show}
              name="Game Type"
              headers={[
                { label: "ID", key: "_id" },
                { label: "Name", key: "name" },
              ]}
            />
          </div>
        </div>

        <DataTableBase
          columns={columns}
          pagination={false}
          data={data}
          loader={isloading}
        />
      </div>
    </>
  )
}

export { GameType }