/* eslint-disable no-unused-vars */
import '../../../styles/Profile.scss';
import { ReactFormBuilder } from 'react-form-builder2';
import 'react-form-builder2/dist/app.css';

const AgentRequestForm = () => {
    const items = [{
        key: 'Header',
    }, {
        key: 'Paragraph',
    }, {
        key: 'TextInput',
    }, {
        key: 'TextArea',
    }, {
        key: 'RadioButtons',
    }, {
        key: 'Checkboxes',
    }, {
        key: 'DatePicker',
    }, {
        key: 'Dropdown',
    }, {
        key: 'NumberInput',
    }, {
        key: 'Camera',
    }];

    const data = [{ "id": "D173622F-DA9E-41FE-8838-C66CF3CED8A7", "element": "Header", "text": "Header Text", "static": true, "required": false, "bold": false, "italic": false, "content": "Placeholder text...", "canHavePageBreakBefore": true, "canHaveAlternateForm": true, "canHaveDisplayHorizontal": true, "canHaveOptionCorrect": true, "canHaveOptionValue": true, "canPopulateFromApi": true }, { "id": "791BD827-B36C-448C-B63B-A887A297FA83", "element": "TextInput", "text": "Text Input", "required": false, "canHaveAnswer": true, "canHavePageBreakBefore": true, "canHaveAlternateForm": true, "canHaveDisplayHorizontal": true, "canHaveOptionCorrect": true, "canHaveOptionValue": true, "canPopulateFromApi": true, "field_name": "text_input_217CBFBB-E83D-4BF5-B5F3-14DACAD5D12A", "label": "Placeholder label" }];

    const LoadDataForm = () => {
        return new Promise((resolve) => {
            resolve(data);
        });
    }

    const handleSubmit = (e) => {
        console.log(e);
    }

    return (
        <>
            <div className='card-body'>
                <h5>Agent Request Form</h5>
                <div className="igameEditor">
                    <ReactFormBuilder
                        onLoad={LoadDataForm}
                        onPost={handleSubmit}
                        toolbarItems={items}
                    />
                </div>
            </div>
        </>
    )
}

export { AgentRequestForm }