import { Modal, ModalFooter, Form,Button } from "react-bootstrap";
import { useState } from "react";
import PropTypes from "prop-types";

const DigitainCategoryModal = (props) => {
  const {
    onSaveCategoryClickedCallback,
    onCancelClickedCallback,
    categoryToEdit,
    onDeleteCallback,
    errorMsg,
    ...modalProps
  } = props;
  const isNewCategory = categoryToEdit?false:true;
  const [displayName, setDisplayName] = useState(() =>
    categoryToEdit ? categoryToEdit.displayName : ""
  );

  const onSaveCategoryClicked = () => {
    if(isNewCategory || categoryToEdit.displayName !== displayName){
      let categoryDetails = {
        displayName,
        isNewCategory,
        id: isNewCategory?null:categoryToEdit.id
      };
      onSaveCategoryClickedCallback(categoryDetails);
    }else{
      //Nothing to save
      onCancelClickedCallback();
    }
  };
  const onCancelClicked = () => {
    onCancelClickedCallback();
  };
  return (
    <Modal centered {...modalProps}>
      <Modal.Header closeButton>
        <Modal.Title>
          {isNewCategory ? "Create Category" : "Edit Category"}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form.Group className="form-group mb-0">
          <Form.Label>Category Display Name</Form.Label>
          <Form.Control
            type="text"
            value={displayName}
            onChange={(e) => setDisplayName(e.target.value)}
            required
          />
          <small id="passwordHelp" className="text-danger">
            {errorMsg}
          </small>
        </Form.Group>
      </Modal.Body>
      <ModalFooter style={{justifyContent:"space-between"}} > 
          {isNewCategory ? '' :
            <button className="btn btn-danger" onClick={onDeleteCallback}>
              Delete
            </button>
          }
          <div>
            <Button variant="success"  className="btn btn-primary" onClick={onCancelClicked}>
              Cancel
            </Button>
            <Button variant="success"  onClick={onSaveCategoryClicked}>
              Save
            </Button>
          </div>
      </ModalFooter>
    </Modal>
  );
};

DigitainCategoryModal.propTypes = {
  categoryToEdit: PropTypes.object,
  onSaveCategoryClickedCallback: PropTypes.func,
  onCancelClickedCallback: PropTypes.func,
  errorMsg: PropTypes.string,
  onDeleteCallback:PropTypes.func
};

export { DigitainCategoryModal };
