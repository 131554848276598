import { combineReducers } from "redux";
import auth from "./authReducer";
import forgotpassword from "./forgotpasswordReducer";
import toast from "./toastReducer";
import chat from "./chatReducer";

export default combineReducers({
    auth,
    chat,
    toast,
    forgotpassword,
});
