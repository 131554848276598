/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import '../../../styles/Profile.scss'
import Breadcrumb from '../../common/Breadcrumb'
import Title from '../../common/Title'
import React, { useState, useEffect } from 'react'
import { ConvertToDMY } from '../../../utils/Helper'
import DataTableBase from '../../common/DataTableBase'
import { Postdata } from '../../../utils/api.service'
import { useDispatch } from "react-redux";
import HttpService from '../../../utils/http.service';
import { message } from '../../../redux/actions/toastActions'
import { Form, Collapse } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import Loader from '../../common/Loader'
import CSV from '../../common/CSV'

function Spin_Wheel_User_Records() {
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setperPage] = useState(process.env.REACT_APP_RecordPerPage);
  const [currentPage, setcurrentPage] = useState(1);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true)
  const [refresh, setRefresh] = useState(false)
  const [open, setOpen] = useState(false)
  const [postData, setPostData] = useState({
    sort_by : "createdAt",
    search_by : "username"
  })

  const dispatch = useDispatch()

  const handlePerRowsChange = async (newPerPage, page) => {
    setperPage(newPerPage);
    setcurrentPage(page);
  };

  const handlePageChange = (page) => {
    setcurrentPage(page);
  };


  const columns = [
    {
      name: '#',
      width: "55px",
      selector: (row, i) => (currentPage * perPage + (i + 1) - perPage),
      center : true
    },
    {
      name: 'Profile',
      cell: (row) =>
        <div className="avatar">
          <img
            alt={row.userData[0]._id}
            src={row.userData[0].profile_pic}
          />
        </div>,
      width: "75px",
      center : true
    },
    {
      name: 'Username',
      selector: row => row.userData[0].username,
      center : true
    },
    {
      name: 'Reward Won',
      selector: row => (row.reward_type === "amount" ? "Amount" : (row.reward_type === "coupon" ? "Coupon" : "Prize")),
      center : true
    },
    {
      name: 'Reward',
      cell: (row) =>
        <div>
          {row.reward_type === "amount" ? `${row.rewardData[0].reward_amount} Coins` : null}
          {row.reward_type === "coupon" ? `${row.rewardData[0].coupon} Coupon` : null}
        </div>,
      center : true
    },
    {
      name: 'Spinned Wheel At',
      selector: row => ConvertToDMY(row.createdAt),
      center : true
    },
  ];

  useEffect(() => {
    setLoading(true);
    Postdata(HttpService.Spin_wheel_user_records_list, {
      page: currentPage,
      perpage: perPage,
      ...postData
    }).then((response) => {
      if (response.status === 200) {
        setData(response.data.data);
        setTotalRows(response.data.count);
        setLoading(false);
      } else {
        setLoading(false);
        dispatch(message(response.message));
      }
    }).catch((e) => {
      setLoading(false);
      dispatch(message(e.message));
    });
  }, [currentPage, perPage, postData]);


  return (
    <>
      <Title name="Spin Wheel User Records" />
      <div className='row'>
        <div className='col-lg-6'>
          <h4>Spin Wheel User Records</h4>
          <Breadcrumb
            active="Spin Wheel User Records"
          />
        </div>
        <div className="col-lg-6 text-end">
          <button className="btn btn-danger" onClick={() => setOpen(!open)}>Search</button>
          <CSV
            link={HttpService.Spin_wheel_user_records_list}
            name="Spin Wheel User Records"
            response="data"
            headers={[
              { label: "ID", key: "_id" },
              { label: "Username", key: "username" },
              { label: "Reward Won", key: "reward_type" },
              { label: "Reward (coins)", key: "reward_amount" },
              { label: "Spinned Wheel At", key: "createdAt" }
            ]}
          />
        </div>
      </div>

      <Collapse in={open}>
        <div>
          <div className="card">
            <p className="card-header">Search</p>
            <div className="card-body">
              <div className='row'>

                {/* <div className='col-lg-3'>
                  <Form.Group className="form-group mb-0">
                    <Form.Label>Sort By</Form.Label>
                    <Form.Select onChange={e => setPostData({
                      ...postData,
                      sort_by: e.target.value
                    })}
                    value = {postData.sort_by || ""}
                    >
                      <option value="username">Username</option>
                      <option value="reward_type">Reward Won</option>
                      <option value="reward_amount">Reward Amount</option>
                      <option value="createdAt">Spinned Wheel Date</option>
                    </Form.Select>
                  </Form.Group>
                </div> */}

                <div className='col-lg-3'>
                  <Form.Group className="form-group mb-0">
                    <Form.Label>Search By</Form.Label>
                    <Form.Select onChange={e => setPostData({
                      ...postData,
                      search_by: e.target.value
                    })} 
                    value = {postData.search_by || ""}
                    >
                      <option value="username">Username</option>
                      <option value="reward_amount">Amount</option>
                      <option value="range">Custom Date</option>
                      {/* <option value="reward_amount">Reward Amount</option> */}
                    </Form.Select>
                  </Form.Group>
                </div>
                <div className='col-lg-3'>
                  <Form.Group className="form-group mb-0">
                    <Form.Label>Search</Form.Label>
                    <Form.Control
                      type="text"
                      onChange={e => setPostData({
                        ...postData,
                        search: e.target.value
                      })}
                    />
                  </Form.Group>
                </div>
                {postData.search_by === 'range' ?
                <>
                  <div className="col-lg-3">
                    <Form.Group className="form-group mb-0">
                      <Form.Label>From Date</Form.Label>
                      <Form.Control
                        type="date"
                        onChange={e => setPostData({ ...postData, from_date: e.target.value })}
                      />
                    </Form.Group>
                  </div>
                  <div className="col-lg-3">
                    <Form.Group className="form-group mb-0">
                      <Form.Label>To Date</Form.Label>
                      <Form.Control
                        type="date"
                        onChange={e => setPostData({ ...postData, to_date: e.target.value })}
                      />
                    </Form.Group>
                  </div>
                  </>
                : ''}
              </div>
              
            </div>
          </div>
        </div>
      </Collapse>


      <div className='card'>
        <p className='card-header'>List Of rewards</p>
        <div className='card-body'>
          {loading ? <Loader /> : (
            <DataTableBase
              columns={columns}
              data={data}
              paginationTotalRows={totalRows}
              onChangeRowsPerPage={handlePerRowsChange}
              onChangePage={handlePageChange}
              loader={loading}
            />
          )}
        </div>
      </div>
    </>
  )
}

export { Spin_Wheel_User_Records }