/* eslint-disable no-unused-vars */
import Title from "../../common/Title";
import { Form } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import Link from "../../common/Link";
import { useEffect, useState } from "react";
import HttpService from "../../../utils/http.service";
import { Get, Postdata } from "../../../utils/api.service";
import { useDispatch, useSelector } from "react-redux";
import { message } from "../../../redux/actions/toastActions";
import Loader from "../../common/Loader";
import Breadcrumb from "../../common/Breadcrumb";
import { isData, isRoleID } from "../../../redux/selectors/authSelector";

const AgentManagementAdd = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const navigate = useNavigate();
  const [games, setgames] = useState([]);
  const [roles, setroles] = useState([]);
  const [localdata, setlocaldata] = useState({});
  const [percentage, setpercentage] = useState([]);
  const [loader, setLoader] = useState(false);
  const [apiloader, setapiloader] = useState(false);
  const localData = useSelector(isData);
  const myisRoleID = useSelector(isRoleID);

  const [updateId, setupdateId] = useState([]);
  const [myroles, setmyroles] = useState(100);
  const [refresh, setrefresh] = useState(false);
  const [loaderforagent, setIsloading] = useState(false);
  const [agentData, setAgentData] = useState([]);

  const [roleusercall, setroleusercall] = useState({ role: '', user_id: '' });
  const [roletable, setroletable] = useState([]);

  const [formdata1, setFormdata1] = useState({
    parent_user_id: '',
    role_id: '',
  });
  const [formdata, setFormdata] = useState({
    name: '',
    email: '',
    role_id: '',
    password: '',
    status: 0,
    commissions: '[]',
  });

  useEffect(() => {
    if (roleusercall.role) {
      const index = roletable.map((o) => o.order).indexOf(roleusercall.role);
      const setDatsjsf = roletable;
      if (roleusercall.user_id) {
        setDatsjsf[index - 1].value = roleusercall.user_id;
        // setDatsjsf[index].value = '';
        setDatsjsf[index].hide = true;
        setroletable(setDatsjsf);
        setrefresh(Math.random());
      }
      if (index != -1) {
        Postdata(HttpService.Agent_list, {
          page: 1,
          perpage: 99999,
          role_id: roletable[index].id,
          id: roleusercall.role == 3 ? '' : roleusercall.user_id,
          // role_id: roleusercall.role,
        }).then((response) => {
          setDatsjsf[index].hide = false;
          setDatsjsf[index].users = response.data.users
          setroletable(setDatsjsf);
          setrefresh(Math.random());
        });
      }
    }
  }, [roleusercall]);

  useEffect(() => {
    let myuser_id = '';
    let myrole_id = '';
    roletable.map(e => {
      if (e.value) {
        const counts = e.users?.filter(d => d._id == e.value);
        if (counts && counts.length) {
          myuser_id = e.value;
          myrole_id = e.id;
        }
      }
    });
    setFormdata1({ parent_user_id: myuser_id, role_id: myrole_id })
  }, [roletable, refresh]);

  useEffect(() => {
    if (updateId.length && myroles !== 100) {
      let setDatsjsf = roletable;
      updateId.forEach((e1, vt) => {
        const index = roletable.map((e, ind) => roletable[ind - 1] && e.order < 8 && myroles <= e.order && e.id).indexOf(e1.role_id);
        if (index !== -1) {
          setDatsjsf[index].value = e1.parent_user_id;
          setTimeout(() => {
            setroleusercall({ role: setDatsjsf[index].order + 1, user_id: e1.parent_user_id })
          }, 1 * vt);
        }
      })
      setroletable(setDatsjsf);
      setrefresh(Math.random());
    }
  }, [updateId, myroles]);

  useEffect(() => {
    Get(HttpService.Role_list).then(roleAPI => {
      setroles(roleAPI.data.length ? roleAPI.data.filter(e => e.type !== 'subadmin') : []);
      if (roleAPI.data.length) {
        const pushRoles = [{ id: Math.random(), order: 2, name: 'Subadmin' }];
        let setId = false;
        roleAPI.data.map(e1 => {
          pushRoles.push({ id: e1._id, name: e1.name, order: e1.order, value: '', users: [] })
          if (!setId) setId = e1.order;
        });
        pushRoles.push({ id: '', name: 'Users', order: 7, value: '', users: [] })
        pushRoles.push({ id: Math.random(), name: 'User', order: 8, value: '', users: [] })
        setroletable(pushRoles);
        if (!params.id) setroleusercall({ role: setId, user_id: '' });
      }
    });
  }, []);

  useEffect(() => {
    async function fetchData(id) {
      const response = await Postdata(HttpService.Agent_show, {
        id: id ? id : 'show'
      });
      if (response.status !== 200) {
        navigate("/agent-management")
      }
      setapiloader(false);
      setgames(response.data.gametypes);
      let setformdata = {};

      if (id) {
        setformdata = {
          id: params.id,
          name: response.data.user.name,
          role_id: response.data.user.role_id._id,
          role_type: response.data.user.role_id.name,
          email: response.data.user.email,
          parent_user_id: response.data.user.parent_user_id || '',
          // password: response.data.user.password,
          status: response.data.user.status,
          // commissions: response.data.user.commissions,
        }

        if (response.data.user.commissions.length) {
          const setPercentages = {};
          response.data.user.commissions.map(d => {
            setPercentages[d.gametype_id] = d.commission_percent ? d.commission_percent : 0;
          })
          setTimeout(() => {
            setpercentage(setPercentages);
          }, 100);
        }
      }

      if (response.data.parent_lists) {
        setupdateId(response.data.parent_lists);
      }

      if (response.data.myrole) {
        setmyroles(response.data.myrole.order + 1);
        id && setroleusercall({ role: response.data.myrole.order + 1, user_id: localData?.user?._id });
      }
      if (myisRoleID && !myisRoleID?.type) {
        // setformdata.role_id = response.data.myrole;
      }
      setFormdata({ ...formdata, ...setformdata });
    }
    setapiloader(true);
    fetchData(params.id);
  }, []);

  // useEffect(() => {
  //   // if (localdata?.user?.role_id) {
  //   if (myisRoleID && !myisRoleID?.type) {
  //     setFormdata({ ...formdata, parent_user_id: localData.user._id })
  //   } else if (formdata.role_id) {
  //     setAgentData([]);
  //     setIsloading(true);
  //     Postdata(HttpService.Agent_list, {
  //       page: 1,
  //       perpage: 99999,
  //       role_id: formdata.role_id,
  //     }).then((response) => {
  //       if (response.status === 200) {
  //         setAgentData(response.data.users);
  //         setIsloading(false);
  //       } else {
  //         setIsloading(false);
  //       }
  //     }).catch(() => {
  //       setIsloading(false);
  //     });
  //   }
  // }, [formdata.role_id]);

  useEffect(() => {
    const commissions = [];
    Object.keys(percentage).map((d) => commissions.push({ gametype_id: d, commission_percent: percentage[d] }));
    setFormdata({ ...formdata, commissions: JSON.stringify(commissions) })
  }, [percentage]);

  useEffect(() => {
    setlocaldata(localData);
  }, [localData]);

  const onAddAgentAction = (event) => {
    event.preventDefault();
    setLoader(true);
    Postdata(HttpService.Agent_add, { ...formdata, ...formdata1 }).then((res) => {
      if (res.status === 200) {
        navigate("/agent-management");
      }
      setLoader(false);
      dispatch(message(res.message));
    }).catch(e => {
      dispatch(message(e.message));
      setLoader(false);
      navigate("/agent-management");
    });
  };

  return (
    <>
      <Title name='Member Management - Add' />
      <div className="row">
        <div className="col-lg-6">
          <h4>Member Management</h4>
          <Breadcrumb
            pages={[
              { title: 'Member Management', link: 'agent-management' }
            ]}
            active={`${params.id ? 'Edit' : 'Add'} Member`}
          />
        </div>
      </div>

      <div className="row">
        <div className="col-lg-12">
          <form onSubmit={onAddAgentAction}>
            <div className={`card ${apiloader ? 'd-none' : ''}`}>
              <p className="card-header">Parent User</p>
              {/* <p className="card-header">Parent {roletable.length && roletable.filter(d => d.order === (roleusercall.role - 1))[0]?.name}</p> */}
              <div className="card-body">
                {(roletable)?.map((e, index) =>
                  (roletable[index - 1] && e.order < 8 && myroles <= e.order) ?
                    <div key={e.id} className={e.order <= roleusercall.role ? '' : 'd-none'}>
                      <Form.Group className="form-group">
                        <Form.Label>{roletable[index - 1]?.name}{e.order === 3 ? '*' : ''}</Form.Label>
                        <Form.Select
                          // onChange={e1 => setroleusercall(e.order + 1)}
                          // onChange={e1 => setroleusercall(e.id)}
                          required={e.order === 3}
                          disabled={e.hide}
                          onChange={e1 => setroleusercall({ role: e.order + 1, user_id: e1.target.value })}
                          value={e.value}
                        >
                          <option value="" hidden>{e.hide ? 'Please wait...' : ' '}</option>
                          {(e.users)?.map((es) => (
                            <option value={es._id} key={es._id}>{es.name}</option>
                          ))}
                        </Form.Select>
                      </Form.Group>
                    </div>
                    : ''
                )}
              </div>
            </div>

            <div className="card">
              <p className="card-header">{params.id ? 'Edit : ' : 'Add Member'} {formdata.role_type}</p>
              <div className="card-body">
                {apiloader ?
                  <Loader /> :
                  <>
                    <Form.Group className="form-group">
                      <Form.Label>Name*</Form.Label>
                      <Form.Control
                        type="text"
                        value={formdata.name}
                        onChange={e => setFormdata({ ...formdata, name: e.target.value })}
                        required
                      />
                    </Form.Group>

                    {/* {(!myisRoleID || myisRoleID?.type) ? <> */}
                    {/* <Form.Group className="form-group">
                      <Form.Label>Role*</Form.Label>
                      <Form.Select
                        value={formdata1.role_id}
                        required
                        onChange={e => setFormdata({ ...formdata, role_id: e.target.value })}
                      >
                        <option value="" hidden> </option>
                        {(roles)?.map((e) => (
                          <option value={e._id} key={e._id}>{e.name}</option>
                        ))}
                      </Form.Select>
                    </Form.Group> */}

                    {/* <Form.Group className="form-group">
                      <Form.Label>Parent User / Member*</Form.Label>
                      <Form.Select
                        required
                        value={formdata.parent_user_id}
                        onChange={e => setFormdata({ ...formdata, parent_user_id: e.target.value })}
                        disabled={loaderforagent}
                      >
                        <option value="" hidden>{loaderforagent ? 'Please wait...' : ' '}</option>
                        {(agentData)?.map((e) => (
                          <option value={e._id} key={e._id}>{e.name}</option>
                        ))}
                      </Form.Select>
                    </Form.Group> */}
                    {/* </> : ''} */}

                    <Form.Group className="form-group">
                      <Form.Label>Email*</Form.Label>
                      <Form.Control
                        type="email"
                        value={formdata.email}
                        onChange={e => setFormdata({ ...formdata, email: e.target.value })}
                        required
                      />
                    </Form.Group>

                    <Form.Group className="form-group">
                      <Form.Label>Password{!formdata.id && '*'}</Form.Label>
                      <Form.Control
                        type="password"
                        value={formdata.password}
                        onChange={e => setFormdata({ ...formdata, password: e.target.value })}
                        required={!formdata.id}
                      />
                    </Form.Group>

                    <Form.Group className="form-group">
                      <Form.Label>Status</Form.Label>
                      <Form.Check
                        type="switch"
                        checked={formdata.status}
                        onChange={e => setFormdata({ ...formdata, status: Number(e.target.checked) })}
                      />
                    </Form.Group>

                    {(games.length) ?
                      <Form.Group className="form-group">
                        {/* <Form.Label>Commission</Form.Label> */}
                        <table className="table v-center">
                          <thead>
                            <tr>
                              <th width='200'>Game Name</th>
                              <th>Commission (%)*</th>
                            </tr>
                          </thead>
                          <tbody>

                            {(games)?.map((e) => (
                              <tr key={e._id}>
                                <td>{e.name}</td>
                                <td>
                                  <Form.Control
                                    type="number"
                                    min="0"
                                    max="100"
                                    step="0.01"
                                    value={percentage[e._id] ? percentage[e._id] : 0}
                                    onChange={d => setpercentage({ ...percentage, [e._id]: d.target.value })}
                                    required
                                  />
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </Form.Group>
                      : ''}

                    <button className="btn btn-primary" type="submit" disabled={loader}>
                      {loader ? 'Please wait...' : (formdata.id ? 'Update' : 'Submit')}
                    </button>
                    <Link className="btn btn-danger" to={'/agent-management'}>Cancel</Link>
                  </>
                }
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  )
}

export { AgentManagementAdd }