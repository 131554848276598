/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import '../../../styles/Profile.scss'
import Breadcrumb from '../../common/Breadcrumb'
import Title from '../../common/Title'
import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { Form, Collapse } from "react-bootstrap";
import { ConvertToDMY } from '../../../utils/Helper'
import DataTableBase from '../../common/DataTableBase'
import HttpService from '../../../utils/http.service';
import { Postdata } from '../../../utils/api.service'
import { useDispatch } from "react-redux";
import { message } from '../../../redux/actions/toastActions'

function Notification_List() {
  const [open, setOpen] = useState(false);
  const [postData, setPostData] = useState({
    search_by: "title",
    search: ""
  })
  
  const [data, setData] = useState([]);
  const [isloading, setIsloading] = useState(true);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setperPage] = useState(process.env.REACT_APP_RecordPerPage);
  const [currentPage, setcurrentPage] = useState(1);

  const dispatch = useDispatch()

  const columns = [
    {
      name: '#',
      width: "55px",
      selector: (row, i) => (currentPage * perPage + (i + 1) - perPage),
    },
    {
      name: 'Type',
      cell : (row) => 
      <div>
        {row.type == "inApp" ? "In-App" : "Push"}
      </div>
    },
    {
      name: 'Sended To',
      cell : (row) => 
      <div>
        {row.user_type == "all" ? "All Users" : (row.user_type === "vipUsers" ? "Only VIP Users" : "Selected Users") }
      </div>
    },
    {
      name: 'Title',
      selector: row => row.title,
    },
    {
      name: 'Message',
      selector: row => row.message,
    },
    // {
    //   name: 'Image',
    //   cell: (row) =>
    //     <div className="avatar auto">
    //       <img
    //         alt={row._id}
    //         src={row.image}
    //       />
    //     </div>,
    // },
    {
      name: 'Send Date',
      selector: row => ConvertToDMY(row.createdAt),
    },
    {
      name: 'Action',
      cell: (row) =>
      <span className="actions">
        <Link to={`/notification/view/${row._id}`}>
        <i className="mdi mdi-eye" />
        </Link>
        <Link to={`/notification/edit-and-send/${row._id}`}>
        <i className="mdi mdi-replay" />
        </Link>
      </span>,
    button: true,
    }
  ];

  const handlePerRowsChange = async (newPerPage, page) => {
    setperPage(newPerPage);
    setcurrentPage(page);
  };

  const handlePageChange = (page) => {
    setcurrentPage(page);
  };

  useEffect(() => {
    setIsloading(true);
    Postdata(HttpService.Notification_fetch, {
      page: currentPage,
      perpage: perPage,
      search: postData.search,
      search_by : postData.search_by
    }).then((response) => {
      if (response.status === 200) {
        setData(response.data.notifications);
        setTotalRows(response.data.count);
        setIsloading(false);
      } else {
        setIsloading(false);
        dispatch(message(response.message));
      }
    }).catch((e) => {
      setIsloading(false);
      dispatch(message(e.message));
    });
  }, [currentPage, perPage, postData]);
  

  return (
    <>
      <Title name='Notifications' />
      <div className='row'>
        <div className='col-lg-4'>
          <h4>Notifications</h4>
          <Breadcrumb
            active="Notifications"
          />
        </div>
        <div className="col-lg-8 text-end">
          <Link to={'/notification/add'} className="btn btn-primary">Send New Notification</Link>
          <button className="btn btn-danger" onClick={() => setOpen(!open)}>Search</button>
        </div>
      </div>

      <Collapse in={open}>
        <div>
          <div className="card">
            <p className="card-header">Search</p>
            <div className="card-body">
              <div className='row'>
                <div className='col-lg-3'>
                  <Form.Group className="form-group mb-0">
                    <Form.Label>Search By</Form.Label>
                    <Form.Select onChange={e => setPostData({
                      ...postData,
                      search_by: e.target.value
                    })} >
                      <option value="title">Title</option>
                      <option value="message">Message</option>
                    </Form.Select>
                  </Form.Group>
                </div>
                <div className='col-lg-9'>
                  <Form.Group className="form-group mb-0">
                    <Form.Label>Search</Form.Label>
                    <Form.Control
                      type="text"
                      onChange={e => setPostData({
                        ...postData,
                        search: e.target.value
                      })}
                      required
                    />
                  </Form.Group>
                </div>
              </div>

            </div>
          </div>
        </div>
      </Collapse>

      <div className='card'>
        <p className='card-header'>List of Notifications</p>
        <div className='card-body'>
        <DataTableBase
            columns={columns}
            data={data}
            paginationTotalRows={totalRows}
            onChangeRowsPerPage={handlePerRowsChange}
            onChangePage={handlePageChange}
          />
        </div>

      </div>
    </>
  )
}

export { Notification_List }