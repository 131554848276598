/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import Title from "../../common/Title";
import { Form } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import Link from "../../common/Link";
import { useEffect, useState } from "react";
import HttpService from "../../../utils/http.service";
import { Postdata } from "../../../utils/api.service";
import { ConvertToYMD } from "../../../utils/Helper";
import { useDispatch } from "react-redux";
import { message } from "../../../redux/actions/toastActions";
import Loader from "../../common/Loader";
import Breadcrumb from "../../common/Breadcrumb";


function Vip_Membership_Add() {
    const dispatch = useDispatch();
    const params = useParams();
    const id = params.id
    const navigate = useNavigate();
    const [formData, setFormData] = useState({
        title: '',
        validity_type: 'oneDay',
        validity_description: '',
        description: '',
        amount: ''
    })
    const [apiloader, setApiLoader] = useState(false)

    const onSubmit = (event) => {
        event.preventDefault()
        let validity_description = ""
        if(formData.validity_type === "oneDay"){
            validity_description = "One day Pass"
        } 
        if(formData.validity_type === "monthly"){
            validity_description = "Monthly Subscription"
        }
        if(formData.validity_type === "yearly"){
            validity_description = "Yearly Subscription"
        }
        if(formData.validity_type === "lifetime"){
            validity_description = "Lifetime access"
        }
        setFormData({
            ...formData,
            validity_description : validity_description
        })
        if(params.id) {          
            Postdata(HttpService.Vip_membership_update, {
                _id : params.id,
                ...formData
            }).then((res) => {
                dispatch(message(res.message));
                navigate("/settings");
            }).catch((e) => {
                dispatch(message(e.message));
            });
        } else {
            Postdata(HttpService.Vip_membership_add, formData).then((res) => {
                dispatch(message(res.message));
                navigate("/settings");
            }).catch((e) => {
                dispatch(message(e.message));
            });
        }
    }

    useEffect(() => {
        async function fetchData(id) {
          const res = await Postdata(HttpService.Vip_membership_get_by_id, {
            id: id
          });
          if (res.status !== 200) {
            navigate("/settings")
          }
          const data = res.data
          console.log(data)
          setFormData({
            ...formData,
            title: data.title,
            validity_type: data.validity_type,
            validity_description: data.validity_description,
            description: data.description,
            amount: data.amount,
            status : data.status,
            set_as_most_popular : data.set_as_most_popular
          })
          setApiLoader(false)
        }
        if (params.id) {
          fetchData(params.id);
          setApiLoader(true)
        }
      }, [])
    

    return (
        <>
            <Title name={`Vip Membership - ${params.id ? 'Edit' : 'Add'}`} />
            <div className="row">
                <div className="col-lg-6">
                    <h4>Game Management</h4>
                    <Breadcrumb
                        pages={[
                            { title: 'Settings', link: 'settings' },
                            { title: 'Vip Membership', link: 'settings' }
                        ]}
                        active={`${params.id ? 'Edit' : 'Add'} Vip Membership`}
                    />
                </div>
            </div>

            <div className="row">
                <div className="col-lg-12">
                    <div className="card">
                        <p className="card-header">{params.id ? 'Edit' : 'Add'} Vip Membership</p>
                        <div className="card-body">
                            {apiloader ? null : (
                                <form onSubmit={onSubmit} >
                                <Form.Group className="form-group">
                                    <Form.Label>Title<span style={{ color: "red" }}>*</span></Form.Label>
                                    <Form.Control
                                        type="text"
                                        required
                                        onChange={e => setFormData({
                                            ...formData,
                                            title: e.target.value
                                        })}
                                        value={formData.title || ''}
                                    />
                                </Form.Group>

                                <Form.Group className="form-group">
                                    <Form.Label>Description</Form.Label>
                                    <Form.Control
                                        type="text"
                                        onChange={e => setFormData({
                                            ...formData,
                                            description: e.target.value
                                        })}
                                        value={formData.description || ''}
                                    />
                                </Form.Group>
                                <div className="row">
                                    <div className="col-lg-6">
                                        <Form.Group className="form-group">
                                            <Form.Label>Validity of membership<span style={{ color: "red" }}>*</span></Form.Label>
                                            <Form.Select onChange={e => setFormData({
                                                ...formData,
                                                validity_type: e.target.value
                                            })} value={formData.validity_type || ''}>
                                                <option value="oneDay" >One Day</option>
                                                <option value="monthly" >Monthly</option>
                                                <option value="yearly" >Yearly</option>
                                                <option value="lifetime" >Lifetime</option>
                                            </Form.Select>
                                        </Form.Group>
                                    </div>
                                    <div className="col-lg-6">
                                        <Form.Group className="form-group">
                                            <Form.Label>Amount<span style={{ color: "red" }}>*</span></Form.Label>
                                            <Form.Control
                                                type="text"
                                                required
                                                onChange={e => setFormData({
                                                    ...formData,
                                                    amount: e.target.value
                                                })}
                                                value={formData.amount || ''}
                                            />
                                        </Form.Group>
                                    </div>
                                </div>
                                <button className="btn btn-primary" type="submit">
                                    {params.id ? 'Edit' : 'Add'}
                                </button>
                                <Link className="btn btn-danger" to={'/settings'}>Cancel</Link>
                            </form>
                            )}

                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

export { Vip_Membership_Add }