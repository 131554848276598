/* eslint-disable no-unused-vars */
import { useState } from "react";
import { Tabs, Tab } from "react-bootstrap";
import Title from "../../common/Title";

import { Coins } from "./Coins";
import { GameManagement } from "./Game_Management";
import { GameType } from "./Game_Type";
import { AgentRequest } from "./Agent_Request";
import { Vip_Membership } from "./Vip_Membership";
import { InAppPurchase } from "./In_App_Purchase";
import Breadcrumb from "../../common/Breadcrumb";
import { CheckPermission } from "../../../utils/Helper";
import { isPermission } from "../../../redux/selectors/authSelector";
import { useSelector } from "react-redux";

const Settings = () => {
  const [key, setKey] = useState(window.sessionStorage.getItem('settingTabs') ? window.sessionStorage.getItem('settingTabs') : 'coins');
  const permission = useSelector(isPermission);

  const topnames = {
    coins: '',
    gamemanagement: 'Game Management',
    gametype: 'Game Type',
    agent: 'Agent Request',
    vip_membership : 'Vip Membership'
  };

  return (
    <>
      <Title name='Settings' />
      <h4>Settings</h4>
      <Breadcrumb
        pages={[
          { title: 'Settings', link: '' }
        ]}
        active={topnames[key]}
      />

      <div className="row">
        <div className="col-lg-12">
          <div className="card tabspage">
            <Tabs
              activeKey={key}
              onSelect={(k) => { window.sessionStorage.setItem('settingTabs', k); setKey(k) }}
            >
              <Tab eventKey="coins" title="Settings" disabled={!CheckPermission(permission, '/setting')}>
                {key === 'coins' && CheckPermission(permission, '/setting') && <Coins />}
              </Tab>
              <Tab eventKey="gamemanagement" title="Game Management" disabled={!CheckPermission(permission, '/game')}>
                {key === 'gamemanagement' && CheckPermission(permission, '/game') && <GameManagement />}
              </Tab>
              <Tab eventKey="gametype" title="Game Type" disabled={!CheckPermission(permission, '/gametype')}>
                {key === 'gametype' && CheckPermission(permission, '/gametype') && <GameType />}
              </Tab>
              <Tab eventKey="agent" title="Agent Request" disabled={!CheckPermission(permission, '/agentrequest')}>
                {key === 'agent' && CheckPermission(permission, '/agentrequest') && <AgentRequest />}
              </Tab>
              <Tab eventKey="vip_membership" title="Vip Membership" 
              // disabled={!CheckPermission(permission, '/vip_membership')}
              >
                {/* {key === 'agent' && CheckPermission(permission, '/vip_membership') &&  */}
                <Vip_Membership />
                {/* } */}
              </Tab>
              {/* <Tab eventKey="purchase" title="In-App Purchase">
                {key === 'purchase' && <InAppPurchase />}
              </Tab> */}
            </Tabs>
          </div>
        </div>
      </div>
    </>
  )
}

export { Settings }