/* eslint-disable react/no-unescaped-entities */
import { InputGroup, FormControl, Alert } from "react-bootstrap"
import Logo from '../assets/igame-logo.webp';
import { Link, useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { login, loginClear } from "../redux/actions/authActions";
import { message } from "../redux/actions/toastActions";
import { isLoading, isError, isAuthenticated, isMessage } from "../redux/selectors/authSelector";
import { Footer } from "./include/Footer";
import Title from "./common/Title";

const Login = () => {
  const [email, setemail] = useState((process.env.NODE_ENV !== 'production') ? 'devadmin' : '');
  const [password, setpassword] = useState((process.env.NODE_ENV !== 'production') ? '123456' : '');
  const [remember, setremember] = useState(false);
  const loader = useSelector(isLoading);
  const Authenticated = useSelector(isAuthenticated);
  const loginMessage = useSelector(isMessage);
  const errorMsg = useSelector(isError);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const onLoginAction = (event) => {
    event.preventDefault();
    dispatch(login({ email: email, password: password, remember: remember }));
  };

  useEffect(() => {
    dispatch(loginClear());
  }, []);

  useEffect(() => {
    if (Authenticated) {
      navigate("/dashboard")
      dispatch(message(loginMessage));
    }
  }, [Authenticated]);

  return (
    <>
      <Title name="Login" />
      <div className="account-pages my-5 pt-sm-5">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-8 col-lg-6 col-xl-5">
              {errorMsg &&
                <Alert variant="danger">
                  {errorMsg}
                </Alert>
              }
              <div className="card overflow-hidden">
                <div className="card-body pt-0">
                  <h3 className="text-center mt-4">
                    <a href="/" className="logo logo-admin"><img src={Logo}
                      height="100" alt="logo" /></a>
                  </h3>
                  <div className="p-3">
                    <form className="form-horizontal mt-4" action="/" onSubmit={onLoginAction}>
                      <InputGroup className="mb-3">
                        <InputGroup.Text><i className="mdi mdi-account" /></InputGroup.Text>
                        <FormControl
                          type="text"
                          value={email}
                          autoComplete="current-email"
                          onChange={(e) => setemail(e.target.value)}
                          placeholder="Enter Username"
                          required
                        />
                      </InputGroup>

                      <InputGroup className="mb-3">
                        <InputGroup.Text><i className="mdi mdi-key-outline" /></InputGroup.Text>
                        <FormControl
                          type="password"
                          value={password}
                          autoComplete="current-password"
                          onChange={(e) => setpassword(e.target.value)}
                          placeholder="Enter Password"
                          required
                        />
                      </InputGroup>
                      <div className="form-group row mt-4">
                        <div className="col-12 d-none">
                          <div className="custom-control custom-checkbox">
                            <input type="checkbox" onChange={(e) => setremember(e.target.checked)} className="custom-control-input" id="customControlInline" />
                            <label className="custom-control-label" htmlFor="customControlInline">Remember me
                            </label>
                          </div>
                        </div>
                      </div>
                      <div className="form-group row mt-4">
                        <div className="col-12 text-center">
                          <button
                            className="btn btn-primary w-md waves-effect waves-light"
                            type="submit"
                            disabled={loader}
                          >
                            {loader ? 'Please wait...' : 'Log In'}
                          </button>
                        </div>
                      </div>
                      <div className="form-group mb-0 row">
                        <div className="col-12 mt-12">
                          <Link to="/forgot-password" className="text-muted"><i className="mdi mdi-lock"></i> Forgot your username/password?</Link>
                        </div>
                        {/* <div className="col-6 mt-4">
                          <Link to="/forgot-username" className="text-muted"><i className="mdi mdi-account-alert"></i> Forgot your username?</Link>
                      </div> */}
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div className="mt-5 text-center">
            <Footer />
          </div>
        </div>
      </div>
    </div>
      </div >
    </>
  )
}

export { Login }