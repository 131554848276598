/* eslint-disable react/prop-types */
import * as Redirect from 'react-router-dom'
import { useSelector } from 'react-redux';
import { isPermission } from '../../redux/selectors/authSelector';
import { CheckPermission } from '../../utils/Helper';

function Link(props) {
  const permission = useSelector(isPermission);
  if (props.to) {
    // if (permission.length && CheckPermission(permission, props.to)) {
    if (CheckPermission(permission, props.to)) {
      return <Redirect.Link
        {...props}>
        {props.children}
      </Redirect.Link>
    }
  } else if (props.permission) {
    if (CheckPermission(permission, props.permission)) {
      return <span {...props}>{props.children}</span>;
    } else if (typeof props.status !== 'undefined') {
      return (props.status) ? 'Active' : 'Inactive';
    }
  }
  return '';
}

export default Link;