/* eslint-disable no-unused-vars */
import Title from "../../common/Title";
import { Form, Collapse } from "react-bootstrap";
import { useState, useEffect } from "react";
import { Postdata } from '../../../utils/api.service';
import DataTableBase from "../../common/DataTableBase";
import HttpService from "../../../utils/http.service";
import { useDispatch } from "react-redux";
import { message } from "../../../redux/actions/toastActions";
import Breadcrumb from "../../common/Breadcrumb";
// import CSV from "../../common/CSV";
import { ConvertToDMY } from "../../../utils/Helper";
import ModalPopup from "../../common/Modal";
import { Link } from 'react-router-dom'


const ReportCommission = () => {
  const [data, setData] = useState([]);
  const [isloading, setIsloading] = useState(true);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setperPage] = useState(process.env.REACT_APP_RecordPerPage);
  const [currentPage, setcurrentPage] = useState(1);
  const [refresh, setRefresh] = useState(false);
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [search, setSearch] = useState(false);
  const [sortBy, setSortBy] = useState('username')

  const [modalswitch, setmodalswitch] = useState(false);
  const [modalcontent, setmodalcontent] = useState({ id: '', username: '' });
  const [subdata, setsubData] = useState([]);
  const [subisloading, setsubIsloading] = useState(true);

  const subcolumns = [
    {
      name: '#',
      width: "55px",
      selector: (row, i) => (i + 1)
    },
    {
      name: 'Game Name',
      selector: row => row._id,
    },
    {
      name: 'Bets',
      selector: row => (row.deposit).toFixed(2),
    },
    {
      name: 'Gross Revenue',
      selector: row => (row.deposit > row.withdraw) ? (row.deposit - row.withdraw).toFixed(2) : '0.00',
    },
    {
      name: 'Costs',
      selector: row => (row.deposit < row.withdraw) ? (row.withdraw - row.deposit).toFixed(2) : '0.00',
    },
    {
      name: 'Applied Commission',
      selector: row => (row.total_applied_commission) ? row.total_applied_commission?.toFixed(2) : '0:00',
    },
    {
      name: 'Net Revenue',
      // selector: row => (row.deposit > row.withdraw) ? (row.deposit - row.withdraw - (row.total_applied_commission || 0)).toFixed(2) : (row.withdraw - row.deposit - row.total_applied_commission || 0).toFixed(2),
      selector: row => (row.deposit - row.withdraw - (row.total_applied_commission || 0)).toFixed(2),
    },
  ];

  const columns = [
    {
      name: '#',
      width: "55px",
      selector: (row, i) => (currentPage * perPage + (i + 1) - perPage),
    },
    {
      name: 'Profile',
      cell: (row) =>
        <Link to={`/user-management/view/${row._id}`}>
          <div className="avatar">
            <img
              alt={row.user_id}
              src={row.profile_pic}
            />
          </div>
        </Link>,
      width: "75px",
    },
    {
      name: 'Username',
      cell: (row) =>
        <Link to={`/user-management/view/${row._id}`}>
          {row.username}
        </Link>
    },
    {
      name: 'Registration Date',
      selector: row => ConvertToDMY(row.createdAt),
    },
    {
      name: 'Bets',
      selector: row => (row.deposit).toFixed(2),
    },
    {
      name: 'Gross Revenue',
      selector: row => (row.deposit > row.withdraw) ? (row.deposit - row.withdraw).toFixed(2) : '0.00',
    },
    {
      name: 'Costs',
      selector: row => (row.deposit < row.withdraw) ? (row.withdraw - row.deposit).toFixed(2) : '0.00',
    },
    {
      name: 'Applied Commission',
      selector: row => (row.total_applied_commission) ? row.total_applied_commission?.toFixed(2) : '0:00',
    },
    {
      name: 'Net Revenue',
      // selector: row => (row.deposit > row.withdraw) ? (row.deposit - row.withdraw - row.total_applied_commission || 0).toFixed(2) : (row.withdraw - row.deposit - row.total_applied_commission || 0).toFixed(2),
      selector: row => (row.deposit - row.withdraw - row.total_applied_commission || 0).toFixed(2),
    },
    {
      name: 'Action',
      id: 'actions',
      cell: (row) =>
        <span className="actions">
          <i onClick={() => setView(row._id, row.username)} className="mdi mdi-eye" />
        </span>,
      button: true,
    }
    // {
    //   name: ' ',
    //   selector: row => ' ',
    // },
    // {
    //   name: 'Deposit',
    //   selector: row => row.deposit,
    // },
    // {
    //   name: 'Withdraw',
    //   selector: row => row.withdraw,
    // },
  ];

  const handlePerRowsChange = async (newPerPage, page) => {
    setperPage(newPerPage);
    setcurrentPage(page);
  };

  const handlePageChange = (page) => {
    setcurrentPage(page);
  };

  const setView = (id, username) => {
    setmodalcontent({ id: id, username: username });
    setmodalswitch(true);
    setsubData([]);
    setsubIsloading(true);
    Postdata(HttpService.Reports_commission_user, {
      id: id,
    }).then((response) => {
      if (response.status === 200) {
        setsubData(response.data);
        setsubIsloading(false);
      } else {
        setsubIsloading(false);
        dispatch(message(response.message));
      }
    }).catch((e) => {
      setsubIsloading(false);
      dispatch(message(e.message));
    });
  };

  useEffect(() => {
    if (search !== false) {
      const timeOutId = setTimeout(() => setRefresh(!refresh), 500);
      return () => clearTimeout(timeOutId);
    }
  }, [search]);

  useEffect(() => {
    setIsloading(true);
    Postdata(HttpService.Reports_commission, {
      page: currentPage,
      perpage: perPage,
      search: (search ? search : ''),
      sortBy: (sortBy ? sortBy : '')
    }).then((response) => {
      if (response.status === 200) {
        setData(response.data.data);
        setTotalRows(response.data.count);
        setIsloading(false);
      } else {
        setIsloading(false);
        dispatch(message(response.message));
      }
    }).catch((e) => {
      setIsloading(false);
      dispatch(message(e.message));
    });
  }, [currentPage, perPage, refresh, search, sortBy]);

  return (
    <>
      <Title name='Revenue Report' />

      <ModalPopup
        show={modalswitch}
        size="lg"
        onHide={() => setmodalswitch(false)}
        heading={`Commission Report - ${modalcontent.username}`}
      >
        <DataTableBase
          columns={subcolumns}
          pagination={false}
          data={subdata}
          loader={subisloading}
        />
      </ModalPopup>

      <div className="row">
        <div className="col-lg-4">
          <h4>Revenue Report</h4>
          <Breadcrumb
            pages={[
              { title: 'Reports', link: '' }
            ]}
            active="Revenue Report"
          />
        </div>
        <div className="col-lg-8 text-end">
          <button className="btn btn-danger" onClick={() => setOpen(!open)}>Search</button>
        </div>
      </div>

      <Collapse in={open}>
        <div>
          <div className="card">
            <p className="card-header">Search</p>
            <div className="card-body">
              <Form.Group className="form-group mb-0">
                <div className="row">
                  <div className="col-lg-9">
                    <Form.Label>Search</Form.Label>
                    <Form.Control
                      type="text"
                      onChange={e => setSearch(e.target.value)}
                      required
                    />
                  </div>
                  <div className="col-lg-3">
                    <Form.Label>Sort By</Form.Label>
                    <Form.Select
                      onChange={e => setSortBy(e.target.value)}
                    >
                      <option value="username">Username</option>
                      <option value="createdAt">Registration Date</option>
                      <option value="deposit">Bets</option>
                      <option value="grossRevenue">Gross Revenue</option>
                      <option value="costs">Costs</option>
                      <option value="appliedCommision">Applied Commision</option>
                      <option value="netRevenue">Net Revenue</option>
                    </Form.Select>
                  </div>
                </div>


              </Form.Group>
            </div>
          </div>
        </div>
      </Collapse>

      <div className="card">
        <p className="card-header">Revenue Report</p>
        <div className="card-body">
          <DataTableBase
            columns={columns}
            loader={isloading}
            data={data}
            paginationTotalRows={totalRows}
            onChangeRowsPerPage={handlePerRowsChange}
            onChangePage={handlePageChange}
          />
        </div>
      </div>
    </>
  )
}

export { ReportCommission }