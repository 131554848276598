/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import Breadcrumb from '../../common/Breadcrumb'
import Title from '../../common/Title'
import DataTableBase from '../../common/DataTableBase'
import React, { useState, useEffect } from 'react'
import HttpService from '../../../utils/http.service'
import { Postdata } from '../../../utils/api.service'
import { useDispatch } from 'react-redux'
import { message } from '../../../redux/actions/toastActions'
import { ConvertToDMYHIS } from '../../../utils/Helper';
import { Link } from 'react-router-dom'
import { Form, Collapse } from "react-bootstrap";
import CSV from '../../common/CSV'

function OpenBets() {
  const [roles, setRoles] = useState([]);
  const [open, setOpen] = useState(false);
  const [isloading, setIsloading] = useState(true);
  const [data, setData] = useState([]);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setperPage] = useState(10);
  const [currentPage, setcurrentPage] = useState(1);
  const [postdata, setPostdata] = useState({
    search_by_date: '',
    search_by: 'username'
  });


  const [search, setSearch] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const dispatch = useDispatch();

  const handlePerRowsChange = async (newPerPage, page) => {
    setperPage(newPerPage);
    setcurrentPage(page);
  };

  const handlePageChange = (page) => {
    setcurrentPage(page);
  };

  const columns = [
    {
        name: 'No.',
        selector: (row, i) => i + 1,
        width: '50px'
    },
    {
      name : 'Username',
      selector : row => row.userName || '-',
      width : '150px'
    },
    {
        name: 'Market Name',
        selector: row => row?.marketName || "-",
        width : '150px'
    },
    {
        name: 'Sport',
        selector: row => row?.sport || "-"
    },
    {
        name: 'Type',
        selector: row => row?.bettingType || "-"
    },
    {
        name: 'Competition Name',
        selector: row => row?.competitionName || '-' ,
        width : '150px'
    },
    {
        name: 'Event Name',
        selector: row => row?.eventName || '-',
    },
    {
        name: 'Selection Name',
        selector: row => row?.selectionName || '-',
        width : '150px'
    },
    {
        name: 'Odds',
        selector: row => row?.odds || '-',
    },
    {
        name: 'Stake',
        selector: row => row?.stake || '-',
    },
    {
        name: 'Profit/Loss',
        selector: row => row?.profitLoss || '-',
    },
    {
        name: 'Market Time',
        selector: row => ConvertToDMYHIS(row.marketTime),
    },
    {
        name: 'Bet Placed At',
        selector: row => ConvertToDMYHIS(row.betPlacedDate),
    },
];


  useEffect(() => {
    setIsloading(true);
    const postBody = Object.fromEntries(Object.entries({
      ...postdata,
      page: currentPage,
      perpage: perPage
    }).filter(([, value]) => value !== ''));
    Postdata(HttpService.Reports_arrowwood_open_bets, postBody).then((response) => {
      console.log(response.data)
      if (response.status === 200) {
        setData(response.data);
        // setTotalRows(response.data.total);
        setIsloading(false);
      } else {
        setIsloading(false);
        dispatch(message(response.message));
      }
    }).catch((e) => {
      dispatch(message(e.message));
    });
  }, [currentPage, perPage, refresh, postdata]);

  return (
    <>
      <Title name='Bet History' />
      <div className='row'>
        <div className='col-lg-8'>
          <h4>SportsBook Open Bets</h4>
          <Breadcrumb
            pages={[
              { title: 'Reports', link: '' }
            ]}
            active="SportsBook Open Bets"
          />
        </div>
        {/* <div className="col-lg-8 text-end">
                    <button className="btn btn-danger" onClick={() => setOpen(!open)}>Search</button>
                    <CSV
                        link={HttpService.Reports_bet_history}
                        name="Bet History"
                        response="records"
                        headers={[
                            { label: "ID", key: "_id" },
                            { label: "Username", key: "user_id.username[0]" },
                            { label: "Date", key: "createdAt"},
                            { label: "Product", key: "transfer_type"},
                            { label: "Stake Amount", key: "stake_amount"},
                            { label: "Win Amount", key: "coins_price"},
                            { label: "Opening Balance", key: "prev_balance"},
                            { label: "Closing Balance", key: "new_balance"},
                        ]}
                        extra={{
                            ...postdata,
                            page: currentPage,
                            perpage: perPage
                        }}
                    />
                </div> */}
      </div>

      <Collapse in={open}>
        <div>
          <div className="card">
            <p className="card-header">Filters</p>
            <div className="card-body">
              <div className="row">
                <div className="col-lg-3">
                  <Form.Group className="form-group mb-0">
                    <Form.Label>Search By</Form.Label>
                    <Form.Select onChange={e => setPostdata({ ...postdata, search_by: e.target.value })}>
                      <option value="username">Username</option>
                      <option value="stake_amount">Stake Amount</option>
                      <option value="game_name">Product</option>
                      <option value="win_amount">Win Amount</option>
                    </Form.Select>
                  </Form.Group>
                </div>
                <div className="col-lg-6">
                  <Form.Group className="form-group">
                    <Form.Label>Search</Form.Label>
                    <Form.Control
                      type="text"
                      onChange={e => setPostdata({ ...postdata, search: e.target.value })}
                    />
                  </Form.Group>
                </div>
                <div className="col-lg-3">
                  <Form.Group className="form-group mb-0">
                    <Form.Label>Transaction Date</Form.Label>
                    <Form.Select onChange={e => setPostdata({ ...postdata, search_by_date: e.target.value })}>
                      <option value="">All</option>
                      <option value="today">Today</option>
                      <option value="week">Last Week</option>
                      <option value="month">Last Month</option>
                      <option value="year">Last Year</option>
                      <option value="range">Select Date</option>
                    </Form.Select>
                  </Form.Group>
                </div>
                {postdata.search_by_date === 'range' ?
                  <>
                    <div className="col-lg-3">
                      <Form.Group className="form-group mb-0">
                        <Form.Label>From Date</Form.Label>
                        <Form.Control
                          type="date"
                          onChange={e => setPostdata({ ...postdata, from_date: e.target.value })}
                        />
                      </Form.Group>
                    </div>
                    <div className="col-lg-3">
                      <Form.Group className="form-group mb-0">
                        <Form.Label>To Date</Form.Label>
                        <Form.Control
                          type="date"
                          onChange={e => setPostdata({ ...postdata, to_date: e.target.value })}
                        />
                      </Form.Group>
                    </div>
                  </>
                  : ''}
              </div>
            </div>
          </div>
        </div>
      </Collapse>

      <div className='card'>
        <p className='card-header'>
          List of Bets
        </p>

        <div className='card-body'>
          <DataTableBase
            columns={columns}
            loader={isloading}
            data={data}
            paginationTotalRows={totalRows}
            onChangeRowsPerPage={handlePerRowsChange}
            onChangePage={handlePageChange}
          />
        </div>
      </div>
    </>
  )
}

export { OpenBets }