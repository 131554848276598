/* eslint-disable no-unused-vars */
import { useEffect, useState } from "react";
import Title from "../../common/Title";
import { isData, isPermission } from "../../../redux/selectors/authSelector";
import { useDispatch, useSelector } from "react-redux";
import '../../../styles/Profile.scss';
import { Tabs, Tab, Form } from "react-bootstrap";
import ModalPopup from '../../common/Modal';

import { AsyncTypeahead } from "react-bootstrap-typeahead";

import { PersonalDetails } from "./Personal_Details";
import { CallDetails } from "./Call_Details";
import { BankDetails } from "./Bank_Details";
import { Friends } from "./Friends";
import { BlockFriends } from "./Block_Friends";
import { TransactionHistory } from "./Transaction_History";
import { Commission } from "./Commission";
import { GamePlayed } from "./Game_Played";
import { Fantasy } from "./Fantasy";
import { ReferHistory } from "./Refer_History";
import { Chat } from "./Chat";
import { useNavigate, useParams } from "react-router-dom";
import { Postdata } from "../../../utils/api.service";
import Loader from "../../common/Loader";
import { message } from "../../../redux/actions/toastActions";
import HttpService from "../../../utils/http.service";
import { CheckPermission, ConvertToDMYHIS, numberWithCommas } from "../../../utils/Helper";
import Breadcrumb from "../../common/Breadcrumb";
import Link from "../../common/Link";
import { UserviewAgents } from "./User_view_agent";
import { KYC } from "./KYC";
import { Open_Bets } from "./Open_Bets";

const UserManagementView = () => {
  const [data, setData] = useState([]);
  const [agentID, setagentID] = useState(false);
  const [isloading, setIsloading] = useState(true);
  const permission = useSelector(isPermission);

  const profile = useSelector(isData);
  const [key, setKey] = useState(window.sessionStorage.getItem('userTabs') ? window.sessionStorage.getItem('userTabs') : 'user');
  const [keysub, setKeysub] = useState(window.sessionStorage.getItem('detailsTabs') ? window.sessionStorage.getItem('detailsTabs') : 'details');

  const [modalAddCoin, setmodalAddCoin] = useState(false);
  const [coinloader, setcoinloader] = useState(false);

  const [modalDeductCoin, setmodalDeductCoin] = useState(false);

  const [modalAssignAgent, setmodalAssignAgent] = useState(false);
  const [modalagentisLoading, setmodalagentIsLoading] = useState(false);
  const [modalagentoptions, setmodalagentOptions] = useState([]);
  const [addcoins, setaddcoins] = useState('');
  const [deductcoins, setdeductcoins] = useState('');

  const params = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [arrowData, setArrowData] = useState({})

  const fetchArrowWoodInfo = (userData) => {
    setIsloading(true);
    Postdata(HttpService.User_arrowwood_show, { username: userData.username }).then((response) => {
      if (response.status === 200) {
        setArrowData(response.data);
        setIsloading(false);
      } else {
        setIsloading(false);
      }
    }).catch((e) => {
      console.log(e)
    });
  }

  const fetchUser = () => {
    setIsloading(true);
    Postdata(HttpService.User_show, { id: params.id }).then((response) => {
      if (response.status === 200) {
        setData(response.data);
        setIsloading(false);
        fetchArrowWoodInfo(response.data)
      } else {
        dispatch(message(response.message));
        navigate("/user-management");
      }
    }).catch((e) => {
      dispatch(message(e.message));
      navigate("/user-management");
    });
  }

  useEffect(() => {
    fetchUser();
  }, [params.id]);

  const handleSearch = (query) => {
    setmodalagentIsLoading(true);
    Postdata(HttpService.Agent_list, {
      page: 1,
      perpage: 10,
      type: 'agent',
      search: query
    }).then((response) => {
      if (response.status === 200) {
        setmodalagentOptions(response.data.users);
      }
      setmodalagentIsLoading(false);
    }).catch((e) => {
      setmodalagentIsLoading(false);
      dispatch(message(e.message));
    });
  };

  const onAddCoins = (event) => {
    setcoinloader(true);
    event.preventDefault();
    Postdata(HttpService.User_add_coins, { id: params.id, amount: addcoins }).then((response) => {
      if (response.status === 200) {
        fetchUser();
        setaddcoins('');
        setmodalAddCoin(false);
      }
      dispatch(message(response.message));
      setcoinloader(false);
    }).catch((e) => {
      dispatch(message(e.message));
      setcoinloader(false);
    });
  };

  const onDeductCoins = (event) => {
    setcoinloader(true);
    event.preventDefault();
    Postdata(HttpService.User_deduct_coins, { id: params.id, amount: deductcoins }).then((response) => {
      if (response.status === 200) {
        fetchUser();
        setdeductcoins(0);
        setmodalDeductCoin(false);
      }
      dispatch(message(response.message));
      setcoinloader(false);
    }).catch((e) => {
      dispatch(message(e.message));
      setcoinloader(false);
    });
  };

  const onAssignAgent = (event) => {
    setcoinloader(true);
    event.preventDefault();
    Postdata(HttpService.User_assign_agent, { id: params.id, agent: agentID }).then((response) => {
      if (response.status === 200) {
        fetchUser();
        setagentID(false);
        setmodalAssignAgent(false);
      }
      dispatch(message(response.message));
      setcoinloader(false);
    }).catch((e) => {
      dispatch(message(e.message));
      setcoinloader(false);
    });
  };

  const handleStatusChange = (status) => {
    // setIsloading(true);
    Postdata(HttpService.User_status, {
      id: data._id,
      private_mode: Number(status)
    }).then((res) => {
      // fetchUser();
      dispatch(message(res.message));
    })
  };

  const handleSocialFeaturesStatusChange = (status) => {
    Postdata(HttpService.User_social_media_status, {
      id: data._id,
      allow_social_media: Number(status)
    }).then((res) => {
      dispatch(message(res.message));
    })
  };


  return (
    <>
      <Title name='User Management - View' />
      <div className="row">
        <div className="col-lg-4">
          <h4>User Management</h4>
          <Breadcrumb
            pages={[
              { title: 'User Management', link: 'user-management' }
            ]}
            active="View User"
          />
        </div>
        <div className="col-lg-8 text-end">
          <Link permission="/user-management/add" className='btn btn-primary' onClick={() => setmodalAddCoin(true)}>Add Coins</Link>
          <Link permission="/user-management/add" className='btn btn-primary' onClick={() => setmodalDeductCoin(true)}>Deduct Coins</Link>
          {/* <button className="btn btn-danger">Export</button> */}
          <button className="btn btn-danger" onClick={() => navigate(-1)}>Back</button>

          <ModalPopup
            show={modalAddCoin}
            size="sm"
            onHide={() => setmodalAddCoin(false)}
            heading="Add Coins to User's Wallets"
          >
            <form onSubmit={onAddCoins}>
              <Form.Group className="form-group">
                <Form.Label>Add Coins*</Form.Label>
                <Form.Control
                  type="number"
                  value={addcoins}
                  onChange={e => setaddcoins(e.target.value)}
                  min="1"
                  required
                />
              </Form.Group>

              <button className="btn btn-primary" type="submit" disabled={coinloader}>
                {coinloader ? 'Please wait...' : 'Add'}
              </button>
            </form>
          </ModalPopup>

          <ModalPopup
            show={modalDeductCoin}
            size="sm"
            onHide={() => setmodalDeductCoin(false)}
            heading="Deduct Coins from User's Wallets"
          >
            <form onSubmit={onDeductCoins}>
              <Form.Group className="form-group">
                <Form.Label>Deduct Coins*</Form.Label>
                <Form.Control
                  type="number"
                  value={deductcoins}
                  onChange={e => setdeductcoins(e.target.value)}
                  min="1"
                  required
                />
              </Form.Group>

              <button className="btn btn-primary" type="submit" disabled={coinloader}>
                {coinloader ? 'Please wait...' : 'Deduct'}
              </button>
            </form>
          </ModalPopup>

          <ModalPopup
            show={modalAssignAgent}
            onHide={() => setmodalAssignAgent(false)}
            heading="Assign Agent"
          >
            <form onSubmit={onAssignAgent}>
              <Form.Group className="form-group">
                {/* <Form.Label>Select Agent (Enter first 3 letter to find agent)*</Form.Label> */}
                <Form.Label>Select Agent*</Form.Label>
                <AsyncTypeahead
                  id="my-typeahead-id"
                  className="typeahead"
                  positionFixed
                  isLoading={modalagentisLoading}
                  onChange={(d) => setagentID(d[0] ? d[0]._id : false)}
                  useCache={false}
                  labelKey="username"
                  minLength={0}
                  onSearch={handleSearch}
                  options={modalagentoptions}
                  placeholder=""
                  renderMenuItemChildren={(option) => (
                    <>
                      <span>{option.username}</span>
                    </>
                  )}
                />
              </Form.Group>

              <button className="btn btn-primary" type="submit" disabled={!agentID}>
                {coinloader ? 'Please wait...' : 'Assign'}
              </button>
            </form>
          </ModalPopup>
        </div>
      </div>

      <div className="row profile-page">
        <div className="col-lg-3">
          <div className="card">
            <p className="card-header">User Profile</p>
            <div className="card-body">
              <div className="user">
                {isloading ?
                  <Loader />
                  : <>
                    <img src={data.profile_pic} />
                    <p>{data.username} ({data.role_id ? data?.role_id?.name : 'Player'})</p>
                    <div className="details">
                      <span><i className="mdi mdi-account" />{data.name} </span>
                      <span><i className="mdi mdi-email" />{data.email} </span>
                      <span><i className="mdi mdi-phone" />{data.mobile} </span>

                      <Form.Check
                        type="switch"
                        id="private-switch"
                        defaultChecked={data.private_mode}
                        // checked={data.private_mode}
                        onChange={e => handleStatusChange(e.target.checked)}
                        label="Private Mode"
                      />

                      <hr />
                      <b>Wallet</b>
                      <span><i className="mdi mdi-coin" />{numberWithCommas(data.usable_coin_balance)}</span>
                      <span><i className="mdi mdi-numeric-1-box" />{numberWithCommas(data.withdrawable_coin_balance)}</span>
                      <span><i className="mdi mdi-dribbble" />{numberWithCommas(arrowData?.balance || 0)}</span>

                      <hr />
                      <b>Referral Code</b>
                      <span><i className="mdi mdi-format-list-bulleted-square" />{data.refercode}</span>

                      <hr />
                      <b>Registration Date</b>
                      <span><i className="mdi mdi-clock" />{ConvertToDMYHIS(data.createdAt)}</span>


                      <hr />
                      <b>Restriction</b>
                      <Form.Check
                        type="switch"
                        id="social-switch"
                        label="Social Features"
                        defaultChecked={!(data.allow_social_media)}
                        onChange={e => handleSocialFeaturesStatusChange(e.target.checked)}
                      />

                      {(data.status == '1' && data.role == 'user') ?
                        <div className={profile.user?.role_id?.type === 'admin' ? '' : 'd-none'}>
                          <hr />
                          <b>Agent</b>
                          <span>
                            <i className="mdi mdi-account-supervisor" />
                            {data?.parent_user_id?.username ?
                              <>
                                {data?.parent_user_id?.username}
                              </>
                              : '-'}
                            <br /> &nbsp; &nbsp; &nbsp; &nbsp;
                            <Link permission="/user-management/add" style={{ display: 'inline', paddingLeft: 0, marginLeft: '-26px' }}>
                              <u onClick={() => setmodalAssignAgent(true)} className="c-p">Assign Agent</u>
                            </Link>
                          </span>
                        </div> : ''}
                      {/* <span><i className="mdi mdi-account-supervisor" /><u>Assign Agent</u></span> */}
                    </div>
                  </>}
              </div>
            </div>
          </div>
        </div>

        <div className="col-lg-9">
          <div className="card tabspage">
            {isloading ? '' :
              data?.role_id?.type == 'agent' ?
                <Tabs
                  activeKey={key}
                  onSelect={(k) => { window.sessionStorage.setItem('userTabs', k); setKey(k) }}
                >
                  <Tab eventKey="user" title="List of Users">
                    {key === 'user' && <UserviewAgents id={params.id} />}
                  </Tab>
                  <Tab eventKey="transaction" title="Transaction History">
                    {key === 'transaction' && <TransactionHistory id={params.id} />}
                  </Tab>
                  {profile?.user?.role_id?.type == 'admin' || profile?.user?.role_id?.type == 'subadmin' ?
                    <Tab eventKey="commisions" title="Commission (%)">
                      {key === 'commisions' && <Commission id={params.id} />}
                    </Tab>
                    : ''}
                  {/* <Tab eventKey="transaction" title="Transaction History" disabled={!CheckPermission(permission, '/transaction')}>
                    {key === 'transaction' && CheckPermission(permission, '/transaction') && <TransactionHistory id={params.id} />}
                  </Tab> */}
                </Tabs>
                :
                <Tabs
                  activeKey={key}
                  onSelect={(k) => { window.sessionStorage.setItem('userTabs', k); setKey(k) }}
                >
                  <Tab eventKey="user" title="User Details">
                    {key === 'user' &&
                      <Tabs
                        activeKey={keysub}
                        onSelect={(k) => { window.sessionStorage.setItem('detailsTabs', k); setKeysub(k) }}
                      >
                        <Tab eventKey="details" title="Personal Details" disabled={!CheckPermission(permission, '/personal')}>
                          {keysub === 'details' && CheckPermission(permission, '/personal') && <PersonalDetails id={params.id} />}
                        </Tab>
                        {/* <Tab eventKey="call" title="Call Details">
                      {keysub === 'call' && <CallDetails />}
                    </Tab> */}
                        <Tab eventKey="bank" title="Bank Details" disabled={!CheckPermission(permission, '/bank')}>
                          {keysub === 'bank' && CheckPermission(permission, '/bank') && <BankDetails id={params.id} />}
                        </Tab>
                      </Tabs>
                    }
                  </Tab>
                  <Tab eventKey="friend" title="Friends" disabled={!CheckPermission(permission, '/friends')}>
                    {key === 'friend' && CheckPermission(permission, '/friends') && <Friends id={params.id} />}
                  </Tab>
                  <Tab eventKey="blocked-friends" title="Blocked Users" disabled={!CheckPermission(permission, '/blocked-friends')}>
                    {key === 'blocked-friends' && CheckPermission(permission, '/blocked-friends') && <BlockFriends id={params.id} />}
                  </Tab>
                  {/* <Tab eventKey="game" title="Game Played">
                {key === 'game' && <GamePlayed />}
              </Tab>  */}
                  {/* <Tab eventKey="fantasy" title="Fantasy" disabled={!CheckPermission(permission, '/fantasy')}>
                    {key === 'fantasy' && CheckPermission(permission, '/fantasy') && <Fantasy id={params.id} />}
                  </Tab> */}
                  <Tab eventKey="fantasy" title="Fantasy">
                    {key === 'fantasy' && <Fantasy id={params.id} />}
                  </Tab>
                  <Tab eventKey="open-bets" title="Open Bets">
                    {key === 'open-bets' && <Open_Bets id={params.id} username={data.username} />}
                  </Tab>
                  <Tab eventKey="refer" title="Refer History" disabled={!CheckPermission(permission, '/refer')}>
                    {key === 'refer' && CheckPermission(permission, '/refer') && <ReferHistory id={params.id} />}
                  </Tab>
                  <Tab eventKey="transaction" title="Transaction History" disabled={!CheckPermission(permission, '/transaction')}>
                    {key === 'transaction' && CheckPermission(permission, '/transaction') && <TransactionHistory id={params.id} />}
                  </Tab>
                  <Tab eventKey="game" title="Game Played">
                    {key === 'game' && <GamePlayed id={params.id} />}
                  </Tab>
                  <Tab eventKey="kyc" title="KYC Details">
                    {key === 'kyc' && <KYC id={params.id} />}
                  </Tab>
                  <Tab eventKey="chat" title="Chat">
                {key === 'chat' && <Chat id={params.id}/>}
              </Tab>
                </Tabs>
            }
          </div>
        </div>
      </div>
    </>
  )
}

export { UserManagementView }