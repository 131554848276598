import Title from "../../common/Title";
import { useNavigate, useParams } from "react-router-dom";
import Link from "../../common/Link";
import { useEffect, useState } from "react";
import HttpService from "../../../utils/http.service";
import { Postdata } from "../../../utils/api.service";
import { ConvertToDMYHIS } from "../../../utils/Helper";
import { useDispatch } from "react-redux";
import Loader from "../../common/Loader";
import Breadcrumb from "../../common/Breadcrumb";
import { Tab, Tabs } from "react-bootstrap";
import DataTableBase from "../../common/DataTableBase";
import { message } from "../../../redux/actions/toastActions";
import CSV from "../../common/CSV";


const PromocodeView = () => {
  const [key, setKey] = useState('view');
  return (
    <>
      <Title name='Promocode Management' />
      <h4>Promocode Management</h4>
      <Breadcrumb
        pages={[
          { title: 'Promocode Management', link: 'promo-code' }
        ]}
        active="View"
      />

      <div className="row">
        <div className="col-lg-3">
          <div className="card">
            <p className="card-header">Promocode View</p>
            <div className="card-body">
              <GameView />
            </div>
          </div>
        </div>
        <div className="col-lg-9">
          <div className="card tabspage">
            <Tabs
              activeKey={key}
              onSelect={(k) => setKey(k)}
            >
              <Tab eventKey="view" title="Applied Users">
                {key === 'view' && <PromocodeApplied />}
              </Tab>
            </Tabs>
          </div>
        </div>
      </div>
    </>
  );

}

const GameView = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const navigate = useNavigate();

  const [apiloader, setapiloader] = useState(false);
  const [readmore, setreadmore] = useState(true);
  const [data, setdata] = useState({});

  useEffect(() => {
    async function fetchData(id) {
      const response = await Postdata(HttpService.Promocode_show, {
        id: id
      }, dispatch);
      if (response.status !== 200) {
        navigate("/settings")
      }
      setdata(response.data);
      setapiloader(false);
    }
    if (params.id) {
      setapiloader(true);
      fetchData(params.id);
    }
  }, [])

  return (
    <>
      {apiloader ?
        <Loader />
        :
        <div className="row profile-page">
          <div className="col-md-12">
            <div className="user">
              <p>Promocode: {data.code}</p>
              <div className="details removeStyle">
                <span><b>Title:</b>{data.title}&nbsp;</span>
                <span><b>Code:</b>{data.code}&nbsp;</span>
                <span><b>Description:</b>
                  {data.description ?
                    readmore
                      ?
                      <>
                        {data.description.toString().substring(0, 50)}...
                        <i className="readmore" onClick={() => setreadmore(!readmore)}>Read more</i>
                      </>
                      :
                      <>
                        {data.description}
                        <i className="readmore" onClick={() => setreadmore(!readmore)}>View less</i>
                      </>
                    : null}
                  &nbsp;</span>
                <span><b>Type:</b>{(data.type === 'percentage') ? 'Percentage (%)' : 'Amount'}&nbsp;</span>
                <span><b>Discount:</b>{data.discount}&nbsp;</span>
                <span><b>Maximum Discount:</b>{data.max_discount}&nbsp;</span>
                <span><b>Maximum user allowed to use:</b>{data.max_allowed_to_user}&nbsp;</span>
                <span><b>Minimum Amount for Promo Code:</b>{data.min_amount}&nbsp;</span>
                <Link to={'/promo-code'} className='btn btn-danger'>Go back</Link>
              </div>
            </div>
          </div>
        </div>
      }
    </>
  )
}


const PromocodeApplied = () => {
  const [promodata, setpromodata] = useState([]);
  const [isloading, setIsloading] = useState(true);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setperPage] = useState(process.env.REACT_APP_RecordPerPage);
  const [currentPage, setcurrentPage] = useState(1);
  const dispatch = useDispatch();
  const params = useParams();

  useEffect(() => {
    setIsloading(true);
    Postdata(HttpService.Promocode_applied_user, {
      promocode_id: params.id,
      page: currentPage,
      perpage: perPage,
    }).then((response) => {
      if (response.status === 200) {
        setpromodata(response.data.users);
        setTotalRows(response.data.count);
        setIsloading(false);
      } else {
        setIsloading(false);
        dispatch(message(response.message));
      }
    }).catch((e) => {
      setIsloading(false);
      dispatch(message(e.message));
    });
  }, [currentPage, perPage]);

  const handlePerRowsChange = async (newPerPage, page) => {
    setperPage(newPerPage);
    setcurrentPage(page);
  };

  const handlePageChange = (page) => {
    setcurrentPage(page);
  };

  const columns = [
    {
      name: '#',
      width: "55px",
      selector: (row, i) => (currentPage * perPage + (i + 1) - perPage),
    },
    {
      name: 'User ID',
      selector: row => row.user_id.user_id,
    },
    {
      name: 'Username',
      selector: row => row.user_id.username,
    },
    {
      name: 'Applied At',
      selector: row => ConvertToDMYHIS(row.updatedAt),
    },
  ];

  return (
    <>
      <div className="card-body filters">
        <div className="row searchaction">
          <div className="col-lg-6">
            <h5>Applied Users</h5>
          </div>
          <div className="col-lg-6 text-end">
            <CSV
              link={HttpService.Promocode_applied_user}
              extra={{
                promocode_id: params.id
              }}
              name="Promocode Management - Applied Users"
              response="users"
              headers={[
                { label: "User ID", key: "user_id.user_id" },
                { label: "Username", key: "user_id.username" },
                { label: "Applied At", key: "updatedAt" },
              ]}
            />
          </div>
        </div>
        <DataTableBase
          columns={columns}
          loader={isloading}
          data={promodata}
          paginationTotalRows={totalRows}
          onChangeRowsPerPage={handlePerRowsChange}
          onChangePage={handlePageChange}
        />
        {/* <Link to={'/settings'} className='btn btn-danger'>Go back</Link> */}
      </div>
    </>
  );
}

export { PromocodeView }