import { useEffect, useState } from "react"
import { Form } from "react-bootstrap"
import { useDispatch } from "react-redux"
import { useNavigate, useParams } from "react-router-dom"
import { message } from "../../../redux/actions/toastActions"
import { Postdata } from "../../../utils/api.service"
import { ConvertToDMY } from "../../../utils/Helper"
import HttpService from "../../../utils/http.service"
import Breadcrumb from "../../common/Breadcrumb"
import DataTableBase from "../../common/DataTableBase"
import Link from "../../common/Link"
import Loader from "../../common/Loader"
import ModalPopup from "../../common/Modal"
import Title from "../../common/Title"

const AgentManagementView = () => {
  const [modalAddCoin, setmodalAddCoin] = useState(false);
  const [coinloader, setcoinloader] = useState(false);
  const [modalDeductCoin, setmodalDeductCoin] = useState(false);
  const [addcoins, setaddcoins] = useState('');
  const [deductcoins, setdeductcoins] = useState(0);
  const params = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [data, setData] = useState([]);
  const [isloading, setIsloading] = useState(true);

  const fetchUser = () => {
    setIsloading(true);
    settableIsloading(true);
    Postdata(HttpService.Agent_show, { id: params.id }).then((response) => {
      if (response.status === 200) {
        setData(response.data.user);
        setIsloading(false);
      } else {
        dispatch(message(response.message));
        navigate("/agent-management");
      }
    }).catch((e) => {
      dispatch(message(e.message));
      navigate("/agent-management");
    });
  }

  useEffect(() => {
    fetchUser();
  }, []);

  const onAddCoins = (event) => {
    setcoinloader(true);
    event.preventDefault();
    Postdata(HttpService.User_add_coins, { id: params.id, amount: addcoins }).then((response) => {
      if (response.status === 200) {
        fetchUser();
        setaddcoins('');
        setmodalAddCoin(false);
      }
      dispatch(message(response.message));
      setcoinloader(false);
    }).catch((e) => {
      dispatch(message(e.message));
      setcoinloader(false);
    });
  };

  const onDeductCoins = (event) => {
    setcoinloader(true);
    event.preventDefault();
    Postdata(HttpService.User_deduct_coins, { id: params.id, amount: deductcoins }).then((response) => {
      if (response.status === 200) {
        fetchUser();
        setdeductcoins(0);
        setmodalDeductCoin(false);
      }
      dispatch(message(response.message));
      setcoinloader(false);
    }).catch((e) => {
      dispatch(message(e.message));
      setcoinloader(false);
    });
  };


  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setperPage] = useState(process.env.REACT_APP_RecordPerPage);
  const [currentPage, setcurrentPage] = useState(1);
  const columns = [
    {
      name: '#',
      width: "55px",
      selector: (row, i) => (currentPage * perPage + (i + 1) - perPage),
    },
    {
      name: 'Name',
      selector: row => row.name,
    },
    {
      name: 'Role Type',
      selector: row => row?.role_id?.name,
    },
    {
      name: 'Email',
      selector: row => row.email,
    },
    {
      name: 'Created Date',
      selector: row => ConvertToDMY(row.createdAt),
    },
    {
      name: 'Action',
      id: 'actions',
      cell: (row) =>
        <span className="actions">
          <Link to={`/agent-management/view/${row._id}`}><i className="mdi mdi-eye" /></Link>
        </span>,
      button: true,
    }
  ];

  const usercolumns = [
    {
      name: '#',
      width: "55px",
      selector: (row, i) => (currentPage * perPage + (i + 1) - perPage),
    },
    {
      name: 'Profile',
      cell: (row) =>
        <div className="avatar">
          <img
            alt={row.user_id}
            src={row.profile_pic}
          />
        </div>,
      width: "75px",
    },
    {
      name: 'Username',
      selector: row => row.username,
    },
    {
      name: 'Mobile Number',
      selector: row => row.mobile,
    },
    {
      name: 'Email',
      selector: row => row.email,
    },
    {
      name: 'Registration Date',
      selector: row => ConvertToDMY(row.createdAt),
    },
  ];

  const handlePerRowsChange = async (newPerPage, page) => {
    setperPage(newPerPage);
    setcurrentPage(page);
  };

  const handlePageChange = (page) => {
    setcurrentPage(page);
  };

  const [tabledata, settableData] = useState([]);
  const [istableloading, settableIsloading] = useState(true);

  useEffect(() => {
    settableIsloading(true);
    if (data._id) {
      const postBody = {
        page: currentPage,
        perpage: perPage,
        id: data._id,
      };
      if (data?.role_id?.name === 'Agent') {
        postBody.search_by_agent = postBody.id;
        delete postBody.id;
      }
      Postdata(data?.role_id?.name === 'Agent' ? HttpService.User_list : HttpService.Agent_list, postBody).then((response) => {
        if (response.status === 200) {
          settableData(response.data.users);
          setTotalRows(response.data.count);
          settableIsloading(false);
        } else {
          settableIsloading(false);
          dispatch(message(response.message));
        }
      }).catch((e) => {
        settableIsloading(false);
        dispatch(message(e.message));
      });
    }
  }, [currentPage, perPage, data]);


  return (
    <>
      <Title name='Member Management - View' />
      <div className="row">
        <div className="col-lg-6">
          <h4>Member Management</h4>
          <Breadcrumb
            pages={[
              { title: 'Member Management', link: 'agent-management' }
            ]}
            active="View Member"
          />
        </div>
        <div className="col-lg-6 text-end">
          <Link permission="/agent-management/add" className="btn btn-primary" onClick={() => setmodalAddCoin(true)}>Add Coins</Link>
          <Link permission="/agent-management/add" className="btn btn-primary" onClick={() => setmodalDeductCoin(true)}>Deduct Coins</Link>
          <button className="btn btn-danger" onClick={() => navigate(-1)}>Back</button>

          <ModalPopup
            show={modalAddCoin}
            size="sm"
            onHide={() => setmodalAddCoin(false)}
            heading="Add Coins to Member's Wallets"
          >
            <form onSubmit={onAddCoins}>
              <Form.Group className="form-group">
                <Form.Label>Add Coins*</Form.Label>
                <Form.Control
                  type="number"
                  value={addcoins}
                  onChange={e => setaddcoins(e.target.value)}
                  min="1"
                  required
                />
              </Form.Group>

              <button className="btn btn-primary" type="submit" disabled={coinloader}>
                {coinloader ? 'Please wait...' : 'Add'}
              </button>
            </form>
          </ModalPopup>

          <ModalPopup
            show={modalDeductCoin}
            size="sm"
            onHide={() => setmodalDeductCoin(false)}
            heading="Deduct Coins from Member's Wallets"
          >
            <form onSubmit={onDeductCoins}>
              <Form.Group className="form-group">
                <Form.Label>Deduct Coins*</Form.Label>
                <Form.Control
                  type="number"
                  value={deductcoins}
                  onChange={e => setdeductcoins(e.target.value)}
                  min="1"
                  required
                />
              </Form.Group>

              <button className="btn btn-primary" type="submit" disabled={coinloader}>
                {coinloader ? 'Please wait...' : 'Deduct'}
              </button>
            </form>
          </ModalPopup>
        </div>
      </div>

      <div className="row profile-page">
        <div className="col-lg-3">
          <div className="card">
            <p className="card-header">Member Profile</p>
            <div className="card-body">
              <div className="user">
                {isloading ?
                  <Loader />
                  : <>
                    <p>{data?.role_id?.name}</p>
                    <div className="details">
                      <span><i className="mdi mdi-account" />{data.name} </span>
                      <span><i className="mdi mdi-email" />{data.email} </span>
                      <span><i className="fas fa-hands-helping" />{data?.role_id?.name} </span>

                      <hr />
                      <b>Wallet</b>
                      <span><i className="mdi mdi-coin" />{data.usable_coin_balance}</span>
                      <span><i className="mdi mdi-numeric-1-box" />{data.withdrawable_coin_balance}</span>

                      <hr />
                      <b>Created Date</b>
                      <span><i className="mdi mdi-clock" />{ConvertToDMY(data.createdAt)}</span>
                    </div>
                  </>}
              </div>
            </div>
          </div>
        </div>

        <div className="col-lg-9">
          <div className="card">
            <p className="card-header">List of {data?.role_id?.name === 'Agent' ? 'Users' : 'Members'}</p>
            <div className="card-body">
              <DataTableBase
                columns={data?.role_id?.name === 'Agent' ? usercolumns : columns}
                loader={istableloading}
                data={tabledata}
                paginationTotalRows={totalRows}
                onChangeRowsPerPage={handlePerRowsChange}
                onChangePage={handlePageChange}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export { AgentManagementView }