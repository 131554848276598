/* eslint-disable no-unused-vars */
import Title from "../../common/Title";
import { Form } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import Link from "../../common/Link";
import { useEffect, useState } from "react";
import HttpService from "../../../utils/http.service";
import { Postdata } from "../../../utils/api.service";
import { useDispatch } from "react-redux";
import { message } from "../../../redux/actions/toastActions";
import Loader from "../../common/Loader";
import Breadcrumb from "../../common/Breadcrumb";

const RoleManagementAdd = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const navigate = useNavigate();
  const [loader, setLoader] = useState(false);
  const [apiloader, setapiloader] = useState(false);
  const [formdata, setFormdata] = useState({
    name: '',
    type: '',
    admin_modules: [],
    current_permissions: [],
  });
  const [permissions, setPermissions] = useState([]);
  const [checktick, setchecktick] = useState([]);

  useEffect(() => {
    async function fetchData(id) {
      const permission = await Postdata(HttpService.Subadmin_show, { id: 'show' });
      const checkTick = [];
      permission?.data?.admin_modules?.map(e => e.permissions.map(d => checkTick.push({ key: d._id, id: [d.admin_module_id, d.name] })))
      setchecktick(checkTick)
      const response = await Postdata(HttpService.Role_show, {
        id: id
      });
      if (response.status !== 200) {
        navigate("/role-management")
      }
      setapiloader(false);
      const current_permissions = [];
      response.data.permissions.map(e => {
        current_permissions.push(e.permission_id?.id);
      });
      setPermissions(current_permissions);
      setFormdata({
        ...formdata,
        id: params.id,
        current_permissions,
        admin_modules: permission.data.admin_modules,
        name: response.data.name,
        type: response.data.type,
      })
    }
    if (params.id) {
      setapiloader(true);
      fetchData(params.id);
    }
  }, [])


  const onAddRoleAction = (event) => {
    event.preventDefault();

    formdata.permissions = []
    for (let i = 0; i < permissions.length; i++) {
      formdata.permissions[i] = {
        permission_id: permissions[i],
        type: formdata.type === 'agent' ? 'own' : 'all'
      };
    }

    setLoader(true);
    Postdata(HttpService.Role_add, {
      id: formdata.id,
      permissions: formdata.permissions
    }).then((res) => {
      if (res.status === 200) {
        navigate("/role-management");
      }
      setLoader(false);
      dispatch(message(res.message));
    }).catch(e => {
      dispatch(message(e.message));
      setLoader(false);
    });
  };


  const handleCheck = (event) => {
    const mykey = checktick.filter(k => k.key === event.target.value)[0].id;

    var permissions_array = [...permissions];
    if (event.target.checked) {
      permissions_array = [...permissions_array, event.target.value];
      if (['store', 'delete'].includes(mykey[1])) permissions_array = [...permissions_array, formdata.admin_modules.filter(e => e._id === mykey[0])[0].permissions.filter(v => v.name === 'view')[0]._id];
    } else {
      // permissions_array.splice(permissions.indexOf(event.target.value), 1);
      permissions_array = permissions_array.filter(v => v !== event.target.value);
      if (mykey[1] === 'view') formdata.admin_modules.filter(e => e._id === mykey[0])[0].permissions.map(v => permissions_array = permissions_array.filter(d => d !== v._id))
    }
    permissions_array = [...new Set(permissions_array)]
    setPermissions(permissions_array);
  };

  return (
    <>
      <Title name='Role Management - Add' />
      <div className="row">
        <div className="col-lg-6">
          <h4>Role Management</h4>
          <Breadcrumb
            pages={[
              { title: 'Role Management', link: 'role-management' }
            ]}
            active={`${params.id ? 'Edit' : 'Add'} Role`}
          />
        </div>
      </div>

      <div className="row">
        <div className="col-lg-12">
          <div className="card">
            <p className="card-header">{params.id ? 'Edit' : 'Add'} Role - {formdata.name}</p>
            <div className="card-body">
              {apiloader ?
                <Loader /> :
                <form onSubmit={onAddRoleAction}>
                  <h5>{formdata.name}</h5>
                  {/* <Form.Group className="form-group">
                    <Form.Label>Name*</Form.Label>
                    <Form.Control
                      type="text"
                      value={formdata.name}
                      onChange={e => setFormdata({ ...formdata, name: e.target.value })}
                      required
                    />
                  </Form.Group> */}

                  {/* <Form.Group className="form-group">
                    <Form.Label>Type*</Form.Label>
                    <Form.Select
                      value={formdata.type}
                      onChange={e => setFormdata({ ...formdata, type: e.target.value })}
                    >
                      <option value="subadmin">Subadmin</option>
                      <option value="agent">Agent</option>
                    </Form.Select>
                  </Form.Group> */}

                  <Form.Group className="form-group">
                    {/* <Form.Label>Permissions</Form.Label> */}
                    <table className="table v-center">
                      <thead>
                        <tr>
                          <th>Modules</th>
                          <th className="text-center">Save/Update</th>
                          <th className="text-center">View</th>
                          {/* <th>Delete</th> */}
                        </tr>
                      </thead>
                      <tbody>

                        {(formdata.admin_modules)?.map((admin_module, index) => (
                          <tr data-index={index} key={admin_module._id}>
                            <td>{admin_module.name}</td>
                            {(admin_module.permissions)?.map((permission, p_index) => (
                              <td data-index={p_index} key={permission._id}>

                                <Form.Check
                                  type="checkbox"
                                  value={permission._id}
                                  checked={permissions.includes(permission._id)}
                                  className="text-center"
                                  // defaultChecked={formdata.current_permissions.includes(permission._id) ? true : false}
                                  onChange={handleCheck}
                                />
                              </td>
                            ))}
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </Form.Group>

                  <button className="btn btn-primary" type="submit" disabled={loader}>
                    {loader ? 'Please wait...' : (formdata.id ? 'Update' : 'Submit')}
                  </button>
                  <Link className="btn btn-danger" to={'/role-management'}>Cancel</Link>
                </form>
              }
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export { RoleManagementAdd }