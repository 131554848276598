/* eslint-disable react/prop-types */
import { Spinner } from 'react-bootstrap';

function Loader(props) {
  return (
    <>
      <div className={`Custom_loader ${props.isClass ? props.isClass : ''}`}>
        <Spinner
          animation="border"
        />
        <p>Please wait...</p>
      </div>
    </>
  );
}


export default Loader;
