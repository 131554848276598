/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import Title from "../../common/Title";
import { Form, Collapse } from "react-bootstrap";
import { useState, useEffect } from "react";
import { Postdata } from '../../../utils/api.service';
import DataTableBase from "../../common/DataTableBase";
import HttpService from "../../../utils/http.service";
import { useDispatch } from "react-redux";
import { message } from "../../../redux/actions/toastActions";
import Breadcrumb from "../../common/Breadcrumb";
// import CSV from "../../common/CSV";
import { ConvertToDMY } from "../../../utils/Helper";
import { Link, useParams } from 'react-router-dom'

function User_Bet_Report() {
    const params = useParams()
    const [data, setData] = useState([]);
    const [isloading, setIsloading] = useState(true);
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setperPage] = useState(process.env.REACT_APP_RecordPerPage);
    const [currentPage, setcurrentPage] = useState(1);
    const [refresh, setRefresh] = useState(false);
    const dispatch = useDispatch();
    const [open, setOpen] = useState(false);
    const [search, setSearch] = useState(false);
    const [sortBy, setSortBy] = useState('username')

    const handlePerRowsChange = async (newPerPage, page) => {
        setperPage(newPerPage);
        setcurrentPage(page);
    };

    const handlePageChange = (page) => {
        setcurrentPage(page);
    };

    const columns = [
        {
            name: '#',
            width: "55px",
            selector: (row, i) => (currentPage * perPage + (i + 1) - perPage),
        },
        {
            name: 'Transaction ID',
            selector: row => row._id.toString(),
            width: "125px"
        },
        {
            name: 'Game Name',
            selector: row => row.transfer_type
        },
        {
            name: 'Opening Balance',
            selector: row => row.prev_balance,
            width: "140px",
            centre: true
        },
        {
            name: 'Closing Balance',
            selector: row => row.new_balance,
            width: "125px"
        },
        {
            name : 'Profit/Loss',
            selector : row => row.profitLoss,
            center : true
        },
        {
            name : 'Bet Date',
            selector : row => ConvertToDMY(row.createdAt)
        }

    ];

    useEffect(() => {
        setIsloading(true);
        Postdata(HttpService.Reports_user_bet_report, {
            page: currentPage,
            perpage: perPage,
            id : params.id
        }).then((response) => {
            if (response.status === 200) {
                console.log(response)
                setData(response.data.data);
                setTotalRows(response.data.count);
                setIsloading(false);
            } else {
                setIsloading(false);
                dispatch(message(response.message));
            }
        }).catch((e) => {
            setIsloading(false);
            dispatch(message(e.message));
        });
    }, [currentPage, perPage, refresh, search, sortBy]);


    return (
        <>
            <Title name="User's Bet Report" />

            <div className="row">
                <div className="col-lg-8">
                    <h4>User&apos;s Bet Report</h4>
                    <Breadcrumb
                        pages={[
                            { title: 'Reports', link: 'report/bet_report' },
                            { title: 'Bet Report', link: '' }
                        ]}
                        active="User's Bet Report"
                    />
                </div>
            </div>

            <div className="card">
                <p className="card-header">User&apos;s Bets</p>
                <div className="card-body">
                    <DataTableBase
                        columns={columns}
                        loader={isloading}
                        data={data}
                        paginationTotalRows={totalRows}
                        onChangeRowsPerPage={handlePerRowsChange}
                        onChangePage={handlePageChange}
                    />
                </div>
            </div>

        </>
    )
}

export { User_Bet_Report }