import Title from "../../common/Title";
import { Form } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import Link from "../../common/Link";
import { useEffect, useState } from "react";
import HttpService from "../../../utils/http.service";
import { Postdata } from "../../../utils/api.service";
import { ConvertToYMD } from "../../../utils/Helper";
import { useDispatch } from "react-redux";
import { message } from "../../../redux/actions/toastActions";
import Loader from "../../common/Loader";
import Breadcrumb from "../../common/Breadcrumb";

const PromocodeManagementAdd = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const navigate = useNavigate();
  const [loader, setLoader] = useState(false);
  const [apiloader, setapiloader] = useState(false);
  const [formdata, setFormdata] = useState({
    code: '',
    title: '',
    description: '',
    type: '',
    discount: '',
    max_discount: '',
    max_allowed_to_user: '',
    min_amount: '',
    start_at: '',
    end_at: '',
    status: 0,
  });

  useEffect(() => {
    async function fetchData(id) {
      const response = await Postdata(HttpService.Promocode_show, {
        id: id
      });
      if (response.status !== 200) {
        navigate("/promo-code")
      }
      setapiloader(false);
      setFormdata({
        id: params.id,
        code: response.data.code,
        title: response.data.title,
        description: response.data.description,
        type: response.data.type,
        discount: response.data.discount,
        max_discount: response.data.max_discount,
        max_allowed_to_user: response.data.max_allowed_to_user,
        min_amount: response.data.min_amount,
        start_at: ConvertToYMD(response.data.start_at),
        end_at: ConvertToYMD(response.data.end_at),
        status: response.data.status,
      })
    }
    if (params.id) {
      setapiloader(true);
      fetchData(params.id);
    }
  }, [])

  const onAddPromocodeAction = (event) => {
    event.preventDefault();
    setLoader(true);
    Postdata(HttpService.Promocode_add, formdata).then((res) => {
      dispatch(message(res.message));
      setLoader(false);
      if (res.status === 200) {
        navigate("/promo-code");
      }
    }).catch(e => {
      dispatch(message(e.message));
      setLoader(false);
    });
  };

  return (
    <>
      <Title name='Promocode Management - Add' />
      <div className="row">
        <div className="col-lg-6">
          <h4>Promocode Management</h4>
          <Breadcrumb
            pages={[
              { title: 'Promocode Management', link: 'promo-code' }
            ]}
            active={`${params.id ? 'Edit' : 'Add'} Promocode`}
          />
        </div>
      </div>

      <div className="row">
        <div className="col-lg-12">
          <div className="card">
            <p className="card-header">{params.id ? 'Edit' : 'Add'} Promocode</p>
            <div className="card-body">
              {apiloader ?
                <Loader /> :
                <form onSubmit={onAddPromocodeAction}>
                  <Form.Group className="form-group">
                    <Form.Label>Code*</Form.Label>
                    <Form.Control
                      type="text"
                      value={formdata.code}
                      style={{ textTransform: 'uppercase' }}
                      onChange={e => setFormdata({ ...formdata, code: e.target.value })}
                      required
                    />
                  </Form.Group>

                  <Form.Group className="form-group">
                    <Form.Label>Title*</Form.Label>
                    <Form.Control
                      type="text"
                      value={formdata.title}
                      onChange={e => setFormdata({ ...formdata, title: e.target.value })}
                      required
                    />
                  </Form.Group>

                  <Form.Group className="form-group">
                    <Form.Label>Description</Form.Label>
                    <Form.Control
                      as="textarea"
                      value={formdata.description}
                      onChange={e => setFormdata({ ...formdata, description: e.target.value })}
                    />
                  </Form.Group>

                  <Form.Group className="form-group">
                    <Form.Label>Type*</Form.Label>
                    <Form.Select
                      required
                      value={formdata.type}
                      onChange={e => setFormdata({ ...formdata, type: e.target.value })}
                    >
                      <option value="" hidden> </option>
                      <option value="percentage">Percentage (%)</option>
                      <option value="amount">Amount</option>
                    </Form.Select>
                  </Form.Group>

                  <Form.Group className="form-group">
                    <Form.Label>Discount*</Form.Label>
                    <Form.Control
                      type="number"
                      value={formdata.discount}
                      onChange={e => setFormdata({ ...formdata, discount: e.target.value })}
                      required
                    />
                  </Form.Group>

                  <Form.Group className="form-group">
                    <Form.Label>Maximum Discount*</Form.Label>
                    <Form.Control
                      type="number"
                      required
                      value={formdata.max_discount}
                      onChange={e => setFormdata({ ...formdata, max_discount: e.target.value })}
                    />
                  </Form.Group>

                  <Form.Group className="form-group">
                    <Form.Label>Maximum user allowed to use*</Form.Label>
                    <Form.Control
                      type="number"
                      required
                      value={formdata.max_allowed_to_user}
                      onChange={e => setFormdata({ ...formdata, max_allowed_to_user: e.target.value })}
                    />
                  </Form.Group>

                  <Form.Group className="form-group">
                    <Form.Label>Minimum Amount for Promo Code*</Form.Label>
                    <Form.Control
                      required
                      type="number"
                      value={formdata.min_amount}
                      onChange={e => setFormdata({ ...formdata, min_amount: e.target.value })}
                    />
                  </Form.Group>

                  <Form.Group className="form-group">
                    <Form.Label>Start Date*</Form.Label>
                    <Form.Control
                      type="date"
                      // min={ConvertToYMD(new Date().toISOString().split("T")[0])}
                      min={params.id ? '1970-01-01' : ConvertToYMD(new Date().toISOString().split("T")[0])}
                      required
                      value={formdata.start_at}
                      onChange={e => setFormdata({ ...formdata, start_at: e.target.value })}
                    />
                  </Form.Group>

                  <Form.Group className="form-group">
                    <Form.Label>End Date*</Form.Label>
                    <Form.Control
                      type="date"
                      min={formdata.start_at}
                      required
                      value={formdata.end_at}
                      onChange={e => setFormdata({ ...formdata, end_at: e.target.value })}
                    />
                  </Form.Group>

                  <Form.Group className="form-group">
                    <Form.Label>Status</Form.Label>
                    <Form.Check
                      type="switch"
                      checked={formdata.status}
                      onChange={e => setFormdata({ ...formdata, status: Number(e.target.checked) })}
                    />
                  </Form.Group>

                  <button className="btn btn-primary" type="submit" disabled={loader}>
                    {loader ? 'Please wait...' : (formdata.id ? 'Update' : 'Submit')}
                  </button>
                  <Link className="btn btn-danger" to={'/promo-code'}>Cancel</Link>
                </form>
              }
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export { PromocodeManagementAdd }