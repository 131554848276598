import Moment from 'react-moment';
import moment from 'moment';
import "moment-timezone";

const ConvertToYMD = (date) => {
  return date ? date.slice(0, 10) : date;
}

export function ConvertDatewithAGO(times) {
  if (!times) times = '';
  const today_date = moment().add(0, 'days').format('DD MMMM YYYY').toString();
  const yeaster_date = moment().add(-1, 'days').format('DD MMMM YYYY').toString();
  const api_date = moment(new Date(times)).add(0, 'days').format('DD MMMM YYYY').toString();
  if (today_date === api_date) return <time>Today</time>;
  if (yeaster_date === api_date) return <time>Yesterday</time>;

  return (
    <Moment format={'DD MMM, YYYY'}>
      {new Date(times)}
    </Moment>
  );
}

export function ConvertYMD(times) {
  if (!times) times = '';
  return times.slice(0, 10);
}

const ConvertToDMY = (date) => {
  if (!date) return '';
  return (
    <Moment format="DD/MM/YYYY">
      {new Date(date)}
    </Moment>
  );
}

const ConvertToDMYHIS = (date) => {
  if (!date) return '-';

  return (
    <Moment tz='Asia/Kolkata' format="DD/MM/YYYY hh:mm a">
      {new Date(date)}
    </Moment>
  );
}

const CheckPermission = (permission, path = window.location.pathname) => {
  let callFunc = true;
  if (permission.length) {
    callFunc = false;
    permission.forEach(function (item) {
      item = item.replaceAll('*', '[a-zA-Z0-9\s]+'); //eslint-disable-line
      // item = item.replaceAll('*', '[a-zA-Z0-9]{24}');
      let regexp = new RegExp('(^' + item + '$)', 'gm');
      if (regexp.test(path)) callFunc = true;
    });
  }
  return callFunc;
}

export function capitalize(text) {
  return text.replace(/\b\w/g, function (m) { return m.toUpperCase(); });
}

export function chatTypes(i, preview) {
  let content = '';
  let html = false;
  if (i.type === 'text') content = i.content;
  if (i.type === 'file') {
    if (i.content.match(/\.(jpg|jpeg|png|gif)$/)) {
      content = '<img src="' + i.content + '" />';
    } else if (i.content.match(/\.(mp3)$/)) {
      content = '<audio controls><source src="' + i.content + '" type="audio/mp3"></audio>';
    } else {
      content = '<a target="_blank" href="' + i.content + '"><i class="material-icons">launch</i>Open File</a>';
    }
    html = true;
  }
  if (html) {
    return <span className='c-p' onClick={() => preview(i.content)} dangerouslySetInnerHTML={{ __html: content }} />
  } else {
    return <><span>{content}</span></>;
  }

}

export function ConvertOnlyTime(times, format = false) {
  const momentFormat = (format) ? format : 'hh:mm a';
  if (!times) return '';

  return (
    <Moment format={momentFormat}>
      {new Date(times)}
    </Moment>
  );
}

const numberWithCommas = (value) => {
  if (!value) value = 0;
  return parseFloat(value).toLocaleString('en-IN', {
    maximumFractionDigits: 2,
  });
}

export {
  CheckPermission,
  ConvertToYMD,
  ConvertToDMY,
  ConvertToDMYHIS,
  numberWithCommas
}