/* eslint-disable react/prop-types */
import { useEffect, useState } from 'react';
import '../../../styles/Profile.scss';
import { Postdata } from '../../../utils/api.service';
// import { ConvertToDMYHIS } from '../../../utils/Helper';
import HttpService from '../../../utils/http.service';
import Loader from '../../common/Loader';
import { Table } from 'react-bootstrap';

const PersonalDetails = (props) => {
  const [data, setData] = useState([]);
  const [isloading, setIsloading] = useState(true);

  useEffect(() => {
    Postdata(HttpService.User_show, { id: props.id }).then((response) => {
      setData(response.data);
      setIsloading(false);
    });
  }, []);

  return (
    <>
      <div className="card-body PersonalDetails">
        {isloading ?
          <Loader />
          : <>
            <div className="row">
              <div className="col-lg-12 mb-4">
                <h5>Personal Details</h5>
                <Table>
                  <tbody>
                    <tr><th width="200">User ID</th><td>{data.user_id} &nbsp;</td></tr>
                    <tr><th>Registered By</th><td>{data?.registered_by?.name || '-'} &nbsp;</td></tr>
                    {/* <tr><th>Name</th><td>{data.name} &nbsp;</td></tr> */}
                    {/* <tr><th>Username</th><td>{data.username} &nbsp;</td></tr> */}
                    {/* <tr><th>Email Address</th><td>{data.email} &nbsp;</td></tr> */}
                    {/* <tr><th>Mobile Number</th><td>{data.mobile} &nbsp;</td></tr> */}
                    <tr><th>Status</th><td>{data.status ? 'Active' : 'Deactive'} &nbsp;</td></tr>
                    {/* <tr><th>Registered Date</th><td>{ConvertToDMYHIS(data.createdAt)} &nbsp;</td></tr> */}
                  </tbody>
                </Table>
              </div>

              <div className="col-lg-12">
                <h5>User Current Login Mobile Device Details</h5>
                <Table>
                  <tbody>
                    <tr><th width="200">Device Type</th><td>{data.device_type}</td></tr>
                    <tr><th>Device Name</th><td>{data.device_name}</td></tr>
                    <tr><th>Device Unique ID</th><td>{data.device_unique_id}</td></tr>
                    <tr><th>App Version</th><td>{data.device_app_version}</td></tr>
                    <tr><th>Root Devices</th><td>{data.device_root_devices}</td></tr>
                    <tr><th>IP Address</th><td>{data.ip_address}</td></tr>
                  </tbody>
                </Table>
              </div>
            </div>
          </>
        }
      </div>
    </>
  )
}

export { PersonalDetails }