/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import { Form, Collapse } from "react-bootstrap";
import { useState, useEffect } from "react";
import { Postdata } from '../../../utils/api.service';
import DataTableBase from "../../common/DataTableBase";
import HttpService from "../../../utils/http.service";
import { ConvertToDMY, ConvertToDMYHIS, CheckPermission } from "../../../utils/Helper";
import { useDispatch, useSelector } from "react-redux";
import { message } from "../../../redux/actions/toastActions";
import ModalPopup from "../../common/Modal";
import '../../../styles/Profile.scss'
import CSV from "../../common/CSV";
import Link from "../../common/Link";
import { isPermission } from "../../../redux/selectors/authSelector";

function Vip_Membership() {
  const [data, setData] = useState([]);
  const [isloading, setIsloading] = useState(true);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setperPage] = useState(process.env.REACT_APP_RecordPerPage);
  const [currentPage, setcurrentPage] = useState(1);
  const [refresh, setRefresh] = useState(true);
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [search, setSearch] = useState(false);

  const [modalswitch, setmodalswitch] = useState(false);
  const [modalswitchcontent, setmodalswitchcontent] = useState([0, 0]);
  const permission = useSelector(isPermission);

  const columns = [
    {
      name: '#',
      width: "55px",
      selector: (row, i) => (currentPage * perPage + (i + 1) - perPage),
    },
    {
      name: 'Title',
      selector: row => row.title,
    },
    {
      name: 'Validity',
      selector: row => row.validity_description,
    },
    {
      name: 'Description',
      selector: row => row.description,
    },
    {
      name: 'Amount',
      selector: row => row.amount,
    },
    {
      name: 'Last Updated at',
      selector: row => ConvertToDMY(row.updatedAt),
    },
    {
      name: 'Most Popular',
      width: "150px",
      cell: (row) =>
        <>
          <Form.Check
            type="switch"
            checked={row.set_as_most_popular}
            onChange={e => handleStatusChangeAction(row, e.target.checked, "set_as_most_popular")}
          />
        </>,
      button: true,
    },
    {
      name: 'Status',
      cell: (row) =>
        <>
          {/* // <Link permission={'/settings/game/add'} status={row.status}> */}
          <Form.Check
            type="switch"
            checked={row.status}
            onChange={e => handleStatusChangeAction(row, e.target.checked, "status")}
          />
          {/* // </Link>, */}
        </>,
      button: true,
    },
    {
      name: 'Action',
      id: 'actions',
      hide: (!CheckPermission(permission, '/settings/game/add') && 999999),
      cell: (row) =>
        <span className="actions">
          <Link to={`/settings/vip/add/${row._id}`}><i className="mdi mdi-pen" /></Link>
        </span>,
      button: true,
    }
  ];


  const handleStatusChangeAction = (data, value, type) => {
    let body = data
    body[type] = value ? 1 : 0
    setIsloading(true);
    Postdata(HttpService.Vip_membership_update, body).then((res) => {
      setRefresh(!refresh);
      dispatch(message(res.message));
    })
  }

  const handlePerRowsChange = async (newPerPage, page) => {
    setperPage(newPerPage);
    setcurrentPage(page);
  };

  const handlePageChange = (page) => {
    setcurrentPage(page);
  };

  useEffect(() => {
    if (search !== false) {
      const timeOutId = setTimeout(() => setRefresh(!refresh), 500);
      return () => clearTimeout(timeOutId);
    }
  }, [search]);

  useEffect(() => {
    setIsloading(true);
    Postdata(HttpService.Vip_membership_fetch, {
      page: currentPage,
      perpage: perPage
    }).then((response) => {
      if (response.status === 200) {
        console.log(response.data)
        setData(response.data.vip_data);
        setTotalRows(response.data.count);
        setIsloading(false);
      } else {
        setIsloading(false);
        dispatch(message(response.message));
      }
    }).catch((e) => {
      setIsloading(false);
      dispatch(message(e.message));
    });
  }, [currentPage, perPage, refresh]);

  return (
    <>
      <div className="card-body filters">
        <div className="row searchaction">
          <div className="col-lg-6">
            <h5>
              Vip Membership
            </h5>
          </div>

          <div className="col-lg-6 text-end">
            <Link to={'/settings/vip/add'} className="btn btn-primary">Add New VIP Membership</Link>
          </div>
        </div>

        <DataTableBase
          columns={columns}
          loader={isloading}
          data={data}
          paginationTotalRows={totalRows}
          onChangeRowsPerPage={handlePerRowsChange}
          onChangePage={handlePageChange}
        />
      </div>
    </>
  )
}

export { Vip_Membership }