import Title from "../../common/Title";
import { Form } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import Link from "../../common/Link";
import { useEffect, useState } from "react";
import HttpService from "../../../utils/http.service";
import { Postdata } from "../../../utils/api.service";
import { ConvertToYMD } from "../../../utils/Helper";
import { useDispatch } from "react-redux";
import { message } from "../../../redux/actions/toastActions";
import Loader from "../../common/Loader";
import Breadcrumb from "../../common/Breadcrumb";

const GameManagementAdd = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const navigate = useNavigate();
  const [editType, seteditType] = useState(true);
  const [loader, setLoader] = useState(false);
  const [image, setImage] = useState('');
  const [apiloader, setapiloader] = useState(false);
  const [formdata, setFormdata] = useState({
    title: '',
    image: '',
    gametype_id: '',
    description: '',
    launch_date: '',
    target_link: '',
    is_subscribe: 0,
    is_live: 0,
    status: 0,
  });
  const [gametype, setgametype] = useState([]);

  useEffect(() => {
    async function fetchGametype() {
      const response = await Postdata(HttpService.Gametype_show);
      setgametype(response.data);
    }
    async function fetchData(id) {
      const response = await Postdata(HttpService.Game_show, {
        id: id
      });
      if (response.status !== 200) {
        navigate("/settings")
      }
      setImage(response.data.image);
      setapiloader(false);
      setFormdata({
        id: params.id,
        title: response.data.title,
        gametype_id: response.data?.gametype_id?._id,
        description: response.data.description,
        launch_date: ConvertToYMD(response.data.launch_date),
        target_link: response.data.target_link,
        is_subscribe: response.data.is_subscribe,
        is_live: response.data.is_live,
        status: response.data.status,
      })
      seteditType((response.data?.gametype_id?.deleted) ? false : true);
    }
    if (params.id) {
      setapiloader(true);
      fetchData(params.id);
    }
    fetchGametype();
  }, [])

  const handleImagePreview = (e) => {
    const image_as_files = e.target.files[0];
    setImage(URL.createObjectURL(e.target.files[0]))
    setFormdata({ ...formdata, image: image_as_files })
  }


  const onAddGameAction = (event) => {
    event.preventDefault();
    setLoader(true);
    Postdata(HttpService.Game_add, formdata).then((res) => {
      dispatch(message(res.message));
      navigate("/settings");
      setLoader(false);
    }).catch((e) => {
      dispatch(message(e.message));
      setLoader(false);
    });
  };

  return (
    <>
      <Title name='Game Management - Add' />
      <div className="row">
        <div className="col-lg-6">
          <h4>Game Management</h4>
          <Breadcrumb
            pages={[
              { title: 'Settings', link: 'settings' },
              { title: 'Game Management', link: 'settings' }
            ]}
            active={`${params.id ? 'Edit' : 'Add'} Game`}
          />
        </div>
      </div>

      <div className="row">
        <div className="col-lg-12">
          <div className="card">
            <p className="card-header">{params.id ? 'Edit' : 'Add'} Game</p>
            <div className="card-body">
              {apiloader ?
                <Loader /> :
                <form onSubmit={onAddGameAction}>
                  <Form.Group className="form-group">
                    <Form.Label>Title*</Form.Label>
                    <Form.Control
                      type="text"
                      value={formdata.title}
                      onChange={e => setFormdata({ ...formdata, title: e.target.value })}
                      required
                    />
                  </Form.Group>

                  <Form.Group className="form-group">
                    <Form.Label>Image{!formdata.id && '*'}</Form.Label>
                    <Form.Control
                      type="file"
                      accept=".jpg,.png,.jpeg,.gif"
                      required={!formdata.id}
                      onChange={handleImagePreview}
                    />
                    <small>Photo need to be (512 X 512) or (1000 x 250) pixels.</small>
                    {image && <>
                      <img className="preview" src={image} style={{ width: 'auto' }} />
                    </>}
                  </Form.Group>

                  {editType &&
                    <Form.Group className="form-group">
                      <Form.Label>Game Type*</Form.Label>
                      <Form.Select
                        required
                        value={formdata.gametype_id}
                        onChange={e => setFormdata({ ...formdata, gametype_id: e.target.value })}
                      >
                        <option hidden value=''></option>
                        {gametype.map((e) => {
                          return (
                            e.status ? <option value={e._id} key={e._id}>{e.name}</option> : ''
                          )
                        })}
                      </Form.Select>
                    </Form.Group>
                  }

                  <Form.Group className="form-group">
                    <Form.Label>Description</Form.Label>
                    <Form.Control
                      as="textarea"
                      value={formdata.description}
                      style={{ height: '100px' }}
                      onChange={e => setFormdata({ ...formdata, description: e.target.value })}
                    />
                  </Form.Group>

                  <Form.Group className="form-group">
                    <Form.Label>Launch Date</Form.Label>
                    <Form.Control
                      type="date"
                      min={params.id ? '1970-01-01' : ConvertToYMD(new Date().toISOString().split("T")[0])}
                      value={formdata.launch_date}
                      onChange={e => setFormdata({ ...formdata, launch_date: e.target.value })}
                    />
                  </Form.Group>

                  <Form.Group className="form-group">
                    <Form.Label>Target Link</Form.Label>
                    <Form.Control
                      type="url"
                      value={formdata.target_link}
                      onChange={e => setFormdata({ ...formdata, target_link: e.target.value })}
                    />
                  </Form.Group>

                  <Form.Group className="form-group">
                    <Form.Label>Want to check excitement?</Form.Label>
                    <Form.Check
                      type="switch"
                      checked={formdata.is_subscribe}
                      onChange={e => setFormdata({ ...formdata, is_subscribe: Number(e.target.checked) })}
                    />
                  </Form.Group>

                  <Form.Group className="form-group">
                    <Form.Label>Make it Live?</Form.Label>
                    <Form.Check
                      type="switch"
                      checked={formdata.is_live}
                      onChange={e => setFormdata({ ...formdata, is_live: Number(e.target.checked) })}
                    />
                  </Form.Group>

                  <Form.Group className="form-group">
                    <Form.Label>Status</Form.Label>
                    <Form.Check
                      type="switch"
                      checked={formdata.status}
                      onChange={e => setFormdata({ ...formdata, status: Number(e.target.checked) })}
                    />
                  </Form.Group>

                  <button className="btn btn-primary" type="submit" disabled={loader}>
                    {loader ? 'Please wait...' : (formdata.id ? 'Update' : 'Submit')}
                  </button>
                  <Link className="btn btn-danger" to={'/settings'}>Cancel</Link>
                </form>
              }
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export { GameManagementAdd }