import axios from 'axios';
axios.interceptors.request.use(function (config) {
  if (window.localStorage.getItem('authorizationToken')) config.headers.Authorization = `Bearer ${window.localStorage.getItem('authorizationToken')}`;
  return config;
});

function errorRedirect(msg) {
  console.log('API Response Error:', msg);
}

export function Post(url, payload, config) {
  return new Promise((resolve, reject) => {
    axios
      .post(url, payload, config)
      .then((response) => {
        resolve(response.data);
      })
      .catch((e) => {
        errorRedirect(e.message);
        reject(e);
      });
  });
}

export function Postdata(url, payload, config = {}) {
  let header = config;
  if (!("headers" in header)) {
    header = Object.assign({
      headers: {
        'Content-Type': 'multipart/form-data',
      }
    }, header);
  }
  return new Promise((resolve, reject) => {
    axios
      .post(url, payload, header)
      .then((response) => {
        response.data.message = (response.data.message == 'Authorization required') ? 'Under Development...' : response.data.message;
        resolve(response.data);
        if (["Unauthorized request", "You are already logged in on another device."].includes(response.data.message)) {
          window.location.href = '/';
        }
      })
      .catch((e) => {
        errorRedirect(e.message);
        e.message = (e.message == 'Network Error') ? 'Please check your internet connection and try again...' : e.message;
        reject(e);
      });
  });
}

export function Put(url, payload, config) {
  return new Promise((resolve, reject) => {
    axios
      .put(url, payload, config)
      .then((response) => {
        resolve(response.data);
      })
      .catch((e) => {
        errorRedirect(e.message);
        reject(e);
      });
  });
}

export function Get(url, config) {
  return new Promise((resolve, reject) => {
    axios.get(url, config)
      .then((response) => {
        resolve(response.data);
      })
      .catch((e) => {
        errorRedirect(e.message);
        reject(e)
      });
  });
}

export function Delete(url, config) {
  return new Promise((resolve, reject) => {
    axios
      .delete(url, config)
      .then((response) => {
        resolve(response.data);
      })
      .catch((e) => {
        errorRedirect(e.message);
        reject(e);
      });
  });
}