/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import Title from "../../common/Title";
import { Form, Collapse } from "react-bootstrap";
import React, { useState, useEffect } from "react";
import { Postdata } from '../../../utils/api.service';
import DataTableBase from "../../common/DataTableBase";
import HttpService from "../../../utils/http.service";
import { CheckPermission, ConvertToDMYHIS } from "../../../utils/Helper";
import { useDispatch, useSelector } from "react-redux";
import { message } from "../../../redux/actions/toastActions";
import Breadcrumb from "../../common/Breadcrumb";
import ModalPopup from "../../common/Modal";
import Loader from "../../common/Loader";
import CSV from "../../common/CSV";
import Link from "../../common/Link";
import { isPermission } from "../../../redux/selectors/authSelector";


const RequestManagement = () => {
  const [data, setData] = useState([]);
  const [isloading, setIsloading] = useState(true);
  const [databank, setDataBank] = useState([]);
  const [isbankloading, setBankIsloading] = useState(true);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setperPage] = useState(process.env.REACT_APP_RecordPerPage);
  const [currentPage, setcurrentPage] = useState(1);
  const [refresh, setRefresh] = useState(false);
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [search, setSearch] = useState({
    search: false,
    status: '',
    type: ''
  });

  const [modalswitch, setmodalswitch] = useState(false);
  const [modalswitchcontent, setmodalswitchcontent] = useState([0, 0, 0]);
  const [formdata, setFormdata] = useState({
    remarks: '',
    status: '',
  });
  const permission = useSelector(isPermission);

  const columns = [
    {
      name: '#',
      width: "55px",
      selector: (row, i) => (currentPage * perPage + (i + 1) - perPage),
    },
    {
      name: 'User ID',
      selector: row => row.user_id.user_id,
    },
    {
      name: 'Username',
      selector: row => row.user_id.username,
    },
    {
      name: 'Email',
      selector: row => row.user_id.email,
    },
    {
      name: 'Withdraw Request (Coins)',
      selector: row => row.coins,
    },
    {
      name: 'Amount in Rupees (INR)',
      selector: row => row.coins_price,
    },
    {
      name: 'Withdraw Type',
      selector: row => row.preferred_payment_method === 'upi' ? 'UPI' : (row.preferred_payment_method === 'none' ? 'None' : (row.preferred_payment_method === 'bank' ? 'Bank' : row.preferred_payment_method)),
    },
    // {
    //   name: 'Updated Date',
    //   selector: row => ConvertToDMYHIS(row.createdAt),
    // },
    {
      name: 'Status',
      selector: row => <span className="text-cap">{row.status}</span>,
    },
    {
      name: 'Action',
      id: 'actions',
      hide: (!CheckPermission(permission, '/request-management/edit') && 999999),
      cell: (row) =>
        <span className="actions">
          {row.status === 'pending' ?
            <Link permission="/request-management/edit"><i onClick={() => handleStatusChange(row._id, row.user_id._id, (row.preferred_payment_method === 'upi' ? 1 : 2))} className="mdi mdi-eye" /></Link>
            : ''}
        </span>,
      button: true,
    }
  ];

  const handleStatusChange = (id, status, type) => {
    setmodalswitchcontent([id, status, type]);
    setmodalswitch(true);
  };

  const handleStatusChangeAction = (event) => {
    event.preventDefault();
    setmodalswitch(false);
    setIsloading(true);
    Postdata(HttpService.Request_update, {
      ...formdata,
      id: modalswitchcontent[0],
    }).then((res) => {
      setRefresh(!refresh);
      dispatch(message(res.message));
    }).catch((res) => {
      setRefresh(!refresh);
      dispatch(message(res.message));
    })
  }

  const handlePerRowsChange = async (newPerPage, page) => {
    setperPage(newPerPage);
    setcurrentPage(page);
  };

  const handlePageChange = (page) => {
    setcurrentPage(page);
  };

  useEffect(() => {
    if (search.search !== false || open) {
      const timeOutId = setTimeout(() => setRefresh(!refresh), 500);
      return () => clearTimeout(timeOutId);
    }
  }, [search, open]);

  useEffect(() => {
    setFormdata({ ...formdata, remarks: '' });
    setDataBank([]);
    setBankIsloading(true);
    if (modalswitch) {
      Postdata(HttpService.Bankdetails_show, { id: modalswitchcontent[1][0] }).then((res) => {
        if (res.status === 200) {
          setDataBank(res.data);
        }
        setBankIsloading(false);
      }).catch((e) => {
        setBankIsloading(false);
      })
    }
  }, [modalswitch]);

  useEffect(() => {
    setIsloading(true);
    Postdata(HttpService.Request_list, {
      page: currentPage,
      perpage: perPage,
      ...search,
      search: (search.search === false) ? '' : search.search
    }).then((response) => {
      if (response.status === 200) {
        setData(response.data.records);
        setTotalRows(response.data.total);
        setIsloading(false);
      } else {
        setIsloading(false);
        dispatch(message(response.message));
      }
    }).catch((e) => {
      setIsloading(false);
      dispatch(message(e.message));
    });
  }, [currentPage, perPage, refresh]);

  return (
    <>
      <Title name='Request Management - Withdrawal Request ( iGame ) ' />

      <ModalPopup
        className="alert-box-popup"
        show={modalswitch}
        size="md"
        onHide={() => setmodalswitch(false)}
        heading="Update Request?"
      >
        <div className='actions mt-0'>
          <form onSubmit={handleStatusChangeAction} className="text-start" style={{ width: '100%' }}>
            {
              isbankloading ?
                <Loader />
                :
                <>
                  <BankList data={databank} type={modalswitchcontent[2]} />

                  <Form.Group className="form-group">
                    <Form.Label>Remarks</Form.Label>
                    <Form.Control
                      as="textarea"
                      value={formdata.remarks}
                      onChange={e => setFormdata({ ...formdata, remarks: e.target.value })}
                    />
                  </Form.Group>

                  <button className="btn btn-primary" onClick={() => setFormdata({ ...formdata, status: 'approved' })}>Approve</button>
                  <button className="btn btn-danger" onClick={() => setFormdata({ ...formdata, status: 'rejected' })}>Reject</button>
                </>
            }
          </form>
        </div>
      </ModalPopup>

      <div className="row">
        <div className="col-lg-8">
          <h4>Request Management</h4>
          <Breadcrumb
            pages={[
              { title: 'Request Management', link: '' }
            ]}
            active="Withdrawal request ( iGame ) "
          />
        </div>
        <div className="col-lg-4 text-end">
          <button className="btn btn-danger" onClick={() => setOpen(!open)}>Search</button>
          <CSV
            link={HttpService.Request_list}
            name="Request Management - Withdrawal Request"
            response="records"
            headers={[
              { label: "User ID", key: "user_id.user_id" },
              { label: "Username", key: "user_id.username" },
              { label: "Email", key: "user_id.email" },
              { label: "Withdraw Request (Coins)", key: "coins" },
              { label: "Amount in Rupees (INR)", key: "coins_price" },
              { label: "Withdraw Type", key: "preferred_payment_method" },
              { label: "Status", key: "status" },
            ]}
          />
        </div>
      </div>

      <Collapse in={open}>
        <div>
          <div className="card">
            <p className="card-header">Search</p>
            <div className="card-body">
              <div className="row">
                <div className="col-lg-4">
                  <Form.Group className="form-group mb-0">
                    <Form.Label>Username</Form.Label>
                    <Form.Control
                      type="text"
                      onChange={e => setSearch({ ...search, search: e.target.value })}
                      required
                    />
                  </Form.Group>
                </div>
                <div className="col-lg-4">
                  <Form.Group className="form-group">
                    <Form.Label>Withdraw Type</Form.Label>
                    <Form.Select onChange={e => setSearch({ ...search, type: (e.target.value == '') ? '' : e.target.value })}>
                      <option value="">All</option>
                      <option value="bank">Bank</option>
                      <option value="upi">UPI</option>
                      <option value="none">None</option>
                    </Form.Select>
                  </Form.Group >
                </div>
                <div className="col-lg-4">
                  <Form.Group className="form-group">
                    <Form.Label>Status</Form.Label>
                    <Form.Select onChange={e => setSearch({ ...search, status: (e.target.value == '') ? '' : e.target.value })}>
                      <option value="">All</option>
                      <option value="pending">Pending</option>
                      <option value="approved">Approved</option>
                      <option value="rejected">Rejected</option>
                    </Form.Select>
                  </Form.Group >
                </div>
              </div>
            </div>
          </div>
        </div>
      </Collapse>

      <div className="card">
        <p className="card-header">List of Withdrawal Requests ( iGame ) </p>
        <div className="card-body">
          <DataTableBase
            columns={columns}
            loader={isloading}
            data={data}
            paginationTotalRows={totalRows}
            onChangeRowsPerPage={handlePerRowsChange}
            onChangePage={handlePageChange}
          />
        </div>
      </div>
    </>
  )
}

const BankList = (props) => {
  const data = props.data.filter(e => e.type === props.type);
  return (
    <>
      <table className="table">
        <thead>
          <tr>
            <th>#</th>
            {props.type === 2 ?
              <>
                <th>Account Holder Name</th>
                <th>Account Number</th>
                <th>IFSC Code</th>
              </>
              : <th>UPI</th>
            }
          </tr>
        </thead>
        <tbody>
          {data.length ?
            (data.map((e, k) => {
              return <tr key={e._id}>
                {(props.type === 2 && e.type === 2) &&
                  <>
                    <td>{k + 1}</td>
                    <td>{e.account_name}</td>
                    <td>{e.account_number}</td>
                    <td>{e.ifsc}</td>
                  </>
                }
                {(props.type === 1 && e.type === 1) &&
                  <>
                    <td>{k + 1}</td>
                    <td>{e.upi}</td>
                  </>
                }
              </tr>
            }))
            : <tr><td colSpan={props.type === 2 ? 4 : 2}>No bank details found...</td></tr>
          }
        </tbody>
      </table>
    </>
  );
}

export { RequestManagement }