//*********************************************************************************
//This component - fetch and displays Category in a table with drag n drop feature
//*********************************************************************************
import React,{useState,useEffect} from 'react'
import {DragDropContext,Droppable,Draggable} from 'react-beautiful-dnd'
import { Table } from 'react-bootstrap';
import { useDispatch } from "react-redux";

import { message } from "../../../../redux/actions/toastActions";
import { Get,Post } from '../../../../utils/api.service';
import HttpService from '../../../../utils/http.service'
import Title from "../../../common/Title";
import Breadcrumb from "../../../common/Breadcrumb";
import Loader from '../../../common/Loader'
import { useParams } from 'react-router-dom';

const DigitainCategorySort = () => {
  
  //State
  const [isloading, setIsloading] = useState(true);
  const [data, setData] = useState([]);
  const [resetData,setResetData]=useState([]);
  const [isSaveButtonDisabled, setSaveButtonDisabled] = useState(true);
  const {gameType} = useParams()

  const dispatch = useDispatch();
  const heading =
    gameType === "slots" ? "Slot Game Settings" : "Live Casino Settings";


  //********************************************************************
  // Get API - fetches category information
  //********************************************************************
  useEffect(() => {
    setIsloading(true);
    let params = {
      page: 1,
      perpage: 20,
    }
    Get(HttpService.Digitain_category+"/"+gameType,{params}).then((response) => {
      if (response.status === 200) {
        setData(response.data);
        setResetData(response.data);
        setIsloading(false);
      } else {
        setIsloading(false);
        dispatch(message(response.message));
      }
    }).catch((e) => {
      setIsloading(false);
      dispatch(message(e.message));
    });
  },[]);

   //********************************************************************
   //*Callback method to save the changed order in local state
   //********************************************************************
   const onDragEnd= (result) =>{
    
    const { destination, source} = result;

    //if no destination in a drag, just exit -
    if(!destination){
      return
    }

    //if droppable id of destination & source i.e if its of same column AND index of destination & source i.e. same index in same column 
    // just exit dont channge state
    if( destination.droppableId === source.droppableId &&
      destination.index === source.index
    ){
      return
    }
    // Check if a row was moved
    if (destination && source.index !== destination.index) {
      setSaveButtonDisabled(false);
    }

    //Next : Re-order the category id in the column. so first retrive the category data first from initial data (i.e useState -data)
    const updatedCategories = Array.from(data);
    // console.log('updatedCategories :>> ', updatedCategories);
    const [reorderedCategory] = updatedCategories.splice(source.index, 1);
    updatedCategories.splice(destination.index, 0, reorderedCategory);

    //Now setState. save the order
    setData(updatedCategories);

  }

 
  //********************************************************************
  //POST API -Function to Save new order of the category to DB 
  //********************************************************************
  
  const saveCategoryOrderToDB = async (data) => {
  
    const newCategoryOrderArray = data.map(category => category.id);
    const payload = {"categoryOrderArray":newCategoryOrderArray};
  
    try {
      // checks newCategoryOrderArray for data
      if (newCategoryOrderArray.length > 0 ) {
        let res = await Post(HttpService.Digitain_save_category_order+"/"+gameType, payload);
        if (res.status === 200) {
          dispatch(message(res.message));
          setSaveButtonDisabled(true);
          setResetData(data)
        } else {
          const errorData = await res.json();
        console.error('Failed to update category order:', errorData);
        }
      } else {
        dispatch(message("Game order array is empty"))
      }
    } catch (error) {
      dispatch(message(error.message));
      console.error('Error making API call:', error);
    }

  };

  //Reset Button
  const resetButton = ()=>{
    setData(resetData)
    setSaveButtonDisabled(true);
  }


  return (
    <>
      <Title name="Game Management - Add" />
      <div className="row">
        <div className="col-lg-6">
          <h4>Category Reorder</h4>
          <Breadcrumb
            pages={[
              { title: heading, link: "evenbet-game-settings/" + gameType },
            ]}
            active="Category Sort"
          />
        </div>
      </div>

      <div className="row">
        <div className="col-lg-12">
          <div className="card">
            <div className="card-body">
              <div className="row mb-3">
                <div className="col-lg-6">
                  <h5>Sort Category</h5>
                </div>
                <div className="col-lg-6 text-end">
                  <button
                    className="btn btn-danger"
                    disabled={isSaveButtonDisabled}
                    onClick={() => resetButton()}
                  >
                    Reset
                  </button>
                  <button
                    className="btn btn-success"
                    disabled={isSaveButtonDisabled}
                    onClick={() => saveCategoryOrderToDB(data)}
                  >
                    Save Category order
                  </button>
                </div>
              </div>

              {/* React-beautiful-dnd config with react-bootstrap table*/}
              <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId="Categories">
                  {(provided) => (
                    <Table
                      striped
                      bordered
                      hover
                      {...provided.droppableProps}
                      ref={provided.innerRef}
                    >
                      <thead>
                        <tr>
                          <th>Re-order</th>
                          <th>Category ID</th>
                          <th>Category Name</th>
                          <th>Display Name</th>
                        </tr>
                      </thead>
                      {isloading ? <Loader /> : " "}
                      <tbody>
                        {data.map((category, index) => (
                          <Draggable
                            key={category.id}
                            draggableId={category.id.toString()}
                            index={index}
                          >
                            {(provided) => (
                              <tr
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                              >
                                <td> :: </td>
                                <td>{category.id}</td>
                                <td>{category.name}</td>
                                <td>{category.displayName}</td>
                              </tr>
                            )}
                          </Draggable>
                        ))}
                      </tbody>
                      {provided.placeholder}
                    </Table>
                  )}
                </Droppable>
              </DragDropContext>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default DigitainCategorySort