//*********************************************************************************
//This component - fetch and displays games in a table with drag n drop feature
//*********************************************************************************
import React,{useState,useEffect} from 'react'
import {useParams} from 'react-router-dom'
import { useDispatch } from "react-redux";
import { Table } from 'react-bootstrap';
import {DragDropContext,Droppable,Draggable} from 'react-beautiful-dnd'

import { Post } from '../../../../utils/api.service';
import HttpService from '../../../../utils/http.service'
import { message } from "../../../../redux/actions/toastActions";
import Title from "../../../common/Title";
import Breadcrumb from "../../../common/Breadcrumb";
import Loader from '../../../common/Loader'

const DigitainGameSort = () => {
  //Find the useParams to fetch the category from url
  const {id} = useParams()
  //useState
  const [isloading, setIsloading] = useState(true);
  const [data, setData] = useState([]);
  const [categoryName,setCategoryName]= useState([])
  const [resetData,setResetData]=useState([]);
  const [isSaveButtonDisabled, setSaveButtonDisabled] = useState(true);
  //dispatch
  const dispatch = useDispatch();

  //********************************************************************
  // Post API - fetches game information with the category id
  //********************************************************************
  useEffect(() => {
    // setIsloading(true);
    const payload = {categoryId: Number(id) }

    Post(HttpService.Digitain_fetch_games_by_id,payload).then((response) => {
      if (response.status === 200) {
        // Use nullish coalescing to default to an empty array
        setData(response.data.sortedGameData ?? []); // Ensure 'data' is defined and an array, 
        setCategoryName(response.data.categoryName); 
        setResetData(response.data.sortedGameData);
        setIsloading(false);
        // console.log('response :>> ', response);
      } else {  
        setIsloading(false);
        dispatch(message(response.message));
        console.error('Failed to fetch category games:', response);
      }
    }).catch((e) => {
      setIsloading(false);
      dispatch(message(e.message));
      console.error('Error making API call:', e);
    });

  },[]);

  //********************************************************************
   //*Callback method to save the changed order in local state
   //********************************************************************
   const onDragEnd= (result) =>{
      const {destination, source} = result;

    //if no destination in a drag, just exit -
    if(!destination){
      return
    }

     //if droppable id of destination & source i.e if its of same column AND index of destination & source i.e. same index in same column 
    // just exit dont channge state
    if( destination.droppableId === source.droppableId &&
      destination.index === source.index
    ){
      return
    }
    // Check if a row was moved
    if (destination && source.index !== destination.index) {
      setSaveButtonDisabled(false);
    }
    //Next : Re-order the category id in the column. so first retrive the category data first from initial data (i.e useState -data)
    const updatedGamesList = Array.from(data);
    const [reorderedGames] = updatedGamesList.splice(source.index, 1);
    updatedGamesList.splice(destination.index, 0, reorderedGames);

    //Now setState. save the order
    setData(updatedGamesList);
   }


  //********************************************************************
  // Post API - saves DB with new game order info with the category id
  //******************************************************************** 
  
  const saveGameOrderToDB =async (data) =>{

    const newGameOrderArray = data.map( game => game.gameId)
    
    const payloadData = {
      categoryId: Number(id),
      gameOrderArray: newGameOrderArray
    }

    try {
      // checks newCategoryOrderArray for data
      if (newGameOrderArray.length > 0 ) {
        let res = await Post(HttpService.Digitain_save_game_order, payloadData);
        if (res.status === 200) {
          dispatch(message(res.message));
          setSaveButtonDisabled(true);
          setResetData(data)
        } else {
          const errorData = await res.json();
        console.error('Failed to update category order:', errorData);
        }
      } else {
        dispatch(message("Game order array is empty"))
      }
    } catch (error) {
      dispatch(message(error.message));
      console.error('Error making API call:', error);
    }

  }

    //Reset Button
    const resetButton = ()=>{
      setData(resetData)
      setSaveButtonDisabled(true);
    }

  return (
    <>
      <Title name='Game Management - Add' />
      <div className="row">
        <div className="col-lg-6">
          <h4>Games Sort</h4>
          <Breadcrumb
            pages={[
              { title: 'evenbet Settings', link: 'evenbet-game-settings' },
            ]}
            active = 'Games Sort'
          />
        </div>
      </div>

      <div className="row">
        <div className="col-lg-12">
            <div className="card">
                <p className="card-header"> Re-order Games of {categoryName}</p>
                <div className="card-body">

                  <div className="row mb-3">
                    <div className="col-lg-6">
                      <h5>Category Name : {categoryName}</h5>
                    </div>
                    <div className="col-lg-6 text-end">
                      <button className='btn btn-danger' disabled={isSaveButtonDisabled} onClick={()=> resetButton()} >Reset</button>
                      <button className="btn btn-success" disabled={isSaveButtonDisabled} onClick={() => saveGameOrderToDB(data) } >
                        Save Category order
                      </button>
                    </div>
                  </div>

                  <DragDropContext onDragEnd = {onDragEnd}>
                    <Droppable droppableId='games'>
                      { (provided)=>(
                        
                        <Table 
                          striped 
                          bordered 
                          hover
                          {...provided.droppableProps}
                          ref={provided.innerRef}
                          >
                            <thead>
                              <tr>
                                <th>Re-order</th>
                                  <th>Game ID</th>
                                  <th>Game Name</th>
                                  <th>Provider Name</th>
                                </tr>
                            </thead>
                            {isloading? <Loader/> : " "}
                            <tbody>
                            { Array.isArray(data) && data.length === 0 ? 
                              <p>No Games available for this category</p>
                            : 
                                data.map( (game,index) => (

                                  <Draggable
                                    draggableId={game.gameId.toString()}
                                    index={index}
                                    key={game.gameId}
                                  >
                                    {(provided)=>(
                                      <tr
                                        {...provided.draggableProps}
                                        {...provided.dragHandleProps}
                                        ref={provided.innerRef}
                                      >
                                        <td>::</td>
                                        <td>{game.gameId}</td>
                                        <td>{game.gameName}</td>
                                        <td>{game.provider.name}</td>
                                      </tr>

                                    )}
                                  </Draggable>
                                ))
                            }
                            </tbody>
                            {provided.placeholder}
                        </Table>
                      )}

                    </Droppable>

                  </DragDropContext>

                </div>
            </div>
        </div>
      </div>
    </>
  )
}

export default DigitainGameSort

