import { Modal, ModalFooter, Form } from "react-bootstrap";
import { useState } from "react";
import PropTypes from "prop-types";

const DigitainEditGameModal = (props) => {
  const {
    categoryData,
    onSaveGameDetailsClickedCallback,
    onCancelClickedCallback,
    gameToEdit,
    ...modalProps
  } = props;
  const [gameDisplayName, setGameDisplayName] = useState(() =>
    gameToEdit ? gameToEdit.gameDisplayName : ""
  );
  const [categories, setCategories] = useState(() =>
    gameToEdit && gameToEdit.iGameCategory ? gameToEdit.iGameCategory : []
  );

  const onCategoryChanged = (event) => {
    const categoryId = parseInt(event.target.value);
    const isChecked = event.target.checked;

    if (isChecked && !categories.includes(categoryId)) {
      setCategories([...categories, categoryId]);
    } else {
      setCategories(categories.filter((id) => id !== categoryId));
    }
  };

  const onSaveGameDetailsClicked = () => {
    let gameDetails = {
      gameId: gameToEdit.gameId,
      gameDisplayName,
      iGameCategory: categories,
    };
    onSaveGameDetailsClickedCallback(gameDetails);
  };
  const onCancelClicked = () => {
    onCancelClickedCallback();
  };
  return (
    <Modal
      centered
      // backdrop="static"
      // keyboard={false}
      {...modalProps}
    >
      <Modal.Header closeButton>
        <Modal.Title>Edit Game</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form.Group className="form-group mb-0">
          <Form.Label>Game Display Name</Form.Label>
          <Form.Control
            type="text"
            value={gameDisplayName}
            onChange={(e) => setGameDisplayName(e.target.value)}
            required
          />
          <Form.Label>Categories</Form.Label>
          {Object.keys(categoryData).map((categoryId) => {
            return (
              <Form.Check
                value={categoryId}
                label={categoryData[categoryId]}
                checked={categories.includes(parseInt(categoryId))}
                key={categoryId}
                type="checkbox"
                onChange={onCategoryChanged}
              />
            );
          })}
          {/* <Form.Control
            as="check"
            onChange={onCategoryChanged}
            value={categories}
            required
            multiple
          >
          </Form.Control> */}
        </Form.Group>
      </Modal.Body>
      <ModalFooter>
        <div className="actions">
          <button className="btn btn-danger" onClick={onCancelClicked}>
            Cancel
          </button>
          <button
            className="btn btn-primary"
            onClick={onSaveGameDetailsClicked}
          >
            Save
          </button>
        </div>
      </ModalFooter>
    </Modal>
  );
};

DigitainEditGameModal.propTypes = {
  categoryData: PropTypes.object,
  gameToEdit: PropTypes.object,
  onSaveGameDetailsClickedCallback: PropTypes.func,
  onCancelClickedCallback: PropTypes.func,
};

export { DigitainEditGameModal };
