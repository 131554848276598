/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import '../../../styles/Profile.scss'
import React, { useState, useEffect, useRef } from 'react'
import Breadcrumb from '../../common/Breadcrumb'
import Title from '../../common/Title'
import { Form, Collapse, InputGroup } from "react-bootstrap";
import { Link, useParams, useNavigate } from 'react-router-dom'
import HttpService from '../../../utils/http.service';
import { Postdata } from '../../../utils/api.service'
import { useDispatch } from "react-redux";
import { message } from '../../../redux/actions/toastActions'
import Loader from '../../common/Loader'
import { AsyncTypeahead } from "react-bootstrap-typeahead";
import 'react-bootstrap-typeahead/css/Typeahead.css';

function Notification_Add() {
  const typeaheadRef = useRef(null)
  const dispatch = useDispatch();
  const params = useParams();
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    type: "inApp",
    user_type: "all",
    title: "",
    message: "",
    image: ""
  })
  const [image, setImage] = useState('');
  const [loader, setLoader] = useState(false);
  const [isSearchUsersModal, setIsSearchUsersModal] = useState(false)
  const [selectedUsers, setSelectedUsers] = useState([])
  const [options, setOptions] = useState([])
  const [isSearchLoading, setIsSearchLoading] = useState(false)


  const handleImagePreview = (e) => {
    const image_as_files = e.target.files[0];
    setImage(URL.createObjectURL(e.target.files[0]))
    setFormData({ ...formData, image: image_as_files })
  }

  const handleUserSearch = (query) => {
    if (query.length > 3) {
      setIsSearchLoading(true)
      Postdata(HttpService.User_list, {
        page: 1,
        perpage: 50,
        search_by_role: 'user',
        search_by_field: 'username',
        search: query
      }).then((response) => {
        if (response.status === 200) {
          const newUsers = response.data.users.map((user) => ({
            ...user,
            label: user.username
          }))
          setOptions(newUsers)
        }
        setIsSearchLoading(false)
      }).catch((e) => {
        dispatch(message(e.message));
        setIsSearchLoading(false)
      });
    }
  }

  const sendNotification = (e) => {
    e.preventDefault()
    setLoader(true)
    if (formData.title == "") {
      setLoader(false)
      return dispatch(message("Enter title of notification"))
    }
    if (formData.message == "") {
      setLoader(false)
      return dispatch(message("Enter message of notification"))
    }
    Postdata(HttpService.Notification_add, {
      ...formData,
      sendedToUsers : selectedUsers
    }
      ).then((res) => {
      if (res.status === 200) {
        dispatch(message(res.message));
      }
    });
    setFormData({
      type: "inApp",
      user_type: "all",
      title: "",
      message: "",
      image: ""
    })
    setImage('')
    setLoader(false)
    setSelectedUsers([])
    setIsSearchUsersModal(false)
  }

  useEffect(() => {
    async function fetchData(id) {
      const response = await Postdata(HttpService.Notification_getById, {
        id: id
      });
      if (response.status !== 200) {
        navigate("/notification")
      }
      let image = response.data.image
      let imageUrlIndex = response.data.image
      imageUrlIndex = imageUrlIndex.lastIndexOf("/")
      image = image.slice(imageUrlIndex + 1)

      setImage(response.data.image);
      setFormData({
        type: response.data.type,
        user_type: response.data.user_type,
        title: response.data.title,
        message: response.data.message,
        image: image
      })
      if(response.data.user_type === "particularUsers"){
        setIsSearchUsersModal(true)
        setSelectedUsers(response.data.sendedToUsers)
      }
    }
    if (params.id) {
      fetchData(params.id);
    }
  }, [])

  return (
    <>
      <Title name={params.id ? "Edit And Resend Notification" : "Add Notification"} />
      <div className='row'>
        <div className='col-lg-6'>
          <h4>{params.id ? "Edit And Resend Notification" : "Send New Notification"}</h4>
          <Breadcrumb
            pages={[
              { title: 'Notifications', link: 'notification' }
            ]}
            active="Send New Notification"
          />
        </div>
      </div>

      <div className='card'>
        <p className='card-header'>Notification Details</p>
        <div className='card-body'>
          <form onSubmit={sendNotification}>
            <Form.Group className="form-group">
              <Form.Label>Type<span style={{ color: "red" }}>*</span></Form.Label>
              <Form.Select value={formData.type || ''} onChange={e => setFormData({
                ...formData,
                type: e.target.value
              })}>
                <option value="inApp">In-App Notification</option>
                <option value="push">Push Notification</option>
              </Form.Select>
            </Form.Group>
            <Form.Group className="form-group">
              <Form.Label>Select User type<span style={{ color: "red" }}>*</span></Form.Label>
              <Form.Select value={formData.user_type || ''} onChange={e => {
                setFormData({
                  ...formData,
                  user_type: e.target.value
                })
                if (e.target.value === "particularUsers") {
                  setIsSearchUsersModal(true)
                } else {
                  setIsSearchUsersModal(false)
                }
              }}>
                <option value="all">Select All</option>
                <option value="vipUsers">VIP Users</option>
                <option value="particularUsers">Particular Users</option>
              </Form.Select>
            </Form.Group>

            <Collapse in={isSearchUsersModal}>
              <div className='row'>
                    <Form.Group className="form-group">
                      <Form.Label>Search and select users</Form.Label>
                      <AsyncTypeahead
                        multiple
                        id="select-users"
                        options={options}
                        onSearch={handleUserSearch}
                        placeholder="Write 3 or more characters to start searching users"
                        onChange={(selected) => {
                          setSelectedUsers(selected)
                        }}
                        isLoading={isSearchLoading}
                        renderMenuItemChildren={(option) => (
                          <>
                          <div className='d-flex justify-content-start align-items-center'>
                            <div>
                            <img src={option.profile_pic} style={{width : "30px", marginRight : "10px", borderRadius : "50%"}} />
                            </div>
                            <div>
                            {option.username}
                            </div>
                          </div>
                          </>
                        )}
                      />
                    </Form.Group>
              </div>
            </Collapse>

            <Form.Group className="form-group">
              <Form.Label>Title<span style={{ color: "red" }}>*</span></Form.Label>
              <Form.Control
                value={formData.title || ''}
                type="text"
                onChange={e => setFormData({
                  ...formData,
                  title: e.target.value
                })}
              />
            </Form.Group>

            <Form.Group className="form-group">
              <Form.Label>Message<span style={{ color: "red" }}>*</span></Form.Label>
              <Form.Control
                type="textarea"
                value={formData.message || ''}
                onChange={e => setFormData({
                  ...formData,
                  message: e.target.value
                })}
              />
            </Form.Group>

            {/* <Form.Group className="form-group">
              <Form.Label>Image</Form.Label>
              <Form.Control
                type="file"
                accept=".jpg,.png,.jpeg,.gif"
                onChange={handleImagePreview}
              />
              {image && <>
                <img className="preview" src={image} style={{ width: 'auto' }} />
              </>}
            </Form.Group> */}

            <button className="btn btn-primary" type="submit" disabled={loader}>
              {loader ? 'Please wait...' : 'Send'}
            </button>
            <Link className="btn btn-danger" to={'/notification'}>Cancel</Link>
          </form>
        </div>

      </div>
    </>
  )
}

export { Notification_Add }