/* eslint-disable no-unused-vars */
import DataTableBase from "../../common/DataTableBase";
import Title from "../../common/Title";
import { Form, Collapse } from "react-bootstrap";
import { useState, useEffect } from "react";
import { isData, isPermission } from "../../../redux/selectors/authSelector";
import { useDispatch, useSelector } from "react-redux";
import { Get, Postdata } from '../../../utils/api.service';
import Link from "../../common/Link";
import HttpService from "../../../utils/http.service";
import { message } from "../../../redux/actions/toastActions";
import { ConvertToDMY } from "../../../utils/Helper";
import Breadcrumb from "../../common/Breadcrumb";
import ModalPopup from '../../common/Modal';
import CSV from "../../common/CSV";

const UserManagement = () => {
  const [roles, setRoles] = useState([]);
  const [data, setData] = useState([]);
  const [isloading, setIsloading] = useState(true);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setperPage] = useState(process.env.REACT_APP_RecordPerPage);
  const [currentPage, setcurrentPage] = useState(1);
  const [refresh, setRefresh] = useState(false);
  const dispatch = useDispatch();
  const [open, setOpen] = useState(true);

  const [modalswitch, setmodalswitch] = useState(false);
  const [modalswitchcontent, setmodalswitchcontent] = useState([0, 0]);

  const [modalAddUser, setmodalAddUser] = useState(false);
  const [loader, setLoader] = useState(false);
  const [username, setusername] = useState('');
  const profile = useSelector(isData);


  const onAddUserAction = (event) => {
    event.preventDefault();
    setLoader(true);
    Postdata(HttpService.User_add, { username: username }).then((res) => {
      dispatch(message(res.message));
      setLoader(false);
      if (res.status === 200) {
        setmodalAddUser(false);
        setRefresh(!refresh);
      }
    });
  };

  const [postdata, setPostdata] = useState({
    search_by_role: 'default',
    search_by_registered_date: ''
  });

  const columns = [
    {
      name: '#',
      // selector: row => row.user_id,
      width: "55px",
      selector: (row, i) => (currentPage * perPage + (i + 1) - perPage),
    },
    {
      name: 'Profile',
      cell: (row) =>
        <Link to={`/user-management/view/${row._id}`}>
          <div className="avatar">
            <img
              alt={row.user_id}
              src={row.profile_pic}
            />
          </div>
        </Link>,
      width: "75px",
    },
    {
      name: 'Role',
      selector: row => row?.role_id?.name || 'Player',
    },
    {
      name: 'Username',
      cell: (row) =>
        <Link to={`/user-management/view/${row._id}`}>
          {row.username}
        </Link>
    },
    {
      name: 'Mobile Number',
      selector: row => row.mobile,
    },
    {
      name: 'Total Played Game',
      selector: row => row.total_played_games,
    },
    {
      name: 'Email',
      selector: row => row.email,
    },
    {
      name: 'Coins',
      selector: row => row.usable_coin_balance,
    },
    {
      name: 'Registration Date',
      selector: row => ConvertToDMY(row.createdAt),
    },
    {
      name: 'Registered By',
      selector: row => row?.registered_by?.username || '-SELF-',
    },
    {
      name: 'Status',
      cell: (row) =>
        <Link permission={'/user-management/add'} status={row.status}>
          <Form.Check
            type="switch"
            checked={row.status}
            onChange={e => handleStatusChange(row._id, e.target.checked)}
          />
        </Link>,
      button: true,
      omit : profile.user.role_id?.name != 'Admin' ? true : false
    },
    {
      name: 'Action',
      id: 'actions',
      cell: (row) =>
        <span className="actions">
          <Link to={`/user-management/edit/${row._id}`}><i className="mdi mdi-pen" /></Link>
          <Link to={`/user-management/view/${row._id}`}><i className="mdi mdi-eye" /></Link>
        </span>,
      button: true,
    }
  ];

  const handleStatusChange = (id, status) => {
    setmodalswitchcontent([id, status]);
    setmodalswitch(true);
  };

  const handleStatusChangeAction = () => {
    setmodalswitch(false);
    setIsloading(true);
    Postdata(HttpService.User_status, {
      id: modalswitchcontent[0],
      status: Number(modalswitchcontent[1])
    }).then((res) => {
      setRefresh(!refresh);
      dispatch(message(res.message));
    });
  }

  const handlePerRowsChange = async (newPerPage, page) => {
    setperPage(newPerPage);
    setcurrentPage(page);
  };

  const handlePageChange = (page) => {
    setcurrentPage(page);
  };

  useEffect(() => {
    setIsloading(true);
    const postBody = Object.fromEntries(Object.entries({
      ...postdata,
      page: currentPage,
      perpage: perPage,
    }).filter(([, value]) => value !== ''));
    Postdata(HttpService.User_list, postBody).then((response) => {
      if (response.status === 200) {
        setData(response.data.users);
        setRoles(response.data.search_roles);
        if (postdata.search_by_role === 'default' && response.data.myrole?._id) {
          setPostdata({ ...postdata, search_by_role: response.data.myrole?._id });
        }
        setTotalRows(response.data.count);
        setIsloading(false);
      } else {
        setIsloading(false);
        dispatch(message(response.message));
      }
    }).catch((e) => {
      setIsloading(false);
      dispatch(message(e.message));
    });
  }, [currentPage, perPage, refresh, postdata]);

  return (
    <>
      <Title name='User Management' />

      <ModalPopup
        className="alert-box-popup"
        show={modalswitch}
        size="sm"
        onHide={() => setmodalswitch(false)}
        heading="Are you sure?"
      >
        <i className="mdi mdi-check"></i>
        <p>You want to change this status!</p>
        <div className='actions'>
          <button className="btn btn-primary" onClick={() => handleStatusChangeAction()}>Yes, Change Status</button>
          <button className="btn btn-danger" onClick={() => setmodalswitch(false)}>Cancel</button>
        </div>
      </ModalPopup>


      <ModalPopup
        show={modalAddUser}
        size="md"
        onHide={() => setmodalAddUser(false)}
        heading="Add New User"
      >
        <form onSubmit={onAddUserAction}>
          <Form.Group className="form-group">
            <Form.Label>Username*</Form.Label>
            <Form.Control
              autoFocus
              type="text"
              pattern="[a-zA-Z0-9]{5,15}"
              onInvalid={e => e.target.setCustomValidity('Username must be between 5 to 15 characters')}
              onInput={e => e.target.setCustomValidity('')}
              onChange={(e) => setusername((e.target.value).toString().replace(/[^a-zA-Z0-9]+/, "").trim())}
              value={username}
              required
            />
          </Form.Group>

          <button className="btn btn-primary" type="submit" disabled={loader}>
            {loader ? 'Please wait...' : 'Create'}
          </button>
        </form>
      </ModalPopup>

      <div className="row">
        <div className="col-lg-4">
          <h4>User Management</h4>
          <Breadcrumb
            active="User Management"
          />
        </div>
        <div className="col-lg-8 text-end">
          {/* <Link permission={'/user-management/add'} className="btn btn-primary" onClick={() => { setmodalAddUser(true); setusername('') }}>Add New User</Link> */}
          <Link to={'/user-management/add'} className="btn btn-primary">Add New User</Link>
          <button className="btn btn-danger" onClick={() => setOpen(!open)}>Filters</button>
          <CSV
            link={HttpService.User_list}
            name="User List"
            response="users"
            headers={[
              { label: "ID", key: "user_id" },
              { label: "Username", key: "username" },
              { label: "Mobile Number", key: "mobile" },
              { label: "Total Played Game", key: "total_played_games" },
              { label: "Email", key: "email" },
              { label: "Coins", key: "usable_coin_balance" },
              { label: "Registration Date", key: "createdAt" },
              { label: "Registered By", key: "registered_by" },
            ]}
          />
        </div>
      </div>

      <Collapse in={open}>
        <div>
          <div className="card">
            <p className="card-header">Filters</p>
            <div className="card-body">
              <div className="row">
                <div className="col-lg-2">
                  <Form.Group className="form-group">
                    <Form.Label>Status</Form.Label>
                    <Form.Select onChange={e => setPostdata({ ...postdata, search_by_status: (e.target.value == '') ? '' : Number(e.target.value) })}>
                      <option value="">All</option>
                      <option value="1">Active</option>
                      <option value="0">Deactive</option>
                    </Form.Select>
                  </Form.Group >
                </div>
                {/* <div className="col-lg-3">
                  <Form.Group className="form-group">
                    <Form.Label>Registered</Form.Label>
                    <Form.Select >
                      <option value="Registered By">Registered By</option>
                      <option value="All">All</option>
                      <option value="Not Assigned">Not Assigned</option>
                      <option value="Agent ABC">Agent ABC</option>
                      <option value="Agent XYZ">Agent XYZ</option>
                    </Form.Select>
                  </Form.Group>
                </div> */}
                <div className="col-lg-2">
                  <Form.Group className="form-group">
                    <Form.Label>Search By</Form.Label>
                    <Form.Select onChange={e => setPostdata({ ...postdata, search_by_field: e.target.value })}>
                      <option value="">All</option>
                      <option value="username">Username</option>
                      <option value="mobile">Mobile Number</option>
                      <option value="email">Email</option>
                    </Form.Select>
                  </Form.Group>
                </div>
                <div className="col-lg-2">
                  <Form.Group className="form-group">
                    <Form.Label>Search</Form.Label>
                    <Form.Control
                      type="text"
                      onChange={e => setPostdata({ ...postdata, search: e.target.value })}
                    />
                  </Form.Group>
                </div>
                <div className="col-lg-2">
                  <Form.Group className="form-group mb-0">
                    <Form.Label>Registered On</Form.Label>
                    <Form.Select onChange={e => setPostdata({ ...postdata, search_by_registered_date: e.target.value })}>
                      <option value="">All</option>
                      <option value="today">Today</option>
                      <option value="week">Last Week</option>
                      <option value="month">Last Month</option>
                      <option value="year">Last Year</option>
                      <option value="range">Select Date</option>
                    </Form.Select>
                  </Form.Group>
                </div>
                <div className="col-lg-2">
                  <Form.Group className="form-group mb-0">
                    <Form.Label>Role</Form.Label>
                    <Form.Select
                      value={postdata.search_by_role}
                      onChange={e => setPostdata({ ...postdata, search_by_role: e.target.value })}
                    >
                      <option value="">All</option>
                      {roles.map(e => {
                        return <option value={e._id} key={e._id}>{e.name}</option>
                      })}
                      <option value="user">Player</option>
                    </Form.Select>
                  </Form.Group>
                </div>
              </div>
              {postdata.search_by_registered_date === 'range' ?
                <div className="row">
                  <div className="col-lg-3">
                    <Form.Group className="form-group mb-0">
                      <Form.Label>From Date</Form.Label>
                      <Form.Control
                        type="date"
                        onChange={e => setPostdata({ ...postdata, from_date: e.target.value })}
                      />
                    </Form.Group>
                  </div>
                  <div className="col-lg-3">
                    <Form.Group className="form-group mb-0">
                      <Form.Label>To Date</Form.Label>
                      <Form.Control
                        type="date"
                        onChange={e => setPostdata({ ...postdata, to_date: e.target.value })}
                      />
                    </Form.Group>
                  </div>
                </div>
                : ''}
            </div>
          </div>
        </div>
      </Collapse>

      <div className="card">
        <p className="card-header">List of Users</p>
        <div className="card-body">
          <DataTableBase
            columns={columns}
            loader={isloading}
            data={data}
            paginationTotalRows={totalRows}
            onChangeRowsPerPage={handlePerRowsChange}
            onChangePage={handlePageChange}
          />
        </div>
      </div>
    </>
  )
}

export { UserManagement }