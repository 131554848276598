/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import { InputGroup, FormControl, Alert } from "react-bootstrap"
import Logo from '../assets/igame-logo.webp';
import { Link, Navigate, useParams, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useState, useEffect } from "react";
import { Footer } from "./include/Footer";
import Title from "./common/Title";
import { Postdata } from "../utils/api.service";
import HttpService from "../utils/http.service";
import Loader from "./common/Loader";

const ResetPassword = () => {
    const params = useParams()
    const navigate = useNavigate();
    const [resetData, setResetData] = useState({
        password: '',
        confirm_password: ''
    });
    const dispatch = useDispatch();
    const [loader, setLoader] = useState(false)
    const [resetAllowed, setResetAllowed] = useState(false)
    const [apiLoader, setApiLoader] = useState(true)
    const [successMsg, setSuccessMsg] = useState('')
    const [errorMsg, setErrorMsg] = useState('')

    const onResetPasswordAction = (event) => {
        event.preventDefault();
        setLoader(true)
        if (resetData.password === resetData.confirm_password) {
            Postdata(HttpService.resetPasswordWithLinkUrl, {
                link: params.link,
                password: resetData.password
            }).then((res) => {
                if (res.status === 200) {
                    setLoader(false)
                    setSuccessMsg('Password successfully reset. You can login now...')
                    setTimeout(() => {
                        navigate('/')
                    }, 2000);
                }
            }).catch((err) => {
                setLoader(false)
                console.log(err)
            });
        } else {
            setErrorMsg('Passwords not matching, Please retry')
            setResetData({
                ...resetData,
                confirm_password: ''
            })
        }

    }

    useEffect(() => {
        setApiLoader(true)
        if (params.link) {
            Postdata(HttpService.validateResetPasswordUrl, {
                link: params.link
            }).then((res) => {
                if (res.status === 200) {
                    if (res.data.password_reset_allowed) {
                        setResetAllowed(true)
                    }
                    if (!res.data.password_reset_allowed) {
                        setResetAllowed(false)
                    }
                    setApiLoader(false)
                }
            }).catch((err) => {
                setApiLoader(false)
                console.log(err)
            });
        }
    }, [params])

    return (
        <>
            <Title name="Reset Password" />
            <div className="account-pages my-5 pt-sm-5">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-md-8 col-lg-6 col-xl-5">
                            {errorMsg &&
                                <Alert variant="danger">
                                    {errorMsg}
                                </Alert>
                            }
                            {successMsg &&
                                <Alert variant="success">
                                    {successMsg}
                                </Alert>
                            }
                            {apiLoader ? <Loader /> : <>
                                <div className="card overflow-hidden">
                                    <div className="card-body pt-0">
                                        <h3 className="text-center mt-4">
                                            <a href="/" className="logo logo-admin"><img src={Logo}
                                                height="100" alt="logo" /></a>
                                        </h3>
                                        {resetAllowed ? <>
                                            <div className="text-center">
                                                <h6>Forgot your password ?</h6>
                                                <p>Don&apos;t worry, just make new password...</p>
                                            </div>
                                            <div className="p-3">
                                                <form className="form-horizontal" action="/" onSubmit={onResetPasswordAction}>
                                                    <InputGroup className="mb-3">
                                                        <InputGroup.Text><i className="mdi mdi-key-outline" /></InputGroup.Text>
                                                        <FormControl
                                                            type="password"
                                                            value={resetData.password}
                                                            onChange={(e) => {
                                                                setResetData({
                                                                    ...resetData,
                                                                    password: ((e.target.value).toString().trim())
                                                                })
                                                            }}
                                                            placeholder="Enter New Password"
                                                            required
                                                        />
                                                    </InputGroup>

                                                    <InputGroup className="mb-3">
                                                        <InputGroup.Text><i className="mdi mdi-key-outline" /></InputGroup.Text>
                                                        <FormControl
                                                            type="password"
                                                            value={resetData.confirm_password}
                                                            onChange={(e) => {
                                                                setResetData({
                                                                    ...resetData,
                                                                    confirm_password: ((e.target.value).toString().trim())
                                                                })
                                                            }}
                                                            placeholder="Confirm Your Password"
                                                            required
                                                        />
                                                    </InputGroup>
                                                    <div className="form-group row mt-4">
                                                        <div className="col-12 text-center">
                                                            <button
                                                                className="btn btn-primary w-md waves-effect waves-light"
                                                                type="submit"
                                                                disabled={loader}
                                                            >
                                                                {loader ? 'Please wait...' : 'Reset Password'}
                                                            </button>
                                                        </div>
                                                    </div>
                                                    <div className="form-group mb-0 row">
                                                        <div className="col-12 mt-4">
                                                            <Link to="/" className="text-muted"><i className="mdi mdi-account"></i> Login?</Link>
                                                        </div>
                                                    </div>
                                                </form>
                                            </div>
                                        </> : <>
                                            <div className="text-center my-5">
                                                <p>Link Expired , Please go to Forgot Password and generate a new link...</p>
                                                <Link to="/forgot-password" className="text-muted mt-3"><i className="mdi mdi-account"></i> Forgot Password?</Link>
                                            </div>
                                        </>}

                                    </div>
                                </div>
                            </>}
                            <div className="mt-5 text-center">
                                <Footer />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export { ResetPassword }