/* eslint-disable react/prop-types */
import { Modal } from "react-bootstrap";
import "../../styles/Modal.scss";

function ConfirmationModal(props) {
    const {heading, promptMessage, confirmationResultCallback, confirmBtnText="yes", cancelBtnText="cancel", ...remainingProps} = props;
  return (
    <Modal centered className="alert-box-popup" {...remainingProps}>
      <Modal.Header closeButton>
        <Modal.Title>{heading}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>{promptMessage}</p>
        <div className="actions">
          <button
            className="btn btn-primary"
            onClick={() => confirmationResultCallback(true)}
          >
            {confirmBtnText}
          </button>
          <button
            className="btn btn-danger"
            onClick={() => confirmationResultCallback(false)}
          >
            {cancelBtnText}
          </button>
        </div>
      </Modal.Body>
    </Modal>
  );
}
export { ConfirmationModal };