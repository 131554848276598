/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import '../../../styles/Profile.scss'
import Breadcrumb from '../../common/Breadcrumb'
import Title from '../../common/Title'
import DataTableBase from '../../common/DataTableBase'
import React, { useState, useEffect } from 'react'
import HttpService from '../../../utils/http.service'
import { Postdata } from '../../../utils/api.service'
import { useDispatch } from 'react-redux'
import { message } from '../../../redux/actions/toastActions'
import { ConvertToDMYHIS } from '../../../utils/Helper';
import { Form, Collapse } from "react-bootstrap";
import CSV from '../../common/CSV'
import { Link } from 'react-router-dom'


function VIP_Membership_Report() {
    const [open, setOpen] = useState(false);
    const [isloading, setIsloading] = useState(true);
    const [data, setData] = useState([]);
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setperPage] = useState(10);
    const [currentPage, setcurrentPage] = useState(1);
    const [postdata, setPostdata] = useState({
        search_by_date: true
    });


    const [search, setSearch] = useState(false);
    const [refresh, setRefresh] = useState(false);
    const dispatch = useDispatch();

    const handlePerRowsChange = async (newPerPage, page) => {
        setperPage(newPerPage);
        setcurrentPage(page);
    };

    const handlePageChange = (page) => {
        setcurrentPage(page);
    };

    const columns = [
        {
            name: '#',
            width: "55px",
            selector: (row, i) => (currentPage * perPage + (i + 1) - perPage),
            center: true
        },
        {
            name: 'Profile',
            cell: (row) =>
                <Link to={`/user-management/view/${row.userData._id}`}>
                    <div className="avatar">
                        <img
                            alt={row._id}
                            src={row.userData.profile_pic}
                        />
                    </div>
                </Link>,
            width: "75px",
            center: true
        },
        {
            name: 'Username',
            cell: (row) =>
                <Link to={`/user-management/view/${row.userData._id}`}>
                    {row.userData.username}
                </Link>
        },
        {
            name: 'Mobile Number',
            selector: row => row.userData.mobile,
            center: true
        },
        {
            name: 'Purchase Date and Time',
            selector: row => ConvertToDMYHIS(row.createdAt),
            width: "250px",
            center: true
        },
        {
            name: 'Amount',
            selector: row => row.amount_paid,
            center: true
        },
        {
            name: 'Validity',
            selector: row => ConvertToDMYHIS(row.validity_till),
            center: true
        },

    ];


    useEffect(() => {
        setIsloading(true);
        const postBody = Object.fromEntries(Object.entries({
            ...postdata,
            page: currentPage,
            perpage: perPage,
        }).filter(([, value]) => value !== ''));
        Postdata(HttpService.Vip_membership_list_of_vip_users, postBody).then((response) => {
            console.log(response)
            if (response.status === 200) {
                console.log(response)
                setData(response.data.vip_users);
                setTotalRows(response.data.count);
                setIsloading(false);
            } else {
                setIsloading(false);
                dispatch(message(response.message));
            }
        }).catch((e) => {
            dispatch(message(e.message));
        });
    }, [currentPage, perPage, refresh, postdata]);


    return (
        <>
            <Title name='VIP Membership' />
            <div className='row'>
                <div className='col-lg-6'>
                    <h4>VIP Membership</h4>
                    <Breadcrumb
                        pages={[
                            { title: 'Reports', link: '' }
                        ]}
                        active="VIP Membership"
                    />
                </div>
                <div className="col-lg-6 text-end">
                    <button className="btn btn-danger" onClick={() => setOpen(!open)}>Search</button>
                    <CSV
                        link={HttpService.Vip_membership_list_of_vip_users}
                        name="VIP Membership"
                        response="vip_users"
                        headers={[
                            { label: "ID", key: "_id" },
                            { label: "Username", key: "username" },
                            { label: "Mobile Number", key: "mobile" },
                            { label: "Membership Date And Time", key: "vip_joined_date" },
                            { label: "Validity", key: "vip_validity" }
                        ]}
                    />
                </div>
            </div>

            <Collapse in={open}>
                <div>
                    <div className="card">
                        <p className="card-header">Filters</p>
                        <div className="card-body">
                            <div className="row">
                                <div className="col-lg-6">
                                    <Form.Group className="form-group">
                                        <Form.Label>Search</Form.Label>
                                        <Form.Control
                                            type="text"
                                            onChange={e => setPostdata({ ...postdata, search: e.target.value })}
                                        />
                                    </Form.Group>
                                </div>
                                {postdata.search_by_date === true ?
                                    <>
                                        <div className="col-lg-3">
                                            <Form.Group className="form-group mb-0">
                                                <Form.Label>From Date</Form.Label>
                                                <Form.Control
                                                    type="date"
                                                    onChange={e => setPostdata({ ...postdata, from_date: e.target.value })}
                                                />
                                            </Form.Group>
                                        </div>
                                        <div className="col-lg-3">
                                            <Form.Group className="form-group mb-0">
                                                <Form.Label>To Date</Form.Label>
                                                <Form.Control
                                                    type="date"
                                                    onChange={e => setPostdata({ ...postdata, to_date: e.target.value })}
                                                />
                                            </Form.Group>
                                        </div>
                                    </>
                                    : ''}
                            </div>
                        </div>
                    </div>
                </div>
            </Collapse>

            <div className='card'>
                <p className='card-header'>
                    List of VIP Members
                </p>

                <div className='card-body'>
                    <DataTableBase
                        columns={columns}
                        loader={isloading}
                        data={data}
                        paginationTotalRows={totalRows}
                        onChangeRowsPerPage={handlePerRowsChange}
                        onChangePage={handlePageChange}
                    />
                </div>

            </div>
        </>
    )
}

export { VIP_Membership_Report }