import { Footer } from "../include/Footer"
import Title from "../common/Title";
import { Link } from "react-router-dom";

const Error404 = () => {
  return (
    <>
      <Title name='404 Page not found' />
      <div className="errorPage">
        <h4 className="mb-4">404 - Page not Found...</h4>
        <Footer innerFooter={true} />
        <div className="mt-5">
          <Link className="btn btn-primary" to="/dashboard">Go to Dashboard</Link>
          <Link className="btn btn-danger" to="/">Logout</Link>
        </div>
      </div>
    </>
  )
}

export { Error404 }