/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import '../../../styles/Profile.scss'
import Breadcrumb from '../../common/Breadcrumb'
import Title from '../../common/Title'
import React, { useState, useEffect } from 'react'
import { Form, Collapse } from "react-bootstrap";
import DataTableBase from '../../common/DataTableBase'
import HttpService from '../../../utils/http.service';
import { Postdata } from '../../../utils/api.service'
import { useDispatch } from "react-redux";
import { message } from '../../../redux/actions/toastActions'
import CSV from "../../common/CSV";


function Leaderboard_List() {
    const [openSearch, setOpenSearch] = useState(false)
    const [search, setSearch] = useState('')
    const [data, setData] = useState([]);
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setperPage] = useState(process.env.REACT_APP_RecordPerPage);
    const [currentPage, setcurrentPage] = useState(1);
    const [isloading, setIsloading] = useState(true);
    const [refresh, setRefresh] = useState(false)
    const [postData, setPostData] = useState({
        type: 'monthly',
        game_type: 'all'
    })
    const [gameType, setGameTypeArr] = useState([])

    const dispatch = useDispatch()

    const columns = [
        {
            name: 'Rank',
            width: "55px",
            selector: (row, i) => (currentPage * perPage + (i + 1) - perPage),
            center: true
        },
        {
            name: 'Profile',
            cell: (row) =>
                <div className="avatar">
                    <img
                        alt={row.user_id}
                        src={row.profile_pic}
                    />
                </div>,
            width: "75px",
            center: true
        },
        {
            name: 'Username',
            selector: row => row.username,
            center: true
        },
        {
            name: 'Account Type',
            selector: row => row.private_mode ? 'Private' : 'Public',
            center: true
        },
        {
            name: 'Total Won Amount',
            selector: row => row.total,
            center: true
        }
    ];


    const handlePerRowsChange = async (newPerPage, page) => {
        setperPage(newPerPage);
        setcurrentPage(page);
    };

    const handlePageChange = (page) => {
        setcurrentPage(page);
    };

    useEffect(() => {
        Postdata(HttpService.Leaderboard_GameTypes, {}).then((res) => {
            if (res.status === 200) {
                setGameTypeArr(res.data);
            }
        }).catch((e) => {
            console.log(e)
        })
    }, [])

    useEffect(() => {
        setIsloading(true);
        Postdata(HttpService.Leaderboard_fetch, {
            page: currentPage,
            perpage: perPage,
            search,
            ...postData
        }).then((response) => {
            if (response.status === 200) {
                setData(response.data.data);
                setTotalRows(response.data.count);
                setIsloading(false);
            } else {
                setIsloading(false);
                dispatch(message(response.message));
            }
        }).catch((e) => {
            setIsloading(false);
            dispatch(message(e.message));
        });
    }, [currentPage, perPage, refresh, search, postData]);


    return (
        <>
            <Title name="Leaderboard" />
            <div className='row'>
                <div className='col-lg-6'>
                    <h4>Leaderboard</h4>
                    <Breadcrumb
                        active="Leaderboard"
                    />
                </div>
                <div className="col-lg-6 text-end">
                    <button className="btn btn-danger" onClick={() => setOpenSearch(!openSearch)}>Search</button>
                    <CSV
                        link={HttpService.Leaderboard_fetch}
                        name="Leaderboard"
                        response="data"
                        headers={[
                            { label: "Username", key: "username" },
                            { label: "Account Type", key: "private_mode" },
                            { label: "Total Won Amount", key: "total" }
                        ]}
                        extra={{
                            page: currentPage,
                            perpage: perPage,
                            search,
                            ...postData
                        }}
                    />
                </div>
            </div>

            <Collapse in={openSearch}>
                <div>
                    <div className="card">
                        <p className="card-header">Search</p>
                        <div className="card-body">
                            <div className='row'>
                                <div className='col-lg-3'>
                                    <Form.Group className="form-group mb-0">
                                        <Form.Label>Game</Form.Label>
                                        <Form.Select onChange={e => setPostData({
                                            ...postData,
                                            game_type: e.target.value
                                        })} >
                                            {gameType && gameType.length > 0 ?
                                                gameType.map((value) => {
                                                    return (
                                                        <option key={value.api_key} value={value.api_key}>{value.name}</option>
                                                    )
                                                })
                                                : ''}
                                        </Form.Select>
                                    </Form.Group>
                                </div>
                                <div className='col-lg-3'>
                                <Form.Label>Transaction Date</Form.Label>
                                        <Form.Select onChange={e => setPostData({ ...postData, type: e.target.value })}>
                                            <option value="monthly">Monthly</option>
                                            <option value="weekly">Weekly</option>
                                            <option value="daily">Daily</option>
                                            <option value="range">Select Date</option>
                                        </Form.Select>
                                </div>
                                {postData.type === 'range' ?
                                    <>
                                        <div className="col-lg-3">
                                            <Form.Group className="form-group mb-0">
                                                <Form.Label>From Date</Form.Label>
                                                <Form.Control
                                                    type="date"
                                                    onChange={e => setPostData({ ...postData, from_date: e.target.value })}
                                                />
                                            </Form.Group>
                                        </div>
                                        <div className="col-lg-3">
                                            <Form.Group className="form-group mb-0">
                                                <Form.Label>To Date</Form.Label>
                                                <Form.Control
                                                    type="date"
                                                    onChange={e => setPostData({ ...postData, to_date: e.target.value })}
                                                />
                                            </Form.Group>
                                        </div>
                                    </>
                                    : ''}
                            </div>
                        </div>
                    </div>
                </div>
            </Collapse>

            <div className='card'>
                <p className='card-header'>List of Leaderboard ||  Game Type : {postData.game_type.charAt(0).toUpperCase() + postData.game_type.slice(1)} || Duration : {postData.type.charAt(0).toUpperCase() + postData.type.slice(1)}  </p>
                <div className='card-body'>
                    <DataTableBase
                        columns={columns}
                        data={data}
                        paginationTotalRows={totalRows}
                        onChangeRowsPerPage={handlePerRowsChange}
                        onChangePage={handlePageChange}
                        loader={isloading}
                    />
                </div>
            </div>
        </>
    )
}

export { Leaderboard_List }