/* eslint-disable react/prop-types */
const Footer = (props) => {
  const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
  const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
  const d = new Date();
  const dayNameWithDate = days[d.getDay()] + ' - ' +
    [
      ('0' + d.getDate()).slice(-2),
      monthNames[d.getMonth()],
    ].join(' ') + ', ' +
    d.getFullYear();

  return (
    <>
      <p className={(props.innerFooter) ? 'innerFooter' : ''}>
        Copyright &copy; {new Date().getFullYear()} iGame365. All rights reserved.
        {/* Crafted with <i className="mdi mdi-heart text-danger"></i> by CaperMint&trade; */}
        {props.innerFooter && <>
          <br />
          {dayNameWithDate}
        </>}
      </p>
    </>
  )
}

export { Footer }