/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import '../../../styles/Profile.scss'
import Breadcrumb from '../../common/Breadcrumb'
import Title from '../../common/Title'
import DataTableBase from '../../common/DataTableBase'
import React, { useState, useEffect } from 'react'
import HttpService from '../../../utils/http.service'
import { Postdata } from '../../../utils/api.service'
import { useDispatch } from 'react-redux'
import { message } from '../../../redux/actions/toastActions'
import { ConvertToDMYHIS } from '../../../utils/Helper';
import { Link } from 'react-router-dom'
import { Form, Collapse } from "react-bootstrap";

function AllTransactionHistory() {
    const [roles, setRoles] = useState([]);
    const [open, setOpen] = useState(false);
    const [isloading, setIsloading] = useState(true);
    const [data, setData] = useState([]);
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setperPage] = useState(10);
    const [currentPage, setcurrentPage] = useState(1);
    const [postdata, setPostdata] = useState({
        search_by_date: ''
    });


    const [search, setSearch] = useState(false);
    const [refresh, setRefresh] = useState(false);
    const dispatch = useDispatch();

    const handlePerRowsChange = async (newPerPage, page) => {
        setperPage(newPerPage);
        setcurrentPage(page);
    };

    const handlePageChange = (page) => {
        setcurrentPage(page);
    };



    const columns = [
        {
            name: '#',
            width: "55px",
            selector: (row, i) => (currentPage * perPage + (i + 1) - perPage),
        },
        {
            name: 'Profile',
            cell: (row) =>
                <Link to={`/user-management/view/${row.user_id._id}`}>
                    <div className="avatar">
                        <img
                            alt={row.user_id._id[0]}
                            src={row.user_id.profile_pic[0]}
                        />
                    </div>
                </Link>,
            width: "75px",
        },
        {
            name: 'Username',
            cell: (row) =>
                <Link to={`/user-management/view/${row.user_id._id}`}>
                    {row.user_id.username[0]}
                </Link>
        },
        {
            name: 'Transaction ID',
            selector: row => row._id.toString(),
            width: "125px"
        },
        {
            name: 'Date',
            selector: row => ConvertToDMYHIS(row.createdAt),
        },
        {
            name: 'Amount',
            selector: row => row.coins_price,
        },
        {
            name: 'Verified By (Role)',
            cell : (row) => <>
            <span className="text-cap">
            { row?.approved_by_user_type != 'bank' && row?.approved_by_details?.length && row?.approved_by_details[0]?.username && row?.approved_by_user_type ? 
            <>
            {`${row?.approved_by_details[0]?.username || ''}(${row?.approved_by_user_type || ''})` || 'Bank'}
            </> : ''}
            </span>
            </>,
            center : true,
            width : '150px'
        },
        {
            name: 'Promocode',
            selector: row => row.promocode_id ? row.promocode_id.code : '-',
        },
        // {
        //     name: 'Game Name',
        //     selector: row => row.transfer_type === 'none' ? '-' : <span className="text-cap">{row.transfer_type}</span>,
        // },
        {
            name: 'Mode',
            selector: row => {
                let mode = row.history_type;
                if (mode === 'transfer_out') mode = 'out';
                if (mode === 'transfer_in') mode = 'in';
                return <span className="text-cap">{mode}</span>
            },
        },
        {
            name: 'Opening Balance',
            selector: row => row.prev_balance,
            width: "140px",
            centre: true
        },
        {
            name: 'Coins',
            selector: row => row.coins,
        },
        {
            name: 'Closing Balance',
            selector: row => row.new_balance,
            width: "125px"
        },
        {
            name: 'Status',
            selector: row => (row.status === 'approved' || !row.status) ? 'Success' : row.status,
        },
    ];

    useEffect(() => {
        setIsloading(true);
        const postBody = Object.fromEntries(Object.entries({
            ...postdata,
            page: currentPage,
            perpage: perPage,
        }).filter(([, value]) => value !== ''));
        Postdata(HttpService.Reports_all_transactions, postBody).then((response) => {
            console.log(response)
            if (response.status === 200) {
                setData(response.data.records);
                setTotalRows(response.data.total);
                setIsloading(false);
            } else {
                setIsloading(false);
                dispatch(message(response.message));
            }
        }).catch((e) => {
            dispatch(message(e.message));
        });
    }, [currentPage, perPage, refresh, postdata]);

    return (
        <>
            <Title name='Transactions History' />
            <div className='row'>
                <div className='col-lg-4'>
                    <h4>Transaction History</h4>
                    <Breadcrumb
                        pages={[
                            { title: 'Reports', link: '' }
                        ]}
                        active="Transaction History"
                    />
                </div>
                <div className="col-lg-8 text-end">
                    <button className="btn btn-danger" onClick={() => setOpen(!open)}>Search</button>
                </div>
            </div>
            <Collapse in={open}>
                <div>
                    <div className="card">
                        <p className="card-header">Filters</p>
                        <div className="card-body">
                            <div className="row">
                                <div className="col-lg-3">
                                    <Form.Group className="form-group">
                                        <Form.Label>Search</Form.Label>
                                        <Form.Control
                                            type="text"
                                            onChange={e => setPostdata({ ...postdata, search: e.target.value })}
                                        />
                                    </Form.Group>
                                </div>
                                <div className="col-lg-3">
                                    <Form.Group className="form-group mb-0">
                                        <Form.Label>Transaction Date</Form.Label>
                                        <Form.Select onChange={e => setPostdata({ ...postdata, search_by_date: e.target.value })}>
                                            <option value="">All</option>
                                            <option value="today">Today</option>
                                            <option value="week">Last Week</option>
                                            <option value="month">Last Month</option>
                                            <option value="year">Last Year</option>
                                            <option value="range">Select Date</option>
                                        </Form.Select>
                                    </Form.Group>
                                </div>
                                {postdata.search_by_date === 'range' ?
                                    <>
                                        <div className="col-lg-3">
                                            <Form.Group className="form-group mb-0">
                                                <Form.Label>From Date</Form.Label>
                                                <Form.Control
                                                    type="date"
                                                    onChange={e => setPostdata({ ...postdata, from_date: e.target.value })}
                                                />
                                            </Form.Group>
                                        </div>
                                        <div className="col-lg-3">
                                            <Form.Group className="form-group mb-0">
                                                <Form.Label>To Date</Form.Label>
                                                <Form.Control
                                                    type="date"
                                                    onChange={e => setPostdata({ ...postdata, to_date: e.target.value })}
                                                />
                                            </Form.Group>
                                        </div>
                                    </>
                                    : ''}
                            </div>
                        </div>
                    </div>
                </div>
            </Collapse>

            <div className='card'>
                <p className='card-header'>
                    List of Transactions
                </p>

                <div className='card-body'>
                    <DataTableBase
                        columns={columns}
                        loader={isloading}
                        data={data}
                        paginationTotalRows={totalRows}
                        onChangeRowsPerPage={handlePerRowsChange}
                        onChangePage={handlePageChange}
                    />
                </div>

            </div>
        </>
    )
}

export { AllTransactionHistory }