import * as types from "../constants/constants";
import { Postdata } from "../../utils/api.service"
import HttpService from "../../utils/http.service"

export const forgotpassword = (data) => {
  return (dispatch) => {
    try {
      dispatch(forgotpasswordInit());
      Postdata(HttpService.forgotpasswordUrl, data).then(
        (result) => {
          if (result.status === 200) {
            dispatch(forgotpasswordSuccess(result));
          } else {
            dispatch(forgotpasswordFail(result.message));
          }
        },
        (e) => {
          dispatch(forgotpasswordFail(e.message));
        }
      );
    } catch (error) {
      dispatch(forgotpasswordFail(error.message));
    }
  };
};

export const forgotpasswordInit = (data) => {
  return { type: types.FORGOTPASSWORD_DATA, data };
};
export const forgotpasswordSuccess = (data) => {
  return { type: types.FORGOTPASSWORD_DATA_SUCCESS, data };
};
export const forgotpasswordFail = (data) => {
  return { type: types.FORGOTPASSWORD_DATA_FAIL, data };
};
export const forgotpasswordClear = (data) => {
  return { type: types.FORGOTPASSWORD_DATA_CLEAR, data };
};