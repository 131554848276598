import Title from "../../common/Title";
import { useState, useEffect } from "react";
import { Get } from '../../../utils/api.service';
import DataTableBase from "../../common/DataTableBase";
import Link from "../../common/Link";
import HttpService from "../../../utils/http.service";
import { ConvertToDMYHIS } from "../../../utils/Helper";
import { useDispatch } from "react-redux";
import { message } from "../../../redux/actions/toastActions";
import Breadcrumb from "../../common/Breadcrumb";
import CSV from "../../common/CSV";

const RoleManagement = () => {
  const [data, setData] = useState([]);
  const [isloading, setIsloading] = useState(true);
  const dispatch = useDispatch();

  const columns = [
    {
      name: '#',
      width: "55px",
      selector: (row, i) => (i + 1),
    },
    {
      name: 'Name',
      selector: row => row.name,
    },
    // {
    //   name: 'Type',
    //   selector: row => <span className="text-cap">{row.type}</span>,
    // },
    {
      name: 'Updated',
      selector: row => ConvertToDMYHIS(row.updatedAt),
    },
    {
      name: 'Action',
      id: 'actions',
      cell: (row) =>
        <span className="actions">
          <Link to={`/role-management/edit/${row._id}`}><i className="mdi mdi-pen" /></Link>
        </span>,
      button: true,
    }
  ];

  useEffect(() => {
    setIsloading(true);
    Get(HttpService.Role_list, {}).then((response) => {
      if (response.status === 200) {
        setData(response.data);
        setIsloading(false);
      } else {
        setIsloading(false);
        dispatch(message(response.message));
      }
    }).catch((e) => {
      setIsloading(false);
      dispatch(message(e.message));
    });
  }, []);

  return (
    <>
      <Title name='Role Management' />

      <div className="row">
        <div className="col-lg-4">
          <h4>Role Management</h4>
          <Breadcrumb
            active="Role Management"
          />
        </div>
        <div className="col-lg-8 text-end">
          <CSV
            link={HttpService.Role_list}
            name="Role Management"
            action="get"
            headers={[
              { label: "Name", key: "name" },
              { label: "Updated At", key: "updatedAt" },
            ]}
          />
        </div>
      </div>

      <div className="card">
        <p className="card-header">List of Roles</p>
        <div className="card-body">
          <DataTableBase
            columns={columns}
            loader={isloading}
            data={data}
            pagination={false}
          />
        </div>
      </div>
    </>
  )
}

export { RoleManagement }