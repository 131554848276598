/* eslint-disable react/prop-types */
import Title from "../../common/Title";
import { useState, useEffect } from "react";
import { Postdata } from '../../../utils/api.service';
import DataTableBase from "../../common/DataTableBase";
import HttpService from "../../../utils/http.service";
import { useDispatch } from "react-redux";
import { message } from "../../../redux/actions/toastActions";
import Breadcrumb from "../../common/Breadcrumb";
import { useParams } from "react-router-dom";
import Loader from "../../common/Loader";
import { ConvertToDMYHIS } from "../../../utils/Helper";
import ModalPopup from "../../common/Modal";

const Players = ({ data, match, points }) => {
  const players_points = [...match.teama.players, ...match.teamb.players];
  const players = players_points.filter(e => data.players.includes(e.pid));
  const substitute = players_points.filter(e => data.substitute.includes(e.pid));

  const columns = [
    {
      name: '#',
      width: "45px",
      selector: (row, i) => i + 1,
    },
    {
      name: 'Profile',
      width: "65px",
      selector: row => <div className="avatar">
        <img
          alt={row.title}
          src={row.thumb_url}
        />
      </div>,
    },
    {
      name: 'Name',
      selector: row => row.title,
    },
    {
      name: '',
      width: "45px",
      selector: row => `${row.pid == data.captain ? 'C' : ''}${row.pid == data.vicecaptain ? 'VC' : ''}`
    },
    {
      name: 'Points',
      width: "55px",
      selector: row => row.fantasy_player_rating,
    },
    {
      name: 'Earned Points',
      width: "65px",
      selector: row => {
        let returns = 0;
        let pointreturn = points.filter(e => e.pid == row.pid);
        if (pointreturn.length) {
          returns = pointreturn[0].point;
          if (row.pid == data.captain) returns = pointreturn[0].point * 2;
          if (row.pid == data.vicecaptain) returns = pointreturn[0].point * 1.5;
        }
        return parseFloat(returns).toFixed(2);
      },
    },
    {
      name: 'Role',
      selector: row => <span style={{ textTransform: 'uppercase' }}>{row.playing_role}</span>,
    }
  ];

  return <>
    <DataTableBase
      columns={columns}
      pagination={false}
      data={players.sort((b, a) => a.fantasy_player_rating - b.fantasy_player_rating)}
    />

    {substitute.length ?
      <>
        <h5 className="mt-4">Substitute Players</h5>
        <DataTableBase
          columns={columns}
          pagination={false}
          data={substitute.sort((b, a) => a.fantasy_player_rating - b.fantasy_player_rating)}
        />
      </>
      : ''}
  </>
}

const MatchUsers = () => {
  const [data, setData] = useState([]);
  const [contest, setContest] = useState([]);
  const [match, setMatch] = useState([]);
  const [points, setPoints] = useState([]);
  const [total, setTotal] = useState(0);
  const [isloading, setIsloading] = useState(true);

  const [isloadingUser, setIsloadingUser] = useState(true);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setperPage] = useState(process.env.REACT_APP_RecordPerPage);
  const [currentPage, setcurrentPage] = useState(1);

  const [modalswitch, setmodalswitch] = useState(false);
  const [modalswitchcontent, setmodalswitchcontent] = useState({});

  const dispatch = useDispatch();
  const { id } = useParams();

  const columns = [
    {
      name: 'Rank',
      width: "55px",
      selector: (row, i) => (currentPage * perPage + (i + 1) - perPage),
    },
    {
      name: 'Profile',
      cell: (row) =>
        <div className="avatar">
          <img
            alt={row.user_id.username}
            src={row.user_id.profile_pic}
          />
        </div>,
    },
    {
      name: 'Username',
      selector: row => row.user_id.username,
    },
    {
      name: 'Points',
      selector: row => row.score,
    },
    {
      name: 'Created At',
      selector: row => ConvertToDMYHIS(row.createdAt),
    },
    {
      name: 'Action',
      id: 'actions',
      cell: (row) =>
        <span className="actions">
          <i onClick={() => handleStatusChange({ players: row.players, substitute: row.substitute, captain: row.captain, vicecaptain: row.vicecaptain })} className="mdi mdi-account-multiple" />
        </span>,
      button: true,
    }
  ];


  const handleStatusChange = (_d) => {
    setmodalswitchcontent(_d);
    setmodalswitch(true);
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    setperPage(newPerPage);
    setcurrentPage(page);
  };

  const handlePageChange = (page) => {
    setcurrentPage(page);
  };

  useEffect(() => {
    setIsloading(true);
    Postdata(HttpService.Match_users, { id: id }).then((response) => {
      if (response.status === 200) {
        setTotal(response.data.total_joined);
        setMatch(response.data.match);
        setPoints(response.data.player_points);
        setContest(response.data.contest);
        setIsloading(false);
      } else {
        setIsloading(false);
        dispatch(message(response.message));
      }
    }).catch((e) => {
      setIsloading(false);
      dispatch(message(e.message));
    });
  }, []);

  useEffect(() => {
    setIsloadingUser(true);
    Postdata(HttpService.Match_users_list, {
      id: id,
      page: currentPage,
      perpage: perPage
    }).then((response) => {
      if (response.status === 200) {
        setData(response.data.users);
        setTotalRows(response.data.count);
        setIsloadingUser(false);
      } else {
        setIsloadingUser(false);
        dispatch(message(response.message));
      }
    }).catch((e) => {
      setIsloadingUser(false);
      dispatch(message(e.message));
    });
  }, [currentPage, perPage]);

  return (
    <>
      <Title name='Match Management' />

      <ModalPopup
        className="alert-box-popup"
        show={modalswitch}
        size="md"
        onHide={() => setmodalswitch(false)}
        heading={`Players`}
      >
        <Players
          data={modalswitchcontent}
          match={match}
          points={points}
        />
      </ModalPopup>

      <div className="row">
        <div className="col-lg-6">
          <h4>Match Management</h4>
          <Breadcrumb
            pages={[
              { title: 'Match Management', link: 'match' },
              { title: 'Contest', link: `match/contest/${match._id}` }
            ]}
            active='Players'
          />
        </div>
        <div className="col-lg-6 text-end">
          {/* <button className="btn btn-danger" onClick={() => setOpen(!open)}>Filters</button> */}
        </div>
      </div>

      <div className="row">
        <div className="col-lg-3">
          <div className="card">
            <p className="card-header">Contest Details</p>
            <div className="card-body">
              {isloading ?
                <Loader />
                : <>
                  <div className="profile-page">
                    <div className="user">
                      <div className="details removeStyle">
                        <span><b>Name:</b>{contest.title}&nbsp;</span>
                        <span><b>Winners:</b>{contest.no_of_winners}&nbsp;</span>
                        <span><b>Prize Pool:</b>{contest.prize_pool}&nbsp;</span>
                        <span><b>Total Team:</b>{contest.total_team}&nbsp;</span>
                        <span><b>Join Team:</b>{total}&nbsp;</span>
                        <span><b>Entry Fees:</b>{contest.entry_fee}&nbsp;</span>
                      </div>
                    </div>
                  </div>
                </>
              }
            </div>
          </div>
          <div className="card">
            <p className="card-header">Match Details</p>
            <div className="card-body">
              {isloading ?
                <Loader />
                : <>
                  <div className="profile-page">
                    <div className="user">
                      <div className="details removeStyle">
                        <span className="text-cap"><b>Sport:</b>{match.sports}&nbsp;</span>
                        <span><b>Match:</b>{match.short_title}&nbsp;</span>
                        <span><b>Team A:</b>{match.teama?.name}&nbsp;</span>
                        <span><b>Team B:</b>{match.teamb?.name}&nbsp;</span>
                        <span><b>Match Time:</b>{ConvertToDMYHIS(match.date_start_ist)}&nbsp;</span>
                        <span><b>Match Type:</b>{match.format_str}&nbsp;</span>
                        <span><b>Status:</b>{match.status_note}&nbsp;</span>
                      </div>
                    </div>
                  </div>
                </>
              }
            </div>
          </div>
        </div>

        <div className="col-lg-9">
          <div className="card">
            <p className="card-header">List of Players</p>
            <div className="card-body">
              <DataTableBase
                columns={columns}
                loader={isloadingUser}
                data={data}
                paginationTotalRows={totalRows}
                onChangeRowsPerPage={handlePerRowsChange}
                onChangePage={handlePageChange}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export { MatchUsers }