/* eslint-disable no-unused-vars */
import Title from "../../common/Title";
import { Form, Collapse } from "react-bootstrap";
import { useState, useEffect } from "react";
import { Postdata } from '../../../utils/api.service';
import DataTableBase from "../../common/DataTableBase";
import HttpService from "../../../utils/http.service";
import { useDispatch, useSelector } from "react-redux";
import { message } from "../../../redux/actions/toastActions";
import Breadcrumb from "../../common/Breadcrumb";
// import CSV from "../../common/CSV";
import { ConvertToDMY } from "../../../utils/Helper";
import ModalPopup from "../../common/Modal";
import { Link } from 'react-router-dom'
import { isData } from "../../../redux/selectors/authSelector";

function Earning_Report() {
    const dispatch = useDispatch();
    const [open, setOpen] = useState(false);
    const [playerSearch, setPlayerSearch] = useState(false);
    const [roles, setRoles] = useState([]);
    const [data, setData] = useState([]);
    const [isloading, setIsloading] = useState(true);
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setperPage] = useState(process.env.REACT_APP_RecordPerPage);
    const [currentPage, setcurrentPage] = useState(1);

    const [playerTotalRows, setPlayerTotalRows] = useState(0);
    const [playerPerPage, setPlayerPerPage] = useState(10);
    const [playerCurrentPage, setPlayerCurrentPage] = useState(1);

    const [refresh, setRefresh] = useState(false);
    const [postdata, setPostdata] = useState({
        search_by: '',
        sort_by: 'grossRevenue',
        sort_order: '1',
        search_by_date: 'month'
    });
    const [playerpostdata, setPlayerPostdata] = useState({
        search: '',
        sort_by: 'grossRevenue',
        sort_order: '1',
        search_by_date: 'month'
    });
    const [agentDetails, setAgentDetails] = useState('')

    const [subAgentsData, setSubAgentsData] = useState([])
    const [isSubAgentLoading, setSubAgentLoading] = useState(false)

    const [currentLevel, setCurrentLevel] = useState(0)

    const [betView, setBetView] = useState(false)
    const [betData, setBetData] = useState({})

    const [startSearch, setStartSearch] = useState(false)

    const [omitTables, setOmitTables] = useState({
        totalBets: true,
        myCommisson: true,
        totalCommisson: true,
        totalLoss: true,
        totalEarning: true,
        netRevenue: true,
        grossRevenue: true
    })

    const userData = useSelector(isData)

    const handlePerRowsChange = async (newPerPage, page) => {
        setperPage(newPerPage);
        setcurrentPage(page);
    };

    const handlePageChange = (page) => {
        setcurrentPage(page);
    };

    const handlePlayerPerRowsChange = async (newPerPage, page) => {
        setPlayerPerPage(newPerPage);
        setPlayerCurrentPage(page);
    };

    const handlePlayerPageChange = (page) => {
        setPlayerCurrentPage(page);
    };

    const columns = [
        {
            name: '#',
            // selector: row => row.user_id,
            width: "55px",
            cell: (row, i) => <>
                {row?.type != 'total' ? (currentPage * perPage + (i + 1) - perPage) : ''}
            </>
        },
        // {
        //     name: 'Profile',
        //     cell: (row) =>
        //         <>
        //             {row?.type != 'total' ?
        //                 <div className="avatar">
        //                     <img
        //                         alt={row._id}
        //                         src={row.topParent.profile_pic}
        //                     />
        //                 </div> : ''}
        //         </>,
        //     width: "75px",
        // },
        {
            name: 'Username',
            cell: (row) =>
                <>
                    {row?.type != 'total' ? <div style={{ cursor: "pointer", color: "blue" }} onClick={() => setAgentDetails({
                        ...row?.topParent
                    })}>
                        {row.topParent?.username || ''}
                    </div> : ''}
                </>
        },
        {
            name: 'Role',
            selector: row => row?.type != 'total' ? currentLevel == 4 ? 'Super Agent' : currentLevel == 3 ? 'Top Agent' : currentLevel == 2 ? 'Commercial Agent' : currentLevel == 1 ? 'Agent' : '' : <b>Total</b>,
        },
        {
            name: 'Registration Date',
            selector: row => ConvertToDMY(row?.topParent?.createdAt) || '',

        },
        {
            name: 'Deposits',
            selector: row => row?.type != 'total' ? parseFloat(row.totalDeposits).toFixed(2) || '00' : <b>{parseFloat(row.totalDeposits).toFixed(2) || '00'}</b>,
        },
        {
            name: 'Withdraws',
            selector: row => row?.type != 'total' ? parseFloat(row.totalWithdrawals).toFixed(2) || '00' : <b>{parseFloat(row.totalWithdrawals).toFixed(2) || '00'}</b>
        },
        {
            name: 'Bets',
            selector: row => row?.type != 'total' ? parseFloat(row.totalBets).toFixed(2) || '00' : <b>{parseFloat(row.totalBets).toFixed(2) || '00'}</b>
        },
        {
            name: 'Bet Amount',
            cell: (row) =>
                <>
                    {row?.type != 'total' ? <div style={{ cursor: "pointer" }} onClick={() => {
                        setOmitTables({
                            totalBets: false,
                            myCommisson: true,
                            totalCommisson: true,
                            totalLoss: true,
                            totalEarning: true,
                            netRevenue: true,
                            grossRevenue: true
                        })
                        setView({
                            ...row.topParent,
                            view_type: "agent"
                        })
                    }}>
                        {parseFloat(row.totalBetAmount).toFixed(2) || '00'}
                    </div> : <b> {parseFloat(row.totalBetAmount).toFixed(2) || '00'} </b>}
                </>
        },
        // {
        //     name: 'Costs',
        //     selector: row => '00',
        // },
        {
            name: 'Commisson',
            cell: (row) =>
                <>
                    {row?.type != 'total' ? <div style={{ cursor: "pointer" }} onClick={() => {
                        setOmitTables({
                            totalBets: true,
                            myCommisson: false,
                            totalCommisson: false,
                            totalLoss: true,
                            totalEarning: true,
                            netRevenue: true,
                            grossRevenue: true
                        })
                        setView({
                            ...row.topParent,
                            view_type: "agent"
                        })
                    }}>
                        {row?.myCommission ? parseFloat(row?.myCommission).toFixed(2) || '00' : '00'}
                    </div> : ''}
                </>
        },
        // {
        //     name: 'Total Commission',
        //     cell: (row) =>
        //         <>
        //             {row?.type != 'total' ? <div>
        //                   {row?.totalCommisson ? parseFloat(row?.totalCommisson).toFixed(2) || '00' : '00'}
        //             </div> : <b>  {row?.totalCommisson ? parseFloat(row?.totalCommisson).toFixed(2) || '00' : '00'} </b>}
        //         </>,
        //         center : true
        // },
        {
            name: 'Total Loss',
            cell: (row) =>
                <>
                    {row?.type != 'total' ? <div style={{ cursor: "pointer" }} onClick={() => {
                        setOmitTables({
                            totalBets: true,
                            myCommisson: true,
                            totalCommisson: true,
                            totalLoss: false,
                            totalEarning: true,
                            netRevenue: true,
                            grossRevenue: true
                        })
                        setView({
                            ...row.topParent,
                            view_type: "agent"
                        })
                    }}>
                        {parseFloat(row.totalWinning).toFixed(2) || '00'}
                    </div> : <b> {parseFloat(row.totalWinning).toFixed(2) || '00'} </b>}
                </>
        },
        {
            name: 'Total Earning',
            // selector: row => row.totalLoss || '00',
            cell: (row) =>
                <>
                    {row?.type != 'total' ? <div style={{ cursor: "pointer" }} onClick={() => {
                        setOmitTables({
                            totalBets: true,
                            myCommisson: true,
                            totalCommisson: true,
                            totalLoss: true,
                            totalEarning: false,
                            netRevenue: true,
                            grossRevenue: true
                        })
                        setView({
                            ...row.topParent,
                            view_type: "agent"
                        })
                    }}>
                        {(row.totalLoss < 0 ? -1 * parseFloat(row.totalLoss).toFixed(2) : parseFloat(row.totalLoss).toFixed(2)) || '00'}
                    </div> : <b> {(row.totalLoss < 0 ? -1 * parseFloat(row.totalLoss).toFixed(2) : parseFloat(row.totalLoss).toFixed(2)) || '00'} </b>}
                </>
        },
        {
            name: 'Gross Revenue',
            cell: (row) =>
                <>
                    {row?.type != 'total' ?
                        <div style={(row.grossRevenue > 0 ? { color: "red", cursor: 'pointer' } : (row.grossRevenue < 0 ? { color: "green", cursor: 'pointer' } : null))} onClick={() => {
                            setView({
                                ...row.topParent,
                                view_type: "agent"
                            })

                            setOmitTables({
                                totalBets: true,
                                myCommisson: true,
                                totalCommisson: true,
                                totalLoss: true,
                                totalEarning: true,
                                netRevenue: true,
                                grossRevenue: false
                            })
                        }} >

                            {(row.netRevenue < 0 ?
                                -1 * parseFloat(row.grossRevenue).toFixed(2) :
                                -1 * parseFloat(row.grossRevenue).toFixed(2)) || '00'}

                        </div> :
                        <b style={(row.grossRevenue > 0 ?
                            { color: "red" } :
                            (row.grossRevenue < 0 ? { color: "green" } : null))}>

                            {(row.grossRevenue < 0 ? -1 * parseFloat(row.grossRevenue).toFixed(2) : -1 * parseFloat(row.grossRevenue).toFixed(2)) || '00'} </b>}
                </>
        },
        {
            name: 'Net Revenue',
            cell: (row) =>
                <>
                    {row?.type != 'total' ? <div style={(row.netRevenue > 0 ? { color: "red", cursor: 'pointer' } : (row.netRevenue < 0 ? { color: "green", cursor: 'pointer' } : null))} onClick={() => {
                        setOmitTables({
                            totalBets: true,
                            myCommisson: true,
                            totalCommisson: true,
                            totalLoss: true,
                            totalEarning: true,
                            netRevenue: false,
                            grossRevenue: true
                        })
                        setView({
                            ...row.topParent,
                            view_type: "agent"
                        })
                    }}>
                        {(row.netRevenue < 0 ? -1 * parseFloat(row.netRevenue).toFixed(2) : -1 * parseFloat(row.netRevenue).toFixed(2)) || '00'}
                    </div> : <b style={(row.netRevenue > 0 ? { color: "red" } : (row.netRevenue < 0 ? { color: "green" } : null))}> {(row.netRevenue < 0 ? -1 * parseFloat(row.netRevenue).toFixed(2) : -1 * parseFloat(row.netRevenue).toFixed(2)) || '00'} </b>}
                </>
            // selector: row => row.netRevenueAmount || '00',
        },
        {
            name: 'Action',
            id: 'actions',
            cell: (row) =>
                <>
                    {row?.type != 'total' ? <span className="actions">
                        <i onClick={() => {
                            setOmitTables({
                                totalBets: false,
                                myCommisson: false,
                                totalCommisson: false,
                                totalLoss: false,
                                totalEarning: false,
                                netRevenue: false,
                                grossRevenue: false
                            })
                            setView({
                                ...row.topParent,
                                view_type: "agent"
                            })
                        }} className="mdi mdi-eye" />
                    </span> : ''}
                </>,
            button: true,
        }
    ];

    // 2nd Table - Player / sub-agent 
    const subAgentColumns = [
        {
            name: '#',
            // selector: row => row.user_id,
            width: "55px",
            selector: (row, i) => row?.type != 'total' ? (currentPage * perPage + (i + 1) - perPage) : '',
        },
        // {
        //     name: 'Profile',
        //     cell: (row) =>
        //         <>
        //             {row?.type != 'total' ?
        //                 <div className="avatar">
        //                     <img
        //                         alt={row._id}
        //                         src={row?.profile_pic}
        //                     />
        //                 </div> : ''}
        //         </>,
        //     width: "75px",
        // },
        {
            name: 'Username',
            cell: (row) =>
                <>
                    {row?.type != 'total' ? <div >
                        {row.username}
                    </div> : <b>Total</b>}

                </>
        },
        {
            name: 'Role',
            selector: row => row?.role === 'user' ? 'Player' : '' || '-',
        },
        {
            name: 'Registration Date',
            selector: row => ConvertToDMY(row?.createdAt) || '',
        },
        {
            name: 'Deposits',
            selector: row => row?.type != 'total' ? parseFloat(row.totalDeposit).toFixed(2) || '-' : <b>{parseFloat(row.totalDeposit).toFixed(2) || '00'}</b>,
        },
        {
            name: 'Withdraws',
            selector: row => row?.type != 'total' ? parseFloat(row.totalWithdraw).toFixed(2) || '-' : <b>{parseFloat(row.totalWithdraw).toFixed(2) || '00'}</b>
        },
        {
            name: 'Bet Amount',
            cell: (row) => <>
                <div>
                    {row?.type != 'total' ?
                        <div style={{ cursor: 'pointer' }} onClick={() => {
                            setView({
                                ...row,
                                view_type: "player"
                            })

                            setOmitTables({
                                totalBets: false,
                                myCommisson: true,
                                totalCommisson: true,
                                totalLoss: true,
                                totalEarning: true,
                                netRevenue: true,
                                grossRevenue: true
                            })
                        }}>
                            {parseFloat(row.totalBets).toFixed(2) || '-'}
                        </div>
                        : <b>{parseFloat(row.totalBets).toFixed(2) || '00'}</b>}
                </div>
            </>
        },
        // {
        //     name: 'Costs',
        //     selector: row => '00',
        // },
        {
            name: 'Commisson',
            cell: (row) => <>
                {'0'}
            </>,
            center: true
        },
        // {
        //     name: 'Total Commisson',
        //     cell : (row) => <>
        //     <div>
        //         {row?.type != 'total' ? 
        //         <div style={{cursor : 'pointer'}} onClick={() => {
        //             setView({
        //                 ...row,
        //                 view_type : "player"
        //             })

        //             setOmitTables({
        //                 totalBets: true,
        //                 myCommisson: false,
        //                 totalCommisson: false,
        //                 totalLoss: true,
        //                 totalEarning: true,
        //                 netRevenue: true,
        //                 grossRevenue : true
        //             })
        //         }}>
        //             {parseFloat(row.totalCommisson).toFixed(2) || '-'}
        //         </div>
        //          : <b>{parseFloat(row.totalCommisson).toFixed(2) || '00'}</b>}
        //     </div>
        //     </>
        // },
        {
            name: 'Total Loss',
            cell: (row) => <>
                <div>
                    {row?.type != 'total' ?
                        <div style={{ cursor: 'pointer' }} onClick={() => {
                            setView({
                                ...row,
                                view_type: "player"
                            })

                            setOmitTables({
                                totalBets: true,
                                myCommisson: true,
                                totalCommisson: true,
                                totalLoss: false,
                                totalEarning: true,
                                netRevenue: true,
                                grossRevenue: true
                            })
                        }}>
                            {parseFloat(row.totalWinning).toFixed(2) || '-'}
                        </div>
                        : <b>{parseFloat(row.totalWinning).toFixed(2) || '00'}</b>}
                </div>
            </>
        },
        {
            name: 'Total Earning',
            cell: (row) => <>
                <div>
                    {row?.type != 'total' ?
                        <div style={{ cursor: 'pointer' }} onClick={() => {
                            setView({
                                ...row,
                                view_type: "player"
                            })

                            setOmitTables({
                                totalBets: true,
                                myCommisson: true,
                                totalCommisson: true,
                                totalLoss: true,
                                totalEarning: false,
                                netRevenue: true,
                                grossRevenue: true
                            })
                        }}>
                            {parseFloat(row.totalLoss).toFixed(2) || '-'}
                        </div>
                        : <b>{parseFloat(row.totalLoss).toFixed(2) || '00'}</b>}
                </div>
            </>
        },
        {
            name: 'Gross Revenue',
            cell: (row) =>
                <>
                    {row?.type != 'total' ?
                        <div style={(row.grossRevenue > 0 ? { color: "red", cursor: 'pointer' } : (row.grossRevenue < 0 ? { color: "green", cursor: 'pointer' } : null))} onClick={() => {
                            setView({
                                ...row,
                                view_type: "player"
                            })

                            setOmitTables({
                                totalBets: true,
                                myCommisson: true,
                                totalCommisson: true,
                                totalLoss: true,
                                totalEarning: true,
                                grossRevenue: false,
                                netRevenue: true,
                            })
                        }} >

                            {(row.netRevenue < 0 ?
                                -1 * parseFloat(row.grossRevenue).toFixed(2) :
                                -1 * parseFloat(row.grossRevenue).toFixed(2)) || '00'}

                        </div> :
                        <b style={(row.grossRevenue > 0 ?
                            { color: "red" } :
                            (row.grossRevenue < 0 ? { color: "green" } : null))}>

                            {(row.grossRevenue < 0 ? -1 * parseFloat(row.grossRevenue).toFixed(2) : -1 * parseFloat(row.grossRevenue).toFixed(2)) || '00'} </b>}
                </>
        },
        {
            name: 'Net Revenue',
            cell: (row) =>
                <>
                    {row?.type != 'total' ?
                        <div style={(row.netRevenue > 0 ? { color: "red", cursor: 'pointer' } : (row.netRevenue < 0 ? { color: "green", cursor: 'pointer' } : null))} onClick={() => {
                            setView({
                                ...row,
                                view_type: "player"
                            })

                            setOmitTables({
                                totalBets: true,
                                myCommisson: true,
                                totalCommisson: true,
                                totalLoss: true,
                                totalEarning: true,
                                grossRevenue: true,
                                netRevenue: false,
                            })
                        }} >

                            {(row.netRevenue < 0 ?
                                -1 * parseFloat(row.netRevenue).toFixed(2) :
                                -1 * parseFloat(row.netRevenue).toFixed(2)) || '00'}

                        </div> :
                        <b style={(row.netRevenue > 0 ?
                            { color: "red" } :
                            (row.netRevenue < 0 ? { color: "green" } : null))}>

                            {(row.netRevenue < 0 ? -1 * parseFloat(row.netRevenue).toFixed(2) : -1 * parseFloat(row.netRevenue).toFixed(2)) || '00'} </b>}
                </>
        },
        {
            name: 'Action',
            id: 'actions',
            cell: (row) =>
                <>
                    {row.type != 'total' ?
                        <span className="actions">
                            <i onClick={() => {
                                setView({
                                    ...row,
                                    view_type: "player"
                                })
                                setOmitTables({
                                    totalBets: false,
                                    myCommisson: true,
                                    totalCommisson: false,
                                    totalLoss: false,
                                    totalEarning: false,
                                    netRevenue: false,
                                    grossRevenue: false
                                })
                            }} className="mdi mdi-eye" />
                        </span> : ''}
                </>,
            button: true,
        }
    ];

    // Modal for game wise details 
    const betDetailsColumns = [
        {
            name: 'Game',
            selector: row => <span className="text-cap">{row._id}</span> || '-',
        },
        {
            name: 'Total Bets',
            cell: (row) => <div>
                {row.totalBets || '00'}
            </div>,
            omit: omitTables.totalBets
            // selector: row => row.totalBets || '00',
        },
        {
            name: 'Commission',
            cell: (row) => <div>
                {parseFloat(row.totalCommisson).toFixed(2) || '00'}
            </div>,
            omit: omitTables.totalCommisson
            // selector: row => parseFloat(row.totalCommisson).toFixed(2) || '00',
        },
        {
            name: 'Total Loss',
            cell: (row) => <div>
                {parseFloat(row.totalWinnings).toFixed(2) || '00'}
            </div>,
            omit: omitTables.totalLoss
            // selector: row => parseFloat(row.totalWinnings).toFixed(2) || '00',
        },
        {
            name: 'Total Earning',
            cell: (row) => <div>
                {row.totalLoss < 0 ? (parseFloat(row.totalLoss).toFixed(2) * -1) : row.totalLoss}
            </div>,
            omit: omitTables.totalEarning
            // selector: row => row.totalLoss || '00',
        },
        {
            name: 'Gross Revenue',
            cell: (row) => <>
                <div style={row.grossRevenue > 0 ? { color: "red" } : (row.grossRevenue < 0 ? { color: "green" } : null)}>
                    {row.grossRevenue < 0 ? (parseFloat(row.grossRevenue).toFixed(2) * -1) : parseFloat(row.grossRevenue).toFixed(2) * -1}
                </div>
            </>,
            omit: omitTables.grossRevenue
        },
        {
            name: 'Net Revenue',
            cell: (row) => <>
                <div style={row.netRevenue > 0 ? { color: "red" } : (row.netRevenue < 0 ? { color: "green" } : null)}>
                    {row.netRevenue < 0 ? (parseFloat(row.netRevenue).toFixed(2) * -1) : parseFloat(row.netRevenue).toFixed(2) * -1}
                </div>
            </>,
            omit: omitTables.netRevenue
            // selector: row => row.netRevenue || '00',

        },
    ];

    const setView = (data) => {
        let search_data = {}
        if (data.view_type === 'agent') {
            search_data = postdata
        } else {
            search_data = {
                player_search: playerpostdata.search,
                player_sort_by: playerpostdata.sort_by,
                player_sort_order: playerpostdata.sort_order,
                player_search_by_date: playerpostdata.search_by_date,
                player_search_from_date: playerpostdata?.player_search_from_date || '',
                player_search_to_date: playerpostdata?.player_search_to_date || ''
            }
        }

        Postdata(HttpService.Reports_earnings_game_wise_transaction, {
            id: (data.view_type === 'agent' ? data._id : data.user_id),
            view_type: data.view_type,
            ...search_data
        }).then((response) => {
            if (response.status === 200) {
                setBetData(response.data.data);
                setBetView(true)
            } else {
                dispatch(message(response.message));
            }
        }).catch((e) => {
            dispatch(message(e.message));
        });
    }

    useEffect(() => {
        if (agentDetails) {
            if (currentLevel == 1) {
                setSubAgentLoading(true)
            } else {
                setIsloading(true)
            }
            Postdata(HttpService.Reports_earnings_sub_agents_list, {
                id: agentDetails._id,
                page: currentPage,
                perpage: perPage,
                ...postdata,
                player_page: playerCurrentPage,
                player_perpage: playerPerPage,
                player_search: playerpostdata.search,
                player_sort_by: playerpostdata.sort_by,
                player_sort_order: playerpostdata.sort_order,
                player_search_by_date: playerpostdata.search_by_date,
                player_search_from_date: playerpostdata?.player_search_from_date || '',
                player_search_to_date: playerpostdata?.player_search_to_date || ''

            }).then((response) => {
                if (response.status === 200) {
                    if (response.data.currentLevel == 0) {
                        // console.log(response.data.data)
                        setSubAgentsData(response.data.data);
                        setSubAgentLoading(false)
                        setPlayerTotalRows(response.data.count)
                    } else {
                        // console.log(response.data.data)
                        setData(response.data.data);
                        setCurrentLevel(response.data.currentLevel)
                        setTotalRows(response.data.count);
                        setIsloading(false);
                    }
                    dispatch(message(response.message));
                }
            }).catch((e) => {
                dispatch(message(e.message));
            });
        }
    }, [agentDetails, playerpostdata, currentPage, perPage, playerPerPage, playerCurrentPage]);

    useEffect(() => {
        setIsloading(true);
        const postBody = Object.fromEntries(Object.entries({
            ...postdata,
            page: currentPage,
            perpage: perPage,
        }).filter(([, value]) => value !== ''));
        Postdata(HttpService.Reports_earnings_agents_list, postBody).then((response) => {
            // console.log(response)
            if (response.status === 200) {
                // console.log(response.data.earning_data)
                setData(response.data.earning_data);
                setRoles(response.data.search_roles);
                setTotalRows(response.data.count);
                setCurrentLevel(response.data.currentLevel)
                setIsloading(false);
            } else {
                setIsloading(false);
                dispatch(message(response.message));
            }
        }).catch((e) => {
            setIsloading(false);
            dispatch(message(e.message));
        });
    }, [currentPage, perPage, refresh, startSearch]);

    useEffect(() => {
        if (userData?.user?.agents_id?.length == 3) {
            setAgentDetails(userData.user)
        }
    }, [])

    return (
        <>
            <Title name='Earnings Report' />

            <div className="row">
                <div className="col-lg-6">
                    <h4>Earnings Report</h4>
                    <Breadcrumb
                        pages={[
                            { title: 'Reports', link: '' }
                        ]}
                        active="Earnings Report"
                    />
                </div>
            </div>

            <ModalPopup
                show={betView}
                size="lg"
                onHide={() => setBetView(false)}
                heading={`Bet Report`}
            >
                <DataTableBase
                    columns={betDetailsColumns}
                    data={betData}
                />
            </ModalPopup>

            {/* ==================== Players Details ================= */}

            <div className="row">
                <div className="col-lg-12">
                    <div className="card">
                        <div className="row p-1">
                            <div className="col-lg-6">
                                <p className="card-header">Details of Players</p>
                            </div>
                            {subAgentsData?.length > 0 ?
                                <div className="col-lg-6 text-end">
                                    <button className="btn btn-danger" onClick={() => setPlayerSearch(!playerSearch)}>Search</button>
                                </div> : null}
                        </div>
                        <Collapse in={playerSearch}>
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-lg-4">
                                        <Form.Group className="form-group">
                                            <Form.Label>Search</Form.Label>
                                            <Form.Control
                                                type="text"
                                                onChange={e => setPlayerPostdata({ ...playerpostdata, search: e.target.value })}
                                            />
                                        </Form.Group>
                                    </div>
                                    <div className="col-lg-3">
                                        <Form.Group className="form-group mb-0">
                                            <Form.Label>Sort By</Form.Label>
                                            <Form.Select
                                                value={playerpostdata.sort_by}
                                                onChange={e => setPlayerPostdata({ ...playerpostdata, sort_by: e.target.value })}
                                            >
                                                <option value="totalDeposit" key="sort-key-1">Deposits</option>
                                                <option value="totalWithdraw" key="sort-key-2">Withdraws</option>
                                                <option value="totalBets" key="sort-key-3">Bets Amount</option>
                                                {/* <option value="totalCommisson" key="sort-key-4">Total Commisson</option> */}
                                                <option value="totalWinning" key="sort-key-5">Total Loss</option>
                                                <option value="totalLoss" key="sort-key-6">Total Earning</option>
                                                {/* <option value="netRevenue" key="sort-key-7">Net Revenue</option> */}
                                            </Form.Select>
                                        </Form.Group>
                                    </div>
                                    <div className="col-lg-2">
                                        <Form.Group className="form-group mb-0">
                                            <Form.Label>Sort Order</Form.Label>
                                            <Form.Select
                                                value={playerpostdata.sort_order}
                                                onChange={e => setPlayerPostdata({ ...playerpostdata, sort_order: e.target.value })}
                                            >
                                                <option value="1" key="asc">Asc</option>
                                                <option value="-1" key="desc">Desc</option>
                                            </Form.Select>
                                        </Form.Group>
                                    </div>
                                    <div className="col-lg-3">
                                        <Form.Group className="form-group mb-0">
                                            <Form.Label>Transaction Date</Form.Label>
                                            <Form.Select onChange={e => setPlayerPostdata({ ...playerpostdata, search_by_date: e.target.value })}
                                            value={playerpostdata.search_by_date}
                                            >
                                                <option value="">All</option>
                                                <option value="today">Today</option>
                                                <option value="week">Last Week</option>
                                                <option value="month">Last Month</option>
                                                <option value="year">Last Year</option>
                                                <option value="range">Select Date</option>
                                            </Form.Select>
                                        </Form.Group>
                                    </div>
                                    {playerpostdata.search_by_date === 'range' ?
                                        <>
                                            <div className="col-lg-3">
                                                <Form.Group className="form-group mb-0">
                                                    <Form.Label>From Date</Form.Label>
                                                    <Form.Control
                                                        type="date"
                                                        onChange={e => setPlayerPostdata({ ...playerpostdata, player_search_from_date: e.target.value })} 
                                                        value={playerpostdata.player_search_from_date}
                                                    />
                                                </Form.Group>
                                            </div>
                                            <div className="col-lg-3">
                                                <Form.Group className="form-group mb-0">
                                                    <Form.Label>To Date</Form.Label>
                                                    <Form.Control
                                                        type="date"
                                                        onChange={e => setPlayerPostdata({ ...playerpostdata, player_search_to_date: e.target.value })}
                                                        value={playerpostdata.player_search_to_date}
                                                    />
                                                </Form.Group>
                                            </div>
                                        </>
                                        : ''}
                                </div>
                            </div>
                        </Collapse>
                    </div>

                    <DataTableBase
                        columns={subAgentColumns}
                        loader={isSubAgentLoading}
                        data={subAgentsData}
                        paginationTotalRows={playerTotalRows}
                        onChangeRowsPerPage={handlePlayerPerRowsChange}
                        onChangePage={handlePlayerPageChange}
                    />
                </div>
            </div>

            {/* ============ Agents ================= */}

            <div className="row">
                <div className="col-lg-12">
                    <div className="card">
                        <div className="row p-1">
                            <div className="col-lg-6">
                                <p className="card-header">List of Agent&apos;s</p>
                            </div>
                            <div className="col-lg-6 text-end">
                                <button className="btn btn-danger" onClick={() => setOpen(!open)}>Search</button>
                            </div>
                        </div>
                        <Collapse in={open}>
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-lg-3">
                                        <Form.Group className="form-group mb-0">
                                            <Form.Label>Role</Form.Label>
                                            <Form.Select
                                                value={postdata.search_by}
                                                onChange={e => setPostdata({ ...postdata, search_by: e.target.value })}
                                            >
                                                {roles.map(e => {
                                                    return <option value={e._id} key={e._id}>{e.name}</option>
                                                })}
                                            </Form.Select>
                                        </Form.Group>
                                    </div>
                                    <div className="col-lg-4">
                                        <Form.Group className="form-group">
                                            <Form.Label>Search</Form.Label>
                                            <Form.Control
                                                type="text"
                                                onChange={e => setPostdata({ ...postdata, search: e.target.value })}
                                            />
                                        </Form.Group>
                                    </div>
                                    <div className="col-lg-3">
                                        <Form.Group className="form-group mb-0">
                                            <Form.Label>Sort By</Form.Label>
                                            <Form.Select
                                                value={postdata.sort_by}
                                                onChange={e => { 
                                                    setPostdata({ ...postdata, sort_by: e.target.value })

                                                    setPlayerPostdata({ ...playerpostdata, sort_by: e.target.value })
                                                }}
                                            >
                                                <option value="totalDeposits" key="sort-key-1">Deposits</option>
                                                <option value="totalWithdrawals" key="sort-key-2">Withdraws</option>
                                                <option value="totalBets" key="sort-key-3">Bets Amount</option>
                                                {/* <option value="totalCommisson" key="sort-key-4">Total Commisson</option> */}
                                                <option value="totalWinning" key="sort-key-5">Total Loss</option>
                                                <option value="totalLoss" key="sort-key-6">Total Earning</option>
                                                {/* <option value="netRevenue" key="sort-key-7">Net Revenue</option> */}

                                            </Form.Select>
                                        </Form.Group>
                                    </div>
                                    <div className="col-lg-2">
                                        <Form.Group className="form-group mb-0">
                                            <Form.Label>Sort Order</Form.Label>
                                            <Form.Select
                                                value={postdata.sort_order}
                                                onChange={e => { 
                                                    setPostdata({ ...postdata, sort_order: e.target.value })

                                                    setPlayerPostdata({ ...playerpostdata, sort_order: e.target.value })
                                                }}
                                            >
                                                <option value="1" key="asc">Asc</option>
                                                <option value="-1" key="desc">Desc</option>
                                            </Form.Select>
                                        </Form.Group>
                                    </div>
                                    <div className="col-lg-3">
                                        <Form.Group className="form-group mb-0">
                                            <Form.Label>Transaction Date</Form.Label>
                                            <Form.Select onChange={e => {
                                                setPostdata({ ...postdata, search_by_date: e.target.value })

                                                setPlayerPostdata({ ...playerpostdata, search_by_date: e.target.value })
                                                }} value={postdata.search_by_date} >
                                                <option value="">All</option>
                                                <option value="today">Today</option>
                                                <option value="week">Last Week</option>
                                                <option value="month">Last Month</option>
                                                <option value="year">Last Year</option>
                                                <option value="range">Select Date</option>
                                            </Form.Select>
                                        </Form.Group>
                                    </div>
                                    {postdata.search_by_date === 'range' ?
                                        <>
                                            <div className="col-lg-3">
                                                <Form.Group className="form-group mb-0">
                                                    <Form.Label>From Date</Form.Label>
                                                    <Form.Control
                                                        type="date"
                                                        onChange={e => {
                                                            setPostdata({ ...postdata, from_date: e.target.value })

                                                            setPlayerPostdata({ ...playerpostdata,player_search_from_date: e.target.value })
                                                        }}
                                                    />
                                                </Form.Group>
                                            </div>
                                            <div className="col-lg-3">
                                                <Form.Group className="form-group mb-0">
                                                    <Form.Label>To Date</Form.Label>
                                                    <Form.Control
                                                        type="date"
                                                        onChange={e => { setPostdata({ ...postdata, to_date: e.target.value })

                                                        setPlayerPostdata({ ...playerpostdata, player_search_to_date: e.target.value })
                                                    }}
                                                    />
                                                </Form.Group>
                                            </div>
                                        </>
                                        : ''}
                                    <div className="col-lg-3">
                                        <button style={{marginTop : '20px'}} className="btn btn-success" onClick={() => setStartSearch(!startSearch)}>Submit</button>
                                        <button style={{marginTop : '20px'}} className="btn btn-danger" onClick={() => { setPostdata({
                                            search_by: '',
                                            sort_by: 'grossRevenue',
                                            sort_order: '1',
                                            search_by_date: 'month'
                                        })
                                        setStartSearch(!startSearch)
                                        }}>Reset</button>
                                    </div>
                                </div>
                            </div>
                        </Collapse>
                    </div>
                    <DataTableBase
                        columns={columns}
                        loader={isloading}
                        data={data}
                        paginationTotalRows={totalRows}
                        onChangeRowsPerPage={handlePerRowsChange}
                        onChangePage={handlePageChange}
                    />
                </div>
            </div>

        </>
    )
}

export { Earning_Report }