import DataTableBase from "../../common/DataTableBase";
import Title from "../../common/Title";
import { Collapse, Form } from "react-bootstrap";
import { useState, useEffect } from "react";
import { Postdata } from '../../../utils/api.service';
import Link from "../../common/Link";
import HttpService from "../../../utils/http.service";
import { useDispatch } from "react-redux";
import { message } from "../../../redux/actions/toastActions";
import { ConvertToDMY } from "../../../utils/Helper";
import Breadcrumb from "../../common/Breadcrumb";
import ModalPopup from '../../common/Modal';
import CSV from "../../common/CSV";

const AgentManagement = () => {
  const [roles, setRoles] = useState([]);
  const [data, setData] = useState([]);
  const [isloading, setIsloading] = useState(true);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setperPage] = useState(process.env.REACT_APP_RecordPerPage);
  const [currentPage, setcurrentPage] = useState(1);
  const [refresh, setRefresh] = useState(false);
  const [search, setSearch] = useState({
    search: '',
    role: ''
  });
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();

  const [modalswitch, setmodalswitch] = useState(false);
  const [modalswitchcontent, setmodalswitchcontent] = useState([0, 0]);

  const columns = [
    {
      name: '#',
      width: "55px",
      selector: (row, i) => (currentPage * perPage + (i + 1) - perPage),
    },
    {
      name: 'Name',
      selector: row => row.name,
    },

    {
      name: 'Role Type',
      selector: row => row.role_id.name,
    },
    {
      name: 'Email',
      selector: row => row.email,
    },
    {
      name: 'Created Date',
      selector: row => ConvertToDMY(row.createdAt),
    },
    {
      name: 'Status',
      cell: (row) =>
        <Link permission={'/agent-management/add'} status={row.status}>
          <Form.Check
            type="switch"
            checked={row.status}
            onChange={e => handleStatusChange(row._id, e.target.checked)}
          />
        </Link>,
      button: true,
    },
    {
      name: 'Action',
      id: 'actions',
      cell: (row) =>
        <span className="actions">
          <Link to={`/agent-management/edit/${row._id}`}><i className="mdi mdi-pen" /></Link>
          <Link to={`/agent-management/view/${row._id}`}><i className="mdi mdi-eye" /></Link>
        </span>,
      button: true,
    }
  ];

  const handleStatusChange = (id, status) => {
    setmodalswitchcontent([id, status]);
    setmodalswitch(true);
  };

  const handleStatusChangeAction = () => {
    setmodalswitch(false);
    setIsloading(true);
    Postdata(HttpService.Agent_add, {
      id: modalswitchcontent[0],
      status: Number(modalswitchcontent[1])
    }).then((res) => {
      setRefresh(!refresh);
      dispatch(message(res.message));
    });
  }

  const handlePerRowsChange = async (newPerPage, page) => {
    setperPage(newPerPage);
    setcurrentPage(page);
  };

  const handlePageChange = (page) => {
    setcurrentPage(page);
  };

  useEffect(() => {
    setIsloading(true);
    const postBody = Object.fromEntries(Object.entries({
      ...search,
      page: currentPage,
      perpage: perPage,
    }).filter(([, value]) => value !== ''));
    Postdata(HttpService.Agent_list, postBody).then((response) => {
      if (response.status === 200) {
        setRoles(response.data.search_roles);
        setData(response.data.users);
        setTotalRows(response.data.count);
        setIsloading(false);
      } else {
        setIsloading(false);
        dispatch(message(response.message));
      }
    }).catch((e) => {
      setIsloading(false);
      dispatch(message(e.message));
    });
  }, [currentPage, perPage, refresh, search]);

  return (
    <>
      <Title name='Member Management' />

      <ModalPopup
        className="alert-box-popup"
        show={modalswitch}
        size="sm"
        onHide={() => setmodalswitch(false)}
        heading="Are you sure?"
      >
        <i className="mdi mdi-check"></i>
        <p>You want to change this status!</p>
        <div className='actions'>
          <button className="btn btn-primary" onClick={() => handleStatusChangeAction()}>Yes, Change Status</button>
          <button className="btn btn-danger" onClick={() => setmodalswitch(false)}>Cancel</button>
        </div>
      </ModalPopup>

      <div className="row">
        <div className="col-lg-6">
          <h4>Member Management</h4>
          <Breadcrumb
            active="Member Management"
          />
        </div>
        <div className="col-lg-6 text-end">
          <Link to={'/agent-management/add'} className="btn btn-primary">Add New Member</Link>
          <button className="btn btn-danger" onClick={() => setOpen(!open)}>Search</button>
          <CSV
            link={HttpService.Agent_list}
            name="Member List"
            response="users"
            headers={[
              { label: "ID", key: "_id" },
              { label: "Name", key: "name" },
              // { label: "Username", key: "username" },
              { label: "Email", key: "email" },
              { label: "Created Date", key: "createdAt" },
            ]}
          />
        </div>
      </div>

      <Collapse in={open}>
        <div>
          <div className="card">
            <p className="card-header">Search</p>
            <div className="card-body">
              <div className="row">
                <div className="col-md-6">
                  <Form.Group className="form-group mb-0">
                    <Form.Label>Search</Form.Label>
                    <Form.Control
                      type="text"
                      onChange={e => setSearch({ ...search, search: e.target.value })}
                      required
                    />
                  </Form.Group>
                </div>
                <div className="col-md-6">
                  <Form.Group className="form-group">
                    <Form.Label>Search By Role Type</Form.Label>
                    <Form.Select onChange={e => setSearch({ ...search, role: e.target.value })}>
                      <option value="">All</option>
                      {roles.map((s) => {
                        return <option value={s._id} key={s._id}>{s.name}</option>
                      })}
                    </Form.Select>
                  </Form.Group>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Collapse>

      <div className="card">
        <p className="card-header">List of Members</p>
        <div className="card-body">
          <DataTableBase
            columns={columns}
            loader={isloading}
            data={data}
            paginationTotalRows={totalRows}
            onChangeRowsPerPage={handlePerRowsChange}
            onChangePage={handlePageChange}
          />
        </div>
      </div>
    </>
  )
}

export { AgentManagement }