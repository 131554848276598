/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import Title from "../../common/Title";
import { useState, useEffect } from "react";
import { Postdata } from '../../../utils/api.service';
import DataTableBase from "../../common/DataTableBase";
import Link from "../../common/Link";
import HttpService from "../../../utils/http.service";
import { useDispatch } from "react-redux";
import { message } from "../../../redux/actions/toastActions";
import Breadcrumb from "../../common/Breadcrumb";
import { useParams } from "react-router-dom";
import Loader from "../../common/Loader";
import { ConvertToDMYHIS } from "../../../utils/Helper";
import ModalPopup from "../../common/Modal";
import { Form } from "react-bootstrap";

const ContestPrice = (props) => {
  const dispatch = useDispatch();

  const [disable, setDisable] = useState(false);
  const [loader, setLoader] = useState(false);
  const [modalswitch, setmodalswitch] = useState(false);
  const [popup, setpopup] = useState({
    from: '',
    to: '',
    price: '',
  });

  const [winnings, setwinnings] = useState({});
  const [formdata, setFormdata] = useState({
    title: '',
    match_id: props.match_id,
    game_type: props.match,
    type: 'manual',
    no_of_winners: '',
    total_team: '',
    prize_pool: '',
    entry_fee: '',
    tagline: '',
    description: '',
    status: 1,
    price: [],
  });

  useEffect(() => {
    setDisable(false);
    if (parseFloat(formdata.total_team) < parseFloat(formdata.no_of_winners)) setDisable(true);
    if (!formdata.price.length) setDisable(true);
    formdata.price.map(e => {
      if (!e.price) setDisable(true);
    });
  }, [formdata])

  useEffect(() => {
    const obj = {};
    if (parseFloat(formdata.no_of_winners)) {
      for (let index = 1; index <= parseFloat(formdata.no_of_winners); index++) obj["key_" + index] = '';
    }
    setwinnings(obj);
  }, [formdata.no_of_winners])

  const onUpdatePrize = (event) => {
    event.preventDefault();
    const obj = winnings;
    for (let index = parseFloat(popup.from); index <= parseFloat(popup.to); index++) obj["key_" + index] = popup.price;
    setwinnings(obj);
    setmodalswitch(false);
    setpopup({
      from: '',
      to: '',
      price: '',
    });

    const priceRange = [];
    let lastprice = false;
    for (let index = 1; index <= parseFloat(formdata.no_of_winners); index++) {
      if (winnings["key_" + index] !== lastprice) {
        lastprice = winnings["key_" + index];
        priceRange.push({ from: index, to: 0, price: lastprice });
      }
    }
    priceRange.forEach((e, i) => {
      priceRange[i].to = priceRange[i + 1] ? (priceRange[i + 1].from - 1) : formdata.no_of_winners;
    })

    setFormdata({ ...formdata, price: priceRange })
  }

  const onAddContestAction = (event) => {
    event.preventDefault();
    setLoader(true);
    Postdata(HttpService.Contest_add, { ...formdata, price: JSON.stringify(formdata.price) }).then((res) => {
      if (res.status === 200) {
        props.closeModal(1);
      }
      dispatch(message(res.message));
    });
  };

  return (
    <div className="row">
      <ModalPopup
        show={modalswitch}
        size="sm"
        onHide={() => setmodalswitch(false)}
        heading="Add Price"
      >
        <form onSubmit={onUpdatePrize}>
          <Form.Group className="form-group">
            <Form.Label>From Rank*</Form.Label>
            <Form.Control
              min='1'
              autoFocus
              max={formdata.no_of_winners ? formdata.no_of_winners : 0}
              type="number"
              value={popup.from}
              onChange={e => setpopup({ ...popup, from: e.target.value, to: e.target.value })}
              required
            />
          </Form.Group>

          <Form.Group className="form-group">
            <Form.Label>To Rank*</Form.Label>
            <Form.Control
              min={popup.from}
              max={formdata.no_of_winners ? formdata.no_of_winners : 0}
              type="number"
              value={popup.to}
              onChange={e => setpopup({ ...popup, to: e.target.value })}
              required
            />
          </Form.Group>

          <Form.Group className="form-group">
            <Form.Label>Price*</Form.Label>
            <Form.Control
              min='1'
              type="number"
              value={popup.price}
              onChange={e => setpopup({ ...popup, price: e.target.value })}
              required
            />
          </Form.Group>

          <button className="btn btn-primary" type="submit">Save</button>
          <button type="button" className="btn btn-danger" onClick={() => setmodalswitch(false)}>Cancel</button>
        </form>
      </ModalPopup>

      <div className={`col-lg-${formdata.no_of_winners > 0 ? 9 : 12}`}>

        <form onSubmit={onAddContestAction}>
          <Form.Group className="form-group">
            <Form.Label>Title*</Form.Label>
            <Form.Control
              type="text"
              value={formdata.title}
              onChange={e => setFormdata({ ...formdata, title: e.target.value })}
              required
            />
          </Form.Group>

          <Form.Group className="form-group">
            <Form.Label>No. of Winners*</Form.Label>
            <Form.Control
              min='1'
              type="number"
              value={formdata.no_of_winners}
              onChange={e => setFormdata({ ...formdata, no_of_winners: e.target.value, price: [] })}
              required
            />
          </Form.Group>

          <Form.Group className="form-group">
            <Form.Label>Total Team*</Form.Label>
            <Form.Control
              min={formdata.no_of_winners}
              type="number"
              value={formdata.total_team}
              onChange={e => setFormdata({ ...formdata, total_team: e.target.value })}
              required
            />
            {disable && (parseFloat(formdata.total_team) < parseFloat(formdata.no_of_winners)) && <small>Total Team should not be greater than No. of Winners</small>}
          </Form.Group>

          <Form.Group className="form-group">
            <Form.Label>Prize Pool*</Form.Label>
            <Form.Control
              min='1'
              type="number"
              value={formdata.prize_pool}
              onChange={e => setFormdata({ ...formdata, prize_pool: e.target.value })}
              required
            />
          </Form.Group>

          <Form.Group className="form-group">
            <Form.Label>Entry Fee*</Form.Label>
            <Form.Control
              min='1'
              type="number"
              value={formdata.entry_fee}
              onChange={e => setFormdata({ ...formdata, entry_fee: e.target.value })}
              required
            />
          </Form.Group>

          <Form.Group className="form-group">
            <Form.Label>Tagline</Form.Label>
            <Form.Control
              type="text"
              value={formdata.tagline}
              onChange={e => setFormdata({ ...formdata, tagline: e.target.value })}
            />
          </Form.Group>

          <Form.Group className="form-group">
            <Form.Label>Description</Form.Label>
            <Form.Control
              as="textarea"
              value={formdata.description}
              onChange={e => setFormdata({ ...formdata, description: e.target.value })}
            />
          </Form.Group>

          <button className="btn btn-primary" type="submit" disabled={loader || disable}>
            {loader ? 'Please wait...' : (formdata.id ? 'Update' : 'Submit')}
          </button>
          <button type="button" className="btn btn-danger" onClick={() => props.closeModal(0)}>Cancel</button>
        </form>
      </div>

      <div className="col-lg-3">
        {formdata.no_of_winners ?
          <div className="card">
            <p className="card-header">Winning Prize</p>
            <div className="card-body">
              <>
                <button className="btn btn-danger" onClick={() => setmodalswitch(true)}>Add Price</button>

                <div className="price_panel">
                  {Object.entries(winnings).map((e, i) => {
                    return <p key={e[0]}><span>Rank {i + 1}</span><b>{e[1]}</b></p>
                  })}
                </div>
              </>
            </div>
          </div>
          : ''}
      </div>
    </div>
  )
}

const MatchContest = () => {
  const [data, setData] = useState([]);
  const [match, setMatch] = useState([]);
  const [refresh, setrefresh] = useState(true);
  const [isloading, setIsloading] = useState(true);
  const dispatch = useDispatch();
  const { id } = useParams();
  const [editModal, setEditModal] = useState(false)
  const [editData, setEditData] = useState({})

  const [modalview, setmodalview] = useState(false);

  const columns = [
    {
      name: '#',
      width: "55px",
      selector: (row, i) => i + 1,
    },
    {
      name: 'Name',
      selector: row => row.contest.title,
    },
    {
      name: 'Winners',
      selector: row => row.contest.no_of_winners,
    },
    {
      name: 'Prize Pool',
      selector: row => row.contest.prize_pool,
    },
    {
      name: 'Total Team',
      selector: row => row.contest.total_team,
    },
    {
      name: 'Join Team',
      selector: row => row.total_joined,
    },
    {
      name: 'Entry Fees',
      selector: row => row.contest.entry_fee,
    },
    {
      name: 'Action',
      id: 'actions',
      cell: (row) =>
        <span className="actions">
          <i className="mdi mdi-pen" onClick={() => {
            setEditData(row.contest)
            setEditModal(true)
            console.log(row)
          }} />
          <Link to={`/match/users/${row._id}`}><i className="mdi mdi-eye" /></Link>
        </span>,
      button: true,
    }
  ];

  useEffect(() => {
    setIsloading(true);
    Postdata(HttpService.Match_contest, { id: id }).then((response) => {
      if (response.status === 200) {
        setData(response.data.records);
        setMatch(response.data.match);
        setIsloading(false);
      } else {
        setIsloading(false);
        dispatch(message(response.message));
      }
    }).catch((e) => {
      setIsloading(false);
      dispatch(message(e.message));
    });
  }, [refresh]);

  const closeModal = (type) => {
    setmodalview(false);
    setEditModal(false);
    type && setrefresh(!refresh);
  }

  const onContestEdit = (event) => {
    event.preventDefault();
    let data = editData
    let _id = editData._id
    delete data._id
    Postdata(HttpService.Contest_add, { id : _id,  ...data}).then((res) => {
      if (res.status === 200) {
        closeModal(1);
      }
      dispatch(message(res.message));
    });
  }

  return (
    <>
      <Title name='Match Management' />

      <ModalPopup
        show={modalview}
        size="lg"
        fullscreen
        onHide={() => setmodalview(false)}
        heading="Add Contest"
      >
        <ContestPrice
          match={match.sports}
          match_id={match._id}
          closeModal={closeModal}
        />
      </ModalPopup>

      <ModalPopup
        show={editModal}
        size="md"
        onHide={() => setEditModal(false)}
        heading="Edit Contest"
      >
        <form onSubmit={onContestEdit}>
          <Form.Group className="form-group">
            <Form.Label>Title</Form.Label>
            <Form.Control
              type="text"
              value={editData.title}
              onChange={e => setEditData({ ...editData, title: e.target.value })}
              required
            />
          </Form.Group>
          <button className="btn btn-primary" type="submit">
            Update
          </button>
        </form>
      </ModalPopup>

      <div className="row">
        <div className="col-lg-6">
          <h4>Match Management</h4>
          <Breadcrumb
            pages={[
              { title: 'Match Management', link: 'match' }
            ]}
            active='Contest'
          />
        </div>
        <div className="col-lg-6 text-end">
          <button className="btn btn-primary" onClick={() => setmodalview(true)}>Add Contest</button>
        </div>
      </div>

      <div className="row">
        <div className="col-lg-3">
          <div className="card">
            <p className="card-header">Match Details</p>
            <div className="card-body">
              {isloading ?
                <Loader />
                : <>
                  <div className="profile-page">
                    <div className="user">
                      <div className="details removeStyle">
                        <span className="text-cap"><b>Sport:</b>{match.sports}&nbsp;</span>
                        <span><b>Match:</b>{match.short_title}&nbsp;</span>
                        <span><b>Team A:</b>{match.teama?.name}&nbsp;</span>
                        <span><b>Team B:</b>{match.teamb?.name}&nbsp;</span>
                        <span><b>Match Time:</b>{ConvertToDMYHIS(match.date_start_ist)}&nbsp;</span>
                        <span><b>Match Type:</b>{match.format_str}&nbsp;</span>
                        <span><b>Status:</b>{match.status_note}&nbsp;</span>
                      </div>
                    </div>
                  </div>
                </>
              }
            </div>
          </div>
        </div>

        <div className="col-lg-9">
          <div className="card">
            <p className="card-header">List of Contests</p>
            <div className="card-body">
              <DataTableBase
                columns={columns}
                loader={isloading}
                pagination={false}
                data={data}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export { MatchContest }